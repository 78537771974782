/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from '../../../../../../Services/JWTBackend/jwtBackendService';
import Header from '../../../../../../Casino/General/Modal/Header';
import Footer from '../../../../../../Casino/General/Modal/Footer';
import TextField from '../../../../../../Generic/Inputs/TextField';
import Checkbox from '../../../../../../Generic/Inputs/Checkbox';
import { urlToBackgroundUrl } from '../../../../../../Utils/uiUtil'
import { displayGenericErrorNotificationIntl, displayInfoNotification, getAmountTxtWithCoin, isDateString, isDateStringInFuture, listToCSV } from '../../../../../../Utils/uiUtil';
import DatePicker from '../../../../../../Generic/Inputs/DatePicker';
import { convertToMoney, convertTransactionTypeToText } from '../../../../../../Utils/converterUtil';
import { getImageUrl } from '../../../../../../Utils/uiUtil';

function TxInfo(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const TYPE_PLAY_GAME = 1;

    const [txType, setTxType] = useState(-1);
    const [gameName, setGameName] = useState('');
    const [amount, setAmount] = useState(0);
    const [type, setType] = useState(-1);
    const [gameImageUrl, setGameImageUrl] = useState(getImageUrl('newui/ManageGames/nogameImage.png'));

    useEffect(() => {
        loadTxInfo()
    }, [])

    function loadTxInfo() {
        jwtBackendService.getTransactionInformation(props.txId, props.walletId, success, failure, navigate, location.pathname);
    }

    function success(data) {
        if (data.gameName !== null && data.gameName !== undefined && data.gameName !== '') {
            setTxType(TYPE_PLAY_GAME);
            setGameName(data.gameName);
            setGameImageUrl(data.gameURL);
        }

        setAmount(convertToMoney(data.amount, props.coin));
        setType(convertTransactionTypeToText(data.type, intl));

    }

    function failure(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    try {
        var header = null;
        var footer = null;

        header = <Header title={intl.formatMessage({ id: 'newcasino.admin.user.popup.tx.title', defaultMessage: 'Transaction Details' })} closeModal={props.closeModal} />
        footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.admin.user.popup.tx.cancel', defaultMessage: 'Close' }), action: props.closeModal }} />

        return (
            <>
                <div>
                    <div>
                        {header}
                    </div>
                    {(txType === TYPE_PLAY_GAME) &&
                        <div className={style.gameDetail}>
                            <div className={style.gameName}>
                                {gameName}
                            </div>
                            <div className={style.gameImage}>
                                <div className={style.middle}>
                                    <img className={style.theImg} src={gameImageUrl} alt='' onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = getImageUrl('newui/ManageGames/nogameImage.png');
                                    }} />
                                </div>
                            </div>

                        </div>
                    }
                    <div className={style.amount}>
                        {amount}
                    </div>
                    <div className={style.type}>
                        {type}
                    </div>
                    <div>
                        {footer}
                    </div>
                </div >
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default TxInfo;
