/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl'

import { setTheme } from '../../../../Utils/themeUtils';
import style from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl'
import * as jwtBackendService from '../../../../Services/JWTBackend/jwtBackendService'
import DivClick from '../../../../Generic/Inputs/DivClick';
import Documents from './Documents';
import PersoInfo from './PersoInfo';
import { displayGenericErrorNotificationIntl } from '../../../../Utils/uiUtil';
import Wallets from './Wallets';
import { displayPage } from '../../../../Utils/routerUtil';
import * as constants from '../../../../constants.js'
import { getImageUrl } from '../../../../Utils/uiUtil';
import PNGPoints from './PNGPoints';

function SingleUserManagement(props) {
    const TAB_INFO = 0;
    const TAB_DOCS = 1;
    const TAB_PNG_POINTS = 2;
    const TAB_WALLETS = 3;

    const [personalData, setPersonalData] = useState(null);

    const [realMoneyWallet, setRealMoneyWallet] = useState(null);
    const [wallets, setWallets] = useState([]);
    const [loyaltyLevel, setLoyaltyLevel] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [transactionsMetaData, setTransactionsMetaData] = useState({});

    const [refresh, setRefresh] = useState(Math.random());

    const [countries, setCountries] = useState(null);

    const [showedTab, setShowedTab] = useState(TAB_INFO);
    const [pngPointHistoric, setPngPointHistoric] = useState([]);
    const [pngDailyHistoric, setPngDailyHistoric] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();

    const userId = props.id;

    useEffect(() => {
        loadCountries()
        loadUserInfo()
    }, [])


    function loadCountries() {
        jwtBackendService.casino_get_country_list(successGetCountryList, failureGetCountryList, navigate, location.pathname)
    }

    function successGetCountryList(data) {
        setCountries(data)
    }

    function failureGetCountryList(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function loadUserInfo() {
        jwtBackendService.getUserInformation(userId, successLoadUserData, failureLoadUserData, navigate, location.pathname)
    }

    function successLoadUserData(data) {
        setPersonalData(data[0][0])
        setWallets(data[1]);

        try {
            for (let i = 0; i < data[1].length; i++) {
                if (data[1][i].walletType === 0 && data[1][i].walletState === 1) {
                    setRealMoneyWallet(data[1][i]);
                }
            }
        } catch (err) {
            console.log('Error: ' + err.message);
        }

        setTransactions(data[2]);
        setTransactionsMetaData(data[3][0]);
        setPngPointHistoric(data[4])
        setPngDailyHistoric(data[5])
        setShowedTab(TAB_INFO)
        setRefresh(Math.random());
        setLoyaltyLevel(data[6][0].level);
    }

    function failureLoadUserData(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function back() {
        displayPage(constants.ROUTE_USER_MANAGEMENT, navigate, location.pathname);
    }
    function reload() {
        loadUserInfo();
    }

    if (personalData && countries) {
        return (
            <>
                <div className={style.master_page_container}>
                    <div className={style.master_grid}>
                        <div>
                            <div className={style.grid50}>
                                <DivClick className={style.arrow} onClick={back}>
                                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                                </DivClick>
                                <div className={style.title_txt}>
                                    {personalData.email}
                                </div>
                            </div>
                            <img className={style.separator} src={setTheme(getImageUrl('newui/themes/{THEME}/Raf/bottomline.png'))}></img>
                            <div>
                                <div className={style.tabLine}>
                                    <DivClick className={(showedTab === TAB_INFO) ? style.tabSelected : style.tabNotSelected} onClick={() => setShowedTab(TAB_INFO)}>
                                        <FormattedMessage id="newcasino.profile.tab.personal" defaultMessage="Personal Information" /></DivClick>
                                    <div className={style.pipe} />
                                    <DivClick className={(showedTab === TAB_DOCS) ? style.tabSelected : style.tabNotSelected} onClick={() => setShowedTab(TAB_DOCS)}>
                                        <FormattedMessage id="newcasino.profile.tab.docs" defaultMessage="Identity Documents" />
                                    </DivClick>
                                    <div className={style.pipe} />
                                    <DivClick className={(showedTab === TAB_PNG_POINTS) ? style.tabSelected : style.tabNotSelected} onClick={() => setShowedTab(TAB_PNG_POINTS)}>
                                        <FormattedMessage id="newcasino.profile.tab.pngpoints" defaultMessage="PNG Points" />
                                    </DivClick>
                                    <div className={style.pipe} />
                                    <DivClick className={(showedTab === TAB_WALLETS) ? style.tabSelected : style.tabNotSelected} onClick={() => setShowedTab(TAB_WALLETS)}>
                                        <FormattedMessage id="newcasino.profile.tab.wallets" defaultMessage="Wallets" />
                                    </DivClick>
                                </div>
                                {showedTab === TAB_INFO &&
                                    <PersoInfo key={'Perso-' + refresh} reload={reload} closeModal={props.closeModal} displayGenericModal={props.displayGenericModal} personalData={personalData} countries={countries} />
                                }
                                {showedTab === TAB_DOCS &&
                                    <Documents reload={reload} personalData={personalData} />
                                }
                                {showedTab === TAB_PNG_POINTS &&
                                    <PNGPoints reload={reload} closeModal={props.closeModal} loyaltyLevel={loyaltyLevel} displayGenericModal={props.displayGenericModal} personalData={personalData} realMoneyWallet={realMoneyWallet} pngPointHistoric={pngPointHistoric} pngDailyHistoric={pngDailyHistoric} transactions={transactions} transactionsMetaData={transactionsMetaData} coin={personalData.coin} />
                                }
                                {showedTab === TAB_WALLETS &&
                                    <Wallets reload={reload} closeModal={props.closeModal} displayGenericModal={props.displayGenericModal} personalData={personalData} wallets={wallets} transactions={transactions} transactionsMetaData={transactionsMetaData} coin={personalData.coin} />
                                }
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}
export default SingleUserManagement;