import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';
import * as jwtBackendService from '../../../../Services/JWTBackend/jwtBackendService'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import { setTheme } from '../../../../Utils/themeUtils'
import { displayErrorNotificationIntl, displayGenericErrorNotificationIntl, displayInfoNotificationIntl, getImageUrl } from '../../../../Utils/uiUtil';
import DivClick from '../../../../Generic/Inputs/DivClick';
import { displayPage } from '../../../../Utils/routerUtil';
import * as constants from '../../../../constants.js'
import TextField from '../../../../Generic/Inputs/TextField';
import Button from '../../../../Generic/Inputs/Button';
import Checkbox from '../../../../Generic/Inputs/Checkbox';
import NonMemoryList from '../../../../Generic/Inputs/NonMemoryList';
import { convertToMoney } from '../../../../Utils/converterUtil';

function PromotionCodeViewer(props) {

    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    const [sortValue, setSortValue] = useState('email');
    const [sortOrder, setSortOrder] = useState(0);


    const [loading, setLoading] = useState(true);
    const [currentPromotion, setCurrentPromotion] = useState(null);
    const [statistics, setStatistics] = useState(null);
    const [statisticsList, setStatisticsList] = useState(null);
    const [statisticsListMeta, setStatisticsListMeta] = useState(null);

    const params = useParams();

    const WHO_TYPE_PLAYERS = 0;
    const WHO_TYPE_PLAYERS_VERIFIED = 1;
    const WHO_TYPE_PLAYERS_ALREADY_DEPOSIT = 2;
    const WHO_TYPE_SPECIFIC_LIST = 3;

    const WHEN_TYPE_ON_USE = 1;
    const WHEN_TYPE_ON_DEPOSIT = 2;

    const PROMO_TYPE_DEPOSIT_XTIME_1 = 1;
    const PROMO_TYPE_INSTANT_XTIME_2 = 2;

    const columnsDefinition = [
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.wager.tx.col1', defaultMessage: 'Email' }), width: '*', hintColumn: 'userId', listColumn: 'email', canSort: true, sortValue: 'email' },
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.wager.tx.col2', defaultMessage: 'Coin' }), width: '100px', listColumn: 'coin', canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.wager.tx.col3', defaultMessage: 'Balance' }), width: '100px', listColumn: 'balance', transformerObj: true, transformer: toMoney, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.wager.tx.col4', defaultMessage: 'Target' }), width: '100px', listColumn: 'wagerTarget', transformerObj: true, transformer: toMoney, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.wager.tx.col5', defaultMessage: 'Prize' }), width: '100px', listColumn: 'wagerWinBalance', transformerObj: true, transformer: toMoney, canSort: true, sortValue: 'wagerWinBalance' }
    ];

    useEffect(() => {
        let id = params.id
        getPromotionCodeInformation(id, 1, null, null);
    },// eslint-disable-next-line
        [])

    function getPromotionCodeInformation(id, pageNumber, sortColumn, sortAsc) {
        jwtBackendService.getPromotionCode(id, pageNumber, sortColumn, sortAsc, onSuccessPromotionList, onFailurePromotionList, navigate, location.pathname);
    }

    function onSuccessPromotionList(data) {
        setLoading(false)
        setCurrentPromotion(data[0][0]);

        if (data[0][0].type === 1) { //Bonus Deposit
            setStatistics(data[2][0]);
            setStatisticsList(data[3]);
            setStatisticsListMeta(data[4][0]);
        }

        else if (data[0][0].type === 2) { //GIFT
            if (data.length > 3) {
                setStatistics(data[2][0]);
            }

            if (data.length > 4) {
                setStatisticsList(data[3]);
                setStatisticsListMeta(data[4][0]);
            }
        }

    }

    function onFailurePromotionList(data) {
        setLoading(false)
        displayGenericErrorNotificationIntl(intl)
    }

    function getDetailOnDepositBonus(details, index) {
        let toReturn = '';

        if (index === 1) {

            let value = Number(details.split(",")[index]);
            if (value === PROMO_TYPE_DEPOSIT_XTIME_1) {
                toReturn = intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.double', defaultMessage: 'Double' });
            } else if (value === PROMO_TYPE_INSTANT_XTIME_2) {
                toReturn = intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.triple', defaultMessage: 'Triple' });
            }
        }
        else if (index === 2) {
            toReturn = details.split(",")[index];
        }

        return toReturn;
    }

    function getDetail(details, index) {
        return details.split(",")[index];
    }

    function getGiftType(details) {
        return Number(getDetail(details, 0));
    }

    function getGiftTypeTxt(details) {
        let type = Number(getDetail(details, 0));

        if (type === 1) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.cash', defaultMessage: 'Money' });
        } else if (type === 2) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.png', defaultMessage: 'Png Points' });
        } else if (type === 3) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.freespin', defaultMessage: 'Freespins' });
        } else if (type === 4) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager', defaultMessage: 'Wager' });
        } else if (type === 5) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.promo.deposit', defaultMessage: 'Deposit Bonus' });
        }

        return ' - ';
    }

    function getAudience(audience) {
        if (audience === WHO_TYPE_PLAYERS) {
            return intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.allReg', defaultMessage: 'All Registred Players' })
        } else if (audience === WHO_TYPE_PLAYERS_VERIFIED) {
            return intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.allVerif', defaultMessage: 'All Verified Players' });
        } else if (audience === WHO_TYPE_PLAYERS_ALREADY_DEPOSIT) {
            return intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.allDeposit', defaultMessage: 'All players who have already deposited' });
        } else if (audience === WHO_TYPE_SPECIFIC_LIST) {
            return intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.specific', defaultMessage: 'Specific List' });
        }

        return 'N/A';
    }

    function getMinLevel(minLevel) {
        if (minLevel === 0) {
            return intl.formatMessage({ id: 'newcasino.admin.promo.combo.minLevel.none', defaultMessage: 'None' })
        } else {
            return minLevel;
        }
    }

    function getHowMuch(howMuch) {
        if (howMuch === 0) {
            return intl.formatMessage({ id: 'newcasino.admin.promo.combo.howmuch.illimited', defaultMessage: 'Illimited' })
        } else {
            return howMuch;
        }
    }

    function getWhen(when) {
        if (when === WHEN_TYPE_ON_USE) {
            return intl.formatMessage({ id: 'newcasino.admin.promo.combo.when.use', defaultMessage: 'On use of promotional code' })
        } else if (when === WHEN_TYPE_ON_DEPOSIT) {
            return intl.formatMessage({ id: 'newcasino.admin.promo.combo.when.deposit', defaultMessage: 'On deposit' });
        }

        return 'N/A';
    }

    function getPromoType() {
        return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.promo.code', defaultMessage: 'Promotion Code' });
    }

    function callOnSuccess(data) {
        if (data[0].status === 'OK') {
            displayInfoNotificationIntl(intl, 'newcasino.promotions.admin.prom.mod.success');
            back();
        } else {
            if (data[0].statusCode === 90) {
                displayErrorNotificationIntl(intl, 'newcasino.promotions.admin.prom.mod.samecode');
            } else {
                displayGenericErrorNotificationIntl(intl)
            }
        }

    }

    function callOnFailed(data) {
        displayGenericErrorNotificationIntl(intl)
    }

    function deleteProm() {
        jwtBackendService.deletePromotionCode(currentPromotion.id, callOnSuccess, callOnFailed, navigate, location.pathname);
    }

    function deactivateProm() {
        jwtBackendService.deactivatePromotionCode(currentPromotion.id, callOnSuccess, callOnFailed, navigate, location.pathname);
    }

    function activateProm() {
        jwtBackendService.activatePromotionCode(currentPromotion.id, callOnSuccess, callOnFailed, navigate, location.pathname);
    }

    function back() {
        displayPage(constants.ROUTE_ADMIN_PROMOTIONS, navigate, location.pathname);
    }

    function successExportToCSV(data) { }

    function failureExportToCSV(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function downloadOri() {
        jwtBackendService.exportPromotionCodeCSV(currentPromotion.id, 1, successExportToCSV, failureExportToCSV, navigate, location.pathname);
    }

    function toMoney(data, obj) {
        return convertToMoney(data, obj.coin);
    }

    function sort(columnDef, order) {
        let newOrder = (sortValue === columnDef.listColumn) ? (sortOrder === 0 ? 1 : 0) : 0;
        let newValue = columnDef.listColumn;
        setSortOrder(newOrder);
        setSortValue(newValue);
        let id = params.id
        getPromotionCodeInformation(id, 1, newValue, newOrder);
    }

    function doNothing() { }

    function changePage(change) {
        let id = params.id
        getPromotionCodeInformation(id, change, sortValue, sortOrder);
    }

    try {
        return (loading === false && currentPromotion) ? (
            <div className={style.master_page_container}>
                <div className={style.topMainDiv}>
                    <div>
                        <div className={style.grid100}>
                            <div className={style.gridBack}>
                                <DivClick className={style.arrow} onClick={back}>
                                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                                </DivClick>

                                <div className={style.case1TopText2}>{currentPromotion.name}</div>
                            </div>
                            <div className={style.case1TopText3}>
                                <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                            </div>
                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.type', defaultMessage: 'Promotion Type' })} values={[getPromoType()]} /></div>
                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.deposit.start', defaultMessage: 'Start Time (London Time)' })} values={[currentPromotion.startDate]} /></div>
                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.deposit.end', defaultMessage: 'End Time (London Time)' })} values={[currentPromotion.endDate]} /></div>
                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.code', defaultMessage: 'Code' })} values={[currentPromotion.code]} /></div>
                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.when', defaultMessage: 'When' })} values={[getWhen(currentPromotion.executionWhen)]} /></div>
                            {
                                (currentPromotion.executionWhen === WHEN_TYPE_ON_DEPOSIT) &&
                                <div className={style.twob}>
                                    <TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.minDepositAmount', defaultMessage: 'Mininum Deposit' })} values={[currentPromotion.minDeposit]} />
                                    <TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.maxDepositAmount', defaultMessage: 'Maximum Deposit' })} values={[currentPromotion.maxDeposit]} />
                                </div>
                            }
                            <div className={style.two}>
                                <TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.howmuch', defaultMessage: 'Maximum usage' })} values={[getHowMuch(currentPromotion.maxUsage)]} />
                                <TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.howmuch.used', defaultMessage: 'Amount of times used' })} values={[currentPromotion.nbrCodeUsed]} />
                            </div>
                            <div className={style.three}>
                                <div className={style.bottomMarge}>
                                    <TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.audience', defaultMessage: 'Receivers' })} values={[getAudience(currentPromotion.audience)]} />
                                </div>
                                {(currentPromotion.audience === WHO_TYPE_SPECIFIC_LIST) &&
                                    <div className={style.bottomButtonSpec}>
                                        <Button width='200px' value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.button.download.original', defaultMessage: 'Download User List' })} onClick={downloadOri} />
                                    </div>
                                }
                            </div>
                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.minlevel', defaultMessage: 'Loyalty Min Level' })} values={[getMinLevel(currentPromotion.minLevel)]} /></div>
                            <div className={style.bottom}><Checkbox checked={currentPromotion.active === 1} label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.active', defaultMessage: 'Active' })} readOnly={true} /></div>
                            <>
                                <div className={style.three}>
                                    <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.gift', defaultMessage: 'Gift Type' })} values={[getGiftTypeTxt(currentPromotion.details)]} /></div>
                                </div>
                                {(getGiftType(currentPromotion.details) === 1) &&
                                    <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.cash.nbr', defaultMessage: 'Cash (Euro)' })} values={[getDetail(currentPromotion.details, 1)]} /></div>
                                }
                                {(getGiftType(currentPromotion.details) === 2) &&
                                    <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.png.nbr', defaultMessage: 'PNG Point' })} values={[getDetail(currentPromotion.details, 1)]} /></div>
                                }
                                {(getGiftType(currentPromotion.details) === 3) &&
                                    <>
                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.freespins.nbr', defaultMessage: 'FreeSpins' })} values={[getDetail(currentPromotion.details, 1)]} /></div>
                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.freespins.expiration', defaultMessage: 'Expiration In Days' })} values={[getDetail(currentPromotion.details, 2)]} /></div>
                                    </>
                                }
                                {(getGiftType(currentPromotion.details) === 4) &&
                                    <>
                                        <div className={style.three}>
                                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.nbr', defaultMessage: 'Wager Amount' })} values={[getDetail(currentPromotion.details, 1)]} /></div>
                                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.x', defaultMessage: 'Wager xTimes' })} values={[getDetail(currentPromotion.details, 2)]} /></div>
                                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.maxwin', defaultMessage: 'Wager Max Win' })} values={[getDetail(currentPromotion.details, 3)]} /></div>
                                        </div>
                                        <div className={style.one}>
                                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.expDate', defaultMessage: 'Wager Expiration Date' })} values={[getDetail(currentPromotion.details, 4)]} /></div>
                                        </div>
                                        {
                                            statistics &&
                                            <>
                                                <div className={style.case1TopText3}>
                                                    <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                                </div>
                                                <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.stats.title', defaultMessage: 'Statistics' })}</div>
                                                <div className={style.five}>
                                                    <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.stats.nbr', defaultMessage: 'Wager Gived' })} values={[statistics.wagerTotal]} /></div>
                                                    <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.stats.inprogress', defaultMessage: 'In Progress' })} values={[statistics.wagerInProgress]} /></div>
                                                    <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.stats.winners', defaultMessage: 'Nbr Winners' })} values={[statistics.wagerWinners]} /></div>
                                                    <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.stats.looser', defaultMessage: 'Nbr Loosers' })} values={[statistics.wagerLosers]} /></div>
                                                    <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.stats.wineuro', defaultMessage: 'Total Wins Euro' })} values={[convertToMoney(statistics.winSoFarEuro, 'EUR')]} /></div>
                                                </div>
                                            </>
                                        }
                                        {(statisticsList) &&
                                            <>
                                                <NonMemoryList key={'DETAILS_' + Math.random()} customHeaderSorted={style.listWalletHeaderSorted} customHeader={style.listWalletHeader} customRowCellLight={style.listWalletLight} onRowClick={doNothing} customRowCellDark={style.listWalletDark} list={statisticsList} changePage={changePage} nbrItemPerPage={20} currentPage={statisticsListMeta.currentPage} nbrPage={statisticsListMeta.nbrPage} columns={columnsDefinition} sortedColumn={sortValue} sortedOrder={sortOrder} onSort={sort} />
                                            </>
                                        }
                                    </>
                                }
                                {(getGiftType(currentPromotion.details) === 5) &&
                                    <>
                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.action', defaultMessage: 'Action on deposit amount' })} values={[getDetailOnDepositBonus(currentPromotion.details, 1)]} /></div>
                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.maxwin', defaultMessage: 'Max Win Per Deposit (EUR)' })} values={[getDetailOnDepositBonus(currentPromotion.details, 2)]} /></div>
                                    </>
                                }
                            </>
                            <div className={style.two}>
                                <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.button.delete', defaultMessage: 'Delete' })} onClick={deleteProm} /></div>

                                {(currentPromotion.active === 0) &&
                                    <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.button.activate', defaultMessage: 'Activate' })} onClick={activateProm} /></div>
                                }

                                {(currentPromotion.active === 1) &&
                                    <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.button.deactivate', defaultMessage: 'Deactivate' })} onClick={deactivateProm} /></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100vh',
                }}
            >
                <i className="fa fa-circle-o-notch fa-spin fa-5x fa-fw" style={{ color: '#b9996a' }}></i>
            </div>
        )
    } catch (error) {
        console.log(error);
    }
}

export default PromotionCodeViewer;