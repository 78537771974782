import React from 'react'
import style from './style.module.scss'
import { useIntl } from 'react-intl';
import { getImageUrl } from '../../../../../../Utils/uiUtil';

function Completed(props) {
  const intl = useIntl();

  try {
    return (
      <div className={style.mainDiv}>
        <div className={style.content}>
          <div className={style.test}>
            <div className={style.test3}>
              <img src={getImageUrl('newui/register/IMG_Register-Complete.png')} alt='' />
            </div>
            <div className={style.test2}>
              <span className={style.normalText}>{intl.formatMessage({ id: 'newcasino.auth.registration.completed.text1', defaultMessage: 'You have successfully' })}</span> <span className={style.boldText}>{intl.formatMessage({ id: 'newcasino.auth.registration.completed.text2', defaultMessage: 'completed your registration' })}</span><span className={style.normalText}>{intl.formatMessage({ id: 'newcasino.auth.registration.completed.text3', defaultMessage: '! You can now access your account and deposit.' })}</span>
            </div>
            <div className={style.test2}>
              <span className={style.yellowText}>{intl.formatMessage({ id: 'newcasino.auth.registration.completed.text4', defaultMessage: 'Please note!' })}</span>
            </div>
            <div className={style.test2}>
              <span className={style.normalText}>{intl.formatMessage({ id: 'newcasino.auth.registration.completed.text5', defaultMessage: 'You must complete your KYC form in order to withdraw. You can do this in your profile.' })}</span>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}
export default Completed