/* eslint-disable */
import React, { useEffect, useState } from 'react'
import * as constants from '../../../../constants.js'
import moment from 'moment'
import { displayPage } from '../../../../Utils/routerUtil'
import { useLocation, useNavigate } from 'react-router-dom'
import MemoryList from '../../../../Generic/Inputs/MemoryList'
import Button from '../../../../Generic/Inputs/Button'
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import { momentToFullText } from '../../../../Utils/dateUtil'
import * as backendService from '../../../../Services/Backend/backendService';
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import { setTheme } from '../../../../Utils/themeUtils'
import { displayGenericErrorNotificationIntl, getImageUrl } from '../../../../Utils/uiUtil';

function BlogList(props) {

  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [allArticles, setAllArticles] = useState([]);

  const columnsDefinition = [
    { name: intl.formatMessage({ id: 'newcasino.blog.admin.list.col1', defaultMessage: 'Creation Date' }), width: '34%', listColumn: 'creationDateFormated', canSort: true, sortField: 'creationDateMillis' },
    { name: intl.formatMessage({ id: 'newcasino.blog.admin.list.col2', defaultMessage: 'Popup Name' }), width: '33%', listColumn: 'internalName', canSort: true },
    { name: intl.formatMessage({ id: 'newcasino.blog.admin.list.col3', defaultMessage: 'Active' }), width: '33%', listColumn: 'active', canSort: true }
  ];

  useEffect(() => {
    loadBlogList();
  }, []);

  /*******************************************
   * convertBlogFromBackend
   *******************************************/
  function convertBlogFromBackend(res) {

    var toReturn = [];

    for (let i = 0; i < res.length; i++) {
      var currentObj = res[i];

      toReturn.push(
        {
          'internalName': currentObj.internalName,
          'id': currentObj.id,
          'category': currentObj.category,
          'titleEn': currentObj.titleEn,
          'titleFr': currentObj.titleFr,
          'titlePt': currentObj.titlePt,
          'textEn': currentObj.textEn,
          'textFr': currentObj.textFr,
          'textPt': currentObj.textPt,
          'active': currentObj.active ? intl.formatMessage({ id: 'newcasino.blog.admin.list.yes', defaultMessage: 'Yes' }) : intl.formatMessage({ id: 'newcasino.blog.admin.list.no', defaultMessage: 'No' }),
          'creationDate': currentObj.creationDate,
          'creationDateMillis': moment(currentObj.creationDate).format('x'),
          'creationDateFormated': momentToFullText(moment(currentObj.creationDate))
        }
      )
    }
    return toReturn;
  }


  /*******************************************
   * loadBlogList
   *******************************************/
  function loadBlogList() {
    backendService.getBlogList(successLoadList, failureLoadList, navigate, location.pathname)
  }

  function successLoadList(data) {
    try {
      setAllArticles(convertBlogFromBackend(data[0]))
    } catch (error) {
      console.error(error.message)
    }
    setLoading(false)
  }

  function failureLoadList(data) {
    displayGenericErrorNotificationIntl(intl)
    setLoading(false);
  }

  function displayDetail(item) {
    displayPage(constants.ROUTE_INFO_BLOG_DETAIL_BASE + item.id, navigate)
  }

  try {

    return loading === false ? (
      <div className={style.master_page_container}>
        <div className={style.topMainDiv}>
          <div>
            <div className={style.grid100}>
              <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.blog.admin.list.title', defaultMessage: 'Blog Management' })}</div>
              <div className={style.case1TopText3}>
                <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
              </div>
              <div className={style.buttonDiv}>
                <Button value={intl.formatMessage({ id: 'newcasino.blog.admin.list.add.button', defaultMessage: 'Add New Article' })} onClick={() => displayPage(constants.ROUTE_INFO_BLOG_DETAIL_BASE + 'NEW', navigate)} />
              </div>
              <MemoryList list={allArticles} nbrItemPerPage={10} columns={columnsDefinition} onRowClick={displayDetail} onRowClickParam='id' />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <i className="fa fa-circle-o-notch fa-spin fa-5x fa-fw" style={{ color: '#b9996a' }}></i>
      </div>
    )
  } catch (error) {
    console.error(error);
  }
}
export default BlogList