/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from '../../../../../Services/JWTBackend/jwtBackendService';
import Header from '../../../../../Casino/General/Modal/Header';
import { displayGenericErrorNotificationIntl, displayInfoNotification, displayInfoNotificationIntl, getAmountTxt, getAmountTxtWithCoin, getImageUrl } from '../../../../../Utils/uiUtil';
import MemoryList from '../../../../../Generic/Inputs/MemoryList';


function FreeSpinInfo(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [spinList, setSpinList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        jwtBackendService.infoFreeSpins(props.userId, successInfoFreeSpins, failureInfoFreeSpins, navigate, location.pathname);
    }, [])

    const spinDefinition = [
        { name: intl.formatMessage({ id: 'newcasino.admin.spinInfo.col1', defaultMessage: 'Game Name' }), hintColumn: 'gameId', width: '80%', listColumn: 'gameName', canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.spinInfo.col2', defaultMessage: 'Nbr Spin' }), width: '20%', listColumn: 'nbRounds', canSort: false },
    ];

    function successInfoFreeSpins(data) {
        setIsLoading(false);
        setSpinList(data);
    }

    function failureInfoFreeSpins(data) {
        setIsLoading(false);
        displayGenericErrorNotificationIntl(intl);
    }

    try {
        var header = null;

        header = <Header title={intl.formatMessage({ id: 'newcasino.admin.user.wallet.add.freespin.info', defaultMessage: 'Free Spins Information' })} subTitle={intl.formatMessage({ id: 'newcasino.admin.user.popup.freespin.info.subtitle', defaultMessage: 'Freespins Information from game provider' })} closeModal={props.closeModal} />

        return (
            <>
                <div>
                    <div>
                        {header}
                    </div>
                    <div className={style.grid100}>
                        {isLoading &&
                            <center><img className={style.marginTop} alt='' width={'50px'} src={getImageUrl('newui/general/loading3.gif')} /></center>
                        }
                        {!isLoading && spinList && spinList.length > 0 &&
                            <MemoryList key={'SpinList_' + Math.random()} nbrItemPerPage={10} list={spinList} columns={spinDefinition} />
                        }
                        {!isLoading && !(spinList && spinList.length > 0) &&
                            <center>{intl.formatMessage({ id: 'newcasino.admin.user.wallet.freespin.info.nodata', defaultMessage: 'No Data' })}</center>
                        }
                    </div>

                </div>
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default FreeSpinInfo;
