import React from 'react'
import style from './style.module.scss'

import { canAccess } from '../../../../Utils/authorizationUtils'
import { displayPage } from '../../../../Utils/routerUtil'
import { useNavigate } from 'react-router-dom'
import ApiPersistence from '../../../../Utils/ApiPersistence'
import DivClick from '../../../../Generic/Inputs/DivClick'
import { FormattedMessage } from 'react-intl'


function UserMenu(props) {

    const navigate = useNavigate();

    const userMenu = props.definition.userMenu;
    const title = ApiPersistence.getInstance().getUser().email;
    const user = ApiPersistence.getInstance().getUser();

    function toggleUserMenu(force) {
        props.toggleUserMenu(force);
    }

    function getTitle(theTitle) {
        let toReturn = theTitle;
        if (toReturn) {
            if (toReturn.length > 28) {
                toReturn = toReturn.substring(0, 28) + '...';
            }
        }

        return toReturn;
    }

    function userMenuClicked(index) {
        props.toggleUserMenu(false);
        if (props.isMobile && props.toggleLeftMenu) {
            props.toggleLeftMenu(false)
        }

        if (userMenu.data[index].action === 'PROMOCODE') {
            props.displayModalPromocode();
        } else {
            displayPage(userMenu.data[index].action, navigate);
        }

    }

    if (userMenu) {

        return (
            <div className={props.className ? props.className : null} onMouseLeave={() => toggleUserMenu(false)}>
                {(props.showTitle === undefined || props.showTitle === true) &&
                    <div className={style.menuTitle}>{getTitle(title)}</div>
                }
                {(userMenu.data.map((item, index) => ((canAccess(user, item)) ? (
                    <DivClick key={'userMenu_' + index} className={style.global_DivsHorizontal} onClick={() => userMenuClicked(index)}>
                        <div className={(item.colorType === 'admin') ? style.adminChoice : style.userChoice}><div className={style.theIcon}><i className={item.image}></i></div></div>
                        <div className={(item.colorType === 'admin') ? style.adminrow : ''}>
                            <FormattedMessage id={item.intl} defaultMessage={item.default} />
                        </div>
                    </DivClick>
                ) : ''))
                )}
            </div>
        );
    }
}

export default UserMenu