import { JSEncrypt } from "jsencrypt";
import * as b64 from './base64Service';
import * as aesService from './aesService';
import ApiPersistence from "../../../Utils/ApiPersistence";
import ApiCache from "../../../Utils/ApiCache";
import * as jose from 'jose'

const CACHE_JWT_PUBLIC_KEY = 'CACHE_JWT_PUBLIC_KEY';
const JWT_TOKEN = 'JWT_TOKEN';
const BASE64 = 'base64';
const KEYSIZE = 2048;


/**********************************************************************
 * cleanUp()
 *********************************************************************/
export function cleanUp() {
    ApiPersistence.getInstance().delete(JWT_TOKEN);
}

/**********************************************************************
 * getStoredToken()
 *********************************************************************/
export function getStoredToken() {
    return ApiPersistence.getInstance().get(JWT_TOKEN);
}

/**********************************************************************
 * generateKeyPair
 *********************************************************************/
export function generateKeyPair() {
    var toReturn = [];

    var crypt = new JSEncrypt({ default_key_size: KEYSIZE });
    var publK = crypt.getPublicKey()
    var privK = crypt.getPrivateKey();
    var PublicPrivateKey = {
        PublicKey: publK,
        PrivateKey: privK,
        PublicKey64: b64.base64Encode(publK),
        PrivateKey64: b64.base64Encode(privK)
    };

    toReturn = PublicPrivateKey;

    return toReturn
}

/**********************************************************************
 * validateToken
 *********************************************************************/
export async function validateToken(pubKey, token) {

    var toReturn = { valid: false }

    var theKey = await jose.importSPKI(pubKey, 'ES512')

    var verified = await jose.jwtVerify(token, theKey
        /*, 
        {
        issuer: 'https://playnumbersgame.com',
        audience: 'https://playnumbersgame.com',
    }*/)

    toReturn = { valid: true, data: verified.payload.data }

    return toReturn;
}

/**********************************************************************
 * dealWithReceivedJWToken
 *********************************************************************/
export async function dealWithReceivedJWToken(token, nbgEncKey64, otherInfo) {
    var isLogin = !((nbgEncKey64 == null || nbgEncKey64 === undefined));
    //STEP 1 - Get the public Key for token validation
    var pubKey = ApiCache.getInstance().getFromCache(CACHE_JWT_PUBLIC_KEY);
    if (!(ApiCache.getInstance().isInCache(CACHE_JWT_PUBLIC_KEY)) || pubKey === undefined || pubKey === null) {
        //GET THE PUBLIC KEY FROM BACKEND
        const request = new XMLHttpRequest();
        request.open("GET", '/api/public/SEC', false); // `false` makes the request synchronous
        request.send(null);
        if (request.status === 200) {
            var base64key = JSON.parse(request.responseText).data;
            pubKey = (Buffer.from(base64key, BASE64)).toString();
            ApiCache.getInstance().setInCache(CACHE_JWT_PUBLIC_KEY, pubKey);
        } else {
            throw new Error("Impossible to get the Public Key: " + request.status);
        }
    }

    //STEP 2 - Validate the Token with the public Key
    var validationResp = await validateToken(pubKey, token);
    if (validationResp.valid) {
        //STEP 3 - SET USER DATA
        ApiPersistence.getInstance().setUser(validationResp.data);
        if (isLogin) {
            var decrypt2 = new JSEncrypt();
            decrypt2.setPrivateKey(otherInfo.PrivateKey);
            var uncrypted2 = decrypt2.decrypt(nbgEncKey64);
            aesService.storeEncryptionKey(uncrypted2);
        }
        ApiPersistence.getInstance().set(JWT_TOKEN, token);
    } else {
        throw new Error("Invalid JWT Token");
    }
    return isLogin;
}