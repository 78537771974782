/* eslint-disable */
import React, { useEffect, useState } from 'react'
import * as constants from '../../../../constants.js'
import moment from 'moment'
import { displayPage } from '../../../../Utils/routerUtil'
import { useLocation, useNavigate } from 'react-router-dom'
import MemoryList from '../../../../Generic/Inputs/MemoryList'
import Button from '../../../../Generic/Inputs/Button'
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import * as backendService from '../../../../Services/Backend/backendService';
import { setTheme } from '../../../../Utils/themeUtils'
import { displayGenericErrorNotificationIntl } from '../../../../Utils/uiUtil'
import { getImageUrl } from '../../../../Utils/uiUtil';

function ContestList(props) {

  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [allContests, setAllContests] = useState([]);

  const columnsDefinition = [
    { name: intl.formatMessage({ id: 'newcasino.contests.admin.list.col1', defaultMessage: 'Contest Date' }), width: '34%', listColumn: 'contestDateFormated', canSort: true, sortField: 'contestDateMillis' },
    { name: intl.formatMessage({ id: 'newcasino.contests.admin.list.col2', defaultMessage: 'Contest Name' }), width: '33%', listColumn: 'contestName', canSort: true },
    { name: intl.formatMessage({ id: 'newcasino.contests.admin.list.col3', defaultMessage: 'Display' }), width: '33%', listColumn: 'contestDisplay', canSort: true }
  ];

  useEffect(() => {
    loadContestList();
  }, []);

  /*******************************************
   * convertContestFromBackend
   *******************************************/
  function convertContestFromBackend(res) {
    var toReturn = [];

    for (let i = 0; i < res.length; i++) {
      var currentContest = res[i];

      toReturn.push(
        {
          'contestName': currentContest.contestName,
          'contestId': currentContest.contestId,
          'contestDisplay': currentContest.contestDisplay ? intl.formatMessage({ id: 'newcasino.contests.admin.list.yes', defaultMessage: 'Yes' }) : intl.formatMessage({ id: 'newcasino.contests.admin.list.no', defaultMessage: 'No' }),
          'contestDate': currentContest.contestDate,
          'contestDateFormated': moment(currentContest.contestDate).locale(intl.locale).format('DD MMMM YYYY'),
          'contestDateMillis': moment(currentContest.contestDate).format('x')
        }

      )
    }
    return toReturn;
  }

  /*******************************************
   * loadContestList
   *******************************************/
  function loadContestList() {
    backendService.getContestList(successLoadList, failureLoadList, navigate, location.pathname)
  }

  function successLoadList(data) {
    var convertedData = convertContestFromBackend(data[0])
    setAllContests(convertedData);
    setLoading(false)
  }

  function failureLoadList(data) {
    displayGenericErrorNotificationIntl(intl);
    setLoading(false);
  }

  function displayDetail(item) {
    displayPage(constants.ROUTE_CONTEST_WINNERS_DETAIL_BASE + item.contestId, navigate)
  }

  try {
    return loading == false ? (
      <div className={style.master_page_container}>
        <div className={style.topMainDiv}>
          <div>
            <div className={style.grid100}>
              <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.contests.admin.list.title', defaultMessage: 'Contest Management' })}</div>
              <div className={style.case1TopText3}>
                <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
              </div>
              <div className={style.buttonDiv}>
                <Button width='200px' value={intl.formatMessage({ id: 'newcasino.contests.admin.list.add.button', defaultMessage: 'Add New Contest' })} onClick={() => displayPage(constants.ROUTE_CONTEST_WINNERS_DETAIL_BASE + 'NEW', navigate)} />
              </div>
              <MemoryList list={allContests} nbrItemPerPage={10} columns={columnsDefinition} onRowClick={displayDetail} onRowClickParam='contestId' />
            </div>
          </div>
          <div className={style.case}>
            <img className={style.caseImg} src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/IMG_Contest2.png'))} />
          </div>
        </div>
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <i className="fa fa-circle-o-notch fa-spin fa-5x fa-fw" style={{ color: '#b9996a' }}></i>
      </div>
    )
  } catch (error) {
    console.log(error);
  }
}
export default ContestList