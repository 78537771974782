import React from 'react'
import style from './style.module.scss'
import Button from '../../../Generic/Inputs/Button';
import DivClick from '../../../Generic/Inputs/DivClick';
import { useIntl } from 'react-intl';
import { getImageUrl } from '../../../Utils/uiUtil';

function Policies(props) {
  const intl = useIntl();
  try {
    return (
      <div className={style.successPop_mainGrid}>
        <div className={style.successPop_welcomeGrid}>
          <div className={style.successPop_welcomeTxt}>
            <div>{intl.formatMessage({ id: 'newcasino.aff.policy.title', defaultMessage: 'Privacy policy' })}</div>
            <div className={style.login_spacer} />
          </div>
          <DivClick className={style.successPop_xClose} onClick={() => props.closeModal()}>
            X
          </DivClick>
        </div>
        <div className={style.successPop_logo}>
          <img className={style.successPop_logoImg} src={getImageUrl('newui/affiliates/Logo-Header_pur.png')} alt='' />
        </div>

        <div className={style.scrollable}>
          <p className={style.bigText}>
            To Come.
          </p>

        </div>
        <div className={style.successPop_button}>
          <Button colorStyle='light' value={intl.formatMessage({ id: 'newcasino.aff.policy.button', defaultMessage: 'Close' })} onClick={() => props.closeModal()} />
        </div>
      </div>

    );

  } catch (error) {
    console.log('ERROR: ' + error.message);

  }
}

export default Policies
