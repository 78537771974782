import React, { useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import { setTheme } from '../../../../../Utils/themeUtils'
import { getImageUrl } from '../../../../../Utils/uiUtil';
import ComboBox from '../../../../../Generic/Inputs/ComboBox';

import Button from '../../../../../Generic/Inputs/Button';
import DatePicker from '../../../../../Generic/Inputs/DatePicker';
import PromoCash from '../Generic/PromoCash';
import PromoFreeSpin from '../Generic/PromoFreeSpin';
import PromoPngPoint from '../Generic/PromoPngPoint';
import PromoWager from '../Generic/PromoWager';
import UserListUpload from '../Generic/UserListUpload';

function NewInstantGift(props) {
    const GIFT_TYPE_NONE = -1;
    const GIFT_TYPE_CASH = 1;
    const GIFT_TYPE_PNG = 2;
    const GIFT_TYPE_FREESPIN = 3;
    const GIFT_TYPE_WAGER = 4;

    const AUDIENCE_NONE = -1;
    const AUDIENCE_ALL = 1;
    const AUDIENCE_SPECIFIC = 2;

    const intl = useIntl();

    const [whoListFile, setWhoListFile] = useState(null);
    const [audienceType, setAudienceType] = useState(AUDIENCE_NONE);
    const [giftType, setGiftType] = useState(GIFT_TYPE_NONE);
    const [details, setDetails] = useState(null);

    const [executionDate, setExecutionDate] = useState('');
    const [executionTime, setExecutionTime] = useState('');

    const [isExecutionDateValidMaster, setIsExecutionDateValidMaster] = useState(false);
    const [isExecutionTimeValidMaster, setIsExecutionTimeValidMaster] = useState(false);

    const audienceGiftChoices = {
        key: 'key',
        value: 'value',
        list: [
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.audience.select', defaultMessage: 'Choose one...' }), key: AUDIENCE_NONE },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.audience.all', defaultMessage: 'All (Except banned)' }), key: AUDIENCE_ALL },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.audience.specific', defaultMessage: 'Upload user list' }), key: AUDIENCE_SPECIFIC }
        ]
    }

    const giftTypeChoices = {
        key: 'key',
        value: 'value',
        list: [
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.select', defaultMessage: 'Choose one...' }), key: GIFT_TYPE_NONE },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.cash', defaultMessage: 'Cash' }), key: GIFT_TYPE_CASH },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.png', defaultMessage: 'Png Points' }), key: GIFT_TYPE_PNG },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.freespin', defaultMessage: 'FreeSpins' }), key: GIFT_TYPE_FREESPIN },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager', defaultMessage: 'Wager' }), key: GIFT_TYPE_WAGER }
        ]
    }

    function isAudienceValid() {
        let toReturn = false;

        if (audienceType === AUDIENCE_ALL) {
            toReturn = true
        } else if (audienceType === AUDIENCE_SPECIFIC && whoListFile !== null) {
            toReturn = true
        }

        return toReturn;
    }

    function switchAudienceType(data) {
        setWhoListFile(null);
        setAudienceType(Number(data));
    }

    function switchGiftType(data) {
        setDetails(null);
        setGiftType(Number(data));
    }

    function updateExecutionDateTime(dateValue, timeValue) {
        setIsExecutionDateValidMaster(true);
        setExecutionDate(dateValue);
        setIsExecutionTimeValidMaster(true);
        setExecutionTime(timeValue);
    }

    function canProcess() {
        return (isExecutionDateValidMaster && isExecutionTimeValidMaster && (details !== null) && isAudienceValid());
    }

    function sendRequest() {
        let data = {
            type: giftType,
            executionDate: executionDate,
            executionTime: executionTime,
            details: details,
            audience: {
                type: audienceType,
            }
        }

        props.sendRequest(whoListFile, data);
    }

    function sendDetails(details) {
        setDetails(details);
    }

    function userListFile(theFile) {
        setWhoListFile(theFile)
    }

    try {
        return (
            <>
                <div className={style.bottom}>
                    <ComboBox label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.title', defaultMessage: 'Choose a gift' })} values={[giftType]} valueList={giftTypeChoices} onChange={switchGiftType} />
                </div>

                <div className={style.lineDates}>
                    <div className={style.bottom}>
                        <DatePicker minDate='TODAY' initDate='TODAY' startTimeLabel={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.cron.time', defaultMessage: 'Execution Time' })} onChange={updateExecutionDateTime} startDateLabel={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.cron.date', defaultMessage: 'Execution Date' })} />
                    </div>
                </div>

                {giftType === GIFT_TYPE_CASH &&
                    <>
                        <div className={style.case1TopText3}>
                            <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                        </div>
                        <PromoCash sendDetails={sendDetails} />
                    </>
                }

                {giftType === GIFT_TYPE_FREESPIN &&
                    <>
                        <div className={style.case1TopText3}>
                            <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                        </div>
                        <PromoFreeSpin sendDetails={sendDetails} />
                    </>
                }

                {giftType === GIFT_TYPE_PNG &&
                    <>
                        <div className={style.case1TopText3}>
                            <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                        </div>
                        <PromoPngPoint sendDetails={sendDetails} />
                    </>
                }

                {giftType === GIFT_TYPE_WAGER &&
                    <>
                        <div className={style.case1TopText3}>
                            <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                        </div>
                        <PromoWager sendDetails={sendDetails} />
                    </>
                }
                <>
                    <div className={style.bottom}>
                        <ComboBox label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.audience.select', defaultMessage: 'Choose recipients' })} values={[audienceType]} valueList={audienceGiftChoices} onChange={switchAudienceType} />
                    </div>

                    {audienceType === AUDIENCE_SPECIFIC &&
                        <>
                            <UserListUpload onFileSelection={userListFile} />
                        </>
                    }
                </>
                <Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.submit', defaultMessage: 'Submit' })} active={canProcess()} onClick={sendRequest} />
            </>
        )
    } catch (error) {
        console.log(error);
    }
}

export default NewInstantGift;