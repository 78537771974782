import React, { useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';

import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work

import { isNumeric } from '../../../../../../Utils/uiUtil';
import TextField from '../../../../../../Generic/Inputs/TextField';

function PromoFreeSpin(props) {
    const intl = useIntl();
    const [expirationFreeSpin, setExpirationFreeSpin] = useState('0');

    const [quantity, setQuantity] = useState(null);
    const [isExpirationFreeSpinValidMaster, setIsExpirationFreeSpinValidMaster] = useState(true);
    const [isQuantityValidMaster, setIsQuantityValidMaster] = useState(false);

    function isExpirationFreeSpinValid(isValid, value) {
        setIsExpirationFreeSpinValidMaster(isValid);

        if (isValid) {
            value = Number(value);
            setExpirationFreeSpin(value);
        } else {
            setExpirationFreeSpin(value);
        }

        if (isValid && isQuantityValidMaster) {
            props.sendDetails({ quantity: quantity, expiration: value });
        } else {
            props.sendDetails(null);
        }
    }

    function isQuantityValid(isValid, value) {
        setIsQuantityValidMaster(isValid);

        if (isValid && isExpirationFreeSpinValidMaster) {
            value = Number(value);
            setQuantity(value);
            props.sendDetails({ quantity: quantity, expiration: value });
        } else {
            setQuantity(value);
            props.sendDetails(null);
        }
    }

    try {
        return (
            <>
                <div className={style.onefield}>
                    <div className={style.bottom}><TextField maxLength='100' autofocus='true' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.freespin.quantity', defaultMessage: 'Quantity' })} values={['']} validator={isNumeric} validatorInformer={isQuantityValid} mandatory='true' showCheck='true' /></div>
                </div>
                <div className={style.onefield}>
                    <div className={style.bottom}><TextField maxLength='5' autofocus='false' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.freespin.expiration.days', defaultMessage: 'Expiry in days' })} values={[expirationFreeSpin]} validator={isNumeric} validatorInformer={isExpirationFreeSpinValid} mandatory='true' showCheck='true' /></div>
                </div>
            </>
        )
    } catch (error) {
        console.log(error);
    }
}

export default PromoFreeSpin;