export function findClidrenByClassName(elementParent, classe) {
    const elementsTrouves = [];

    // Vérifier si l'élément parent a la classe spécifiée
    if (elementParent.classList && elementParent.classList.contains(classe)) {
        elementsTrouves.push(elementParent);
    }

    // Parcourir les enfants de l'élément parent
    for (const enfant of elementParent.children) {
        // Appeler la fonction récursivement pour chaque enfant
        const resultatsEnfants = findClidrenByClassName(enfant, classe);
        elementsTrouves.push(...resultatsEnfants); // Ajouter les résultats des enfants
    }

    return elementsTrouves;
}