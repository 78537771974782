import React, { useState } from 'react'
import style from './style.module.scss'


import newsLetterImg from "resources/newui/dotnet/newsletter.png";
import TextField from 'newui/Generic/Inputs/TextField';
import { useIntl } from 'react-intl';
import Button from 'newui/Generic/Inputs/Button';

import * as jwtBackendService from 'newui/Services/JWTBackend/jwtBackendService'
import { displayInfoNotificationIntl, isEmail } from 'newui/Utils/uiUtil';

function NewsLetter(props) {
  const intl = useIntl();
  const [emailValid, setEmailValid] = useState(false);
  const [email, setEmail] = useState('');
  const [refreshInputText, setRefreshInputText] = useState(Math.random());

  function emailChange(isValid, newValue) {
    setEmailValid(isValid);
    setEmail(newValue);
  }

  function subscribe() {
    jwtBackendService.registerNewsLetter(email, registerSuccess, registerSuccess, null, null);
  }

  function registerSuccess(data) {
    displayInfoNotificationIntl(intl, 'dotnet.newsletter.register.success')
    setEmail('');
    setEmailValid(false);
    setRefreshInputText(Math.random());
  }

  return (
    <div className={style.centerize}>
      <div className={style.cloudBox}>
        <div className={style.mainGrid}>
          <div>
            <img className={style.newsletterImg} alt='' src={newsLetterImg} />
          </div>
          <div>
            <div className={style.title}>{intl.formatMessage({ id: 'dotnet.newsletter.title', defaultMessage: 'Newsletter' })}</div>
            <div className={style.subtitle}>{intl.formatMessage({ id: 'dotnet.newsletter.subtitle', defaultMessage: 'Subscribe to our newsletter to get the latest news!' })}</div>
          </div>
          <div>
            <div className={style.verticalCenterize}>
              <TextField key={refreshInputText} hint={intl.formatMessage({ id: 'dotnet.newsletter.hint', defaultMessage: 'Type your email here' })} values={[email]} validator={isEmail} validatorInformer={emailChange} mandatory='true' showTitleError='false' showCheck='true' />
            </div>
          </div>
          <div>
            <div className={style.verticalCenterize}>
              <Button active={emailValid} colorStyle='light' value={intl.formatMessage({ id: 'dotnet.newsletter.button', defaultMessage: 'Subscribe' })} onClick={subscribe} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default (NewsLetter)