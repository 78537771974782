/* eslint-disable */
import React from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import { setTheme } from '../../../../../Utils/themeUtils';
import DivClick from '../../../../../Generic/Inputs/DivClick';
import { getCurrencySign, toTwoDecimal } from '../../../../../Utils/converterUtil';

function ShopElement(props) {

    const intl = useIntl()

    const isSelected = props.selected;
    const elementData = props.value;
    const canBuy = props.canBuy;

    function getImageUrl() {
        let toReturn = setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Money.png');

        if (elementData.elementType === 1) {
            toReturn = setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Money.png');
        }
        else if (elementData.elementType === 2) {
            toReturn = setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Wager.png');
        }
        else if (elementData.elementType === 3) {
            toReturn = setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Slot-Machine.png');
        }

        return toReturn;

    }
    function getDayGiftStyle() {
        let toReturn = style.dayGift_2;

        if (props.status === 0) {
            toReturn = style.dayGift_0;
        }
        else if (props.status === 1) {
            toReturn = style.dayGift_1;
        }

        return toReturn;

    }

    function getBaseStyle() {
        let toReturn = style.elementNotSelected;

        if (!canBuy) {
            toReturn = style.elementNotAvailable;
        }

        if (isSelected) {
            toReturn = style.elementSelected;
        }

        return toReturn;
    }

    function getWagerDetail(elementDetail, index) {
        let toReturn = '';
        try {
            let array = elementDetail.split(',');
            if (index === 1) {
                toReturn = array[index] - (array[4] * props.level);
            } else {
                toReturn = array[index];
            }
        } catch (err) { }

        return toReturn;
    }

    return (
        <DivClick className={getBaseStyle()} onClick={() => canBuy ? props.onClick(props.status) : {}}>
            <div className={style.imgGift}>
                <img className={canBuy ? style.imgTag : style.filter} src={getImageUrl()} alt='' />
            </div>
            <div className={(elementData.elementType === 2) ? style.elementMainWager : (elementData.elementType === 3) ? style.elementMainFreeSpin : style.elementMain}>
                {(elementData.elementType === 1) &&
                    <div className={canBuy ? style.money : style.moneyFilter}>
                        <div className={style.gridMobile}>
                            <div></div>
                            <div className={style.moneyGrid}>
                                <div className={style.moneySign}>{getCurrencySign('EUR', 'sign')} </div>
                                <div className={style.moneyAmount}>{toTwoDecimal(elementData.elementDetail)}</div>
                            </div>
                            <div></div>
                            <div className={style.mobile}>
                                <div className={canBuy ? style.cost : style.costFilter}>
                                    <div className={style.costGrid}>
                                        <div className={style.costLabel}>{intl.formatMessage({ id: 'newcasino.loyalty.shop.element.cost', defaultMessage: 'Cost:' })}&nbsp;</div>
                                        <div className={style.costValue}>{elementData.elementCost} {intl.formatMessage({ id: 'newcasino.loyalty.shop.element.pts', defaultMessage: 'PTS' })}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {(elementData.elementType === 2) &&
                    <div className={canBuy ? style.wager : style.wagerFilter}>
                        <div className={style.gridMobile}>
                            <div></div>
                            <div>
                                <div className={style.wagerGrid}>
                                    <div className={style.moneySign}>{getCurrencySign('EUR', 'sign')} </div>
                                    <div className={style.moneyAmount}>{toTwoDecimal(getWagerDetail(elementData.elementDetail, 0))}</div>
                                </div>
                                <div className={style.wagerX}>{getWagerDetail(elementData.elementDetail, 1)}x</div>
                            </div>
                            <div></div>
                            <div className={style.mobile}>
                                <div className={canBuy ? style.cost : style.costFilter}>
                                    <div className={style.costGrid}>
                                        <div className={style.costLabel}>{intl.formatMessage({ id: 'newcasino.loyalty.shop.element.cost', defaultMessage: 'Cost:' })}&nbsp;</div>
                                        <div className={style.costValue}>{elementData.elementCost} {intl.formatMessage({ id: 'newcasino.loyalty.shop.element.pts', defaultMessage: 'PTS' })}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(elementData.elementType === 3) &&
                    <div className={canBuy ? style.freespin : style.freespinFilter}>
                        <div className={style.gridMobile}>
                            <div></div>
                            <div>
                                <div className={style.freespinGrid}>
                                    <div className={style.freespinQte}>{elementData.elementDetail}</div>
                                </div>
                                <div className={style.freespinTxt}>{intl.formatMessage({ id: 'newcasino.loyalty.shop.element.freespin', defaultMessage: 'Freespins' })}</div>
                            </div>
                            <div></div>
                            <div className={style.mobile}>
                                <div className={canBuy ? style.cost : style.costFilter}>
                                    <div className={style.costGrid}>
                                        <div className={style.costLabel}>{intl.formatMessage({ id: 'newcasino.loyalty.shop.element.cost', defaultMessage: 'Cost:' })}&nbsp;</div>
                                        <div className={style.costValue}>{elementData.elementCost} {intl.formatMessage({ id: 'newcasino.loyalty.shop.element.pts', defaultMessage: 'PTS' })}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className={style.desktop}>
                    <div className={canBuy ? style.cost : style.costFilter}>
                        <div className={style.costGrid}>
                            <div className={style.costLabel}>{intl.formatMessage({ id: 'newcasino.loyalty.shop.element.cost', defaultMessage: 'Cost:' })}&nbsp;</div>
                            <div className={style.costValue}>{elementData.elementCost} {intl.formatMessage({ id: 'newcasino.loyalty.shop.element.pts', defaultMessage: 'PTS' })}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.elementMain2}>
                <div className={style.availability}>{canBuy ? intl.formatMessage({ id: 'newcasino.loyalty.shop.element.avail', defaultMessage: 'Available' }) : intl.formatMessage({ id: 'newcasino.loyalty.shop.element.na', defaultMessage: 'Not enough PTS' })} </div>
            </div>
        </DivClick>
    );
}
export default ShopElement;
