/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from '../../../../Services/JWTBackend/jwtBackendService';
import Header from '../../../../Casino/General/Modal/Header';
import Footer from '../../../../Casino/General/Modal/Footer';
import TextField from '../../../../Generic/Inputs/TextField';
import { displayGenericErrorNotificationIntl } from '../../../../Utils/uiUtil';
import Checkbox from '../../../../Generic/Inputs/Checkbox';

function StreamerDeal(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [amount, setAmount] = useState(0);
    const [xTime, setXTime] = useState(0);
    const [streamPerWeek, setStreamPerWeek] = useState(0);
    const [giveawayAmount, setGiveawayAmount] = useState(0);
    const [refresh, setRefresh] = useState(Math.random());
    const [streamerData, setStreamerData] = useState(null);

    const [commissionFixAmount, setCommissionFixAmount] = useState(false);
    const [commissionFixAmountValue, setCommissionFixAmountValue] = useState(0);
    const [commissionPercDeposit, setCommissionPercDeposit] = useState(false);
    const [commissionPercDepositValue, setCommissionPercDepositValue] = useState(0);
    const [commissionPercVault, setCommissionPercVault] = useState(false);
    const [commissionPercVaultBasedOnDeposit, setCommissionPercVaultBasedOnDeposit] = useState(true);
    const [commissionPercVaultBasedOnDepositValue, setCommissionPercVaultBasedOnDepositValue] = useState(0);
    const [commissionMinFTD, setCommissionMinFTD] = useState(false);
    const [commissionMinFTDValue, setCommissionMinFTDValue] = useState(0);

    useEffect(() => {
        getStreamerDeal(props.userId)
    },// eslint-disable-next-line
        [])

    function getStreamerDeal(streamerId) {
        jwtBackendService.getStreamerDeals(streamerId, successGetStreamerDealJWT, failureGetStreamerDealJWT, navigate, location.pathname);
    }

    function successGetStreamerDealJWT(data) {
        setStreamerData(data);
        setAmount(data.deal.balance);
        setXTime(data.deal.wager);
        setStreamPerWeek(data.deal.streamPerWeek);

        setCommissionFixAmount(data.deal.commMonthlyFixedAmount !== -1);
        setCommissionFixAmountValue((data.deal.commMonthlyFixedAmount !== -1) ? data.deal.commMonthlyFixedAmount : 0);

        setCommissionMinFTD(data.deal.commissionMinFTD !== -1);
        setCommissionMinFTDValue((data.deal.commissionMinFTD !== -1) ? data.deal.commissionMinFTD : 0);

        setCommissionPercDeposit(data.deal.commPercDeposit !== -1);
        setCommissionPercDepositValue((data.deal.commPercDeposit !== -1) ? data.deal.commPercDeposit : 0);

        setCommissionPercVault(data.deal.commissionPercVault !== -1);

        setCommissionPercVaultBasedOnDeposit(!(data.deal.commissionPercVault > -1));
        setCommissionPercVaultBasedOnDepositValue(data.deal.commissionPercVault > -1 ? data.deal.commissionPercVault : 0);


        setGiveawayAmount(data.giveAway.amount);
        setRefresh(Math.random);
    }

    function failureGetStreamerDealJWT(data) {
        displayGenericErrorNotificationIntl(intl);
    }


    function saveDeal() {
        var clone = JSON.parse(JSON.stringify(streamerData));
        clone.deal.streamerID = props.userId;
        clone.deal.balance = amount;
        clone.deal.wager = xTime;
        clone.deal.streamPerWeek = streamPerWeek;
        clone.giveAway.amount = giveawayAmount;

        clone.deal.commMonthlyFixedAmount = commissionFixAmount ? commissionFixAmountValue : -1;
        clone.deal.commPercDeposit = commissionPercDeposit ? commissionPercDepositValue : -1;
        clone.deal.commissionMinFTD = commissionMinFTD ? commissionMinFTDValue : -1;
        clone.deal.commissionPercVault = commissionPercVault ? (commissionPercVaultBasedOnDeposit ? -2 : commissionPercVaultBasedOnDepositValue) : -1;

        jwtBackendService.updateStreamerDeals(clone, successUpdateStreamerDealsJWT, failureUpdateStreamerDealsJWT, navigate, location.pathname);
    }

    function successUpdateStreamerDealsJWT(data) {
        props.closeModal();
    }

    function failureUpdateStreamerDealsJWT(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function amountChange(data) {
        setAmount(data);
    }

    function xTimeChange(data) {
        setXTime(data);

    }
    function streamPerWeekChange(data) {
        setStreamPerWeek(data);
    }

    function giveAwayChange(data) {
        setGiveawayAmount(data);
    }


    try {
        var header = <Header title={intl.formatMessage({ id: 'newCasino.admin.streamerDeal.title', defaultMessage: 'Streamer\'s Deal' })} subTitle={intl.formatMessage({ id: 'newCasino.admin.streamerDeal.subtitle', defaultMessage: 'Adjust streamer\'s deal parameters' })} closeModal={props.closeModal} />
        var footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newCasino.admin.streamerDeal.cancel', defaultMessage: 'Cancel' }), action: props.closeModal, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newCasino.admin.streamerDeal.save', defaultMessage: 'Save' }), action: saveDeal, canProcess: true }} />

        return (
            <>
                <div>
                    <div>
                        {header}
                    </div>
                    <div className={style.streamingWeek}>
                        <div>{intl.formatMessage({ id: 'newCasino.admin.streamerDeal.amount', defaultMessage: 'Amount' })} <TextField key={'Amount-' + refresh} values={[amount]} onChange={amountChange} type='digit' /></div>
                        <div>{intl.formatMessage({ id: 'newCasino.admin.streamerDeal.xtime', defaultMessage: 'X Time' })} <TextField key={'X-' + refresh} values={[xTime]} onChange={xTimeChange} type='digit' /></div>
                        <div>{intl.formatMessage({ id: 'newCasino.admin.streamerDeal.perweek', defaultMessage: 'Stream Per Week' })}<TextField key={'Stream-' + refresh} values={[streamPerWeek]} onChange={streamPerWeekChange} type='digit' /></div>
                    </div>
                    <div className={style.streamingGiveaway}>
                        <div>{intl.formatMessage({ id: 'newCasino.admin.streamerDeal.giveaway', defaultMessage: 'Giveaway' })} <TextField key={'GiveAway-' + refresh} values={[giveawayAmount]} onChange={giveAwayChange} type='digit' /></div>
                    </div>

                    <div className={style.commissionTitle}>Commissions</div>
                    <div className={style.streamingGiveaway}>
                        <div className={style.streamingCommFixAmount}>
                            <Checkbox key={'CHK1_' + refresh} checked={commissionFixAmount === true} label='Monthly Fix Amount' onClick={setCommissionFixAmount} />
                            {commissionFixAmount &&
                                <div><TextField key={'CommFixAmount-' + refresh} values={[commissionFixAmountValue]} onChange={setCommissionFixAmountValue} type='2decimals' /></div>
                            }
                        </div>
                        <div className={style.streamingCommPercDeposit}>
                            <Checkbox key={'CHK2_' + refresh} checked={commissionPercDeposit === true} label='% On Deposit' onClick={setCommissionPercDeposit} />
                            {commissionPercDeposit &&
                                <div><TextField key={'CommPercDeposit-' + refresh} values={[commissionPercDepositValue]} onChange={setCommissionPercDepositValue} type='digit' /></div>
                            }
                        </div>
                        <div className={style.streamingCommPercDeposit}>
                            <Checkbox key={'CHK3_' + refresh} checked={commissionPercVault === true} label='% On Vault' onClick={setCommissionPercVault} />
                            {commissionPercVault &&
                                <>
                                    <div className={style.grid2}>
                                        <Checkbox key={'CHK4_' + refresh} checked={commissionPercVaultBasedOnDeposit === true} label='Based on deposit' onClick={setCommissionPercVaultBasedOnDeposit} />
                                        {commissionPercVaultBasedOnDeposit == false &&
                                            <TextField label='Fixed %' key={'CommPercVaultBasedOnDeposit-' + refresh} values={[commissionPercVaultBasedOnDepositValue]} onChange={setCommissionPercVaultBasedOnDepositValue} type='digit' />
                                        }
                                    </div>
                                </>
                            }
                        </div>
                        <div className={style.streamingCommPercDeposit}>
                            <Checkbox key={'CHK5_' + refresh} checked={commissionMinFTD === true} label='Minimum FTD' onClick={setCommissionMinFTD} />
                            {commissionMinFTD &&
                                <div><TextField key={'CommMinFTD-' + refresh} values={[commissionMinFTDValue]} onChange={setCommissionMinFTDValue} type='digit' /></div>
                            }
                        </div>

                    </div>

                    <div>
                        {footer}
                    </div>
                </div >
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default StreamerDeal;
