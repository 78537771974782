import React, { useState } from 'react';
import style from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl'

import * as jwtBackendService from '../../../../../Services/JWTBackend/jwtBackendService'
import * as constants from '../../../../../constants.js'
import NonMemoryList from '../../../../../Generic/Inputs/NonMemoryList';
import { convertTDate, convertToMoney, convertTransactionTypeToText, convertWalletStateToText, convertWalletTypeToText } from '../../../../../Utils/converterUtil';
import MemoryList from '../../../../../Generic/Inputs/MemoryList';
import Button from '../../../../../Generic/Inputs/Button';
import AddMoney from '../AddMoney';
import AddWager from '../AddWager';
import TxInfo from './TxInfo';
import AddFreeSpin from '../AddFreeSpin';
import FreeSpinInfo from '../FreeSpinInfo';

function Wallets(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();

    const [wallets] = useState(props.wallets);
    const [transactions, setTransactions] = useState(props.transactions);
    const [transactionsMetaData, setTransactionsMetaData] = useState(props.transactionsMetaData);
    const [refreshWallets] = useState(Math.random())
    const [refreshTx, setRefreshTx] = useState(Math.random())

    const [currentWalletId, setCurrentWalletId] = useState(wallets[0].id);
    const [currentWalletType, setCurrentWalletType] = useState(wallets[0].walletType);

    const [sortedColumn] = useState(1); //name
    const [sortedOrder] = useState(1);  //desc

    const walletsDefinition = [
        { name: intl.formatMessage({ id: 'newcasino.admin.user.wallet.col1', defaultMessage: 'Type' }), hintColumn: 'id', width: '20%', listColumn: 'walletType', transformer: convertWalletTypeToText, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.wallet.col2', defaultMessage: 'State' }), width: '20%', listColumn: 'walletState', transformer: convertWalletStateToText, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.wallet.col3', defaultMessage: 'Balance' }), width: '20%', listColumn: 'balance', transformer: toMoney, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.wallet.col4', defaultMessage: 'Wager Target' }), width: '13%', listColumn: 'wagerTarget', transformer: hideIfRealMoney, transformerObj: true, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.wallet.col5', defaultMessage: 'Wager Win Bal.' }), width: '13%', listColumn: 'wagerWinBalance', transformer: hideIfRealMoney, transformerObj: true, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.wallet.col6', defaultMessage: 'Free Spins' }), width: '14%', listColumn: 'availableFreeSpin', canSort: false }
    ];

    const columnsDefinition = [
        { name: intl.formatMessage({ id: 'newcasino.admin.user.wallet.tx.col1', defaultMessage: 'Date' }), width: '25%', listColumn: 'txDate', transformer: convertTDate, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.wallet.tx.col2', defaultMessage: 'Type' }), width: '25%', listColumn: 'transactionType', transformer: convertTransactionTypeToText, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.wallet.tx.col3', defaultMessage: 'Amount' }), width: '25%', listColumn: 'transactionAmount', txtStyle: colorTx, transformer: toMoney, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.wallet.tx.col4', defaultMessage: 'Balance' }), width: '25%', listColumn: 'balance', transformer: toMoney, canSort: false }
    ];

    function hideIfRealMoney(value, item) {
        if (item['walletType'] === 0) {
            return ''
        }

        return toMoney(value);
    }

    function displayWalletDetail(wallet) {
        switchWallet(wallet.id, wallet.walletType, 1, 10, 1, 1, 1);
    }

    function showTxDetails(info) {
        const theMsg = {
            component: <TxInfo key={Math.random()} txId={info.id} walletId={info.walletId} coin={props.coin} />,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)
    }

    function switchWallet(walletId, walletType, pageNumber, nbrElements, filter, orderColumn, desc) {
        jwtBackendService.getWalletDetailsWithFilter(walletId, walletType, pageNumber, nbrElements, filter, orderColumn, desc, successWalletDetail, failureWalletDetail, navigate, location.pathname);
    }

    function successWalletDetail(data) {
        setTransactions(data[0]);
        setCurrentWalletId(data[1][0].walletId);
        setCurrentWalletType(data[1][0].walletType);
        setTransactionsMetaData(data[1][0]);
        setRefreshTx(Math.random())
    }

    function failureWalletDetail(data) {
    }


    function colorTx(item, inter) {
        //return convertToMoney(data, props.coin);à

        if (item.transactionType >= 100 && item.transactionType < 200) {
            return style.colorGreen;
        }
        if (item.transactionType >= 200 && item.transactionType < 300) {
            return style.colorRed;
        }

        return '';
    }

    function toMoney(data, inter) {
        return convertToMoney(data, props.coin);
    }

    function changePage(change) {
        jwtBackendService.getWalletDetailsWithFilter(currentWalletId, currentWalletType, change, 10, null, 1, 1, successWalletDetail, failureWalletDetail, navigate, location.pathname);
    }



    function sort(columnDef, order) {
    }

    function addWager() {
        const theMsg = {
            component: <AddWager key={Math.random()} reload={props.reload} userId={props.personalData.id} isStreamer={props.personalData.typeAccount === 5} />,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)
    }

    function infoFreeSpin() {
        const theMsg = {
            component: <FreeSpinInfo key={Math.random()} reload={props.reload} coin={props.personalData.coin} walletId={currentWalletId} userId={props.personalData.id} isStreamer={props.personalData.typeAccount === 5} />,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)
    }

    function addFreeSpin() {
        const theMsg = {
            component: <AddFreeSpin key={Math.random()} reload={props.reload} coin={props.personalData.coin} walletId={currentWalletId} userId={props.personalData.id} isStreamer={props.personalData.typeAccount === 5} />,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)
    }

    function addMoney() {
        const theMsg = {
            component: <AddMoney key={Math.random()} reload={props.reload} coin={props.personalData.coin} userId={props.personalData.id} isStreamer={props.personalData.typeAccount === 5} />,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)
    }

    return (
        <>
            <div className={style.grid4}>
                <Button value={intl.formatMessage({ id: 'newcasino.admin.user.wallet.add.money', defaultMessage: 'Add Real Money' })} onClick={addMoney} />
                <Button value={intl.formatMessage({ id: 'newcasino.admin.user.wallet.add.wager', defaultMessage: 'Add Wager' })} onClick={addWager} />
                <Button value={intl.formatMessage({ id: 'newcasino.admin.user.wallet.add.freespin', defaultMessage: 'Add Free Spin' })} onClick={addFreeSpin} />
                <Button value={intl.formatMessage({ id: 'newcasino.admin.user.wallet.add.freespin.info', defaultMessage: 'Free Spin Information' })} onClick={infoFreeSpin} />
            </div>

            {(wallets.length > 0) &&
                <>
                    <div className={style.labelTitle}>{intl.formatMessage({ id: 'newcasino.admin.user.wallet.wallets.title', defaultMessage: 'User\'s Wallets' })}</div>
                    <div className={style.bottomSpacer}><MemoryList key={'WAL' + refreshWallets} boldElement={{ key: 'id', value: currentWalletId }} nbrItemPerPage={3} list={wallets} onRowClick={displayWalletDetail} columns={walletsDefinition} /></div>
                </>
            }
            <div title={(wallets.length === 1) ? 'Wallet Id: ' + wallets[0].id : ''} className={style.labelTitle}>{intl.formatMessage({ id: 'newcasino.admin.user.wallet.tx.title', defaultMessage: 'Wallet\'s transactions' })}</div>
            <NonMemoryList key={'DET' + refreshTx} customHeaderSorted={style.listWalletHeaderSorted} customHeader={style.listWalletHeader} customRowCellLight={style.listWalletLight} onRowClick={showTxDetails} customRowCellDark={style.listWalletDark} list={transactions} changePage={changePage} nbrItemPerPage={20} currentPage={transactionsMetaData.currentPage} nbrPage={transactionsMetaData.nbrPage} columns={columnsDefinition} sortedColumn={sortedColumn} sortedOrder={sortedOrder} onSort={sort} />
        </>
    );
}

export default Wallets;
