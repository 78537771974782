/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl'
import { Spin } from 'antd'
import * as backendService from '../../../Services/Backend/backendService';
import DivClick from '../../../Generic/Inputs/DivClick';
import { copyToClipboard, displayErrorNotification, displayInfoNotification, getAmountTxt } from '../../../Utils/uiUtil';
import Checkbox from '../../../Generic/Inputs/Checkbox';
import Button from '../../../Generic/Inputs/Button';
import { setTheme } from '../../../Utils/themeUtils';
import style from './style.module.scss'
import { displayPage } from '../../../Utils/routerUtil';
import * as constants from '../../../constants.js'
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl'
import { getImageUrl } from '../../../Utils/uiUtil';

function ReferAFriendCash(props) {
    const [inProgress, setInProgress] = useState(false);
    const [copied, setCopied] = useState(false);
    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState(null);
    const [results, setResults] = useState(null);
    const [backendData, setBackendData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showResultModal, setShowResultModal] = useState(false);
    const [claimSelected, setClaimSelected] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();

    useEffect(() => {
        getReferFriendInfo();
    }, []);

    function copyInClipboard() {
        copyToClipboard(document, backendData?.referralUrl)
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    function toggleModal() {
        if (backendData?.info?.nbrFriendToClaim > 0) {
            for (let a = 0; a < backendData.claims.length; a++) {
                backendData.claims[a].selected = false
            }
            checkSelection();
            setShowModal(!showModal);
        }
    };

    function claimWager() {

        var idList = [];

        for (let a = 0; a < backendData.claims.length; a++) {
            var currentClaim = backendData.claims[a];
            if (currentClaim.selected === true) {
                idList.push(currentClaim.id);
            }
        }

        if (idList.length > 0) {
            setInProgress(true);
            backendService.claimReferAFriendCash(idList, claimWagerSuccess, claimWagerFailure, navigate, location.pathname);
        }

    }

    function claimWagerSuccess(data) {
        setInProgress(false);
        displayInfoNotification(intl.formatMessage({ id: 'newcasino.success.title', defaultMessage: 'Success' }), intl.formatMessage({ id: 'newcasino.referafriend.wager.success', defaultMessage: 'Amazing!!! You just got {money}.' }, { money: getAmountTxt(data.totalAmount) }));
        displayPage(constants.ROUTE_HOME, navigate);
    }

    function claimWagerFailure(data) {
        setInProgress(false);
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.contact.support', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
        displayPage(constants.ROUTE_HOME, navigate);
    }

    function getReferFriendInfo() {
        backendService.getReferFriendInfoCash(getReferFriendInfoSuccess, getReferFriendInfoFailure, navigate, location.pathname);
    }

    function getReferFriendInfoSuccess(data) {
        setBackendData(data);
    }

    function getReferFriendInfoFailure(data) {
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.contact.support', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
        displayPage(constants.ROUTE_HOME, navigate);
    }

    function onCheckBoxSelected(index) {
        var sel = backendData.claims[index].selected === undefined ? false : backendData.claims[index].selected
        backendData.claims[index].selected = !sel;
        checkSelection()
    }

    function checkSelection() {
        var found = false;
        for (let a = 0; a < backendData.claims.length; a++) {
            var currentClaim = backendData.claims[a];
            if (currentClaim.selected === true) {
                found = true;
                break;
            }
        }

        setClaimSelected(found);
    }

    return (
        <>
            <div className={style.master_page_container}>
                <div className={style.master_grid}>
                    <div>
                        <div className={style.title_txt}>
                            <FormattedMessage id="newcasino.referafriend.title" defaultMessage="Refer-a-Friend" />
                        </div>
                        <img className={style.separator} src={setTheme(getImageUrl('newui/themes/{THEME}/Raf/bottomline.png'))}></img>
                        <div>
                            <div className={style.span_spacer}>
                                <FormattedMessage
                                    id='newcasino.referafriend.info.part1'
                                    values={{
                                        amountEuro: (
                                            <span className={style.spinnerText} >
                                                <FormattedMessage id='newcasino.referafriend.info.part1-amountEuro' defaultMessage="17 Euro" />
                                            </span>
                                        ),
                                        wagerRequired: (
                                            <span className={style.spinnerText} >
                                                <FormattedMessage id='newcasino.referafriend.info.part1-wagerRequired' defaultMessage="1x" />
                                            </span>
                                        ),
                                        localCurrency: (
                                            <span className={style.spinnerText} >
                                                {getAmountTxt(backendData?.info?.cashPrizeInUserCurrency)}
                                            </span>
                                        )
                                    }}
                                />
                            </div>

                            <div className={style.span_spacer}>
                                <FormattedMessage
                                    id='newcasino.referafriend.info.part2'
                                />
                            </div>

                            <div className={style.span_spacer}>
                                <FormattedMessage
                                    id='newcasino.referafriend.info.part3'
                                />
                            </div>

                            <div className={style.sameLineAlignment}>
                                <img className={style.hearthImg} src={setTheme(getImageUrl('newui/themes/{THEME}/Raf/heart-up-down.png'))}></img>
                                <div>
                                    <FormattedMessage
                                        id="newcasino.referafriend.invite"
                                        defaultMessage="Invite your {friends}"
                                        values={{
                                            friends: (
                                                <span className={style.txt_yellow_bold} >
                                                    <FormattedMessage
                                                        id='newcasino.referafriend.invite.part1'
                                                        defaultMessage="friends"
                                                    />
                                                </span>
                                            )
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Link Line for Desktop */}
                            <div className={style.desktop}>
                                <div className={style.bubble_dark}>
                                    <div className={style.grid_link}>
                                        <div className={style.divLink}>
                                            <span className={style.rafLink_span}>
                                                <a href={backendData?.referralUrl} className={style.rafLink}>{backendData?.referralUrl}</a>
                                            </span>
                                        </div>

                                        <div className={style.divLink}>
                                            <Button iconTextAlign='left' colorStyle='light' icon='fa fa-clone' onClick={copyInClipboard} value={copied ? intl.formatMessage({ id: 'newcasino.referafriend.copied', defaultMessage: 'Copied' }) : intl.formatMessage({ id: 'newcasino.referafriend.copy', defaultMessage: 'Copy Link' })} />
                                        </div>
                                        <div className={style.vertLine}></div>
                                        <div className={style.divLink}>
                                            <div className={style.mediaBox}>
                                                <a href="https://www.facebook.com/PlayThenumbersgame" title="Facebook" target="new">
                                                    <img className={style.mediaBoxImg} src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/fb-icon.svg"))} />
                                                </a>
                                            </div>
                                        </div>
                                        <div className={style.divLink}>
                                            <div className={style.mediaBox}>
                                                <a title="Instagram" href="https://www.instagram.com/playnumbersgame/" target="new">
                                                    <img className={style.mediaBoxImg} src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/insta-icon.svg"))} />
                                                </a>
                                            </div>
                                        </div>
                                        <div className={style.divLink}>
                                            <div className={style.mediaBox}>
                                                <a title="Discord" href="https://discord.com/invite/7RnnvMuHkp" target="new">
                                                    <img className={style.mediaBoxImg} src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/discord-icon.svg"))} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Link Line for Mobile */}
                            <div className={style.mobile}>
                                <div className={style.bubble_dark}>
                                    <div className={style.grid_link}>
                                        <div className={style.divLink}>
                                            <span className={style.rafLink_span}>
                                                <a href={backendData?.referralUrl} className={style.rafLink}>{backendData?.referralUrl}</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className={style.grid_link_mobile}>
                                        <div className={style.divLink}>
                                            <Button colorStyle='light' icon='fa fa-clone' onClick={copyInClipboard} value={copied ? intl.formatMessage({ id: 'newcasino.referafriend.copied', defaultMessage: 'Copied' }) : intl.formatMessage({ id: 'newcasino.referafriend.copy', defaultMessage: 'Copy Link' })} />
                                        </div>
                                        <div className={style.vertLine}></div>
                                        <div className={style.divLink}>
                                            <div className={style.mediaBox}>
                                                <a href="https://www.facebook.com/PlayThenumbersgame" title="Facebook" target="new">
                                                    <img src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/fb-icon.svg"))} />
                                                </a>
                                            </div>
                                        </div>
                                        <div className={style.divLink}>
                                            <div className={style.mediaBox}>
                                                <a title="Instagram" href="https://www.instagram.com/playnumbersgame/" target="new">
                                                    <img src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/insta-icon.svg"))} />
                                                </a>
                                            </div>
                                        </div>
                                        <div className={style.divLink}>
                                            <div className={style.mediaBox}>
                                                <a title="Discord" href="https://discord.com/invite/7RnnvMuHkp" target="new">
                                                    <img src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/discord-icon.svg"))} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img className={style.separator} src={setTheme(getImageUrl('newui/themes/{THEME}/Raf/bottomline.png'))}></img>
                            {/* Claim Line for Desktop */}
                            <div className={style.desktop}>
                                <div className={style.bubble_light}>
                                    <div className={style.grid_claim}>
                                        <div className={style.divClaimLogo}>
                                            <img className={style.spinLogo} src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/spin.svg"))} />
                                        </div>
                                        <div className={style.divClaim}>
                                            <div className={style.txt_yellow_bold}>
                                                <FormattedMessage id='newcasino.referafriend.money.to.claim' defaultMessage="Wager availaible" />
                                            </div>
                                        </div>
                                        <div className={style.divClaim}>
                                            <p className={style.txt_big_number}>{backendData?.info?.nbrFriendToClaim}</p>
                                        </div>
                                        <div className={style.divClaim}>
                                            <div className={style.vertLine_dark} />
                                        </div>
                                        <div className={style.divClaim}>
                                            <Button width='240px' colorStyle='light' icon='fa fa-smile-o' active={backendData?.claims?.length > 0} onClick={toggleModal} value={intl.formatMessage({ id: 'newcasino.referafriend.claim', defaultMessage: 'CLAIM MY WAGERS' })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Claim Line for Mobile */}
                            <div className={style.mobile}>
                                <div className={style.bubble_light}>
                                    <div className={style.grid_claim}>
                                        <div className={style.divClaimLogo}>
                                            <img className={style.spinLogo} src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/spin.svg"))} />
                                        </div>
                                        <div className={style.divClaim}>
                                            <div className={style.txt_yellow_bold}>
                                                <FormattedMessage id='newcasino.referafriend.money.to.claim' defaultMessage="Wager availaible" />
                                            </div>
                                        </div>
                                        <div className={style.divClaim}>
                                            <p className={style.txt_big_number}>{backendData?.info?.nbrFriendToClaim}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className={style.grid_claim_mobile}>
                                        <div className={style.divClaimCenter}>
                                            <Button width='240px' colorStyle='light' icon='fa fa-smile-o' active={backendData?.claims?.length > 0} onClick={toggleModal} value={intl.formatMessage({ id: 'newcasino.referafriend.claim', defaultMessage: 'CLAIM MY WAGERS' })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img className={style.separator} src={setTheme(getImageUrl('newui/themes/{THEME}/Raf/bottomline.png'))}></img>
                            <div className={style.bubble_light}>
                                <div className={style.grid_stat}>
                                    <div className={style.divStatLogo}>
                                        <img className={style.spinLogo} src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/spin.svg"))} />
                                    </div>
                                    <div className={style.divStat}>
                                        <div className={style.divStatGrid}>
                                            <div className={style.txt_yellow_bold}>
                                                <FormattedMessage id='newcasino.referafriend.nbr.player.use.link' defaultMessage="Friends who register with your link" />
                                            </div>
                                            <div className={style.info_result}>{backendData?.info?.nbrFriendRegisteredWithLink}</div>
                                        </div>
                                        <div className={style.divStatGrid}>
                                            <div className={style.txt_yellow_bold}>
                                                <FormattedMessage id='newcasino.referafriend.nbr.player.deposit' defaultMessage="Friends who deposit money" />
                                            </div>
                                            <div className={style.info_result}>{backendData?.info?.nbrFriendWhoDeposit}</div>
                                        </div>
                                        <div className={style.divStatGrid}>
                                            <div className={style.txt_yellow_bold}>
                                                <FormattedMessage id='newcasino.referafriend.nbr.player.wager' defaultMessage="Friends who beat the wager of 1x" />
                                            </div>
                                            <div className={style.info_result}>{backendData?.info?.nbrFriendWhoWager}</div>
                                        </div>
                                        <div className={style.divStatGrid}>
                                            <div className={style.txt_yellow_bold}>
                                                <FormattedMessage id='newcasino.referafriend.money.claimed' defaultMessage="Wagers you already claimed" />
                                            </div>
                                            <div className={style.info_result}>{backendData?.info?.nbrFriendAlreadyClaimed}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* MODALS COMPONENTS */}

                            {showModal &&
                                <div className={style.theModal}>
                                    <div className={style.theModalContent}>
                                        <div className={style.title_txt}>
                                            <FormattedMessage id="newcasino.referafriend.popup.title" defaultMessage="Claim my Cash" />
                                        </div>

                                        {inProgress &&
                                            <center>
                                                <div className={style.waitingBox}>
                                                    <Spin size="large" />&nbsp;&nbsp; <FormattedMessage
                                                        id='newcasino.referafriend.popup.wait'
                                                        defaultMessage="Please Wait..."
                                                    />
                                                </div>
                                            </center>
                                        }

                                        {!(inProgress) &&
                                            <>
                                                <div>
                                                    <FormattedMessage
                                                        id='newcasino.referafriend.popup.text'
                                                        defaultMessage="Select the Euro(s) you wish to use. If the amounts are in foreign currency they will be automatically converted."
                                                    />
                                                </div>
                                                <div className={style.availableWagersGridTitle}>
                                                    <div className={style.availTitle}>
                                                        <FormattedMessage id='newcasino.referafriend.header.name' defaultMessage="Friend Name" />
                                                    </div>
                                                    <div className={style.availTitle}>
                                                        <FormattedMessage id='newcasino.referafriend.header.amount' defaultMessage="Amount" />
                                                    </div>
                                                    <div />
                                                </div>
                                                <div className={style.availableWagersGridScrollable}>
                                                    {backendData?.claims.map((item, index) => (
                                                        <React.Fragment key={'wagerList_' + index}>
                                                            <div className={style.availTxt}>
                                                                {item.kycFullName}
                                                            </div>
                                                            <div className={style.availTxt}>
                                                                &#8364;17.00
                                                            </div>
                                                            <div className={style.centerVert}>
                                                                <Checkbox selected={item.selected} onClick={() => onCheckBoxSelected(index)} label='' />
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                                <Button key={Math.random()} active={claimSelected} value={intl.formatMessage({ id: 'newcasino.referafriend.button.claim', defaultMessage: 'Claim!' })} onClick={() => claimWager()} />
                                                <br />

                                                <DivClick className={style.clickable} onClick={toggleModal}>
                                                    <img className={style.chevron} src={setTheme(getImageUrl('newui/themes/{THEME}/Raf/chevron.svg'))} />
                                                    <span className={style.cancelTxt}>
                                                        <FormattedMessage id='newcasino.referafriend.popup.close' defaultMessage="Cancel" />
                                                    </span>
                                                </DivClick>
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={style.rafImage}>
                        <img src={setTheme(getImageUrl("newui/themes/{THEME}/Raf/big-img.png"))} />
                    </div>
                </div>
            </div >
        </>
    );
}

export default injectIntl(ReferAFriendCash);
