import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import * as constants from '../../../../constants.js'
import style from './style.module.scss'

import ChooseBonus, { REGISTRATION_STEP1_PROMOTION, PROMO_CODE } from './step/ChooseBonus';
import UserPassword, { DATA_USERPASSWORD_EMAIL, DATA_USERPASSWORD_PASSWORD, DATA_USERPASSWORD_LICENSE, DATA_USERPASSWORD_PROMOCODE } from './step/UserPassword';
import UserGeneralInfo, { DATA_USERGENERALINFO_LASTNAME, DATA_USERGENERALINFO_FIRSTNAME, DATA_USERGENERALINFO_BIRTHDAY } from './step/UserGeneralInfo';
import UserAddress, { DATA_USERADDRESS_ADDRESS, DATA_USERADDRESS_COUNTRY, DATA_USERADDRESS_STATE, DATA_USERADDRESS_CURRENCY, DATA_USERADDRESS_CITY, DATA_USERADDRESS_ZIPCODE, DATA_USERADDRESS_PHONE, DATA_USERADDRESS_PHONECODE } from './step/UserAddress';
import MobileConfirmation, { DATA_MOBILECONFIRMATION_CODE } from './step/MobileConfirmation';
import EmailConfirmation, { DATA_EMAILCONFIRMATION_CODE } from './step/EmailConfirmation';
import Completed from './step/Completed';
import * as backendService from '../../../../Services/Backend/backendService'
import { displayPage } from '../../../../Utils/routerUtil';
import { useIntl } from 'react-intl';
import TermsDisplay from './step/ShowTerms';
import PolicyDisplay from './step/ShowPolicy';
import { displayErrorNotification, displayGenericErrorNotificationIntl, displayInfoNotification } from '../../../../Utils/uiUtil';
import ApiCache from '../../../../Utils/ApiCache';
import Header from '../../Modal/Header';
import Footer from '../../Modal/Footer';

function Register(props) {

  const [searchParams] = useSearchParams();
  const intl = useIntl();

  const STEP_ChooseBonus = 1;
  const STEP_UserPassword = 2;
  const STEP_UserGeneralInfo = 3;
  const STEP_UserAddress = 4;
  const STEP_MobileConfirmation = 5;
  const STEP_EmailConfirmation = 6;
  const STEP_Completed = 7;

  const STEP_SHOW_TERMS = 98;
  const STEP_SHOW_POLICY = 99;

  const navigate = useNavigate();
  const location = useLocation();

  const [isReferedByAFriend] = useState(isReferedByFriend())
  const [stepData, setStepData] = useState(getInitialStepsValues(searchParams.get('email')))
  const [currentStep, setCurrentStep] = useState(isEmailVerification() ? STEP_EmailConfirmation : STEP_ChooseBonus);
  const [countryList, setCountryList] = useState(null);
  const [currencyList] = useState(createCurrencyList());

  useEffect(() => {
    loadNeededInformations()
  },// eslint-disable-next-line
    [])

  function createCurrencyList() {
    return [
      { name: intl.formatMessage({ id: 'newcasino.auth.registration.currency.canada', defaultMessage: 'Canadian Dollar' }), cod: 'CAD' },
      { name: intl.formatMessage({ id: 'newcasino.auth.registration.currency.usa', defaultMessage: 'American Dollar' }), cod: 'USD' },
      { name: intl.formatMessage({ id: 'newcasino.auth.registration.currency.australia', defaultMessage: 'Australian Dollar' }), cod: 'AUD' },
      { name: intl.formatMessage({ id: 'newcasino.auth.registration.currency.brazil', defaultMessage: 'Brazilian Real' }), cod: 'BRL' },
      { name: intl.formatMessage({ id: 'newcasino.auth.registration.currency.europe', defaultMessage: 'European Euro' }), cod: 'EUR' },
    ]
  }

  function loadNeededInformations() {
    props.spinnerOn();
    backendService.getCountryList(successCountryListCallBack, failureCountryListCallBack, navigate, location.pathname);
  }

  function successCountryListCallBack(data) {
    props.spinnerOff();
    backendService.setInCache(backendService.CACHE_COUNTRY_LIST, data, ApiCache.getInstance());
    setCountryList(data);
  }

  function failureCountryListCallBack(err) {
    props.spinnerOff();
  }

  function isReferedByFriend() {
    var toReturn = false;

    if (props.urlParams) {
      var hash = props.urlParams.hash;
      if (hash && hash.startsWith('refer-')) {
        toReturn = true;
      }
    }

    return toReturn;
  }

  function isEmailVerification() {
    var toReturn = false;

    if (props.searchParams) {
      var uuid = props.searchParams.getAll('uuid') ? ((props.searchParams.getAll('uuid').length > 0) ? props.searchParams.getAll('uuid')[0] : null) : null
      if (uuid && uuid.length > 0) {
        toReturn = true;
      }
    }

    return toReturn;
  }


  function getInitialStepsValues(emailParam) {
    var step1 = { valid: false, fields: new Map() };
    var step5 = { valid: false, fields: new Map() };

    if (isEmailVerification()) {
      var theMap2 = new Map();
      theMap2.set("code", { value: props.searchParams.getAll('uuid')[0], valid: true });
      step5 = { valid: true, fields: theMap2 };
    }

    var toReturn = [
      step1,
      { valid: false, fields: new Map() },
      { valid: false, fields: new Map() },
      { valid: false, fields: new Map() },
      { valid: false, fields: new Map() },
      step5
    ]

    if (emailParam) {
      toReturn[1].fields.set(DATA_USERPASSWORD_EMAIL, { value: emailParam, valid: true });
    }

    return toReturn;
  }

  function setStepValidity(arrayIndex, value, data, reset) {

    var newArray = reset ? getInitialStepsValues(searchParams.get('email')) : [...stepData];
    newArray[arrayIndex].valid = value;
    newArray[arrayIndex].fields = data;
    setStepData(newArray);
  }

  function validationChooseBonusCallBack(isValid, data) {
    setStepValidity(0, isValid, data, true);
  }

  function validationUserPasswordCallBack(isValid, data) {
    setStepValidity(1, isValid, data, false);
  }

  function validationStepUserGeneralInfoCallBack(isValid, data) {
    setStepValidity(2, isValid, data, false);
  }

  function validationStepUserAddressCallBack(isValid, data) {
    setStepValidity(3, isValid, data, false);
  }

  function validationStepMobileConfirmationCallBack(isValid, data) {
    setStepValidity(4, isValid, data, false);
  }

  function validationStepEmailConfirmationCallBack(isValid, data) {
    setStepValidity(5, isValid, data, false);
  }

  function addInfoToAccount() {
    if (stepData[2].valid === true && stepData[3].valid === true) {
      var email = stepData[1].fields.get(DATA_USERPASSWORD_EMAIL).value;
      var firstName = stepData[2].fields.get(DATA_USERGENERALINFO_FIRSTNAME).value;
      var lastName = stepData[2].fields.get(DATA_USERGENERALINFO_LASTNAME).value;
      var birthday = stepData[2].fields.get(DATA_USERGENERALINFO_BIRTHDAY).value;

      var adresse = stepData[3].fields.get(DATA_USERADDRESS_ADDRESS).value;
      var city = stepData[3].fields.get(DATA_USERADDRESS_CITY).value;
      var zipcode = stepData[3].fields.get(DATA_USERADDRESS_ZIPCODE).value;
      var country = stepData[3].fields.get(DATA_USERADDRESS_COUNTRY).value;
      var state = stepData[3].fields.get(DATA_USERADDRESS_STATE).value;
      var currency = stepData[3].fields.get(DATA_USERADDRESS_CURRENCY).value;
      var phonecode = stepData[3].fields.get(DATA_USERADDRESS_PHONECODE).value;
      var phone = stepData[3].fields.get(DATA_USERADDRESS_PHONE).value;

      props.spinnerOn();
      backendService.addInfoToAccount(email, firstName, lastName, birthday, adresse, city, zipcode, country, state, currency, phonecode, phone, onSuccessAddInfoToAccount, onFailureAddInfoToAccount, navigate, location.pathname);
    }
  }

  function onSuccessAddInfoToAccount(resp) {
    props.spinnerOff();

    if (resp && resp.smsSend === false) {
      displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.sms.send.failed', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
    } else {
      next();
    }
  }

  function onFailureAddInfoToAccount(resp) {
    props.spinnerOff();
    displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.contact.support', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
  }

  function validateEmailCode() {
    if (stepData[5].valid === true) {
      var code = stepData[5].fields.get(DATA_EMAILCONFIRMATION_CODE).value;

      props.spinnerOn();
      backendService.validateEmailCode(code, onSuccessValidateEmailCode, onFailureValidateEmailCode, navigate, location.pathname);
    }
  }

  function onSuccessValidateEmailCode(resp) {
    props.spinnerOff();

    if (resp && resp.emailValidated === false) {
      displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.email.validation.failed', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
    } else {
      next();
    }
  }

  function onFailureValidateEmailCode(resp) {
    props.spinnerOff();
    displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.contact.support', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
  }

  function validateMobileCode() {
    if (stepData[4].valid === true) {
      var email = stepData[1].fields.get(DATA_USERPASSWORD_EMAIL).value;
      var code = stepData[4].fields.get(DATA_MOBILECONFIRMATION_CODE).value;
      var phonecode = stepData[3].fields.get(DATA_USERADDRESS_PHONECODE).value;
      var phone = stepData[3].fields.get(DATA_USERADDRESS_PHONE).value;

      props.spinnerOn();
      backendService.validateMobileCode(email, code, phonecode, phone, onSuccessValidateMobileCode, onFailureValidateMobileCode, navigate, location.pathname);
    }
  }

  function onSuccessValidateMobileCode(resp) {
    props.spinnerOff();

    if (resp && resp.phoneValidated === false) {
      displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.sms.validation.failed', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
    } else if (resp && resp.emailSend === false) {
      displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.email.send.failed', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
    } else {
      next();
    }

  }


  function onFailureValidateMobileCode(resp) {
    props.spinnerOff();
    displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.contact.support', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
  }

  function createAccount() {
    if (stepData[1].valid === true && stepData[0].valid === true) {
      var promo = stepData[0].fields.get(REGISTRATION_STEP1_PROMOTION).value;
      var email = stepData[1].fields.get(DATA_USERPASSWORD_EMAIL).value;
      var password = stepData[1].fields.get(DATA_USERPASSWORD_PASSWORD).value;
      var license = stepData[1].fields.get(DATA_USERPASSWORD_LICENSE).value;
      var sponsorCode = props.sponsorCode ? props.sponsorCode : null;
      var promodata = stepData[1].fields.get(DATA_USERPASSWORD_PROMOCODE).value;

      props.spinnerOn();
      var referByFriendData = {
        active: false,
      }

      if (isReferedByAFriend) {
        referByFriendData = {
          active: isReferedByAFriend,
          hash: props.urlParams.hash
        }
      }

      backendService.createAccount(email, password, sponsorCode, license, promo, promodata, referByFriendData, onSuccessCreateAccount, onSuccessCreateAccountJWT, onFailureCreateAccount, navigate, location.pathname);
    }

  }

  function onSuccessCreateAccountJWT(resp) {
    props.spinnerOff();

    if (resp && resp.userCreate === false) {
      displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.user.creation.failed', defaultMessage: 'This email is already assign' }));
    } else {
      next();
    }
  }

  function onSuccessCreateAccount(resp) {
    props.spinnerOff();

    if (resp && resp.data && resp.data.userCreate === false) {
      displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.user.creation.failed', defaultMessage: 'This email is already assign' }));
    } else {
      next();
    }
  }

  function onFailureCreateAccount(resp) {
    props.spinnerOff();
    displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.contact.support', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
  }

  function back() {
    if (currentStep === STEP_SHOW_TERMS || currentStep === STEP_SHOW_POLICY) {
      setCurrentStep(STEP_UserPassword);
    }
    else if (currentStep > STEP_ChooseBonus) {
      setCurrentStep(currentStep - 1);
    }
  }

  function next() {
    if (currentStep < STEP_Completed) {
      setCurrentStep(currentStep + 1);
    }
  }

  function resendEmail() {
    backendService.resendVerificationEmail(stepData[1].fields.get(DATA_USERPASSWORD_EMAIL).value, onSuccessResendEmail, onFailureResendEmail, navigate, location.pathname)
  }

  function onSuccessResendEmail(data) {
    if (data && data.success !== 'OK') {
      displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.email.send.failed', defaultMessage: 'We were not able to send a verification code to your email address, Please contact support.' }));
    } else {
      displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.error.email.send.success', defaultMessage: 'The email has been resend to your mailbox.' }));
    }
  }

  function onFailureResendEmail(data) {
    displayGenericErrorNotificationIntl(intl);
  }

  function resendSMS() {
    backendService.resendVerificationSms(stepData[1].fields.get(DATA_USERPASSWORD_EMAIL).value, stepData[3].fields.get(DATA_USERADDRESS_PHONECODE).value, stepData[3].fields.get(DATA_USERADDRESS_PHONE).value, onSuccessResendSMS, onFailureResendSMS, navigate, location.pathname)
  }

  function onSuccessResendSMS(resp) {
    if (resp && resp.smsSend === false) {
      displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.sms.send.failed', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
    } else {
      displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.error.sms.send.success', defaultMessage: 'The sms has been resend to your phone.' }));
    }
  }

  function onFailureResendSMS(data) {
    displayGenericErrorNotificationIntl(intl);
  }

  function showTerms() {
    setCurrentStep(STEP_SHOW_TERMS);
  }

  function showPolicy() {
    setCurrentStep(STEP_SHOW_POLICY);
  }

  function displayLoginPage() {
    props.closeModal();
    displayPage(constants.ROUTE_LOGIN, navigate);
  }

  try {

    var header = null;
    var body = null;
    var footer = null;

    if (currentStep === STEP_ChooseBonus) {
      var preSubtitle = null;
      var subTitle = intl.formatMessage({ id: 'newcasino.auth.registration.bonus.subTitle', defaultMessage: 'Welcome to Numbers Game! Choose a welcome bonus to proceed.' });
      if (isReferedByAFriend) {
        preSubtitle = intl.formatMessage({ id: 'newcasino.auth.registration.bonus.refered', defaultMessage: 'You friend refered you! ' });
        subTitle = intl.formatMessage({ id: 'newcasino.auth.registration.bonus.refer2', defaultMessage: 'Proceed to the next step or choose a promotion.' })
      }
      header = <Header title={intl.formatMessage({ id: 'newcasino.auth.registration.bonus.title', defaultMessage: 'Registration' })} preSubTitle={preSubtitle} subTitle={subTitle} closeModal={props.closeModal} />
      body = <ChooseBonus isReferedByAFriend={isReferedByAFriend} hash={props.urlParams?.hash} validatorInformer={validationChooseBonusCallBack} value={stepData[0]} />
      footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.bonus.cancel', defaultMessage: 'Cancel' }), action: props.closeModal, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.bonus.next', defaultMessage: 'Next Step 1/5' }), action: next, canProcess: stepData[0].valid }} subTitle={{ text: intl.formatMessage({ id: 'newcasino.auth.registration.bonus.already', defaultMessage: 'Already have an account?' }), link: intl.formatMessage({ id: 'newcasino.auth.registration.bonus.already.link', defaultMessage: 'Login' }), linkAction: displayLoginPage }} />
    }
    else if (currentStep === STEP_UserPassword) {
      header = <Header title={intl.formatMessage({ id: 'newcasino.auth.registration.cred.title', defaultMessage: 'Registration' })} subTitle={intl.formatMessage({ id: 'newcasino.auth.registration.cred.subTitle', defaultMessage: 'Enter the required information to register.' })} closeModal={props.closeModal} />
      body = <UserPassword showTerms={showTerms} showPolicy={showPolicy} hasPromocode={stepData[0].fields.get(REGISTRATION_STEP1_PROMOTION).value === PROMO_CODE} validatorInformer={validationUserPasswordCallBack} value={stepData[1]} />
      footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.cred.back', defaultMessage: 'Back' }), action: back, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.cred.next', defaultMessage: 'Next Step 2/5' }), action: createAccount, canProcess: stepData[1].valid }} />
    }
    else if (currentStep === STEP_UserGeneralInfo) {
      header = <Header title={intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.title', defaultMessage: 'Registration' })} subTitle={intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.subTitle', defaultMessage: 'Enter the required information to register.' })} closeModal={props.closeModal} />
      body = <UserGeneralInfo validatorInformer={validationStepUserGeneralInfoCallBack} value={stepData[2]} />
      footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.back', defaultMessage: 'Back' }), action: back, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.next', defaultMessage: 'Next Step 3/5' }), action: next, canProcess: stepData[2].valid }} />
    }
    else if (currentStep === STEP_UserAddress) {
      if (countryList && currencyList) {
        header = <Header title={intl.formatMessage({ id: 'newcasino.auth.registration.address.title', defaultMessage: 'Registration' })} subTitle={intl.formatMessage({ id: 'newcasino.auth.registration.address.subTitle', defaultMessage: 'Enter the required information to register.' })} closeModal={props.closeModal} />
        body = <UserAddress currencyList={currencyList} countryList={countryList} validatorInformer={validationStepUserAddressCallBack} value={stepData[3]} />
        footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.address.back', defaultMessage: 'Back' }), action: back, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.address.next', defaultMessage: 'Next Step 4/5' }), action: addInfoToAccount, canProcess: stepData[3].valid }} />
      }
    }
    else if (currentStep === STEP_MobileConfirmation) {
      header = <Header title={intl.formatMessage({ id: 'newcasino.auth.registration.mobilecode.title', defaultMessage: 'Registration' })} subTitle={intl.formatMessage({ id: 'newcasino.auth.registration.mobilecode.subTitle', defaultMessage: 'We have sent a confirmation code to your phone number. Enter the code to complete your registration.' })} closeModal={props.closeModal} />
      body = <MobileConfirmation validatorInformer={validationStepMobileConfirmationCallBack} value={stepData[4]} />
      footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.mobilecode.back', defaultMessage: 'Back' }), action: back, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.mobilecode.next', defaultMessage: 'Next Step 5/5' }), action: validateMobileCode, canProcess: stepData[4].valid }} subTitle={{ text: intl.formatMessage({ id: 'newcasino.auth.registration.mobilecode.resend', defaultMessage: 'Did not received the sms?' }), link: intl.formatMessage({ id: 'newcasino.auth.registration.mobilecode.resend.link', defaultMessage: 'Click here to send it again' }), linkAction: resendSMS }} />
    }
    else if (currentStep === STEP_EmailConfirmation) {
      header = <Header title={intl.formatMessage({ id: 'newcasino.auth.registration.emailcode.title', defaultMessage: 'Registration' })} subTitle={intl.formatMessage({ id: 'newcasino.auth.registration.emailcode.subTitle', defaultMessage: 'We have sent a confirmation code to your email address. Enter the code to complete your registration.' })} closeModal={props.closeModal} />
      body = <EmailConfirmation validatorInformer={validationStepEmailConfirmationCallBack} submit={validateEmailCode} value={stepData[5]} />
      footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.emailcode.back', defaultMessage: 'Back' }), action: back, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.emailcode.next', defaultMessage: 'Complete' }), action: validateEmailCode, canProcess: stepData[5].valid }} subTitle={{ text: intl.formatMessage({ id: 'newcasino.auth.registration.emailcode.resend', defaultMessage: 'Did not received the email' }), link: intl.formatMessage({ id: 'newcasino.auth.registration.emailcode.resend.link', defaultMessage: 'Click here to send it again' }), linkAction: resendEmail }} />
    }
    else if (currentStep === STEP_Completed) {
      header = <Header title={intl.formatMessage({ id: 'newcasino.auth.registration.completed.title', defaultMessage: 'Registration completed' })} subTitle='' closeModal={props.closeModal} />
      body = <Completed />
      footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.completed.next', defaultMessage: 'Go to login page' }), action: displayLoginPage }} />
    }
    else if (currentStep === STEP_SHOW_TERMS) {
      header = <Header title={intl.formatMessage({ id: 'newcasino.auth.registration.showterms.title', defaultMessage: 'Terms ans Conditions' })} subTitle='' closeModal={back} />
      body = <TermsDisplay />
      footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.showterms.back', defaultMessage: 'Back' }), action: back, chevron: 'left' }} />
    }
    else if (currentStep === STEP_SHOW_POLICY) {
      header = <Header title={intl.formatMessage({ id: 'newcasino.auth.registration.showpolicy.title', defaultMessage: 'Privacy Policy' })} subTitle='' closeModal={back} />
      body = <PolicyDisplay />
      footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.auth.registration.showpolicy.back', defaultMessage: 'Back' }), action: back, chevron: 'left' }} />
    }
    return (
      <>
        <div className={style.registerMainDiv}>
          <div className={style.registerMainDivHeader}>
            {header}
          </div>
          <div className={style.registerMainDivBody}>
            {body}
          </div>
          <div className={style.registerMainDivFooter}>
            {footer}
          </div>
        </div>
      </>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default Register