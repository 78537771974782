import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl'
import { canAccess } from '../../Utils/authorizationUtils'
import { setTheme } from '../../Utils/uiUtil'
import { displayPage } from '../../Utils/routerUtil'
import { useNavigate, useLocation } from 'react-router-dom'
import ApiPersistence from '../../Utils/ApiPersistence';
import DivClick from '../../Generic/Inputs/DivClick';

function BorderMenu(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuCategories] = useState(props.definition.elements);
  const [selectedElement, setSelectedElement] = useState(0);
  var showStyle = style.show;

  const user = ApiPersistence.getInstance().getUser()

  useEffect(() => {
    checkCurrentLocation(location.pathname);
  },// eslint-disable-next-line  
    [])

  function checkCurrentLocation(currentPath) {
    for (let i = 0; i < menuCategories.length; i++) {
      if (menuCategories[i].action === currentPath && selectedElement !== i) {
        setSelectedElement(i)
        return;
      }
    }
  }

  function getImageToShow(elemIndex, elementObj) {
    var toReturn = elementObj.images.notSelected;
    if (isSelected(elemIndex)) {
      toReturn = elementObj.images.selected;
    }
    return setTheme(toReturn);
  }

  function newSelection(elemIndexParam, elementObj) {
    setSelectedElement(elemIndexParam)
    props.resetToLoggedUser();
    displayPage(elementObj.action, navigate, location.pathname);
  }

  function isSelected(elemIndexParam) {
    return (elemIndexParam === selectedElement)
  }

  function getElementMapStyle(elemIndex) {
    var toReturn = [style.borderMenu_elemBar_color_notSelected, style.borderMenu_elemBar_title_notSelected];
    if (isSelected(elemIndex)) {
      toReturn = [style.borderMenu_elemBar_color_selected, style.borderMenu_elemBar_title_selected];
    }
    return toReturn;
  }

  try {
    if (menuCategories) {
      return (
        <div className={`${style.borderMenu_div} ${showStyle}`}>
          <div className={`${style.scrollableBorder}`}>
            <div key={'borderMenuCat'}>
              {menuCategories.map((itemElem, indexElem) => ((canAccess(user, itemElem)) ? (
                <DivClick key={'borderMenuCat_' + indexElem} className={`${style.borderMenuElem_row} ${getElementMapStyle(indexElem)[0]}`} onClick={() => newSelection(indexElem, itemElem)}>
                  <div className={`${style.borderMenu_elemBar} ${style.borderMenu_elem_grid}`}>
                    <div className={`${style.borderMenu_elemBar_image}`}>
                      <div className={`${style.image_trans}`} style={{
                        backgroundImage: getImageToShow(indexElem, itemElem)
                      }} />
                    </div>
                    <div className={`${getElementMapStyle(indexElem)[1]}`}>
                      {(!(itemElem.type)) &&
                        <FormattedMessage
                          id={itemElem.intl}
                          defaultMessage={itemElem.default}
                        />
                      }
                    </div>
                  </div>
                </DivClick>
              ) : ''
              )
              )}
            </div>
          </div>
          <div className={style.copyright}><FormattedMessage id="newcasino.aff.menuleft.copyright1" defaultMessage="© Copyright 2024 Numbers Game" /></div>
          <div className={style.copyright}><FormattedMessage id="newcasino.aff.menuleft.copyright2" defaultMessage="All rights reserved." /></div>
        </div>
      );
    } else {
      return (
        <></>
      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}
export default BorderMenu