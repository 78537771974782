import React, { useState } from 'react'
import style from './style.module.scss'
import Button from '../../../Generic/Inputs/Button';
import DivClick from '../../../Generic/Inputs/DivClick';
//import ReCAPTCHA from 'react-google-recaptcha';
import TextField from '../../../Generic/Inputs/TextField';
import { useLocation, useNavigate } from 'react-router-dom'
import * as jwtBackendService from '../../../Services/JWTBackend/jwtBackendService';
import { displayErrorNotificationIntl, displayInfoNotificationIntl, isEmail, isTextMin5Char } from '../../../Utils/uiUtil';
import { useIntl } from 'react-intl';

function ContactUs(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [nameValid, setNameValid] = useState(null);
  const [emailValid, setEmailValid] = useState(null);
  const [subjectValid, setSubjectValid] = useState(null);
  const [messageValid, setMessageValid] = useState(null);

  function sendMessage() {

    var theData = {
      fullname: name,
      email: email,
      subject: subject,
      message: message,
    }

    jwtBackendService.sendContactUsMsg(theData, successSendContactUsMsgJWT, failureSendContactUsMsgJWT, navigate, location.pathname);
  }

  function nameValidator(isValid, newValue) {
    setName(newValue)
    setNameValid(isValid)
  }

  function emailValidator(isValid, newValue) {
    setEmail(newValue)
    setEmailValid(isValid)
  }

  function msgValidator(isValid, newValue) {
    setMessage(newValue)
    setMessageValid(isValid)
  }

  function subjectValidator(isValid, newValue) {
    setSubject(newValue)
    setSubjectValid(isValid)
  }

  function isAllValid() {
    return nameValid && emailValid && subjectValid && messageValid;
  }

  function successSendContactUsMsgJWT(data) {
    props.closeModal();
    displayInfoNotificationIntl(intl, 'newcasino.aff.contactus.success')
  }

  function failureSendContactUsMsgJWT(data) {
    displayErrorNotificationIntl(intl, 'newcasino.aff.contactus.failure')
  }


  try {
    return (
      <div className={style.successPop_mainGrid}>
        <div className={style.successPop_welcomeGrid}>
          <div className={style.successPop_welcomeTxt}>
            <div>{intl.formatMessage({ id: 'newcasino.aff.contactus.title', defaultMessage: 'Contact Us' })}</div>
            <div className={style.login_spacer} />
          </div>
          <DivClick className={style.successPop_xClose} onClick={() => props.closeModal()}>
            X
          </DivClick>
        </div>
        <div className={style.contentGrid}>
          <div className={style.contentLeft}>
            <div className={style.contentTitle}>
              <div className={style.titleGrid}>
                <div className={style.titleGridElem}>
                  <i class="fa fa-question-circle fa-2x" aria-hidden="true"></i>
                </div>
                <div className={style.titleGridElem}>
                  {intl.formatMessage({ id: 'newcasino.aff.contactus.sub1', defaultMessage: 'Have a question? Contact us' })}
                </div>
              </div>
            </div>
            <div className={style.contentContent}>
              <div className={style.input}><TextField height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} values={[name, nameValid]} autofocus='true' showCheck='true' hint={intl.formatMessage({ id: 'newcasino.aff.contactus.name', defaultMessage: 'What is your name?' })} maxLength='50' mandatory={true} validator={isTextMin5Char} validatorInformer={nameValidator} /></div>
              <div className={style.input}><TextField height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} values={[email, emailValid]} displayCheck={true} showCheck='true' hint={intl.formatMessage({ id: 'newcasino.aff.contactus.email', defaultMessage: 'What is your email?' })} maxLength='60' mandatory={true} validator={isEmail} validatorInformer={emailValidator} /></div>
              <div className={style.input}><TextField height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} values={[subject, subjectValid]} displayCheck={true} showCheck='true' hint={intl.formatMessage({ id: 'newcasino.aff.contactus.subject', defaultMessage: 'What is the subject?' })} maxLength='50' mandatory={true} validator={isTextMin5Char} validatorInformer={subjectValidator} /></div>
              <div className={style.input}><TextField border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} values={[message, messageValid]} displayCheck={true} showCheck='true' type='textarea' maxLength='400' mandatory={true} validator={isTextMin5Char} hint={intl.formatMessage({ id: 'newcasino.aff.contactus.msg', defaultMessage: 'Type your message here' })} validatorInformer={msgValidator} /></div>
            </div>
          </div >
          <div>&nbsp;</div>
          <div className={style.contentRight}>
            <div className={style.contentTitle}>
              <div className={style.titleGrid}>
                <div className={style.titleGridElem}>
                  <i class="fa fa-globe fa-2x" aria-hidden="true"></i>
                </div>
                <div className={style.titleGridElem}>
                  {intl.formatMessage({ id: 'newcasino.aff.contactus.sub2', defaultMessage: 'Where to find us?' })}
                </div>
              </div>
            </div>
            <div className={style.contentContent}>
              {intl.formatMessage({ id: 'newcasino.aff.contactus.add1', defaultMessage: 'First Floor, Millennium House,' })}<br />
              {intl.formatMessage({ id: 'newcasino.aff.contactus.add2', defaultMessage: 'Victoria Road, Douglas, IM24RW,' })}<br />
              {intl.formatMessage({ id: 'newcasino.aff.contactus.add3', defaultMessage: 'Isle of Man' })}
            </div>
          </div>

        </div >
        <div className={style.successPop_button}>
          <Button active={isAllValid()} width='160px' colorStyle='light' value={intl.formatMessage({ id: 'newcasino.aff.contactus.button.send', defaultMessage: 'Send your message' })} onClick={sendMessage} />
        </div>
      </div >

    );

  } catch (error) {
    console.log('ERROR: ' + error.message);

  }
}

export default ContactUs
