import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import * as constants from '../../../constants.js'
import { useIntl } from 'react-intl';
import Header from '../Modal/Header';
import TextField from '../../../Generic/Inputs/TextField'
import Button from '../../../Generic/Inputs/Button'
import { displayErrorNotification, displayGenericErrorNotificationIntl, displayInfoNotification, getAmountTxt, isTextMin2Char } from '../../../Utils/uiUtil'
import * as jwtBackendService from '../../../Services/JWTBackend/jwtBackendService';
import { useLocation, useNavigate } from 'react-router-dom';
import { displayPage } from '../../../Utils/routerUtil';

function PromoCode(props) {
  const [isCashierPage] = useState(props.isCashierPage ? true : false);
  const [showHeader] = useState(props.isCashierPage ? false : true);

  const [promoCode, setPromoCode] = useState(props.initCode ? props.initCode : '');
  const [promoCodeValid, setPromoCodeValid] = useState(false);

  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isCashierPage && promoCode) {
      submitPromoCode(true)
    }
  }, // eslint-disable-next-line
    []);

  function submitPromoCode(onlyValidate) {

    if (props.spinnerOn) {
      props.spinnerOn()
    }
    jwtBackendService.usePromoCode(promoCode, onlyValidate, promoOk, promoFail, navigate, location.pathname)
  }

  function displaySuccessMessage(details, isGiven, min, max) {
    try {
      min = min ? min : null;
      max = max ? max : null;

      let parts = details.split(',');

      if (parts.length > 0) {
        let giftType = Number(parts[0]);

        if (giftType === 1) {//CASH
          let amount = parts[1];
          if (isGiven) {
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.promo.msg.gift.1', defaultMessage: 'Congratulations, you have just received {AMOUNT} Euros. If you do not use Euro, the amount is automatically converted in your device' }, { AMOUNT: amount }));
          } else {
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.promo.msg.gift.onDeposit.1', defaultMessage: 'This code will give you {AMOUNT} Euros extra on your next deposit.' }, { AMOUNT: amount, MIN: min ? getAmountTxt(min) : 'No Min', MAX: max ? getAmountTxt(max) : 'No Max' }));
          }

        }
        else if (giftType === 2) {//PNG
          let nbr = parts[1];

          if (isGiven) {
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.promo.msg.gift.2', defaultMessage: 'Congratulations, you have just received {NBR} PNG Points.' }, { NBR: nbr }));
          } else {
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.promo.msg.gift.onDeposit.2', defaultMessage: 'This code will give you {NBR} PNG Points on your next deposit.' }, { NBR: nbr, MIN: min ? getAmountTxt(min) : 'No Min', MAX: max ? getAmountTxt(max) : 'No Max' }));
          }
        }
        else if (giftType === 3) {//Free Spin
          let nbr = parts[1];
          if (isGiven) {
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.promo.msg.gift.3', defaultMessage: 'Congratulations, you have just received {NBR} free spins.' }, { NBR: nbr }));
          } else {
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.promo.msg.gift.onDeposit.3', defaultMessage: 'This code will give you {NBR} free spins on your next deposit.' }, { NBR: nbr, MIN: min ? getAmountTxt(min) : 'No Min', MAX: max ? getAmountTxt(max) : 'No Max' }));
          }

        }
        else if (giftType === 4) {//Wager
          let amount = parts[1];
          let x = parts[2];
          let maxwin = parts[3];

          if (isGiven) {
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.promo.msg.gift.4', defaultMessage: 'Congratulations, You have just received a Wager of {AMOUNT} Euro with a {X}x requirement. Maximum Win Amount is {MAXWIN}.' }, { AMOUNT: amount }, { X: x }, { MAXWIN: maxwin }));
          } else {
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.promo.msg.gift.onDeposit.4', defaultMessage: 'This code will give you a Wager of {AMOUNT} Euro with a {X}x requirement. Maximum Win Amount is {MAXWIN} on your next deposit.' }, { AMOUNT: amount, X: x, MAXWIN: maxwin, MIN: min ? getAmountTxt(min) : 'No Min', MAX: max ? getAmountTxt(max) : 'No Max' }));
          }

        }
        else if (giftType === 5) {//Deposit Bonus
          //let amount = parts[1];
          let x = Number(parts[1]) + 1;
          let maxwin = parts[2];

          if (isGiven) {
            //displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.promo.msg.gift.5', defaultMessage: 'Congratulations, You have just received a Wager of {AMOUNT} Euro with a {X}x requirement. Maximum Win Amount is {MAXWIN}.' }, { AMOUNT: amount }, { X: x }, { MAXWIN: maxwin }));
          } else {
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Info' }), intl.formatMessage({ id: 'newcasino.promo.msg.gift.onDeposit.5', defaultMessage: 'This code will multiply your deposit by {X}x. Maximum Amount is {MAXWIN} Euros.' }, { X: x, MAXWIN: maxwin }));
          }

        }

        props.refreshWallets()
      }
    } catch (error) {
    }
  }

  function redirectToCashier() {
    displayPage(constants.ROUTE_CASHIER_PROMOCODE_BASE + promoCode, navigate, location.pathname);
  }

  function displayErrorMessage(errorCode) {
    let msg = 'newcasino.promo.msg.error.' + errorCode;
    displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: msg, defaultMessage: 'An error occurs, please contact support' }));
  }

  function promoOk(data) {
    if (props.spinnerOff) {
      props.spinnerOff();
    }


    if (data.status === 'OK') {
      if (data.statusWhen === 1 && data.statusGiven === 1) {
        displaySuccessMessage(data.statusDetails, true, data.statusMin, data.statusMax);
        if (props.closeModal) {
          props.closeModal();
        }

      } else if (data.statusWhen === 2 && data.statusGiven === 0 && isCashierPage) {
        displaySuccessMessage(data.statusDetails, false, data.statusMin, data.statusMax);
      } else {
        if (!isCashierPage) {
          if (props.closeModal) {
            props.closeModal();
          }
          redirectToCashier();
        }

      }
    } else if (data.statusError) {
      displayErrorMessage(data.statusError);
    } else {
      displayGenericErrorNotificationIntl(intl);
    }
  }

  function promoFail(data) {
    if (props.spinnerOff) {
      props.spinnerOff();
    }
    displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.contact.support', defaultMessage: 'An unexpected error occurs. Please contact support.' }));
  }

  function isPromoCodeValid(isValid, value) {
    setPromoCodeValid(isValid);
    setPromoCode(value);
  }

  try {
    return (
      <>
        <div className={style.registerMainDiv}>
          {showHeader &&
            <div className={style.registerMainDivHeader}>
              <Header title={intl.formatMessage({ id: 'newcasino.modal.promo.title', defaultMessage: 'Promotion Code' })} subTitle={intl.formatMessage({ id: 'newcasino.modal.promo.subtitle', defaultMessage: 'Type your promotion code here' })} closeModal={props.closeModal} />
            </div>
          }
          <div className={style.registerMainDivBody}>

            <div className={style.desktopView}>
              <div className={style.promoCodeGrid}>
                <div><TextField maxLength='30' mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isPromoCodeValid} values={[promoCode ? promoCode : '']} hint={intl.formatMessage({ id: 'casino.cashier.promocode.hint', defaultMessage: 'Enter your promo code here' })} /></div>
                <div><Button colorStyle='light' width='140px' active={promoCodeValid} value={intl.formatMessage({ id: 'casino.cashier.promocode.button', defaultMessage: 'Submit' })} onClick={() => submitPromoCode(false)} /></div>
              </div>
            </div>
            <div className={style.mobileView}>
              <div className={style.promoCodeGrid}>
                <div><TextField maxLength='30' mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isPromoCodeValid} values={[promoCode ? promoCode : '']} hint={intl.formatMessage({ id: 'casino.cashier.promocode.hint', defaultMessage: 'Enter your promo code here' })} /></div>
                <div><Button colorStyle='light' width='50px' active={promoCodeValid} iconOnly={true} icon={'fa fa-play'} onClick={() => submitPromoCode(false)} /></div>
              </div>
            </div>
          </div>
        </div >
      </>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default PromoCode