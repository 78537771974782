import React, { useState } from 'react'
import { isMobile } from 'newui/Utils/uiUtil';
import ApiPersistence from 'newui/Utils/ApiPersistence';


import style from './style.module.scss'
import moment from 'moment';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import english from '../../locales/en-US'
import french from '../../locales/fr-FR'
import portuguese from '../../locales/pt-BR'
import TopMenu from './Elements/TopMenu';
import Banner from './Elements/Banner';
import NewsLetter from './Elements/NewsLetter';
import Games from './Elements/Games';
import Footer from './Elements/Footer';
import DivClick from 'newui/Generic/Inputs/DivClick';
import { SnackbarProvider } from 'notistack';
import { closeSnackbar } from 'notistack'

function DotNet(props) {

  const [lang, setLang] = useState(ApiPersistence.getInstance().getLanguage())

  const locales = {
    'en-US': english,
    'fr-FR': french,
    'pt-BR': portuguese,
  }

  var currentLocale = locales[lang];

  const configurations =
  {
    "debugMode": false,
    "languages": [
      {
        "intl": "newcasino.menutop.lang.en",
        "image": "url('https://playnumbersgame.net/images/general/newui/IMG_United Kingdom.png')",
        "default": "English",
        "code": "en-US"
      },
      {
        "intl": "newcasino.menutop.lang.fr",
        "image": "url('https://playnumbersgame.net/images/general/newui/IMG_France.png')",
        "default": "Français",
        "code": "fr-FR"
      },
      {
        "intl": "newcasino.menutop.lang.pt",
        "image": "url('https://playnumbersgame.net/images/general/newui/IMG_Brazil.png')",
        "default": "Português",
        "code": "pt-BR"
      }
    ],
    "topMenuDefinition": {
      "logo": {
        "image": "url('https://playnumbersgame.net/images/general/newui/IMG_Logo-Header2x.png')",
        "action": "/"
      },
      "defaultElement": 0,
      "elements": []
    },
    "demoGames": [
      { "img": "https://playnumbersgame.net/images/games/1740070916371.png", "url": "https://demogamesfree.jtmmizms.net/gs2c/openGame.do?gameSymbol=vswaysmltchmgw&lang={LANG_CODE}&cur={CUR_CODE}&lobbyUrl=https://playnumbersgame.net/&stylename=grvgm_playnumbersgame&isGameUrlApiCalled=true", "langFormat": 2 }, //OK
      { "img": "https://playnumbersgame.net/images/games/1740070654508.png", "url": "https://demogamesfree.jtmmizms.net/gs2c/openGame.do?gameSymbol=vswaystonypzz&lang={LANG_CODE}&cur={CUR_CODE}&lobbyUrl=https://playnumbersgame.net/&stylename=grvgm_playnumbersgame&isGameUrlApiCalled=true", "langFormat": 2 }, //OK
      { "img": "https://playnumbersgame.net/images/games/1739296591633.png", "url": "https://demogamesfree.jtmmizms.net/gs2c/openGame.do?gameSymbol=vswaysmodfr&lang={LANG_CODE}&cur={CUR_CODE}&lobbyUrl=https://playnumbersgame.net/&stylename=grvgm_playnumbersgame&isGameUrlApiCalled=true", "langFormat": 2 }, //OK

      { "img": "https://playnumbersgame.net/images/games/1732736980908.png", "url": "https://demogamesfree.jtmmizms.net/gs2c/openGame.do?gameSymbol=vs20minerush&lang={LANG_CODE}&cur={CUR_CODE}&lobbyUrl=https://playnumbersgame.net/&stylename=grvgm_playnumbersgame&isGameUrlApiCalled=true", "langFormat": 2 }, //TO CHANGE
      { "img": "https://playnumbersgame.net/images/games/1.jpg", "url": "https://demogamesfree.jtmmizms.net/gs2c/openGame.do?gameSymbol=vs20olympx&lang={LANG_CODE}&cur={CUR_CODE}&lobbyUrl=https://playnumbersgame.net/&stylename=grvgm_playnumbersgame&isGameUrlApiCalled=true", "langFormat": 2 }, //TO CHANGE
      { "img": "https://playnumbersgame.net/images/games/1717090373543.jpg", "url": "https://demogamesfree.jtmmizms.net/gs2c/openGame.do?gameSymbol=vs20fruitswx&lang={LANG_CODE}&cur={CUR_CODE}&lobbyUrl=https://playnumbersgame.net/&stylename=grvgm_playnumbersgame&isGameUrlApiCalled=true", "langFormat": 2 }, //TO CHANGE

      { "img": "https://playnumbersgame.net/images/games/1740070956416.png", "url": "https://demogamesfree.jtmmizms.net/gs2c/openGame.do?gameSymbol=vs25luckwildpb&lang={LANG_CODE}&cur={CUR_CODE}&lobbyUrl=https://playnumbersgame.net/&stylename=grvgm_playnumbersgame&isGameUrlApiCalled=true", "langFormat": 2 }, //TO CHANGE
      { "img": "https://playnumbersgame.net/images/games/1732726123362.png", "url": "https://demogamesfree.jtmmizms.net/gs2c/openGame.do?gameSymbol=vs50fatfrogs&lang={LANG_CODE}&cur={CUR_CODE}&lobbyUrl=https://playnumbersgame.net/&stylename=grvgm_playnumbersgame&isGameUrlApiCalled=true", "langFormat": 2 }, //TO CHANGE
      { "img": "https://playnumbersgame.net/images/games/1732737099741.png", "url": "https://demogamesfree.jtmmizms.net/gs2c/openGame.do?gameSymbol=vs20plsmcannon&lang={LANG_CODE}&cur={CUR_CODE}&lobbyUrl=https://playnumbersgame.net/&stylename=grvgm_playnumbersgame&isGameUrlApiCalled=true", "langFormat": 2 }, //TO CHANGE

      { "img": "https://playnumbersgame.net/images/games/1738084460241.png", "url": "https://demogamesfree.jtmmizms.net/gs2c/openGame.do?gameSymbol=vs12bgrbspl&lang={LANG_CODE}&cur={CUR_CODE}&lobbyUrl=https://playnumbersgame.net/&stylename=grvgm_playnumbersgame&isGameUrlApiCalled=true", "langFormat": 2 }, //OK
      { "img": "https://playnumbersgame.net/images/games/1732737375600.png", "url": "https://demogamesfree.jtmmizms.net/gs2c/openGame.do?gameSymbol=vswayspearls&lang={LANG_CODE}&cur={CUR_CODE}&lobbyUrl=https://playnumbersgame.net/&stylename=grvgm_playnumbersgame&isGameUrlApiCalled=true", "langFormat": 2 }, //OK
      { "img": "https://playnumbersgame.net/images/games/1736870685345.png", "url": "https://demogamesfree.jtmmizms.net/gs2c/openGame.do?gameSymbol=vs10fdsnake&lang={LANG_CODE}&cur={CUR_CODE}&lobbyUrl=https://playnumbersgame.net/&stylename=grvgm_playnumbersgame&isGameUrlApiCalled=true", "langFormat": 2 }, //OK

      { "img": "https://playnumbersgame.net/images/games/1704814083284.jpg", "url": "https://groove-lrs.box-int-54f2g.com/launch?currency={CUR_CODE}&gameName=blazing_wins&historyURL=https%3A%2F%2Fplaynumbersgame.net%2F&key=TEST5000&lang={LANG_CODE}&partner=groove-prod-2143&platform=desk&rcExitURL=https%3A%2F%2Fplaynumbersgame.net%2F&rci=10000", "langFormat": 2 }, //OK
      { "img": "https://playnumbersgame.net/images/games/3-Pots-Riches-Hold-and-Win.jpg", "url": "https://groove-lrs.box-int-54f2g.com/launch?currency={CUR_CODE}&gameName=3pots_riches&historyURL=https%3A%2F%2Fplaynumbersgame.net%2F&key=TEST5000&lang={LANG_CODE}&partner=groove-prod-2143&platform=desk&rcExitURL=https%3A%2F%2Fplaynumbersgame.net%2F&rci=10000", "langFormat": 2 },
      { "img": "https://playnumbersgame.net/images/games/Wolf-Land-Hold-and-Win.jpg", "url": "https://groove-lrs.box-int-54f2g.com/launch?currency={CUR_CODE}&gameName=wolf_land&historyURL=https%3A%2F%2Fplaynumbersgame.net%2F&key=TEST5000&lang={LANG_CODE}&partner=groove-prod-2143&platform=desk&rcExitURL=https%3A%2F%2Fplaynumbersgame.net%2F&rci=10000", "langFormat": 2 }, //OK
      { "img": "https://playnumbersgame.net/images/games/1704815013972.jpg", "url": "https://cdn-live.spinomenal.com/external_components/generic-play.html?partnerId=gg-playnumbers-EUR&launchToken=b9e55b8728f986425728&gameCode=SlotMachine_StoryOfZeus&langCode={LANG_CODE}&HomeUrl=https%3a%2f%2fplaynumbersgame.net%2f&IsFunMode=true&inter=0&InitClientUrl=https%3a%2f%2frgs-demo.spinomenal.com%2fapi%2fInitClientUrl&PlatformId=1", "langFormat": 4 },
      { "img": "https://playnumbersgame.net/images/games/1705000417931.jpg", "url": "https://cdn-live.spinomenal.com/external_components/generic-play.html?partnerId=gg-playnumbers-EUR&launchToken=4fef35a728beeb425731&gameCode=SlotMachine_Dracula-Unleashed&langCode={LANG_CODE}&HomeUrl=https%3a%2f%2fplaynumbersgame.net%2f&IsFunMode=true&inter=0&InitClientUrl=https%3a%2f%2frgs-demo.spinomenal.com%2fapi%2fInitClientUrl&PlatformId=1", "langFormat": 4 },
      { "img": "https://playnumbersgame.net/images/games/1703604810406.jpg", "url": "https://cdn-live.spinomenal.com/external_components/generic-play.html?partnerId=gg-playnumbers-EUR&launchToken=6723e57728031a425693&gameCode=SlotMachine_QueenOfWater-TidesOfFortune&langCode={LANG_CODE}&HomeUrl=https%3a%2f%2fplaynumbersgame.net%2f&IsFunMode=true&inter=0&InitClientUrl=https%3a%2f%2frgs-demo.spinomenal.com%2fapi%2fInitClientUrl&PlatformId=1", "langFormat": 4 }
    ]
  }

  function changeLanguage(index, value) {
    ApiPersistence.getInstance().setLanguage(value);
    setLang(value);
    setMoment(value);
  }

  function setMoment(value) {
    var newLang = value;
    if (newLang === undefined) {
      newLang = lang;
    }
    if (newLang.startsWith('fr-')) {
      moment.locale('fr')
    } else if (newLang.startsWith('pt-')) {
      moment.locale('pt')
    } else {
      moment.locale('en')
    }
  }

  function endGame() {
    const popupContainer = document.getElementById('popup-container');
    const popupIframe = document.getElementById('popup-iframe');

    if (popupContainer.style.display === 'block') {
      popupIframe.src = '';
      popupContainer.style.display = 'none';
    }
  }

  return (
    <React.Fragment>
      <ConfigProvider locale={lang}>
        <IntlProvider locale={lang} messages={currentLocale.messages}>
          <SnackbarProvider
            action={(snackbarId) => (
              <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} onClick={() => closeSnackbar(snackbarId)} />
            )}
          ></SnackbarProvider>
          <div id="popup-container" className={style.popupContainer} onClick={endGame}>
            <iframe title='demo game' id="popup-iframe" className={style.popupIframe}></iframe>
          </div>
          <DivClick onClick={endGame}>
            <TopMenu isMobile={isMobile()} setLanguage={changeLanguage} languages={configurations.languages} definition={configurations.topMenuDefinition} />
            <Banner />
            <div className={style.backgroundDiv}>
              <div className={style.spacer}>
                <NewsLetter />
                <Games gameList={configurations.demoGames} />
              </div>
            </div>
            <Footer />
          </DivClick>
        </IntlProvider>
      </ConfigProvider>
    </React.Fragment>
  );
}

export default (DotNet)