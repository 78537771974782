/* eslint-disable */
import React from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import { setTheme } from '../../../../Utils/themeUtils';
import DivClick from '../../../../Generic/Inputs/DivClick';

function DailyGift(props) {

    const intl = useIntl()

    function getImageUrl() {
        let toReturn = setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_GiftFuture.png');

        if (props.status === 0) {
            toReturn = setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_GiftDone.png');
        }
        else if (props.status === 1) {
            toReturn = setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Gift.png');
        }

        return toReturn;

    }
    function getDayGiftStyle() {
        let toReturn = style.dayGift_2;

        if (props.status === 0) {
            toReturn = style.dayGift_0;
        }
        else if (props.status === 1) {
            toReturn = style.dayGift_1;
        }

        return toReturn;

    }

    function getBaseStyle() {
        let toReturn = style.daily_gift_status_2;
        if (props.status === 0) {
            toReturn = style.daily_gift_status_0;
        }
        else if (props.status === 1) {
            toReturn = style.daily_gift_status_1;
        }

        return toReturn;
    }

    return (
        <DivClick className={getBaseStyle()} onClick={() => props.onClick(props.status)}>
            <div className={style.imgGift}>
                <img className={style.giftMobile} src={getImageUrl()} alt='' />
            </div>
            <div className={getDayGiftStyle()}>
                {props.value}
            </div>
        </DivClick>
    );
}
export default DailyGift;
