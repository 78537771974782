/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from '../../../../Services/JWTBackend/jwtBackendService';
import Header from '../../../../Casino/General/Modal/Header';
import Footer from '../../../../Casino/General/Modal/Footer';
import TextField from '../../../../Generic/Inputs/TextField';
import { displayGenericErrorNotificationIntl } from '../../../../Utils/uiUtil';
import Checkbox from '../../../../Generic/Inputs/Checkbox';

function PartnerDeal(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [refresh, setRefresh] = useState(Math.random());
    const [streamerData, setStreamerData] = useState(null);

    const [commissionFixAmount, setCommissionFixAmount] = useState(false);
    const [commissionFixAmountValue, setCommissionFixAmountValue] = useState(0);

    useEffect(() => {
        getPartnerDeal(props.userId)
    },// eslint-disable-next-line
        [])

    function getPartnerDeal(streamerId) {
        jwtBackendService.getStreamerDeals(streamerId, successGetPartnerDealJWT, failureGetPartnerDealJWT, navigate, location.pathname);
    }

    function successGetPartnerDealJWT(data) {
        setStreamerData(data);

        setCommissionFixAmount(data.deal.commMonthlyFixedAmount !== -1);
        setCommissionFixAmountValue((data.deal.commMonthlyFixedAmount !== -1) ? data.deal.commMonthlyFixedAmount : 0);

        setRefresh(Math.random);
    }

    function failureGetPartnerDealJWT(data) {
        displayGenericErrorNotificationIntl(intl);
    }


    function saveDeal() {
        var clone = JSON.parse(JSON.stringify(streamerData));
        clone.deal.streamerID = props.userId;
        clone.deal.balance = 0;
        clone.deal.wager = 0;
        clone.deal.streamPerWeek = 0;
        clone.giveAway.amount = 0;

        clone.deal.commMonthlyFixedAmount = commissionFixAmount ? commissionFixAmountValue : -1;
        clone.deal.commPercDeposit = -1;
        clone.deal.commissionMinFTD = -1;
        clone.deal.commissionPercVault = -1;

        jwtBackendService.updateStreamerDeals(clone, successUpdatePartnerDealsJWT, failureUpdatePartnerDealsJWT, navigate, location.pathname);
    }

    function successUpdatePartnerDealsJWT(data) {
        props.closeModal();
    }

    function failureUpdatePartnerDealsJWT(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    try {
        var header = <Header title={intl.formatMessage({ id: 'newCasino.admin.partnerDeal.title', defaultMessage: 'Partner\'s Deal' })} subTitle={intl.formatMessage({ id: 'newCasino.admin.streamerDeal.subtitle', defaultMessage: 'Adjust streamer\'s deal parameters' })} closeModal={props.closeModal} />
        var footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newCasino.admin.streamerDeal.cancel', defaultMessage: 'Cancel' }), action: props.closeModal, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newCasino.admin.streamerDeal.save', defaultMessage: 'Save' }), action: saveDeal, canProcess: true }} />

        return (
            <>
                <div>
                    <div>
                        {header}
                    </div>
                    <div className={style.commissionTitle}>Monthly Amount</div>
                    <div className={style.streamingGiveaway}>
                        <div className={style.streamingCommFixAmount}>
                            <Checkbox key={'CHK1_' + refresh} checked={commissionFixAmount === true} label='Monthly Fix Amount' onClick={setCommissionFixAmount} />
                            {commissionFixAmount &&
                                <div><TextField key={'CommFixAmount-' + refresh} values={[commissionFixAmountValue]} onChange={setCommissionFixAmountValue} type='2decimals' /></div>
                            }
                        </div>
                    </div>
                    <div>
                        {footer}
                    </div>
                </div >
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default PartnerDeal;
