/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl'

import { setTheme } from '../../../Utils/themeUtils';
import style from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl'
import * as jwtBackendService from '../../../Services/JWTBackend/jwtBackendService'
import DivClick from '../../../Generic/Inputs/DivClick';
import ChangePassword from './ChangePassword';
import BankAccount from './BankAccount';
import Documents from './Documents';
import PersoInfo from './PersoInfo';
import { getImageUrl } from '../../../Utils/uiUtil';

function Profile(props) {
    const [personalData, setPersonalData] = useState(null);
    const [countries, setCountries] = useState(null);

    const [showedTab, setShowedTab] = useState(0);

    const [refresh, setRefresh] = useState(Math.random());

    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();

    useEffect(() => {
        loadCountries()
        loadPersonalData()
    }, [])


    function loadCountries() {
        jwtBackendService.casino_get_country_list(successGetCountryList, failureGetCountryList, navigate, location.pathname)
    }

    function successGetCountryList(data) {
        setCountries(data)
    }

    function failureGetCountryList(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function loadPersonalData() {
        jwtBackendService.loadPersonalData(successLoadPersonalData, failureLoadPersonalData, navigate, location.pathname)
    }

    function successLoadPersonalData(data) {
        setPersonalData(data)
        setRefresh(Math.random());
    }

    function failureLoadPersonalData(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function reload() {
        loadPersonalData();
    }

    if (personalData && countries) {
        return (
            <>
                <div className={style.master_page_container}>
                    <div className={style.master_grid}>
                        <div>
                            <div className={style.title_txt}>
                                <FormattedMessage id="newcasino.profile.title" defaultMessage="My Profile" />
                            </div>
                            <img className={style.separator} src={setTheme(getImageUrl('newui/themes/{THEME}/Raf/bottomline.png'))}></img>
                            <div>
                                <div className={style.tabLine}>
                                    <DivClick className={(showedTab === 0) ? style.tabSelected : style.tabNotSelected} onClick={() => setShowedTab(0)}>
                                        <FormattedMessage id="newcasino.profile.tab.personal" defaultMessage="Personal Information" /></DivClick>
                                    <div className={style.pipe} />
                                    <DivClick className={(showedTab === 1) ? style.tabSelected : style.tabNotSelected} onClick={() => setShowedTab(1)}>
                                        <FormattedMessage id="newcasino.profile.tab.docs" defaultMessage="Identity Documents" />
                                    </DivClick>
                                    <div className={style.pipe} />
                                    <DivClick className={(showedTab === 2) ? style.tabSelected : style.tabNotSelected} onClick={() => setShowedTab(2)}>
                                        <FormattedMessage id="newcasino.profile.tab.password" defaultMessage="Change Password" />
                                    </DivClick>
                                    {/*
                                    <div className={style.pipe} />
                                    <DivClick className={(showedTab === 3) ? style.tabSelected : style.tabNotSelected} onClick={() => setShowedTab(3)}>
                                        <FormattedMessage id="newcasino.profile.tab.bank" defaultMessage="Transaction" />
                                    </DivClick>
                                     */}
                                </div>
                                {showedTab === 0 &&
                                    <PersoInfo key={'PI_' + refresh} personalData={personalData} reload={reload} closeModal={props.closeModal} displayGenericModal={props.displayGenericModal} countries={countries} />
                                }
                                {showedTab === 1 &&
                                    <Documents personalData={personalData} />
                                }
                                {showedTab === 2 &&
                                    <ChangePassword />
                                }
                                {showedTab === 3 &&
                                    <BankAccount />
                                }
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}
export default Profile;