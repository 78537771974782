import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import style from './style.module.scss'
import DivClick from '../../../../Generic/Inputs/DivClick';
import GameSearch from '../../Search';
import { getImageUrl } from '../../../../Utils/uiUtil';
import UseFreeSpin from '../../Home/UseFreeSpin';

function SearchResultModal(props) {

    const msg = props.msg;

    const [lastMessageId, setLastMessageId] = useState(-1);
    const [display, setDisplay] = useState(false);
    const [nbrResult, setNbrResult] = useState(0);

    let showHideClassName = style.SearchResultModal_inactive;

    if (lastMessageId !== msg.id) {
        showHideClassName = style.SearchResultModal_active;
    }

    function getShowHideClassName() {
        if (!display) {
            return style.SearchResultModal_inactive
        }

        return showHideClassName;
    }


    function handleNbrResult(nbrResult) {
        setNbrResult(nbrResult)
        if (nbrResult === 0) {
            setDisplay(true);
        } else {
            setDisplay(true);
        }
    }

    function gameStartedAction(gameObj, availableFreeSpin) {

        setDisplay(false);
        if (gameObj.freeSpinAllowed && availableFreeSpin > 0) {
            props.handleGameStart();

            props.displayComponent(
                <UseFreeSpin
                    gameId={gameObj.gameId}
                    nbrFreeSpins={props.getFreeSpinAvailable()}
                    key={Math.random()}
                    callback={props.useFreeSpin} />);

            return false;
        }
        else {
            if (props.handleGameStart) {
                props.handleGameStart();
            }
            return true;
        }
    }

    function getSearchHeight() {
        var toReturn = window.innerHeight;
        if (props.isMobile) {
            toReturn = ((toReturn - 360) * 0.9) + 4
        } else {
            toReturn = (toReturn - 300) * 0.9
        }

        return toReturn + "px";
    }
    function handleClose(msgId) {
        setLastMessageId(msgId);
    }

    let bgImage = {};

    if (msg && msg.image) {
        bgImage = { backgroundImage: 'url(' + msg.image + ')' }
    }


    return (<>
        <DivClick className={`${style.SearchResultModal_top} ${getShowHideClassName()}`} onClick={() => handleClose(msg.id)} />
        <DivClick className={`${style.SearchResultModal_main} ${getShowHideClassName()}`} onClick={() => handleClose(msg.id)}>
            <DivClick className={`${style.SearchResultModal_content}`} style={bgImage} onClick={(e) => e.stopPropagation()}>
                <div className={`${style.SearchResultModal_pops} ${style.SearchResultModal_title}`}>
                    <h4 className={`${style.SearchResultModal_heads}`}>
                        {msg.title1} {nbrResult} {msg.title2}
                    </h4>
                </div>

                <div style={{ height: getSearchHeight() }} className={`${style.SearchResultModal_scrollable}`}>
                    <GameSearch keyword={msg.message} getFreeSpinAvailable={props.getFreeSpinAvailable} actionOnResult={handleNbrResult} gameStartedAction={gameStartedAction} />
                </div>
                <br />
                <DivClick className={`${style.SearchResultModal_clickable}`} onClick={() => handleClose(msg.id)}>
                    <img className={`${style.SearchResultModal_chevron}`} alt='' src={getImageUrl('newui/general/chevron.svg')} />
                    <span className={`${style.SearchResultModal_cancel}`}>
                        <FormattedMessage
                            id='casino.modal.popup.close'
                            defaultMessage="Close"
                        />
                    </span>
                </DivClick>

            </DivClick>
        </DivClick >
    </>
    );
};

export default SearchResultModal