import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import './DefaultModal.scss';
import DivClick from '../../../Generic/Inputs/DivClick';
import Button from '../../../Generic/Inputs/Button';
import { getImageUrl } from '../../../Utils/uiUtil';

function DefaultModal(props) {
    var msg = props.msg;
    var isHtml = (msg.body.isHtml === true) ? true : false;
    var bgColor = msg.body.bgColor ? msg.body.bgColor : null;
    var component = msg.component;
    var showClose = (msg.showClose === 'false' || msg.showClose === false) ? false : true;
    var theWidth = msg.width ? msg.width : '50%';
    const intl = useIntl();
    const [lastMessageId, setLastMessageId] = useState(-1);
    const [spinnerClassName, setSpinnerClassName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    let bgImage = {
        width: theWidth
    };

    if (msg.height !== 'full') {
        bgImage = {
            width: theWidth,
            height: 'fit-content',
            maxHeight: 'fit-content'

        };
    }

    let showHideClassName = "topModal_modal topModal_inactive";

    if (lastMessageId !== msg.id) {
        showHideClassName = "topModal_modal topModal_active";
    }

    if (component !== undefined) {
        component = React.cloneElement(
            component,
            {
                closeModal: () => handleClose(msg.id, msg.callback),
                isLoading: isLoading,
                spinnerOn: spinnerOn,
                spinnerOff: spinnerOff
            }
        );
    }

    function spinnerOn() {
        setSpinnerClassName('deactivateAll')
        setIsLoading(true)
    }

    function spinnerOff() {
        setSpinnerClassName('')
        setIsLoading(false)
    }
    function handleClose(msgId, callback) {
        setLastMessageId(msgId);
        if (callback) {
            callback();
        }
    }



    if (bgColor !== null) {
        bgImage = { width: theWidth, backgroundColor: bgColor };
    }

    if (msg && msg.body && msg.body.image) {
        bgImage = { backgroundImage: 'url(' + msg.body.image + ')', width: theWidth }
    }

    return (
        <>
            <div className={spinnerClassName}></div>
            <div className={showHideClassName}>
                {(component !== undefined) && (
                    <div className="topModal_modal-content" style={bgImage}>
                        <div className={showClose ? "topModal_modal-scroll_withclose" : "topModal_modal-scroll"}>
                            {component}
                        </div>
                        {showClose &&
                            <>
                                <br />
                                <DivClick className='topModal_clickable' onClick={() => handleClose(msg.id, msg.callback)}>
                                    <img className='topModal_chevron' alt='' src={getImageUrl('newui/general/chevron.svg')} />
                                    <span className='topModal_cancel'>
                                        <FormattedMessage
                                            id='casino.modal.popup.close'
                                            defaultMessage="Close"
                                        />
                                    </span>
                                </DivClick>
                            </>}
                    </div>
                )}
                {(msg.component === undefined && !isHtml) && (
                    <div className="topModal_modal-content" style={bgImage}>
                        <div className='topModal_pops topModal_title'>
                            <h4 className='topModal_rg-heads'>
                                {msg.body.title}
                            </h4>
                        </div>
                        <p>
                            {msg.body.message}
                        </p>
                        {showClose &&
                            <>
                                <br />
                                <DivClick className='topModal_clickable' onClick={() => handleClose(msg.id, msg.callback)}>
                                    <img className='topModal_chevron' alt='' src={getImageUrl('newui/general/chevron.svg')} />
                                    <span className='topModal_cancel'>
                                        <FormattedMessage
                                            id='casino.modal.popup.close'
                                            defaultMessage="Close"
                                        />
                                    </span>
                                </DivClick>
                            </>
                        }
                    </div>
                )}
                {(msg.component === undefined && isHtml) && (
                    <div className="topModal_modal-content" style={bgImage}>

                        {/*
                        <iframe frameborder="0" style={{ height: msg.body.height ? msg.body.height : '100%' }} className='topModal_theIFrame' title='theIFrame' srcdoc={msg.body.message} />
                */}     <div style={{ height: msg.body.height ? msg.body.height : '100%' }}>
                            <iframe frameborder="0" width="100%" height="100%" title='theIFrame' srcdoc={msg.body.message} />
                        </div>
                        <div className='topModal_buttonhtml'>
                            <Button value={intl.formatMessage({ id: 'casino.modal.popup.close', defaultMessage: 'Close' })} onClick={() => handleClose(msg.id, msg.callback)} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default DefaultModal