import React, { useState } from 'react'
import style from './style.module.scss'

import ApiPersistence from '../../../../Utils/ApiPersistence'
import { setUserDetailInChat } from '../../../../Services/ThirdParty/intercom';
import DivClick from '../../../../Generic/Inputs/DivClick';
import LanguageMenu from 'newui/Casino/General/Menus/LanguageMenu';

function TopMenu(props) {
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);

  const menuLanguages = props.languages;
  const logo = props.definition.logo;

  let langIndex = 0;

  if (menuLanguages) {
    for (let i = 0; i < menuLanguages.length; i++) {
      var currentLang = menuLanguages[i];
      if (currentLang.code === ApiPersistence.getInstance().getLanguage()) {
        langIndex = i;
        break;
      }
    }
  }

  const [selectedLanguage, setSelectedLanguage] = useState(langIndex);


  /**********************************************
  * getLanguageMenuStyle()
  *********************************************/
  function getLanguageMenuStyle() {
    if (showLanguageMenu) {
      return style.flag_dropdown_show;
    } else {
      return style.flag_dropdown;
    }
  }

  /**********************************************
  * getLanguage()
  *********************************************/
  function getLanguage() {
    return menuLanguages[selectedLanguage];
  }

  /**********************************************
  * changeLanguage()
  *********************************************/
  function changeLanguage(index) {
    toggleLanguageMenu();
    setSelectedLanguage(index);
    props.setLanguage(index, menuLanguages[index].code);
  }

  /**********************************************
  * toggleLanguageMenu()
  *********************************************/
  function toggleLanguageMenu() {
    if (props.toggleWalletsMenu) {
      props.toggleWalletsMenu(false);
    }
    if (props.toggleUserMenu) {
      props.toggleUserMenu(false);
    }

    setShowLanguageMenu(!showLanguageMenu);
  }

  setUserDetailInChat()
  try {

    return (
      <React.Fragment key={Math.random}>
        <div className={`${style.menuTop_sticky}`}>
          <div className={`${style.menuTop_sticky} ${style.menuTop_mainDiv} ${style.menuTop_grid}`}>
            <div className={`${style.menuTop_left}`}>
              <div className={`${style.menuTop_logo}`}
                style={{ backgroundImage: logo.image }}
              />
            </div>
            <div className={`${style.menuTop_center} ${style.menuTop_centerize_elements} ${style.maximize}`}>
              <div className={`${style.menuTop_centerize_elements}`}></div>
            </div>
            <div className={style.menuTop_right}>
              <div className={`${style.menuTop_langBox}`}>
                <DivClick className={`${style.dot}`} onClick={() => toggleLanguageMenu()} style={{
                  backgroundImage: getLanguage().image
                }}>
                </DivClick>
                <DivClick onClick={() => toggleLanguageMenu()} />
                <DivClick className={`${style.menuTop_arrowDown}`} onClick={() => toggleLanguageMenu()}><i className="fa fa-chevron-down" aria-hidden="true"></i></DivClick>
                <div className={getLanguageMenuStyle()} onMouseLeave={() => toggleLanguageMenu()}>
                  <LanguageMenu changeLanguage={changeLanguage} languages={menuLanguages} />
                </div>
              </div>
            </div >
          </div >
        </div>
      </React.Fragment>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}

export default TopMenu