import React, { useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import Button from '../../../../../../Generic/Inputs/Button';

function UserListUpload(props) {
    const intl = useIntl();
    const [file, setFile] = useState(null);

    function truncate(name) {
        if (name.length > 20) {
            return name.substring(0, 20) + '...';
        } else {
            return name;
        }
    }
    function handleFileUpload(event) {

        event.preventDefault();

        if (event.dataTransfer && event.dataTransfer.items) {
            if (event.dataTransfer.items.length > 1) {
                console.log('More than one file');
                return;
            }

            if (event.dataTransfer.items[0].kind === 'file') {
                const file = event.dataTransfer.items[0];
                setFile(file)
                if (props.onFileSelection) {
                    props.onFileSelection(file)
                }
            }
        } else if (event.target.files) {
            if (event.target.files.length > 1) {
                console.log('More than one file');
                return;
            }

            const file = event.target.files[0];
            setFile(file)
            if (props.onFileSelection) {
                props.onFileSelection(file)
            }
        }
    }


    try {

        return (
            <>
                <input className={style.fileInput} type="file" id="uploadMyFile" name="myfile1" onChange={(e) => handleFileUpload(e, 1)} />
                <div className={style.bottom}>
                    <Button width='250px' colorStyle='light' value={(file && file.name) ? truncate(file.name) : intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.upload', defaultMessage: 'Select File To Upload' })} onClick={() => document.getElementById('uploadMyFile').click()} />
                </div>
            </>

        )
    } catch (error) {
        console.log(error);
    }
}


export default UserListUpload;
