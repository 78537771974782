import React, { useEffect, useState } from 'react'
import { DtPicker } from 'react-calendar-datetime-picker'
import 'react-calendar-datetime-picker/dist/style.css'
import TextField from '../TextField';
import Button from '../Button';
import { findClidrenByClassName } from '../../../Utils/domUtil';
import style from './style.module.scss'

function DatePicker(props) {

  const [divRef] = useState('DATEPICKER_' + Math.random());
  const [startTextDate, setStartTextDate] = useState('');
  const [startTextTime, setStartTextTime] = useState('');

  const [endTextDate, setEndTextDate] = useState('');
  const [endTextTime, setEndTextTime] = useState('');

  const [startTextDateKey, setStartTextDateKey] = useState('STD' + Math.random());
  const [startTextTimeKey, setStartTextTimeKey] = useState('STT' + Math.random());

  const [endTextDateKey, setEndTextDateKey] = useState('ETD' + Math.random());
  const [endTextTimeKey, setEndTextTimeKey] = useState('ETT' + Math.random());

  const [startDateLabel] = useState(props.startDateLabel ? props.startDateLabel : '');
  const [startTimeLabel] = useState(props.startTimeLabel ? props.startTimeLabel : '');

  const [endDateLabel] = useState(props.endDateLabel ? props.endDateLabel : '');
  const [endTimeLabel] = useState(props.endTimeLabel ? props.endTimeLabel : '');

  const [buttonLabel] = useState(props.buttonLabel ? props.buttonLabel : '');

  useEffect(() => {
    setTimeout(displayComponent, 500);
  }
  );

  function displayComponent() {
    let divParent = document.getElementById(divRef);
    const elements = findClidrenByClassName(divParent, 'input-picker');
    elements[0].classList.add(style.inputClass);
    divParent.style.display = 'block';
  }

  function addZero(data) {
    let stringValue = '' + data;
    return stringValue.length > 1 ? stringValue : '0' + stringValue;
  }

  function updateMe(value) {
    let startDateNewValue = ''
    let startTimeNewValue = ''
    let endDateNewValue = null
    let endTimeNewValue = null

    if (value) {
      if (props.type === 'INTERVAL' && value.from && value.to) {
        let from = value.from;
        let to = value.to;
        startDateNewValue = addZero(from.day) + '/' + addZero(from.month) + '/' + from.year;
        setStartTextDate(startDateNewValue)
        setStartTextDateKey('STD' + Math.random());
        if (Number.isInteger(from.hour)) {
          startTimeNewValue = addZero(from.hour) + ':' + addZero(from.minute);
          setStartTextTime(startTimeNewValue)
          setStartTextTimeKey('STT' + Math.random());
        }

        endDateNewValue = addZero(to.day) + '/' + addZero(to.month) + '/' + to.year;
        setEndTextDate(endDateNewValue)
        setEndTextDateKey('ETD' + Math.random());
        if (Number.isInteger(to.hour)) {
          endTimeNewValue = addZero(to.hour) + ':' + addZero(to.minute);
          let error = false;


          if ((startTextDate === endTextDate) && ((to.hour < from.hour) || (to.hour === from.hour && to.minute <= from.minute))) {
            error = true;
          }

          if (error) {
            setEndTextTime('23:59');
            setEndTextTimeKey('ETT' + Math.random());
          } else {
            setEndTextTime(endTimeNewValue)
            setEndTextTimeKey('ETT' + Math.random());
          }

        }
        if (props.onChange) {
          props.onChange(startDateNewValue, startTimeNewValue, endDateNewValue, endTimeNewValue);
        }

      } else if (props.type !== 'INTERVAL') {
        startDateNewValue = addZero(value.day) + '/' + addZero(value.month) + '/' + value.year;
        setStartTextDate(startDateNewValue)
        setStartTextDateKey('STD' + Math.random());
        if (Number.isInteger(value.hour)) {
          startTimeNewValue = addZero(value.hour) + ':' + addZero(value.minute);
          setStartTextTime(startTimeNewValue)
          setStartTextTimeKey('STT' + Math.random());
        }
        if (props.onChange) {
          props.onChange(startDateNewValue, startTimeNewValue, null, null);
        }
      }
    }
  }

  function renderButtonText() {
    return props.label ? props.label : 'Select Date'
  }

  function handleButtonClick() {
    let divParent = document.getElementById(divRef);
    const elements = findClidrenByClassName(divParent, 'input-picker--input');
    if (elements && elements.length > 0)
      elements[0].click();
  }

  function getDate(theDate) {
    let toReturn = null;

    if (theDate) {
      let baseDate = new Date();
      if (theDate === 'TOMORROW') {
        baseDate.setDate(baseDate.getDate() + 1)
        toReturn = { year: baseDate.getFullYear(), month: baseDate.getMonth() + 1, day: baseDate.getDate(), hour: 0, minute: 0, second: 0 };
      }
      else if (theDate === 'TODAY') {
        toReturn = { year: baseDate.getFullYear(), month: baseDate.getMonth() + 1, day: baseDate.getDate(), hour: 0, minute: 0, second: 0 };
      }
      else if (theDate === 'MIDNIGHT') {
        toReturn = { year: baseDate.getFullYear(), month: baseDate.getMonth() + 1, day: baseDate.getDate(), hour: 23, minute: 59, second: 59 };
      }
    }

    return toReturn;
  }

  function getMinimumDate() {
    return getDate(props.minDate);
  }

  function getInitialDate() {
    if (props.type === 'INTERVAL') {
      return { from: getDate(props.initDate), to: getDate(props.initEndDate ? props.initEndDate : props.initDate) };
    } else {
      return getDate(props.initDate);
    }

  }

  try {
    return (
      <div className={style.grid}>
        <TextField key={startTextDateKey} label={startDateLabel} values={[startTextDate]} readOnly={true} />
        <TextField key={startTextTimeKey} label={startTimeLabel} values={[startTextTime]} readOnly={true} />
        <div className={style.buttonMargin}>
          <Button value={buttonLabel} colorStyle='light' onClick={handleButtonClick} icon='fa fa-calendar' width='30px' />
        </div>
        <div id={divRef} className={style.inputClass}>
          <DtPicker autoClose={false} type={props.type ? (props.type === 'INTERVAL' ? 'range' : '') : ''} withTime={true} onChange={updateMe} minDate={getMinimumDate()} initValue={getInitialDate()} placeholder={renderButtonText()} headerClass={style.calendar} />
        </div>
        {
          (props.type === 'INTERVAL') &&
          <>
            <TextField topTitleStyle={style.secondLine} key={endTextDateKey} label={endDateLabel} values={[endTextDate]} readOnly={true} />
            <TextField topTitleStyle={style.secondLine} key={endTextTimeKey} label={endTimeLabel} values={[endTextTime]} readOnly={true} />
          </>
        }
      </div>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default DatePicker