import React, { useState } from 'react'
import style from './style.module.scss'
import { isNumeric, isText } from '../../../../Utils/uiUtil';
import TextField from '../../../../Generic/Inputs/TextField';
import Button from '../../../../Generic/Inputs/Button';

function TypeEditor(props) {

  const [id] = useState(props.type ? props.type.id : -1);
  const [name, setName] = useState(props.type ? props.type.name : '')
  const [code, setCode] = useState(props.type ? props.type.code : '')
  const [uiOrder, setUIOrder] = useState(props.type ? props.type.uiOrder : '')
  const [error, setError] = useState('');

  function validate(clone) {
    return isText(clone.code) && isText(clone.name) && isNumeric(clone.uiOrder);

  }


  function onSave() {
    var toClone = props.type ? props.type : {}
    var clone = JSON.parse(JSON.stringify(toClone))

    clone.id = id;
    clone.code = code;
    clone.name = name;
    clone.uiOrder = uiOrder;

    if (validate(clone)) {
      props.closeModal();

      var action = 'MODIFY';
      if (props.index === -1) {
        action = 'ADD'
      }

      props.callback(clone, props.index, action);
    } else {
      setError('Validation Error');
    }
  }

  function onDelete() {
    props.closeModal();
    props.callback(props.type, props.index, 'DELETE_TYPE');
  }

  try {

    return (
      <>
        <div className='topModal_pops topModal_title'>
          <h4 className='topModal_rg-heads'>
            Game Type Editor
          </h4>
        </div>
        <div>
          <div>
            <div className={style.bottomPad}><TextField autofocus='true' values={[name]} label={'Name'} onChange={setName} /></div>
            <div className={style.bottomPad}><TextField values={[code]} label={'Code'} onChange={setCode} /></div>
            <div className={style.bottomPad}><TextField type='digit' values={[uiOrder]} label={'Order'} onChange={setUIOrder} /></div>
            <div className={style.twoColGrid50}>
              <div><Button width='100px' colorStyle='dark' value={'Save'} onClick={onSave} /></div>

              {(props.index > -1) &&
                <div><Button width='100px' colorStyle='light' value={'Delete'} onClick={onDelete} /></div>

              }
            </div>

            {/*}
            <div>Name:</div>
            <div><input className={style.input} type="text" value={name} onChange={(e) => setName(e.target.value)} /></div>
            <div>Code:</div>
            <div><input className={style.input} type="text" value={code} onChange={(e) => setCode(e.target.value)} /></div>
            <div>Order:</div>
            <div><input className={style.input} type="text" value={uiOrder} onChange={(e) => setUIOrder(e.target.value)} /></div>
            <div><button className={style.button} onClick={onSave}>Save</button></div>
            {(props.index > -1) &&
              <div><button className={style.button} onClick={onDelete}>Delete</button></div>
            }
          */}
          </div>
          {error}
        </div>


      </>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default TypeEditor