import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import * as jwtBackendService from '../../Services/JWTBackend/jwtBackendService';
import { useIntl } from 'react-intl';
import MemoryList from '../MemoryList';
import Button from '../../Generic/Inputs/Button';
import { displayErrorNotificationIntl, displayInfoNotificationIntl } from '../../Utils/uiUtil';
import { displayPage } from '../../Utils/routerUtil';
import * as constants from '../../constants';


function Affiliates(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  const [affiliatesPending, setAffiliatesPending] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [showedTab, setShowedTab] = useState(-1);

  const columnsDefinitionPending = [
    { name: intl.formatMessage({ id: 'newcasino.aff.affiliates.pending.col.name', defaultMessage: 'Name' }), width: '20%', listColumn: 'fullName', canSort: true },
    { name: intl.formatMessage({ id: 'newcasino.aff.affiliates.pending.col.email', defaultMessage: 'Email address' }), width: '30%', listColumn: 'email', canSort: true },
    { name: intl.formatMessage({ id: 'newcasino.aff.affiliates.pending.col.twitch', defaultMessage: 'Twitch' }), width: '15%', listColumn: 'streamPage', canSort: true },
    { name: intl.formatMessage({ id: 'newcasino.aff.affiliates.pending.col.avg', defaultMessage: 'Average views' }), width: '15%', listColumn: 'avgViews', canSort: true },
    { name: intl.formatMessage({ id: 'newcasino.aff.affiliates.pending.col.action', defaultMessage: 'Action' }), width: '20%', canSort: false, component: generateComponent }
  ];

  const columnsDefinition = [
    { name: intl.formatMessage({ id: 'newcasino.aff.affiliates.pending.col.name', defaultMessage: 'Name' }), width: '25%', listColumn: 'fullName', canSort: true },
    { name: intl.formatMessage({ id: 'newcasino.aff.affiliates.pending.col.email', defaultMessage: 'Email address' }), width: '35%', listColumn: 'email', canSort: true },
    { name: intl.formatMessage({ id: 'newcasino.aff.affiliates.pending.col.twitch', defaultMessage: 'Twitch' }), width: '25%', listColumn: 'streamPage', canSort: true },
    { name: intl.formatMessage({ id: 'newcasino.aff.affiliates.pending.col.approvalDate', defaultMessage: 'Average views' }), width: '15%', listColumn: 'approvedDate', canSort: true }
  ];

  useEffect(() => {
    GetAffiliates();
  },// eslint-disable-next-line
    [])

  function GetAffiliates() {
    props.spinner(true)
    jwtBackendService.getAffiliates(successGetAffiliates, failureGetAffiliates, navigate, location.pathname);
    /*
    var tempoList = [
      {
        fullName: 'Laurent Ipsum',
        email: 'laurent.ipsum@email.com',
        twitch: 'twitch/li1234',
        averageView: '1234',
        approved: true,
        approveDate: '07/11/2023'
      },
      {
        fullName: 'Laurent Ipsum',
        email: 'laurent.ipsum@email.com',
        twitch: 'twitch/li1234',
        averageView: '1234',
        approved: false,
        approveDate: '07/11/2023'
      }
    ]


    successGetAffiliates(tempoList)
    */
  }

  function approve(item) {
    jwtBackendService.approveAffiliate(item.email, successApproveAffiliate, failureApproveAffiliate, navigate, location.pathname);
  }

  function reject(item) {
    jwtBackendService.rejectAffiliate(item.email, successRejectAffiliate, failureRejectAffiliate, navigate, location.pathname);
  }

  function successApproveAffiliate(data) {
    displayInfoNotificationIntl(intl, 'newcasino.aff.affiliates.approve.pass')
    GetAffiliates();
  }

  function failureApproveAffiliate(data) {
    displayErrorNotificationIntl(intl, 'newcasino.aff.affiliates.approve.fail')
  }

  function successRejectAffiliate(data) {
    displayInfoNotificationIntl(intl, 'newcasino.aff.affiliates.reject.pass')
    GetAffiliates();
  }
  function failureRejectAffiliate(data) {
    displayErrorNotificationIntl(intl, 'newcasino.aff.affiliates.reject.fail')
  }


  function generateComponent(item) {
    return (<div className={style.buttons}>
      <div className={style.buttonLeft}><Button buttonStyle={style.gridButtonStyle} icon='fa fa-check-circle-o' width='105px' height='28px' iconTextAlign='left' paddingTop='6px' font='normal normal 500 12px/15px Montserrat' colorStyle='light' value={intl.formatMessage({ id: 'newcasino.aff.userlist.approve', defaultMessage: 'Approve' })} onClick={() => approve(item)} /></div>
      <div><Button buttonStyle={style.gridButtonStyle} icon='fa fa-times-circle-o' width='105px' height='28px' iconTextAlign='left' paddingTop='6px' font='normal normal 500 12px/15px Montserrat' colorStyle='light' value={intl.formatMessage({ id: 'newcasino.aff.userlist.reject', defaultMessage: 'Reject' })} onClick={() => reject(item)} /></div>
    </div>
    )
  }

  function successGetAffiliates(data) {
    props.spinner(false)
    var theListA = [...data[0]]; //data[0];
    var theListB = [...data[0]]; //data[0];

    var pendingList = theListA.filter(o => o.userType === -1);
    var approveList = theListB.filter(o => o.userType !== -1);

    setAffiliatesPending(pendingList);
    setAffiliates(approveList);

    if (pendingList && pendingList.length > 0) {
      setShowedTab(0);
    } else {
      setShowedTab(1);
    }
  }

  function failureGetAffiliates(data) {
    props.spinner(false)
    displayErrorNotificationIntl(intl, 'newcasino.aff.affiliates.get.list.fail')
  }

  function displayDetail(user) {
    if (user.userType === 5) {
      props.showDashBoardFor(user.email);
      displayPage(constants.ROUTE_HOME, navigate, location.pathname);
    }

  }

  try {

    return (
      <div className={style.topDiv}>
        <div className={style.title}>
          {intl.formatMessage({ id: 'newcasino.aff.userlist.title', defaultMessage: 'Affiliates' })}
        </div>
        <div className={style.subTitle}>
          {intl.formatMessage({ id: 'newcasino.aff.userlist.sub.title', defaultMessage: 'Affiliates list' })}
        </div>

        <div className={style.bubble}>
          <div className={style.gridTwo}>
            <div className={(showedTab === 0) ? style.tabSelected : style.tab} onClick={() => setShowedTab(0)}>
              {intl.formatMessage({ id: 'newcasino.aff.userlist.pending', defaultMessage: 'Pending Approval' })}
            </div>
            <div className={(showedTab === 1) ? style.tabSelected : style.tab} onClick={() => setShowedTab(1)}>
              {intl.formatMessage({ id: 'newcasino.aff.userlist.approved', defaultMessage: 'Approved' })}
            </div>
          </div>
          {((affiliatesPending && affiliatesPending.length > 0) || (affiliates && affiliates.length > 0)) &&
            <>
              {(showedTab === 0) &&
                <>
                  {(affiliatesPending && affiliatesPending.length > 0) ?
                    <MemoryList key={Math.random()} list={affiliatesPending} nbrItemPerPage={10} columns={columnsDefinitionPending} onRowClick={displayDetail} />
                    : <div>{intl.formatMessage({ id: 'newcasino.aff.userlist.emtpy', defaultMessage: 'No User to show' })}</div>
                  }
                </>
              }
              {(showedTab === 1) &&
                <>
                  {(affiliates && affiliates.length > 0) ?
                    <MemoryList key={Math.random()} list={affiliates} nbrItemPerPage={10} columns={columnsDefinition} onRowClick={displayDetail} />
                    : <div>{intl.formatMessage({ id: 'newcasino.aff.userlist.emtpy', defaultMessage: 'No User to show' })}</div>
                  }
                </>
              }
            </>

          }
        </div>

      </div>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}

export default Affiliates