/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import * as constants from '../../../constants.js'
import * as backendService from '../../../Services/Backend/backendService';
import { patternToFullText } from '../../../Utils/dateUtil';
import { displayErrorNotification, displayInfoNotification } from '../../../Utils/uiUtil';
import { setTheme } from '../../../Utils/themeUtils';
import { displayPage } from '../../../Utils/routerUtil';
import { useLocation, useNavigate } from 'react-router-dom';
import { getImageUrl } from '../../../Utils/uiUtil';

function ShowContestWinners(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const [currentContestIndex, setCurrentContestIndex] = useState(-1);
    const [contestList, setContestList] = useState();
    const [currentWinnerIndex, setCurrentWinnerIndex] = useState(-1);

    const intl = useIntl()

    useEffect(() => {
        backendService.getContestInformation(-1, contestWinnerSuccess, contestWinnerFailure, navigate, location.pathname)
        addEventListener("resize", forceClick, true);
    }, []);

    function forceClick() {
        if (document.getElementById('contestWinnerPage') === null) {
            removeEventListener("resize", forceClick, true);
        } else {
            var elem = document.getElementById('win_0');
            if (elem) {
                elem.click();
            }
        }
    }

    function getCurrentContest() {
        if (currentContestIndex === -1) {
            return false;
        }

        return contestList[currentContestIndex];
    }

    function contestWinnerSuccess(data) {

        if (data.result !== 'OK') {
            contestWinnerFailure(data.data);
            return;
        }
        data = data.data;


        if (data === null || data === undefined || data.length === 0) {
            displayPage(constants.ROUTE_HOME, navigate);
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.no.contest', defaultMessage: 'Their is no contest at the moment.' }));
        } else {
            setContestList(data)
            setCurrentContestIndex(data.length - 1);
            setCurrentWinnerIndex(0);
        }
    }

    function contestWinnerFailure(data) {
        displayPage(constants.ROUTE_HOME, navigate);
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.fetching.contest', defaultMessage: 'Error trying to get contests information' }));
    }

    function previousContest() {
        if (currentContestIndex > 0) {
            setCurrentContestIndex(currentContestIndex - 1);
            setCurrentWinnerIndex(0);
        }
    }

    function nextContest() {
        if (currentContestIndex + 1 < contestList.length) {
            setCurrentContestIndex(currentContestIndex + 1);
            setCurrentWinnerIndex(0);
        }
    }

    function setCurrentWinner(e, index) {
        setCurrentWinnerIndex(index);
        var bb = e.currentTarget.getBoundingClientRect();
        document.getElementById('theBanner').style.top = (bb.y - 16 + window.scrollY) + constants.PIXEL

        bb = document.getElementById('baseMobileDiv').getBoundingClientRect();
        var space = (index * 93) + 9

        if (index === 0) {
            space = space - 8;
        }

        document.getElementById('theBannerMobile').style.top = (bb.y + space + window.scrollY) + constants.PIXEL

    }
    function isFrench() {
        return props.lang.startsWith('fr-');
    }

    function isPortuguese() {
        return props.lang.startsWith('pt-');
    }

    function getWinnerName(index) {
        return isFrench() ? getCurrentContest().winners[index].name : getCurrentContest().winners[index].name;
    }

    function getWinnerDate(index) {
        if (getCurrentContest().winners[index].date) {
            return patternToFullText(getCurrentContest().winners[index].date, constants.DDMMYYYY, props.lang)
        } else {
            return '';
        }
    }

    function getWinnerPrize() {
        if (currentWinnerIndex > -1 && getCurrentContest().winners.length > currentWinnerIndex) {
            return isFrench() ? getCurrentContest().winners[currentWinnerIndex].prize.fr : isPortuguese() ? getCurrentContest().winners[currentWinnerIndex].prize.pt : getCurrentContest().winners[currentWinnerIndex].prize.en;
        } else {
            return '';
        }
    }

    function getBullet(index) {
        return isFrench() ? getCurrentContest().bullets[index].fr : isPortuguese() ? getCurrentContest().bullets[index].pt : getCurrentContest().bullets[index].en;
    }

    function getContestDescription() {
        return isFrench() ? getCurrentContest().description.fr : isPortuguese() ? getCurrentContest().description.pt : getCurrentContest().description.en;
    }

    function getContestName() {
        return isFrench() ? getCurrentContest().name.fr : isPortuguese() ? getCurrentContest().name.pt : getCurrentContest().name.en;
    }

    function getContestDates() {
        var toReturn = '';
        if (getCurrentContest().from && getCurrentContest().to) {

            if (getCurrentContest().from !== getCurrentContest().to) {
                toReturn = intl.formatMessage({
                    id: 'casino.general.date.from',
                    defaultMessage: 'From',
                }) + ' ' + patternToFullText(getCurrentContest().from, constants.DDMMYYYY, props.lang) + ' ' + intl.formatMessage({
                    id: 'casino.general.date.to',
                    defaultMessage: 'to',
                }) + ' ' + patternToFullText(getCurrentContest().to, constants.DDMMYYYY, props.lang);
            } else {
                toReturn = patternToFullText(getCurrentContest().from, constants.DDMMYYYY, props.lang);
            }
        }
        return toReturn;
    }



    if (getCurrentContest()) {
        return (
            <>
                <div id='contestWinnerPage' className={style.master_page_container}>
                    {contestList.length > 1 &&
                        <div className={(currentContestIndex === 0) ? style.chevrons2 : style.chevrons}>

                            {currentContestIndex > 0 &&
                                <div onClick={previousContest} className={style.chevronYellow}>
                                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                </div>
                            }
                            <div className={(currentContestIndex === 0) ? style.otherContests2 : style.otherContests}>{intl.formatMessage({ id: 'newcasino.contests.other', defaultMessage: 'Other contest(s)' })}</div>
                            <div onClick={nextContest} className={style.chevronYellow2}>
                                {(currentContestIndex < contestList.length - 1) &&
                                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                }
                            </div>

                        </div>
                    }
                    <div className={style.topMainDiv}>
                        <div>
                            <div className={style.case1grid}>
                                <div className={style.case1TopText}>{getContestDates()}</div>
                                <div className={style.case1TopText2}>{getContestName()}</div>
                                <div className={style.case1TopText3}>
                                    <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                </div>
                                <div className={style.case1TopText4}>{getContestDescription()}</div>
                                {getCurrentContest().bullets.map((item, index) => (
                                    <div key={'bullet_' + index} className={style.bulletGrid}>
                                        <div className={style.bullet1}>{(index + 1)}. </div>
                                        <div className={style.bullet2}>{getBullet(index)}</div>
                                    </div>))}
                            </div>
                        </div>
                        <div className={style.case}>
                            <img className={style.caseImg} src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/IMG_Contest2.png'))} />
                        </div>
                    </div>

                    {(getCurrentContest().winners.length == 0) &&
                        <>
                            <div className={style.bottomMainDivNoWinner}>
                                <div className={style.bannerNoWinner}>
                                    <div className={style.caseNoWinnerText}>{intl.formatMessage({ id: 'newcasino.contests.winner.none', defaultMessage: 'No winner yet!' })}</div>
                                    <div className={style.caseNoWinnerText2}>{intl.formatMessage({ id: 'newcasino.contests.winner.none2', defaultMessage: 'Future Winner(s) will appear right here' })}</div>
                                </div>
                            </div>
                        </>
                    }
                    {(getCurrentContest().winners.length > 0) &&
                        <>
                            <div className={style.bottomMainDiv}>
                                <div id='theBanner' className={style.banner}>
                                    <div className={style.right}>
                                        <div className={style.ggg}>
                                            <div className={style.ggg1}>{intl.formatMessage({ id: 'newcasino.contests.winner.of', defaultMessage: 'Winner of' })}</div>
                                            <div className={style.ggg2}>{getWinnerPrize()}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={style.left}>
                                    <div className={style.prizeGrid}>
                                        {getCurrentContest().winners.map((item, index) => (
                                            <React.Fragment key={'WINNER_' + index}>
                                                <div className={style.imgpopo} onClick={(e) => setCurrentWinner(e, index)}>
                                                    {(currentWinnerIndex == index) &&
                                                        <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/IMG_Winner.png'))} />
                                                    }
                                                </div>
                                                <div id={'win_' + index} className={(currentWinnerIndex !== index) ? style.hand : style.hand2} onClick={(e) => setCurrentWinner(e, index)}>
                                                    <div>
                                                        <div className={style.bottomText1}>{intl.formatMessage({ id: 'newcasino.contests.winner.no', defaultMessage: 'Contest winner no.' })} {(index + 1)}</div>
                                                        <div className={style.bottomText2}>{getWinnerName(index)}</div>
                                                        <div className={style.bottomText3}>{getWinnerDate(index)}</div>
                                                    </div>
                                                </div>
                                                <div />
                                                <div className={style.spacer} />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div id='baseMobileDiv' className={style.bottomMainDivMobile}>
                                <div className={style.testff}>
                                    <div id='theBannerMobile' className={style.bannerMobile}>
                                        {
                                            <div className={style.rightMobile}>
                                                <div className={style.gggMobile}>
                                                    <div className={style.gggMobile1}>{intl.formatMessage({ id: 'newcasino.contests.winner.of', defaultMessage: 'Winner of' })}</div>
                                                    <div className={style.gggMobile2}>{getWinnerPrize()}</div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {(currentWinnerIndex > 0) &&
                                        <div className={(currentWinnerIndex > 0) ? style.leftMobileBottomSpacer : style.leftMobile}>
                                            <div className={style.prizeGridMobile}>
                                                {getCurrentContest().winners.map((item, index) => (
                                                    (index < currentWinnerIndex) &&
                                                    <React.Fragment key={'PRIZE_' + index}>
                                                        <div id={'win_' + index} className={(currentWinnerIndex !== index) ? style.hand : ''} onClick={(e) => setCurrentWinner(e, index)}>
                                                            <div>
                                                                <div className={style.bottomText1}>{intl.formatMessage({ id: 'newcasino.contests.winner.no', defaultMessage: 'Contest winner no.' })} {(index + 1)}</div>
                                                                <div className={style.bottomText2}>{getWinnerName(index)}</div>
                                                                <div className={style.bottomText3}>{getWinnerDate(index)}</div>
                                                            </div>
                                                        </div>
                                                        <div />

                                                        {((index + 1) < currentWinnerIndex) &&
                                                            <div className={style.spacer} />
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    {getCurrentContest().winners.map((item, index) => (
                                        <React.Fragment key={'WINNER_' + index}>
                                            {(currentWinnerIndex === index) &&
                                                <div className={style.leftMobile}>
                                                    <div className={style.prizeGridMobile}>
                                                        <div className={(currentWinnerIndex !== index) ? style.hand : ''} onClick={(e) => setCurrentWinner(e, index)}>
                                                            <div>
                                                                <div className={style.bottomText1}>{intl.formatMessage({ id: 'newcasino.contests.winner.no', defaultMessage: 'Contest winner no.' })} {(index + 1)}</div>
                                                                <div className={style.bottomText2}>{getWinnerName(index)}</div>
                                                                <div className={style.bottomText3}>{getWinnerDate(index)}</div>
                                                            </div>
                                                        </div>
                                                        <div />
                                                    </div>
                                                </div>
                                            }
                                        </React.Fragment>
                                    ))}
                                    {(currentWinnerIndex < (getCurrentContest().winners.length - 1)) &&
                                        <div className={style.leftMobileSpace}>
                                            <div className={style.prizeGridMobile}>
                                                {getCurrentContest().winners.map((item, index) => (
                                                    (index > currentWinnerIndex) &&
                                                    <React.Fragment key={'WINNER_' + index}>
                                                        <div className={(currentWinnerIndex !== index) ? style.hand : ''} onClick={(e) => setCurrentWinner(e, index)}>
                                                            <div>
                                                                <div className={style.bottomText1}>{intl.formatMessage({ id: 'newcasino.contests.winner.no', defaultMessage: 'Contest winner no.' })} {(index + 1)}</div>
                                                                <div className={style.bottomText2}>{getWinnerName(index)}</div>
                                                                <div className={style.bottomText3}>{getWinnerDate(index)}</div>
                                                            </div>
                                                        </div>
                                                        <div />
                                                        {(index < (getCurrentContest().winners.length - 1)) &&
                                                            <div className={style.spacer} />
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </>
        );
    }
}
export default ShowContestWinners;
