/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from '../../../../Services/JWTBackend/jwtBackendService';
import { displayGenericErrorNotificationIntl, displayInfoNotification, displayInfoNotificationIntl, displayInfoNotificationWithButton, isMobile } from '../../../../Utils/uiUtil';
import Button from '../../../../Generic/Inputs/Button';
import { setTheme } from '../../../../Utils/themeUtils';
import DivClick from '../../../../Generic/Inputs/DivClick';
import ShopElement from './ShopElement';
import { convertToMoney } from '../../../../Utils/converterUtil';
import ApiPersistence from '../../../../Utils/ApiPersistence';
import ComboBox from '../../../../Generic/Inputs/ComboBox';
import { displayPage } from '../../../../Utils/routerUtil';
import * as constants from '../../../../constants'
import { closeSnackbar } from 'notistack';

function PNGShop(props) {

    const CATEGORY_ALL = 0;
    const CATEGORY_CASH = 1;
    const CATEGORY_WAGER = 2;
    const CATEGORY_FREESPIN = 3;
    const CATEGORY_AVAILABLE = 4;

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();
    let notificationKey = null;

    const [selectedElements, setSelectedElements] = useState(-1);
    const [shopElements, setShopElements] = useState([]);
    const [currentCategory, setCurrentCategory] = useState(props.category ? props.category : CATEGORY_ALL);

    const nbrPoints = props.nbrPoints;
    const currentLevelTxt = props.levelTxt;
    const currentLevel = props.level;

    const categoryList = [
        { key: CATEGORY_ALL, value: intl.formatMessage({ id: 'newcasino.loyalty.shop.category.1', defaultMessage: 'All' }) },
        { key: CATEGORY_CASH, value: intl.formatMessage({ id: 'newcasino.loyalty.shop.category.2', defaultMessage: 'Cash' }) },
        { key: CATEGORY_WAGER, value: intl.formatMessage({ id: 'newcasino.loyalty.shop.category.3', defaultMessage: 'Wagers' }) },
        { key: CATEGORY_FREESPIN, value: intl.formatMessage({ id: 'newcasino.loyalty.shop.category.4', defaultMessage: 'Freespins' }) },
        { key: CATEGORY_AVAILABLE, value: intl.formatMessage({ id: 'newcasino.loyalty.shop.category.5', defaultMessage: 'All I can afford' }) }
    ];

    useEffect(() => {
        loadStore();
    }, []);

    function loadStore() {
        jwtBackendService.loadShop(successLoadShop, failureLoadShop, navigate, location.pathname);
    }

    function successLoadShop(data) {
        setShopElements(data[0]);

    }

    function failureLoadShop(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function previousButtonClicked(scrollname) {
        let left = -450;
        if (isMobile()) {
            left = -200;
        }

        document.getElementById(scrollname).scrollBy({
            top: 0,
            left: left,
            behavior: "smooth",
        });
    }

    function nextButtonClicked(scrollname) {
        let left = 450;
        if (isMobile()) {
            left = 200;
        }
        document.getElementById(scrollname).scrollBy({
            top: 0,
            left: left,
            behavior: "smooth",
        });
    }

    function upButtonClicked(scrollname) {
        let left = -450;
        if (isMobile()) {
            left = -163;
        }

        document.getElementById(scrollname).scrollBy({
            top: left,
            left: 0,
            behavior: "smooth",
        });
    }

    function downButtonClicked(scrollname) {
        let left = 450;
        if (isMobile()) {
            left = 163;
        }
        document.getElementById(scrollname).scrollBy({
            top: left,
            left: 0,
            behavior: "smooth",
        });
    }

    function getPointsLeft() {
        return nbrPoints;
    }


    function getLevelTxt() {
        return currentLevelTxt;
    }

    function buyElement() {
        if (props.kycApproved === 1) {
            jwtBackendService.buyElementFromShop(selectedElements, successBuyElement, failureBuyElement, navigate, location.pathname);
        } else {
            displayInfoNotificationIntl(intl, 'newcasino.loyalty.shop.no.kyc');
        }
    }

    function displayFreeSpinPage() {
        try {
            if (notificationKey) {
                closeSnackbar(notificationKey);
            }

            jwtBackendService.switchWallet(-1, switchWalletSuccess, switchWalletSuccess, navigate, location.pathname)


        } catch (error) {
            console.log(error.message);
        }
    }

    function switchWalletSuccess(data) {

        props.refreshWallets();
        displayPage(constants.ROUTE_HOME_FREESPIN, navigate);
    }

    function successBuyElement(data) {
        if (data.status === 'OK') {
            if (data.purchaseType === 1) {
                props.closeModal();
                props.refreshWallets();
                displayInfoNotification('', intl.formatMessage({ id: 'newcasino.loyalty.shop.confirm.cash', defaultMessage: 'You just bought:' }) + ' ' + convertToMoney(data.purchaseCashAmount, ApiPersistence.getInstance().getUser().coin), intl)
            }
            else if (data.purchaseType === 2) {
                props.closeModal();
                props.refreshWallets();
                displayInfoNotification('', intl.formatMessage({ id: 'newcasino.loyalty.shop.confirm.wager.1', defaultMessage: 'You just bought a wager wallet of' }) + ' ' + convertToMoney(data.amountWagerWork, ApiPersistence.getInstance().getUser().coin) + ' ' + intl.formatMessage({ id: 'newcasino.loyalty.shop.confirm.wager.2', defaultMessage: 'with a' }) + ' ' + data.xTimeWagerWork + intl.formatMessage({ id: 'newcasino.loyalty.shop.confirm.wager.3', defaultMessage: 'x requirement.' }), intl)
            }
            else if (data.purchaseType === 3) {
                props.closeModal();
                props.refreshWallets();
                notificationKey = displayInfoNotificationWithButton('', intl.formatMessage({ id: 'newcasino.loyalty.shop.confirm.freespin.1', defaultMessage: 'You just bought' }) + ' ' + data.purchaseFreeSpinQte + ' ' + intl.formatMessage({ id: 'newcasino.loyalty.shop.confirm.freespin.2', defaultMessage: 'freespins' }), <Button width='200px' active={true} colorStyle='light' value={intl.formatMessage({ id: 'newcasino.loyalty.shop.confirm.freespin.usethemnow', defaultMessage: 'Use them now' })} onClick={() => displayFreeSpinPage()} />)

            }

            props.reloadParent();
        } else {
            throw new error();
        }
    }

    function failureBuyElement(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function changeSelection(data) {
        setCurrentCategory(Number(data));
    }

    function hasNoElement() {
        for (let i = 0; i < shopElements.length; i++) {
            if (shopElements[i].elementCost <= nbrPoints) {
                return false;
            }
        }
        return true;
    }

    function displayPrize(item) {
        let toReturn = false;

        if (currentCategory === CATEGORY_ALL) {
            toReturn = true;
        } else if (currentCategory === CATEGORY_AVAILABLE && item.elementCost <= nbrPoints) {
            toReturn = true;
        } else if (currentCategory === item.elementType) {

            toReturn = true;
        }

        return toReturn;
    }

    try {
        return (
            <>
                <div className={style.mainDiv}>
                    <div className={style.headerTitle}>
                        {intl.formatMessage({ id: 'newcasino.loyalty.shop.title', defaultMessage: 'Reward Shop' })}
                    </div>
                    <div>
                        <DivClick className={style.closeModal}><i className="fa fa-times fa-2x" aria-hidden="true" onClick={props.closeModal}></i></DivClick>
                    </div>
                </div >

                <div className={style.headerSubTitle}>
                    {intl.formatMessage({ id: 'newcasino.loyalty.shop.subtitle', defaultMessage: 'Exchange your PNG Reward points for the bonus of your choice! Select a reward and click on the confirm button.' })}
                </div>
                <div className={style.grid50}>
                    <div className={style.dashInfoDesktop}>
                        <div className={style.dashInfoGrid}>
                            <div className={style.dashInfoImg}>
                                <img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Points.svg')} alt='' />
                            </div>
                            <div className={style.dashInfoLabel}>{intl.formatMessage({ id: 'newcasino.loyalty.mypoint', defaultMessage: 'My PNG Rewards PTS' })}</div>
                            <div className={style.dashInfoValue}>{getPointsLeft()}</div>
                            <div></div>
                        </div>
                    </div>
                    <div className={style.dashInfoMobile}>
                        <div className={style.dashInfoLabel}>{intl.formatMessage({ id: 'newcasino.loyalty.mypoint', defaultMessage: 'My PNG Rewards PTS' })}</div>
                        <div className={style.dashInfoGrid}>
                            <div className={style.dashInfoImg}>
                                <img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Points.svg')} alt='' />
                            </div>
                            <div className={style.dashInfoValue}>{getPointsLeft()}</div>
                            <div></div>
                        </div>
                    </div>
                    <div className={style.dashInfoDesktop}>
                        <div className={style.dashInfoGrid}>
                            <div className={style.dashInfoImg}>
                                <img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Level.svg')} alt='' />
                            </div>
                            <div className={style.dashInfoLabel}>{intl.formatMessage({ id: 'newcasino.loyalty.mylevel', defaultMessage: 'My Level' })}</div>
                            <div className={style.dashInfoValue}>{getLevelTxt()}</div>
                            <div></div>
                        </div>
                    </div>
                    <div className={style.dashInfoMobile}>
                        <div className={style.dashInfoLabel}>{intl.formatMessage({ id: 'newcasino.loyalty.mylevel', defaultMessage: 'My Level' })}</div>
                        <div className={style.dashInfoGrid}>
                            <div className={style.dashInfoImg}>
                                <img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Level.svg')} alt='' />
                            </div>
                            <div className={style.dashInfoValue}>{getLevelTxt()}</div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <div><ComboBox label='Categories' onChange={changeSelection} values={[currentCategory]} valueList={{ list: categoryList, key: 'key', value: 'value' }} /></div>

                {((currentCategory === CATEGORY_AVAILABLE) && hasNoElement()) &&
                    <div className={style.canBuyNothing}>
                        {intl.formatMessage({ id: 'newcasino.loyalty.no.points', defaultMessage: 'You don\'t have enought point left to buy anything' })}
                    </div>
                }

                {(!((currentCategory === CATEGORY_AVAILABLE) && hasNoElement())) &&
                    <>
                        <div className={style.master_page_container_gridDesktop}>
                            <DivClick className={style.chevronLeft} onClick={() => previousButtonClicked('scrollbarShop')}><i className="fa fa-chevron-left" aria-hidden="true"></i></DivClick>
                            <div className={style.topMainDiv}>
                                <div id={'scrollbarShop'} className={`${style.GameSelection_scrollTestContainer} ${style.GameSelection_scrollTestContainer_paddingbottom}`}>
                                    <ul className={`${style.GameSelection_ul}`}>
                                        <div key={Math.random()}>
                                            {
                                                shopElements.map((itemProvider, indexProvider) => (
                                                    (displayPrize(itemProvider)) && (
                                                        <li key={'shopElem' + indexProvider}>
                                                            <div className={`${style.GameSelection_showAllGridElementForceSize}`}>
                                                                <div className={`${style.GameSelection_showAllGridElement_div}`}>
                                                                    <div>
                                                                        <div className={`${style.GameSelection_showAllGridElement}`}>
                                                                            <ShopElement selected={selectedElements === itemProvider.id} level={currentLevel} canBuy={itemProvider.elementCost <= getPointsLeft()} value={itemProvider} onClick={() => setSelectedElements(itemProvider.id)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                ))}
                                        </div>
                                    </ul>
                                </div>
                            </div >
                            <DivClick className={style.chevronRight} onClick={() => nextButtonClicked('scrollbarShop')}><i className="fa fa-chevron-right" aria-hidden="true"></i></DivClick>
                        </div >

                        <div className={style.master_page_container_gridMobile}>
                            <DivClick className={style.chevronLeft} onClick={() => upButtonClicked('scrollbarShopMobile')}><i className="fa fa-chevron-up" aria-hidden="true"></i></DivClick>
                            <div className={style.topMainDiv}>
                                <div id={'scrollbarShopMobile'} className={`${style.GameSelection_scrollTestContainer} ${style.GameSelection_scrollTestContainer_paddingbottom}`}>
                                    <ul className={`${style.GameSelection_ul}`}>
                                        <div key={Math.random()} className={style.vertical}>
                                            {
                                                shopElements.map((itemProvider, indexProvider) => (
                                                    (displayPrize(itemProvider)) && (
                                                        <li key={'shopElem' + indexProvider} className={style.li100}>
                                                            <div className={`${style.GameSelection_showAllGridElementForceSize}`}>
                                                                <div className={`${style.GameSelection_showAllGridElement_div}`}>
                                                                    <div>
                                                                        <div className={`${style.GameSelection_showAllGridElement}`}>
                                                                            <div className={style.center}>
                                                                                <ShopElement selected={selectedElements === itemProvider.id} level={currentLevel} canBuy={itemProvider.elementCost <= getPointsLeft()} value={itemProvider} onClick={() => setSelectedElements(itemProvider.id)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                ))}
                                        </div>
                                    </ul>
                                </div>
                            </div >
                            <DivClick className={style.chevronRight} onClick={() => downButtonClicked('scrollbarShopMobile')}><i className="fa fa-chevron-down" aria-hidden="true"></i></DivClick>
                        </div >

                        <div className={style.buttonBar}>
                            <div className={style.button1}><Button chevron='left' value={intl.formatMessage({ id: 'newcasino.loyalty.shop.cancel', defaultMessage: 'Cancel' })} onClick={props.closeModal} /></div>
                            <div className={style.button2}><Button value={intl.formatMessage({ id: 'newcasino.loyalty.shop.confirm', defaultMessage: 'Confirm' })} active={(selectedElements > -1)} onClick={buyElement} /></div>
                        </div>
                    </>
                }
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default PNGShop;
