import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import { setTheme } from '../../../Utils/themeUtils';
import ReceiverLine from './ReceiverLine';
import Button from '../../../Generic/Inputs/Button';
import { displayErrorNotificationIntl, getAmountTxt } from '../../../Utils/uiUtil';
import { useLocation, useNavigate } from 'react-router-dom'
import * as jwtBackendService from '../../../Services/JWTBackend/jwtBackendService'
import { getImageUrl } from '../../../Utils/uiUtil';

function GiveAways(props) {

    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    const [loaded, setLoaded] = useState(false);
    const [serverData, setServerData] = useState([]);
    const [serverDataUpdated, setServerDataUpdated] = useState([]);

    useEffect(() => {
        getGiveAwayInformation();
    },// eslint-disable-next-line
        [])

    function getGiveAwayInformation() {
        setLoaded(false)
        setServerData([])
        setServerDataUpdated([])
        jwtBackendService.getNonApprovedGiveaways(onSuccessGiveAwayInformation, onFailureGiveAwayInformation, navigate, location.pathname);
    }

    function onSuccessGiveAwayInformation(data) {
        setLoaded(true);
        setServerData(data);
        setServerDataUpdated(JSON.parse(JSON.stringify(data)));

    }

    function onFailureGiveAwayInformation(data) {
        setLoaded(true);
    }

    function doSelection(index, value) {
        let newData = JSON.parse(JSON.stringify(serverDataUpdated));
        newData[index].isAccepted = value;
        setServerDataUpdated(newData);
    }

    function getAmount(item) {
        return getAmountTxt(item.receiverAmount, item.streamerCoin)
    }

    function getHint(item) {
        return 'UserId: ' + item.receiverId + ' - ' + item.requestDate;
    }

    function save() {
        jwtBackendService.saveGiveawaysApproval(serverDataUpdated, onSuccessSaveGiveAway, onFailureSaveGiveAway, navigate, location.pathname);
    }

    function onSuccessSaveGiveAway(data) {
        getGiveAwayInformation();
    }

    function onFailureSaveGiveAway(data) {
        displayErrorNotificationIntl(intl, 'newcasino.generic.error.msg')

    }
    var hasData = (serverData && serverData.length > 0);
    var lastStreamer = -1;
    if (loaded) {
        return (
            <div className={style.master_page_container}>
                {(props.header !== false && !hasData) &&
                    <div className={hasData ? style.mainTitle : style.mainTitleCenter}>
                        <FormattedMessage
                            id="newcasino.giveaways.admin.title"
                            defaultMessage="Giveaway"
                        />
                    </div>
                }

                {(props.header !== false && hasData) &&
                    <div className={style.grid50}>
                        <div>
                            <img className={style.theImgSmall} src={setTheme(getImageUrl('newui/themes/{THEME}/Giveaways/giveaways.jpg'))} alt='' /><br />
                        </div>
                        <div className={style.mainTitle}>
                            <FormattedMessage
                                id="newcasino.giveaways.admin.title"
                                defaultMessage="Giveaway"
                            />
                        </div>

                    </div>
                }

                {(hasData) &&
                    <div className={style.mainDiv}>
                        {serverData.map((item, index) => {
                            let lastOne = lastStreamer;
                            lastStreamer = item.streamerId

                            if (lastOne !== item.streamerId) {
                                return (
                                    <React.Fragment key={'ELEM' + index}>
                                        <div className={style.gridStreamer}>
                                            <div className={style.gridStreamerIcon}>
                                                <img src={setTheme(getImageUrl('newui/themes/{THEME}/BorderMenu/IMG_StreamingGet-sel.png'))} alt='' />
                                            </div>
                                            <div>
                                                <div className={style.gridStreamerName}>{item.streamerName} ({item.streamerEmail})</div>
                                                {
                                                    serverData.map((item2, index2) => {
                                                        if (index2 >= index && item2.streamerId === item.streamerId) {
                                                            return (
                                                                <ReceiverLine key={'REC' + index2} index={index2} hint={getHint(item2)} alert={doSelection} name={item2.receiverName} email={item2.receiverEmail} amount={getAmount(item2)} />
                                                            )
                                                        } else {
                                                            return '';
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            } else {
                                return '';
                            }
                        })}
                        <div className={style.buttonSpacer}><Button value={intl.formatMessage({ id: 'newcasino.giveaways.admin.save', defaultMessage: 'Save', })} onClick={save} /></div>
                    </div>
                }
                {(serverData && serverData.length === 0) &&
                    <div className={style.nothingToShow}>
                        <img className={style.theImg} src={setTheme(getImageUrl('newui/themes/{THEME}/Giveaways/giveaways.jpg'))} alt='' /><br />
                        <FormattedMessage id="newcasino.giveaways.admin.none" defaultMessage="Their is no pending giveaway" />
                    </div>
                }
            </div>
        );
    }

}

export default GiveAways;
