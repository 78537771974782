import React from 'react'
import SearchResultModal from './SearchResultModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { displayPage } from '../../../Utils/routerUtil';
import * as constants from '../../../constants.js'

function ModalSearchController(props) {

    const navigate = useNavigate();
    const location = useLocation();

    function useFreeSpin(gameId) {
        props.closeModal();
        displayPage(constants.ROUTE_PLAY_GAME_BASE + gameId, navigate, location.pathname);
    }

    return ((props.msg.message.trim().length > 0) &&
        <main>
            <SearchResultModal isMobile={props.isMobile} useFreeSpin={useFreeSpin} displayComponent={props.displayComponent} getFreeSpinAvailable={props.getFreeSpinAvailable} handleGameStart={props.handleGameStart} msg={props.msg} />
        </main>
    );

}

export default ModalSearchController