import React, { useState } from 'react'
import style from './style.module.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import TextField from '../../../Generic/Inputs/TextField';
import Button from '../../../Generic/Inputs/Button';
import Checkbox from '../../../Generic/Inputs/Checkbox';
import { displayErrorNotificationIntl, isEmail, isTextMin2Char } from '../../../Utils/uiUtil';
import { useIntl } from 'react-intl';
import * as jwtBackendService from '../../../Services/JWTBackend/jwtBackendService';
import ApiPersistence from '../../../Utils/ApiPersistence';

function Login(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  var defaultEmail = ''
  var defaultPassword = ''
  var defaultRememberMe = false
  var defaultPasswordValid = null
  var defaultEmailValid = null

  var remerberMeInformation = ApiPersistence.getInstance().getRememberMe();
  if (remerberMeInformation) {
    defaultEmail = remerberMeInformation[0];
    defaultRememberMe = true;
    defaultEmailValid = isEmail(defaultEmail);
  }

  const [stayConnected, setStayConnected] = useState(defaultRememberMe);
  const [email, setEmail] = useState(defaultEmail);
  const [password, setPassword] = useState(defaultPassword);
  const [emailValid, setEmailValid] = useState(defaultEmailValid);
  const [passwordValid, setPasswordValid] = useState(defaultPasswordValid);

  function processLogin() {
    if (canLogin) {
      props.spinner(true);
      jwtBackendService.login(email, password, stayConnected, callbackLoginSuccess, callbackLoginFailure, navigate, location.pathname);
    }
  }

  function callbackLoginSuccess(data) {

    props.spinner(false);

    if (data) {
      //Login is not good
      if (data.result === 'KO') {
        displayErrorNotificationIntl(intl, 'newcasino.aff.auth.login.err.fail')
      }
    } else {

      if (stayConnected === true) {
        ApiPersistence.getInstance().setRememberMe(email, '')
      } else {
        ApiPersistence.getInstance().deleteRememberMe()
      }
    }
  }
  function callbackLoginFailure(data) {
    props.spinner(false);
  }

  function processRegister() {
    props.showRegistration();
  }

  function processStayConnected(value) {
    setStayConnected(value);
  }

  function emailValidCallBack(isValid, value) {
    setEmailValid(isValid);
    setEmail(value)
  }

  function passwordValidCallBack(isValid, value) {
    setPasswordValid(isValid);
    setPassword(value);
  }

  function forgetPassword() {
    props.showForgetPassword();
  }

  function canLogin() {
    return (emailValid && passwordValid)
  }

  try {
    return (
      <div className={style.login_mainGrid}>
        <div className={style.login_title}>{intl.formatMessage({ id: 'newcasino.aff.auth.login.title', defaultMessage: 'Welcome to the Numbers Game back-office' })}</div>
        <div className={style.login_spacer} />

        <div className={style.login_text}>
          {intl.formatMessage({ id: 'newcasino.aff.auth.login.sub.title', defaultMessage: 'Enter the required information to login or register to open a new account.' })}
        </div>
        <div className={style.login_input}>
          <TextField maxLength='50' height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' autofocus='true' topTitleStyle={style.inputTitle} values={[email, emailValid]} hint={intl.formatMessage({ id: 'newcasino.aff.auth.login.email.hint', defaultMessage: 'Type your email here' })} label={intl.formatMessage({ id: 'newcasino.aff.auth.login.email', defaultMessage: 'Email address' })} validator={isEmail} validatorInformer={emailValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.aff.auth.login.email.err', defaultMessage: 'Invalid Email' })} />
        </div>
        <div className={style.login_input}>
          <TextField maxLength='20' height='36px' border='1px solid #DBDBDB' checkMarginTop='3px' topTitleStyle={style.inputTitle} onEnterPress={processLogin} values={[password, passwordValid]} type='password' canSee='false' mandatory='true' label={intl.formatMessage({ id: 'newcasino.aff.auth.login.password', defaultMessage: 'Password' })} validator={isTextMin2Char} showTitleError='false' showCheck='false' validatorInformer={passwordValidCallBack} validationMsg='' />
        </div>
        <div className={style.login_grid50}>
          <div className={style.login_checkLine}>
            <Checkbox checked={stayConnected} labelStyle={style.inputTitle} onClick={processStayConnected} label={intl.formatMessage({ id: 'newcasino.aff.auth.login.stay', defaultMessage: 'Stay Connected' })} />
          </div>
          <div className={style.login_checkLineForget} onClick={forgetPassword}>
            {intl.formatMessage({ id: 'newcasino.aff.auth.login.forget', defaultMessage: 'Forget your password?' })}
          </div>
        </div>
        <div className={style.login_grid50}>
          <div className={style.login_buttonLine}>
            <Button width='180px' height='36px' paddingTop='8px' font='normal normal 500 12px/15px Montserrat' onClick={processRegister} value={intl.formatMessage({ id: 'newcasino.aff.auth.login.button.register', defaultMessage: 'Register' })} colorStyle='dark' />

          </div>
          <div className={style.login_buttonLineRight}>
            <Button width='180px' height='36px' paddingTop='8px' font='normal normal 500 12px/15px Montserrat' onClick={processLogin} active={canLogin()} value={intl.formatMessage({ id: 'newcasino.aff.auth.login.button.login', defaultMessage: 'Login' })} colorStyle='light' />
          </div>
        </div>
      </div>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}
export default Login
