/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from '../../../Services/JWTBackend/jwtBackendService';
import Button from '../../../Generic/Inputs/Button';
import NonMemoryList from '../../../Generic/Inputs/NonMemoryList';
import { displayPage } from '../../../Utils/routerUtil';
import * as constants from '../../../constants.js'
import Checkbox from '../../../Generic/Inputs/Checkbox';
import StreamerDeal from './StreamerDeal';
import { displayGenericErrorNotificationIntl, displayInfoNotificationIntl } from '../../../Utils/uiUtil';
import ApiPersistence from '../../../Utils/ApiPersistence';
import PartnerDeal from './PartnerDeal';

function UserManagement(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();
    const [loaded, setLoaded] = useState(false)

    const [userType, setUserType] = useState(ApiPersistence.getInstance().getUserManagementLastSearch().userType) //-1
    const [banned, setBanned] = useState(ApiPersistence.getInstance().getUserManagementLastSearch().banned) //false
    const [kyc, setKyc] = useState(ApiPersistence.getInstance().getUserManagementLastSearch().kyc) //false

    const [refresh, setRefresh] = useState(Math.random())

    const [searchText, setSearchText] = useState(ApiPersistence.getInstance().getUserManagementLastSearch().searchText) //''
    const [currentPage, setCurrentPage] = useState(0)
    const [nbrPage, setNbrPage] = useState(0)
    const [nbrTotalElement, setNbrTotalElement] = useState(0)

    const [sortedColumn, setSortedColumn] = useState(ApiPersistence.getInstance().getUserManagementLastSearch().sortedColumn); //name 1
    const [sortedOrder, setSortedOrder] = useState(ApiPersistence.getInstance().getUserManagementLastSearch().sortedOrder);  //asc 0

    const [users, setUsers] = useState();

    const columnsDefinition = [
        { name: intl.formatMessage({ id: 'newcasino.admin.userlist.col1', defaultMessage: 'Name' }), width: '34%', hintColumn: 'id', listColumn: 'name', canSort: true, sortValue: 1 },
        { name: intl.formatMessage({ id: 'newcasino.admin.userlist.col2', defaultMessage: 'Email' }), width: '33%', listColumn: 'email', canSort: true, sortValue: 2 },
        { name: intl.formatMessage({ id: 'newcasino.admin.userlist.col3', defaultMessage: 'Options' }), width: '33%', listColumn: 'userType', condition: 'equals', conditionValue: [3, 5], icon: 'fa fa-sliders', function: openStreamerDeal, canSort: false }
    ];

    useEffect(() => {
        fetchData()
    }, [])

    function fetchData() {
        jwtBackendService.getUserListWithFilter(1, 10, userType, (banned ? 1 : 0), (kyc ? 1 : 0), searchText, 1, 0, successUserList, failureUserList, navigate, location.pathname);
    }


    function openStreamerDeal(data) {
        let userId = data.id;
        let theComponent = null;

        if (data.userType === 3) {
            theComponent = <PartnerDeal key={Math.random()} userId={userId} />
        } else {
            theComponent = <StreamerDeal key={Math.random()} userId={userId} />
        }


        const theMsg = {
            component: theComponent,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)

    }

    function successUserList(data) {
        setUsers(data[0]);
        setCurrentPage(data[1][0].currentPage)
        setNbrPage(data[1][0].nbrPage)
        setNbrTotalElement(data[1][0].nbrElementTotal)
        setRefresh(Math.random())
        setLoaded(true);
    }

    function failureUserList(data) {
        setLoaded(true);
    }

    function displayDetail(userId) {
        displayPage(constants.ROUTE_ADMIN_USERDATA_BASE + userId, navigate, location.pathname);
    }

    function sort(columnDef, order) {
        let theOrder = 0;
        let theColumn = columnDef.sortValue
        if (sortedColumn === columnDef.sortValue) {
            theOrder = (sortedOrder === 0) ? 1 : 0;
        }

        setSortedColumn(theColumn);
        setSortedOrder(theOrder);
        ApiPersistence.getInstance().setUserManagementLastSearch(userType, banned, kyc, searchText, theColumn, theOrder);
        jwtBackendService.getUserListWithFilter(1, 10, userType, (banned ? 1 : 0), (kyc ? 1 : 0), searchText, theColumn, theOrder, successUserList, failureUserList, navigate, location.pathname);

    }

    function exportToCSV() {
        jwtBackendService.exportUserListWithFilter(userType, (banned ? 1 : 0), (kyc ? 1 : 0), searchText, sortedColumn, sortedOrder, successExportToCSV, failureExportToCSV, navigate, location.pathname);
    }

    function successExportToCSV(data) {
        //jwtBackendService.getUserListCSVFile(successDownload, failureDownload, navigate, location.pathname);
    }

    function successDownload() {
        displayInfoNotificationIntl(intl, 'newcasino.admin.userlist.download')
    }

    function failureDownload() {
        displayGenericErrorNotificationIntl(intl);
    }

    function failureExportToCSV(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function changePage(change) {
        let curPage = (change);
        jwtBackendService.getUserListWithFilter(curPage, 10, userType, (banned ? 1 : 0), (kyc ? 1 : 0), searchText, sortedColumn, sortedOrder, successUserList, failureUserList, navigate, location.pathname);
    }


    function bannedClick() {
        let value = !banned;
        setBanned(value);
        ApiPersistence.getInstance().setUserManagementLastSearch(userType, value, kyc, searchText, 1, 0);
        jwtBackendService.getUserListWithFilter(1, 10, userType, (value ? 1 : 0), (kyc ? 1 : 0), searchText, 1, 0, successUserList, failureUserList, navigate, location.pathname);
    }

    function kycClick() {
        let value = !kyc;
        setKyc(value);
        ApiPersistence.getInstance().setUserManagementLastSearch(userType, banned, value, searchText, 1, 0);
        jwtBackendService.getUserListWithFilter(1, 10, userType, (banned ? 1 : 0), (value ? 1 : 0), searchText, 1, 0, successUserList, failureUserList, navigate, location.pathname);
    }

    function doNothing() { }

    function streamerClick() {
        let newValue = (userType === -1) ? 5 : -1;
        setUserType(newValue);
        ApiPersistence.getInstance().setUserManagementLastSearch(newValue, banned, kyc, searchText, 1, 0);
        jwtBackendService.getUserListWithFilter(1, 10, newValue, (banned ? 1 : 0), (kyc ? 1 : 0), searchText, 1, 0, successUserList, failureUserList, navigate, location.pathname);
    }

    function setNewSearchTxt(event) {
        setSearchText(event.currentTarget.value);
    }

    function userSearch(event) {
        if (event.key === 'Enter') {
            setSearchText(event.currentTarget.value);
            setSortedColumn(1);
            setSortedOrder(0);
            ApiPersistence.getInstance().setUserManagementLastSearch(userType, banned, kyc, event.currentTarget.value, 1, 0);
            jwtBackendService.getUserListWithFilter(1, 10, userType, (banned ? 1 : 0), (kyc ? 1 : 0), event.currentTarget.value, 1, 0, successUserList, failureUserList, navigate, location.pathname);
        }
    }

    if (loaded) {
        return (
            <div id='contestWinnerPage' className={style.master_page_container}>
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.admin.userlist.title"
                        defaultMessage="User Management"
                    />
                </div>

                <div className={style.mainDiv}>
                    <div className={`${style.borderMenu_row}`}>
                        <div className={`${style.borderMenu_searchBar} ${style.borderMenu_search_grid}`}>
                            <div className={`${style.borderMenu_searchBar_input}`}>
                                <input value={searchText} onChange={setNewSearchTxt} maxLength={50} className={`${style.borderMenu_searchBar_input_tag}`} placeholder={intl.formatMessage({ id: 'newcasino.admin.userlist.search', defaultMessage: 'Search Users' })} onKeyUp={(e) => userSearch(e)} />
                            </div>
                            <div className={`${style.borderMenu_searchBar_search}`}><i className="fa fa-search" aria-hidden="true"></i></div>
                        </div>
                    </div>
                    <div className={style.labelTitle}>{intl.formatMessage({ id: 'newcasino.admin.userlist.list.filters', defaultMessage: 'Filters' })}</div>
                    <div className={style.grid3}>
                        <Checkbox checked={userType === 5} label={intl.formatMessage({ id: 'newcasino.admin.userlist.list.filters.streamers', defaultMessage: 'Only Streamers' })} onClick={streamerClick} />
                        <Checkbox checked={banned === true} label={intl.formatMessage({ id: 'newcasino.admin.userlist.list.filters.banned', defaultMessage: 'Banned' })} onClick={bannedClick} />
                        <Checkbox checked={kyc === true} label={intl.formatMessage({ id: 'newcasino.admin.userlist.list.filters.kyc', defaultMessage: 'KYC Pending' })} onClick={kycClick} />
                    </div>

                    <div className={style.labelTitle}>{intl.formatMessage({ id: 'newcasino.admin.userlist.list.title', defaultMessage: 'List of players' })} ({nbrTotalElement})</div>
                    <NonMemoryList key={refresh} noResultTxt={intl.formatMessage({ id: 'newcasino.admin.userlist.list.noresult', defaultMessage: 'No Result' })} list={users} changePage={changePage} nbrItemPerPage={10} currentPage={currentPage} nbrPage={nbrPage} columns={columnsDefinition} sortedColumn={sortedColumn} sortedOrder={sortedOrder} onSort={sort} onRowClick={displayDetail} onRowClickParam='id' />
                    {(nbrPage > 0) &&
                        <Button value={intl.formatMessage({ id: 'newcasino.admin.userlist.export', defaultMessage: 'Export to CSV' })} onClick={exportToCSV} />
                    }
                </div>
            </div >
        );
    }
}

export default UserManagement;
