/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';
import DivClick from '../../../Generic/Inputs/DivClick';
import * as constants from '../../../constants.js'
import { displayPage } from '../../../Utils/routerUtil'
import { useLocation, useNavigate } from 'react-router-dom';


function ResponsibleGambling(props) {

    const navigate = useNavigate();
    const location = useLocation();

    function openPage(pageRoute) {
        displayPage(pageRoute, navigate, location.pathname);
    }

    return (
        <div id='contestWinnerPage' className={style.master_page_container}>
            <div className={style.mainTitle}>
                <FormattedMessage
                    id="casino.rg.title"
                    defaultMessage="Responsible Gambling"
                />
            </div>
            <div className={style.mainDiv}>
                <div className={style.elemSection}>
                    <div className={style.elemTitle}><FormattedMessage id="casino.rg.depositlimit" defaultMessage="Deposit Limit" /></div>
                    <div className={style.elemText}><FormattedMessage id="casino.rg.depositlimitmsg" /></div>
                    <DivClick className={style.showCursor} onClick={() => openPage(constants.ROUTE_RESPONSBILE_GAMING_DEPOSIT_LINIT)}>
                        <div className={style.elemAction}><FormattedMessage id="casino.rg.setlimit" defaultMessage="Set Limit" /></div>
                    </DivClick>
                </div>

                <div className={style.elemSection}>
                    <div className={style.elemTitle}><FormattedMessage id="casino.rg.losslimit" defaultMessage="Loss Limit" /></div>
                    <div className={style.elemText}><FormattedMessage id="casino.rg.losslimitmsg" /></div>
                    <DivClick className={style.showCursor} onClick={() => openPage(constants.ROUTE_RESPONSBILE_GAMING_LOSSLIMIT)}>
                        <div className={style.elemAction}><FormattedMessage id="casino.rg.setlimit" defaultMessage="Set Limit" /></div>
                    </DivClick>

                </div>
                <div className={style.elemSection}>
                    <div className={style.elemTitle}><FormattedMessage
                        id="casino.rg.takebreak"
                        defaultMessage="Take a break"
                    /></div>
                    <div className={style.elemText}><FormattedMessage
                        id="casino.rg.takebreakmsg"
                    /></div>
                    <DivClick className={style.showCursor} onClick={() => openPage(constants.ROUTE_RESPONSBILE_GAMING_TAKE_A_BREAK)}>
                        <div className={style.elemAction}><FormattedMessage id="casino.rg.setlimit" defaultMessage="Set Limit" /></div>
                    </DivClick>

                </div>
                <div className={style.elemSection}>
                    <div className={style.elemTitle}><FormattedMessage
                        id="casino.rg.selfexclusion"
                        defaultMessage="Self Exclusion"
                    /></div>
                    <div className={style.elemText}><FormattedMessage
                        id="casino.rg.selfexclusionmsg"
                    /></div>
                    <DivClick className={style.showCursor} onClick={() => openPage(constants.ROUTE_RESPONSBILE_GAMING_SELF_EXCLUSION)}>
                        <div className={style.elemAction}><FormattedMessage id="casino.rg.setlimit" defaultMessage="Set Limit" /></div>
                    </DivClick>

                </div>
                <div className={style.elemSection}>
                    <div className={style.elemTitle}><FormattedMessage
                        id="casino.rg.activitycheck"
                        defaultMessage="Activity check"
                    /></div>
                    <div className={style.elemText}><FormattedMessage
                        id="casino.rg.activitycheckmsg"
                    /></div>
                    <DivClick className={style.showCursor} onClick={() => openPage(constants.ROUTE_RESPONSBILE_GAMING_ACTIVITY_CHECK)}>
                        <div className={style.elemAction}><FormattedMessage id="casino.rg.setlimit" defaultMessage="Set Limit" /></div>
                    </DivClick>

                </div>
            </div>
        </div >
    );
}

export default ResponsibleGambling;
