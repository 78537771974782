import React, { useState } from 'react'
import style from './style.module.scss'
import { isNumeric, isText } from '../../../../Utils/uiUtil';
import TextField from '../../../../Generic/Inputs/TextField';
import Checkbox from '../../../../Generic/Inputs/Checkbox';
import Button from '../../../../Generic/Inputs/Button';

function ProviderEditor(props) {

  const [id] = useState(props.provider ? props.provider.id : -1);
  const [active, setActive] = useState(props.provider ? props.provider.active : 0);
  const [api, setApi] = useState(props.provider ? props.provider.api : 'GROOVE');
  const [name, setName] = useState(props.provider ? props.provider.name : '')
  const [organization_name, setOrganization_name] = useState(props.provider ? props.provider.organization_name : '')
  const [provider_id, setProvider_id] = useState(props.provider ? props.provider.provider_id : '')
  const [error, setError] = useState('');

  function validate(clone) {
    return isText(clone.name) && isText(clone.api) && isNumeric(clone.provider_id) && isText(clone.organization_name);
  }

  function onSave() {
    var toClone = props.provider ? props.provider : {}
    var clone = JSON.parse(JSON.stringify(toClone))

    clone.id = id;
    clone.active = active;
    clone.api = api;
    clone.name = name;
    clone.provider_id = provider_id;
    clone.organization_name = organization_name;

    if (validate(clone)) {
      props.closeModal();

      var action = 'MODIFY';
      if (props.index === -1) {
        action = 'ADD'
      }

      props.callback(clone, props.index, action);
    } else {
      setError('Validation Error');
    }
  }

  function onDelete() {
    props.closeModal();
    props.callback(props.provider, props.index, 'DELETE_PROV');
  }

  function setApiGroove(data) {
    if (data === true) {
      setApi('GROOVE');
    } else {
      setApi('HACKSAW');
    }
  }

  function setApiHacksaw(data) {
    if (data === true) {
      setApi('HACKSAW');
    } else {
      setApi('GROOVE');
    }
  }

  function toggleActive(value) {
    if (value === false) {
      setActive(0)
    } else {
      setActive(1)
    }
  }

  try {

    return (
      <>
        <div className='topModal_pops topModal_title'>
          <h4 className='topModal_rg-heads'>
            Provider Editor
          </h4>
        </div>
        <div>
          <div>
            <div className={style.bottomPad}><TextField autofocus='true' values={[name]} label={'Name'} onChange={setName} /></div>
            <div className={style.bottomPad}><TextField type='digit' values={[provider_id]} label={'Provider Id'} onChange={setProvider_id} /></div>
            <div className={style.bottomPad}><TextField values={[organization_name]} label={'Organization Name'} onChange={setOrganization_name} /></div>
            <div className={style.bottomPad}><Checkbox label={'Active'} checked={(active === 1)} onClick={toggleActive} /></div>
            <div className={style.bottomPad}><Checkbox key={api} label={'Groove API'} checked={(api === 'GROOVE')} onClick={setApiGroove} /></div>
            <div className={style.bottomPad}><Checkbox key={api} label={'Hacksaw API'} checked={(api === 'HACKSAW')} onClick={setApiHacksaw} /></div>

            <div className={style.twoColGrid50}>
              <div><Button width='100px' colorStyle='dark' value={'Save'} onClick={onSave} /></div>

              {(props.index > -1) &&
                <div><Button width='100px' colorStyle='light' value={'Delete'} onClick={onDelete} /></div>

              }
            </div>
          </div>
          {error}
        </div>


      </>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default ProviderEditor