import React from 'react'
import style from './style.module.scss'
import PrivacyPolicy from '../../../../../../Casino/General/PrivacyPolicy';

function PolicyDisplay(props) {


  function getScrollHeight() {
    var toReturn = window.innerHeight;
    if (props.isMobile) {
      toReturn = (toReturn - 300) * 0.9
    } else {
      toReturn = (toReturn - 300) * 0.9
    }

    return toReturn + "px";
  }

  try {
    return (
      <div className={style.mainDiv}>
        <div style={{ height: getScrollHeight() }} className={`${style.SearchResultModal_scrollable}`}>
          <PrivacyPolicy header={false} />
        </div>
      </div>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}
export default PolicyDisplay