import React, { useEffect, useState } from 'react'
import ImagesGroup from '../ImagesGroup';
import * as backEndService from '../../../Services/Backend/backendService';
import HorizontalSlider from '../HorizontalSlider';

function GenericTest(props) {

  const [list, setList] = useState(null);

  useEffect(() => {
    getAllGamesInformation()

  },// eslint-disable-next-line
    [])


  function onSuccessGetAllGamesInformation(newDefinition) {
    setList(newDefinition.games[0].list)
  }

  function onFailureGetAllGamesInformation(error) {
    console.log(error);
  }

  function getAllGamesInformation() {
    return backEndService.getHomePageGamesInformation(false, props.definition, onSuccessGetAllGamesInformation, onFailureGetAllGamesInformation)
  }



  if (list) {
    try {
      return (
        <HorizontalSlider>
          <ImagesGroup images={list} />
        </HorizontalSlider>
      );
    } catch (error) {
      console.log('ERROR: ' + error.message);
      return '';
    }
  }
}

export default GenericTest

