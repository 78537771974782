import React, { useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';

import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work

import { isNumeric } from '../../../../../../Utils/uiUtil';
import TextField from '../../../../../../Generic/Inputs/TextField';

function PromoPngPoint(props) {
    const intl = useIntl();

    // eslint-disable-next-line no-unused-vars
    const [quantity, setQuantity] = useState(null);

    // eslint-disable-next-line no-unused-vars
    const [isQuantityValidMaster, setIsQuantityValidMaster] = useState(false);

    function isQuantityValid(isValid, value) {
        setIsQuantityValidMaster(isValid);


        if (isValid) {
            value = Number(value)
            setQuantity(value);
            props.sendDetails({ quantity: value });
        } else {
            props.sendDetails(null);
            setQuantity(value);
        }
    }

    try {
        return (
            <>
                <div className={style.onefield}>
                    <div className={style.bottom}><TextField maxLength='100' autofocus='true' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.cash.quantity', defaultMessage: 'Quantity' })} values={['']} validator={isNumeric} validatorInformer={isQuantityValid} mandatory='true' showCheck='true' /></div>
                </div>
            </>
        )
    } catch (error) {
        console.log(error);
    }
}

export default PromoPngPoint;