/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import DivClick from '../../../Generic/Inputs/DivClick';
import * as constants from '../../../constants.js'
import { displayPage } from '../../../Utils/routerUtil'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as jwtBackendService from '../../../Services/JWTBackend/jwtBackendService';
import { getCoinValue, isTextMin2Char } from '../../../Utils/uiUtil';
import PaymentIQ from './PaymentIQ';
import ApiPersistence from '../../../Utils/ApiPersistence';
import TxHistory from './TxHistory';
import TextField from '../../../Generic/Inputs/TextField';
import Button from '../../../Generic/Inputs/Button';
import PromoCode from '../../../Casino/General/PromoCode';

function Cashier(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [kyc, setKyc] = useState(undefined);
    const [phoneVerify_phone, setPhoneVerify_phone] = useState(undefined);
    const [is_full_kyc_done, setIs_full_kyc_done] = useState(undefined);

    const [wallets, setWallets] = useState([]);
    const [historicalData, setHistoricalData] = useState([]);
    const [reloadHistory, setReloadHistory] = useState(Math.random());

    const [showHistoryTab, setShowHistoryTab] = useState(false);
    const [vaultBalance, setVaultBalance] = useState(0);

    const [promoCode, setPromoCode] = useState(params.id ? params.id : '');

    const [promoCodeMessage, setPromoCodeMessage] = useState('');
    const [promoCodeAllowed, setPromoCodeAllowed] = useState(false);
    const [promoCodeValid, setPromoCodeValid] = useState(false);

    useEffect(() => {
        getDepositInformation()
    }, []);


    function toggleHistoryTab() {
        setShowHistoryTab(!showHistoryTab);
    }
    function getDepositInformation() {
        getDepositInformationJWT()
    }

    function getDepositInformationJWT() {
        jwtBackendService.getDepositInformation(successGetDepositInformationJWT, failureGetDepositInformationJWT, navigate, location.pathname);
    }

    function reloadBalance() {
        getDepositInformation();
        props.refreshWallets();
    }

    function successGetDepositInformationJWT(data) {
        setKyc(data[0][0].kyc)
        setPhoneVerify_phone(data[1][0].phoneVerify_phone)
        setIs_full_kyc_done(data[2][0].is_full_kyc_done)
        setWallets(data[3])
        setVaultBalance(data[4][0].vaultBalance);
    }

    function getCurrentBalance() {
        let toReturn = 0;
        if (wallets && wallets.length > 0) {
            for (let i = 0; i < wallets.length; i++) {
                if (wallets[i].walletState === 1 && wallets[i].walletType === 0) {
                    toReturn = wallets[i].balance;
                    break;
                }
            }
        }
        return toReturn;
    }



    function setTxHistory(data) {
        if (data.status === 'OK') {
            setHistoricalData(data.transactions)
            setReloadHistory(Math.random())
        }
    }

    function failureGetDepositInformationJWT(data) {
        //Should do something
    }

    return (

        <div id='cashierPage' className={style.master_page_container}>
            <div className={style.mainTitle}>
                <FormattedMessage
                    id="newcasino.cashier.title"
                    defaultMessage="Cashier"
                />
            </div>
            <div className={style.mainSubTitle}>
                <FormattedMessage
                    id="newcasino.cashier.subtitle"
                    defaultMessage="From here, you can make deposit and withdrawal of money"
                />
            </div>



            <div className={style.currBalanceGrid}>
                <div className={style.currBalanceBoxCenter}>
                    <div className={style.currBalanceBox}>
                        <div className={style.currBalanceLine1}>
                            <div className={style.currBalanceLine1_1}><img className={style.currBalanceLine1_1_img} src='https://playnumbersgame.com/images/general/newui/IMG_Wallet.png' alt='' /></div>
                            <div className={style.currBalanceLine1_2}><FormattedMessage id="casino.cashier.currentBalance" defaultMessage="Current Balance" /></div>
                        </div>
                        <div className={style.currBalanceLine2}>
                            <div className={style.currBalanceLine2_1}>{getCoinValue('signo', ApiPersistence.getInstance().getUser().coin)}</div>
                            <div className={style.currBalanceLine2_2}>{parseFloat(getCurrentBalance()).toFixed(2)}</div>
                        </div>
                        <div className={style.currBalanceLine3}>
                            ({ApiPersistence.getInstance().getUser().coin})
                        </div>
                    </div>
                </div>

                <div className={style.desktopView}>
                    <div className={style.currBalanceBoxCenter}>
                        <div className={style.promoBox}>
                            <div className={style.promoCodeLine1}>
                                <div className={style.promoCodeRedeem}><FormattedMessage id="casino.cashier.promocode" defaultMessage="Redeem promo code" /></div>
                            </div>
                            <PromoCode initCode={promoCode} refreshWallets={props.refreshWallets} isCashierPage={true} />
                            <div className={promoCodeAllowed ? style.promoCodeLine3 : style.promoCodeLine4}>
                                {promoCodeMessage}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.currBalanceBoxCenter}>
                <div>
                    <div className={style.currBalanceBoxMobile}>
                        <div className={style.currBalanceBoxMobileCol1}><FormattedMessage id="casino.cashier.currentBalance" defaultMessage="Current Balance" /></div>
                        <div className={style.currBalanceBoxMobileCol2}>
                            <div className={style.currBalanceBoxMobileDollar}>{getCoinValue('signo', ApiPersistence.getInstance().getUser().coin)}</div>
                            <div className={style.currBalanceBoxMobileAmount}>{parseFloat(getCurrentBalance()).toFixed(2)}</div>
                        </div>
                    </div>
                    <div className={style.currBalanceBoxMobileGrid2}>
                        <div className={style.currBalanceBoxMobileCurrency1}>Current currency : </div>
                        <div className={style.currBalanceBoxMobileCurrency2}>CAD</div>
                    </div>
                </div>
            </div>

            <div className={style.mobileView}>
                <div className={style.currBalanceBoxCenter}>
                    <div className={style.promoBox}>
                        <div className={style.promoCodeLine1}>
                            <div className={style.promoCodeRedeem}><FormattedMessage id="casino.cashier.promocode" defaultMessage="Redeem promo code" /></div>
                        </div>
                        <PromoCode initCode={promoCode} refreshWallets={props.refreshWallets} isCashierPage={true} onOneLine={true} />
                        <div className={promoCodeAllowed ? style.promoCodeLine3 : style.promoCodeLine4}>
                            {promoCodeMessage}
                        </div>
                    </div>
                </div>
            </div>

            {kyc === "None" &&
                <div className={style.note} onClick={() => displayPage(constants.ROUTE_PROFILE, navigate)}>
                    <div className={style.noteTitle}>
                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;&nbsp;
                        <FormattedMessage id="casino.cashier.alert1.message" defaultMessage="Important!" />
                    </div>
                    <DivClick className={style.noteMsg}>
                        <FormattedMessage id="casino.cashier.alert1.description" defaultMessage="You need to complete your personal information to be able to deposit or withdraw. Click on this notice or visit the Edit Profile section." />
                    </DivClick>
                </div>
            }
            {
                (phoneVerify_phone === null || (is_full_kyc_done == 0 && is_full_kyc_done != -1)) &&
                <div className={style.note} onClick={() => displayPage(constants.ROUTE_PROFILE, navigate)}>
                    <div className={style.noteTitle}>
                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;&nbsp;
                        <FormattedMessage id="casino.cashier.alert1.message" defaultMessage="Important!" />
                    </div>
                    <DivClick className={style.noteMsg}>
                        <FormattedMessage id="casino.cashier.alert2.description" defaultMessage="You need to verify your `phone number` and `add identity photo` to be able to deposit and withdraw. Click on this notice or visit the Edit Profile section." />
                    </DivClick>
                </div>

            }
            {
                is_full_kyc_done === -1 &&
                <div className={style.note} onClick={() => displayPage(constants.ROUTE_PROFILE, navigate)}>
                    <div className={style.noteTitle}>
                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;&nbsp;
                        <FormattedMessage id="casino.cashier.alert1.message" defaultMessage="Important!" />
                    </div>
                    <DivClick className={style.noteMsg}>
                        <FormattedMessage id="casino.cashier.alert3.description" defaultMessage="Your KYC document has been rejected. Please add a new \'Add Identity Photo\'. Click on this notice or visit the Edit Profile section." />
                    </DivClick>
                </div>
            }
            {(kyc === "Submited" && phoneVerify_phone !== null) && (
                <div className={style.cashierbg}>
                    {(historicalData && historicalData.length > 0) &&
                        <div className={style.cashierHistoryContainer}><DivClick className={style.cashierHistory} onClick={toggleHistoryTab}>{showHistoryTab ? intl.formatMessage({ id: 'newcasino.cashier.tab.back', defaultMessage: 'Back to Cashier' }) : intl.formatMessage({ id: 'newcasino.cashier.tab.history', defaultMessage: 'Transaction history' })}</DivClick></div>
                    }
                    <div className={showHistoryTab ? style.noShow : style.show}><PaymentIQ reloadBalance={reloadBalance} isMobile={props.isMobile} locale={ApiPersistence.getInstance().getLanguage()} setTxHistory={setTxHistory} /></div>
                    <div className={showHistoryTab ? style.show : style.noShow}><TxHistory key={reloadHistory} data={historicalData} /></div>
                </div>
            )}
        </div >
    );
}

export default Cashier;
