import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import { useIntl } from 'react-intl';
import { isMobile } from '../../../Utils/uiUtil';
/*
 nbrItemPerPage
 list
 onRowClick
 columns
 boldElement
 */
function MemoryList(props) {

  const intl = useIntl();
  const DEFAULT_ITEM_PERPAGE = 10;

  const [list, setList] = useState(null);
  const [currentSortedColumn, setCurrentSortedColumn] = useState(props.initSortColumn ? props.initSortColumn : '');
  const [currentSortedColumnAsc, setCurrentSortedColumnAsc] = useState(props.initSortColumnAsc != null ? props.initSortColumnAsc : true);

  const [currentPage, setCurrentPage] = useState(1);

  const nbrItemPerPage = props.nbrItemPerPage ? props.nbrItemPerPage : DEFAULT_ITEM_PERPAGE;

  const startIndex = (currentPage - 1) * nbrItemPerPage;
  const lastIndex = startIndex + (nbrItemPerPage - 1);

  useEffect(() => {
    setList(props.list);
  },// eslint-disable-next-line
    []);

  function getNbrPage() {
    var toReturn = list.length / nbrItemPerPage;
    return Math.ceil(toReturn);
  }

  function onRowClick(value) {
    if (props.onRowClick) {
      props.onRowClick(value);
    }
  }

  function previousPage() {
    setCurrentPage(currentPage - 1);
  }

  //canSort: true, sortField: 'creationDateMillis' },

  function sort(col) {
    var clone = JSON.parse(JSON.stringify(list));
    if (col.canSort === true) {
      var field = col.listColumn;
      if (col.sortField) {
        field = col.sortField;
      }
      var isAsc = true;

      if (currentSortedColumn === field) {
        isAsc = !currentSortedColumnAsc;
      }

      clone.sort(function (a, b) {
        return compareAlpha(a[field], b[field], isAsc);
      })

      setCurrentSortedColumnAsc(isAsc)
      setCurrentSortedColumn(field);
      setList(clone);
    }
  }

  function canBeSorted(col) {
    return (col.canSort === true);
  }

  function isSorted(col) {
    return (col.listColumn === currentSortedColumn || col.sortField === currentSortedColumn)
  }

  function compareAlpha(a, b, isAsc) {
    let x = a ? a.toLowerCase() : '';
    let y = b ? b.toLowerCase() : '';

    var toReturn = 0
    if (x > y) { toReturn = isAsc ? 1 : -1; }
    if (x < y) { toReturn = isAsc ? -1 : 1; }

    return toReturn;
  }


  function nextPage() {
    setCurrentPage(currentPage + 1);
  }

  function getPagination() {
    var previous = (currentPage > 1) ? <span className={style.page} onClick={previousPage}>{intl.formatMessage({ id: 'newcasino.memorylist.previous', defaultMessage: 'Previous Page' })} - </span> : ''
    var pageTxt = <>{intl.formatMessage({ id: 'newcasino.memorylist.page', defaultMessage: 'Page' })} {currentPage} {intl.formatMessage({ id: 'newcasino.memorylist.of', defaultMessage: 'of' })} {getNbrPage()}</>
    var next = (currentPage < getNbrPage()) ? <span className={style.page} onClick={nextPage}> - {intl.formatMessage({ id: 'newcasino.memorylist.next', defaultMessage: 'Next Page' })}</span> : ''
    return (
      <div className={style.pagination}>
        {previous}
        {pageTxt}
        {next}
      </div>
    );
  }

  function getRowCellStyle(index, index2, item) {
    var toReturn = '';
    let isBold = false;

    if (props.boldElement) {
      isBold = (item[props.boldElement.key] === props.boldElement.value) ? true : false;
    }

    if (props.lineSeparator === true) {
      if (isMobileDevice()) {
        if (index2 === 0) {
          toReturn = isBold ? style.rowCellLightBoldTopSeparator : style.rowCellLightTopSeparator;
        } else {
          toReturn = isBold ? style.rowCellLightBold : style.rowCellLight;
        }
      } else {
        toReturn = isBold ? style.rowCellLightBoldTopSeparator : style.rowCellLightTopSeparator;
      }

    } else {
      toReturn = isBold ? style.rowCellLightBold : style.rowCellLight;

      if (index % 2 === 0) {
        toReturn = isBold ? style.rowCellDarkBold : style.rowCellDark;
      }
    }

    return toReturn;
  }

  function getRawValue(data, colDef) {
    return data[colDef.listColumn];
  }

  function getValue(data, colDef) {
    if (colDef.transformer) {
      if (colDef.transformerObj === true) {
        return colDef.transformer(data[colDef.listColumn], data);
      } else {
        return colDef.transformer(data[colDef.listColumn], intl);
      }

    } else {
      return data[colDef.listColumn];
    }

  }

  function getGridTemplateColumns() {
    var toReturn = '';
    for (let i = 0; i < props.columns.length; i++) {
      toReturn = toReturn + ' ' + props.columns[i].width;
    }

    return toReturn.trim();
  }

  function getArrow(col) {
    let sorted = isSorted(col);
    let asc = currentSortedColumnAsc;
    let toReturn = '';

    if (sorted) {
      if (asc === true) {
        toReturn = <i className="fa fa-arrow-down" style={{ color: props.headerSortArrow }} aria-hidden="true"></i>
      } else {
        toReturn = <i className="fa fa-arrow-up" style={{ color: props.headerSortArrow }} aria-hidden="true"></i>
      }
    }

    return toReturn;

  }

  function getHeaderStyle(col) {
    let theStyle = isSorted(col) ? style.headerSorted : style.header

    if (props.headerStyle) {
      theStyle = props.headerStyle;
    }

    return theStyle;
  }

  function isMobileDevice() {
    return isMobile();
  }

  function getStyle(item, colDef) {
    let toReturn = props.rowBackGroundColor ? { 'background-color': props.rowBackGroundColor } : {};

    if (colDef.colorTrue || colDef.colorFalse) {
      let theValue = getRawValue(item, colDef);
      if (theValue) {
        toReturn.color = colDef.colorTrue;
      } else {
        toReturn.color = colDef.colorFalse;
      }
    }

    return toReturn;
  }

  try {
    if (list && list.length > 0) {
      return (
        <div className={style.mainGrid} style={isMobileDevice() ? { 'gridTemplateColumns': '100%' } : { 'gridTemplateColumns': getGridTemplateColumns() }}>
          {!isMobileDevice() &&
            <>
              {props.columns.map((col, index) => (
                <div key={'col_' + index} onClick={() => sort(col)} style={canBeSorted(col) ? { cursor: 'pointer' } : {}} className={getHeaderStyle(col)}>{col.name} {props.headerSortArrow != null ? getArrow(col) : ''}</div>
              ))}
            </>
          }
          {list.map((item, index) => (
            <React.Fragment key={'colRow_' + index}>
              {(index >= startIndex && index <= lastIndex) &&
                <>
                  {
                    props.columns.map((col, index2) => (
                      <div key={'colRow_' + index + '_row_' + index2} title={col.hintColumn ? item[col.hintColumn] : ''} className={getRowCellStyle(index, index2, (item))} style={getStyle(item, col)} onClick={() => onRowClick(item)}>{(isMobileDevice() && col.hideLabelOnMobile !== true) ? (col.name + ' : ') : ''}{col.transformerHTML ? col.transformerHTML(item, col) : getValue(item, col)}</div>
                    ))
                  }
                </>}</React.Fragment>))}
          <div style={isMobileDevice() ? {} : { gridColumn: 'span ' + props.columns.length }}>
            {getPagination()}
          </div>
        </div>

      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default MemoryList

