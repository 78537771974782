import React from 'react'
import * as constants from '../../../constants.js'
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl'
import { displayPage } from '../../../Utils/routerUtil'
import { useNavigate, useLocation } from 'react-router-dom'
import DivClick from '../../../Generic/Inputs/DivClick'
import { getImageUrl } from '../../../Utils/uiUtil';

function PageFooter(props) {

  const navigate = useNavigate();
  const location = useLocation();

  var maintenance = props.maintenance;

  function checkIt() {
    if (maintenance) {
      props.allowLogin();
    }
  }

  function openPage(page) {
    if (!maintenance) {
      props.resetBorderMenu();
      displayPage(page, navigate, location.pathname)
    }
  }

  function kgcClicked() {
    window.open('https://certificates.gamingcommission.ca/KGCCertificates.aspx?id=0b29be10-ba25-4522-bfc7-af5dc330dcd8', 'BrandNameCertificates', 'location=0,status=0,scrollbars=1');
  }

  try {
    if (props.forceHide === false) {
      return (

        <div className={`${style.Footer_parent}`}>
          <div className={`${style.Footer_gridMain}`}>
            <div className={`${style.Footer_gridLine1}`}>

              <div className={`${style.Footer_gridLine1_txtalign}`}>
                <div className={`${style.Footer_gridLine1_col1_bg}`}><img className={`${style.Footer_logo}`} src={getImageUrl('newui/footer/IMG_Logo-Header.png')} alt='' /></div>
                <div className={`${style.Footer_adress}`}>
                  <FormattedMessage id="newcasino.footer.col1.address" defaultMessage="First Floor, Millennium House Victoria Road, Douglas, IM24RW Isle of Man" />
                </div>
                <div>
                  <img className={`${style.Footer_social_logo}`} src={getImageUrl('newui/footer/IMG_Facebook.svg')} alt='' onClick={() => openPage('https://www.facebook.com/PlayThenumbersgame')} /><img className={`${style.Footer_social_logo}`} src={getImageUrl('newui/footer/IMG_Instagram.svg')} alt='' onClick={() => openPage('https://www.instagram.com/playnumbersgame/')} />
                </div>
                <div>
                  <DivClick className={`${style.menuTop_button_dark} ${style.Footer_button_contact}`} onClick={() => openPage(constants.ROUTE_CONTACT)}>
                    <div className={`${style.menuTop_centerize}`} >
                      <i>
                        <FormattedMessage id="newcasino.footer.col1.contactus" defaultMessage="Contact us" />
                      </i>
                    </div>
                  </DivClick>
                </div>
                <div className={`${style.Footer_gridLine3} ${style.Footer_mobile}`} />
              </div>

              <div className={`${style.Footer_colSpacer}`}>
                <div className={`${style.Footer_gridLine2} ${style.Footer_gridLine1_txtalign}`}>
                  <div className={`${style.Footer_aboutUs}`}><FormattedMessage id="newcasino.footer.col2.title" defaultMessage="About us" /></div>
                  <div className={`${style.Footer_aboutUs_text}`}><FormattedMessage id="newcasino.footer.col2.text" defaultMessage="We are Numbers Game, deeply inhabited by uncompromising freedom, we believe in free will and in the relevance of everyone's decisions to have their life as they see fit. Discretion and trust are the values that elevate us. place your bets here in cryptocurrencies or with the method of deposit you prefer and enjoy your journey" /></div>
                  <div>
                    <DivClick className={`${style.menuTop_button_dark} ${style.Footer_button_contact}`} onClick={() => openPage(constants.ROUTE_ABOUT)}>
                      <div className={`${style.menuTop_centerize}`}>
                        <i>
                          <FormattedMessage id="newcasino.footer.col2.learnmore" defaultMessage="Learn more" />
                        </i>
                      </div>
                    </DivClick>
                  </div>
                </div>
                <div className={`${style.Footer_gridLine3} ${style.Footer_mobile}`} />
              </div>
              <div className={`${style.Footer_2grid}`}>
                <div className={`${style.Footer_colSpacer}`}>
                  <DivClick className={`${style.Footer_aboutUs}`}><FormattedMessage id="newcasino.footer.col3.title" defaultMessage="Numbers Game" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_HOME)}><FormattedMessage id="newcasino.footer.col3.link.home" defaultMessage="Home" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_HOME_CASINO)}><FormattedMessage id="newcasino.footer.col3.link.casino" defaultMessage="Casino" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_HOME_LIVEDEALER)}><FormattedMessage id="newcasino.footer.col3.link.livecasino" defaultMessage="Live Casino" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_ABOUT)}><FormattedMessage id="newcasino.footer.col3.link.aboutus" defaultMessage="About us" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_CONTACT)}><FormattedMessage id="newcasino.footer.col3.link.contact" defaultMessage="Contact" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_LOGIN)}><FormattedMessage id="newcasino.footer.col3.link.login" defaultMessage="Login" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_REGISTER)}><FormattedMessage id="newcasino.footer.col3.link.register" defaultMessage="Register" /></DivClick>
                </div>
                <div className={`${style.Footer_colSpacer}`}>
                  <DivClick className={`${style.Footer_aboutUs}`}><FormattedMessage id="newcasino.footer.col4.title" defaultMessage="Important links" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_TERMS_AND_CONDITIONS)}><FormattedMessage id="newcasino.footer.col4.link.terms" defaultMessage="Terms and Conditions" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_BONUS_TERMS)}><FormattedMessage id="newcasino.footer.col4.link.bonusterms" defaultMessage="Bonus Terms" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_BETTING_RULES)}><FormattedMessage id="newcasino.footer.col4.link.betting" defaultMessage="Betting Rules" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_PRIVACY_POLICY)}><FormattedMessage id="newcasino.footer.col4.link.privacy" defaultMessage="Privacy Policy" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_FAQ)}><FormattedMessage id="newcasino.footer.col4.link.faq" defaultMessage="FAQ" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage('https://affiliatepng.com/')}><FormattedMessage id="newcasino.footer.col4.link.affiliate" defaultMessage="Affiliates" /></DivClick>
                  <DivClick className={`${style.Footer_link_text}`} onClick={() => openPage(constants.ROUTE_BLOG)}><FormattedMessage id="newcasino.footer.col4.link.blog" defaultMessage="Blog" /></DivClick>
                </div>
              </div>
            </div>
            <div className={`${style.Footer_gridLine3}`} />
            <div className={`${style.Footer_gridLine4} ${style.Footer_desktop}`}>
              <div><img src={getImageUrl('newui/footer/IMG_Mastercard.svg')} alt='' /></div>
              <div><img src={getImageUrl('newui/footer/IMG_GameCare.svg')} alt='' /></div>
              <div><img src={getImageUrl('newui/footer/IMG_18-Plus.svg')} alt='' /></div>
              <a className={style.kgcLink} href='#' onClick={kgcClicked}><img className={style.kgcImage} src='https://certificates.gamingcommission.ca/Members/Pages/Certificates/GeneratedCertificates/logo.gif' alt='' /></a>
              <div><img src={getImageUrl('newui/footer/IMG_Interac.svg')} alt='' /></div>
              <div><img src={getImageUrl('newui/footer/AstroPay-Logo.png')} alt='' /></div>
              <div><img src={getImageUrl('newui/footer/IMG_Payz.svg')} alt='' /></div>
            </div>
            <div className={`${style.Footer_gridLine4mobile} ${style.Footer_mobile}`}>

              <div className={`${style.Footer_grid2col}`}>
                <div className={`${style.Footer_alignC}`}><img src={getImageUrl('newui/footer/IMG_Mastercard.svg')} alt='' /></div>
                <div className={`${style.Footer_alignC}`}><img src={getImageUrl('newui/footer/IMG_GameCare.svg')} alt='' /></div>
              </div>

              <div className={`${style.Footer_grid3col}`}>
                <div className={`${style.Footer_alignC}`}><img src={getImageUrl('newui/footer/IMG_18-Plus.svg')} alt='' /></div>
                <a className={style.kgcLink} href='#' onClick={kgcClicked}><img className={style.kgcImage} src='https://certificates.gamingcommission.ca/Members/Pages/Certificates/GeneratedCertificates/logo.gif' alt='' /></a>
                <div className={`${style.Footer_alignC}`}><img src={getImageUrl('newui/footer/IMG_Interac.svg')} alt='' /></div>
              </div>

              <div className={`${style.Footer_grid2col}`}>
                <div className={`${style.Footer_alignC}`}><img src={getImageUrl('newui/footer/AstroPay-Logo.png')} alt='' /></div>
                <div className={`${style.Footer_alignC}`}><img src={getImageUrl('newui/footer/IMG_Payz.svg')} alt='' /></div>
              </div>
            </div>
            <div onClick={checkIt} className={`${style.Footer_gridLine5}`}>
              <FormattedMessage id="newcasino.footer.copy.line1" defaultMessage="Numbers Games is owned by GAC Group Limited and operated under the Kahnawake Gaming License of GAC Group Limited, a company incorporated in the Isle of Man." /> <br /><FormattedMessage id="newcasino.footer.copy.line2" defaultMessage="© Copyright 2022 Numbers Game. All rights reserved." />
            </div>
          </div>
        </div>

      );
    } else {
      return (
        <></>
      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }

}

export default PageFooter
