import React, { useState } from 'react'
import style from './style.module.scss'
import { isNumeric, isNumericAndNot, isText } from '../../../../Utils/uiUtil';
import TextField from '../../../../Generic/Inputs/TextField';
import ComboBox from '../../../../Generic/Inputs/ComboBox';
import Button from '../../../../Generic/Inputs/Button';
import Checkbox from '../../../../Generic/Inputs/Checkbox';

function SubTypeEditor(props) {

  const [id] = useState(props.subType ? props.subType.id : -1);
  const [freeSpinAllowed, setFreeSpinAllowed] = useState(props.subType ? props.subType.freeSpinAllowed : 0)
  const [name, setName] = useState(props.subType ? props.subType.name : '')
  const [code, setCode] = useState(props.subType ? props.subType.code : '')
  const [type, setType] = useState(props.subType ? props.subType.type : (props.currentType ? props.currentType.id : -1))
  const [uiOrder, setUIOrder] = useState(props.subType ? props.subType.uiOrder : '')
  const [error, setError] = useState('');


  function validate(clone) {
    return isText(clone.code) && isText(clone.name) && isNumericAndNot(clone.type, -1) && isNumeric(clone.uiOrder);
  }
  function onSave() {
    var toClone = props.subType ? props.subType : {}
    var clone = JSON.parse(JSON.stringify(toClone))

    clone.id = id;
    clone.code = code;
    clone.freeSpinAllowed = freeSpinAllowed;
    clone.name = name;
    clone.type = type + '';
    clone.uiOrder = uiOrder;

    if (validate(clone)) {
      props.closeModal();

      var action = 'MODIFY';
      if (props.index === -1) {
        action = 'ADD'
      }

      props.callback(clone, props.index, action);
    } else {
      setError('Validation Error');
    }
  }

  function onDelete() {
    props.closeModal();
    props.callback(props.subType, props.index, 'DELETE_SUBTYPE');
  }

  function toggleFreeSpinAllowed(value) {
    if (value === false) {
      setFreeSpinAllowed(0)
    } else {
      setFreeSpinAllowed(1)
    }
  }

  try {

    return (
      <>
        <div className='topModal_pops topModal_title'>
          <h4 className='topModal_rg-heads'>
            Game SubType Editor
          </h4>
        </div>
        <div>
          <div>
            <div className={style.bottomPad}><TextField autofocus='true' values={[name]} label={'Name'} onChange={setName} /></div>
            <div className={style.bottomPad}><TextField values={[code]} label={'Code'} onChange={setCode} /></div>
            <div className={style.bottomPad}><TextField type='digit' values={[uiOrder]} label={'Order'} onChange={setUIOrder} /></div>

            <div className={style.bottomPad}><ComboBox label={'Type'} onChange={setType} values={[type]} valueList={{ list: props.types, key: 'id', value: 'name' }} selectLabel={{ value: '-1', label: 'Select One' }} /></div>
            <div className={style.bottomPad}><Checkbox label={'Allow Free Spin'} checked={(freeSpinAllowed === 1)} onClick={toggleFreeSpinAllowed} /></div>
            <div className={style.twoColGrid50}>
              <div><Button width='100px' colorStyle='dark' value={'Save'} onClick={onSave} /></div>

              {(props.index > -1) &&
                <div><Button width='100px' colorStyle='light' value={'Delete'} onClick={onDelete} /></div>

              }
            </div>
          </div>

          {/*}
            <div>Name:</div>
            <div><input className={style.input} type="text" value={name} onChange={(e) => setName(e.target.value)} /></div>
            <div>Code:</div>
            <div><input className={style.input} type="text" value={code} onChange={(e) => setCode(e.target.value)} /></div>
            <div>Order:</div>
            <div><input className={style.input} type="text" value={uiOrder} onChange={(e) => setUIOrder(e.target.value)} /></div>
            <div>Type:</div>
            <div>
              <select className={style.select} value={type} onChange={(e) => setType(e.target.value)}>
                <option value={-1}>Select one</option>
                {props.types.map((item, index) =>
                  <option key={'subType_option_' + index} value={item.id}>{item.name}</option>
                )}
              </select>
            </div>


            <div><button className={style.button} onClick={onSave}>Save</button></div>
            {(props.index > -1) &&
              <div><button className={style.button} onClick={onDelete}>Delete</button></div>
            }
          </div>
          */}
          {error}
        </div>


      </>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default SubTypeEditor