/* eslint-disable */
import React, { useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from '../../../../../Services/JWTBackend/jwtBackendService';
import Header from '../../../../../Casino/General/Modal/Header';
import Footer from '../../../../../Casino/General/Modal/Footer';
import { displayGenericErrorNotificationIntl, displayInfoNotification, displayInfoNotificationIntl, getAmountTxt, getAmountTxtWithCoin } from '../../../../../Utils/uiUtil';
import ComboBox from '../../../../../Generic/Inputs/ComboBox';


function SwitchUserType(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [userType, setUserType] = useState(props.personalData.typeAccount);

    function switchUserType(newUserType) {
        jwtBackendService.updateUserType(userType, props.personalData.id, successSwitchUserType, failureSwitchUserType, navigate, location.pathname);
    }

    function successSwitchUserType(data) {
        displayInfoNotificationIntl(intl, 'newcasino.admin.user.popup.switchuser.success');
        props.reload();
        props.closeModal();
    }

    function failureSwitchUserType(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function setNewUserType(data, data2) {
        setUserType(data);
    }

    const userTypeList = [
        { key: '1', value: 'Player' },
        { key: '5', value: 'Streamer' },
        { key: '3', value: 'Partner' },
        { key: '4', value: 'Admin' }

    ]

    try {
        var header = null;
        var footer = null;

        header = <Header titleClass={style.headerTitle} title={intl.formatMessage({ id: 'newcasino.admin.user.popup.switchuser.title', defaultMessage: '' })} subTitle={intl.formatMessage({ id: 'newcasino.admin.user.popup.switchuser.subtitle', defaultMessage: '' })} closeModal={props.closeModal} />
        footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.admin.user.popup.switchuser.button.cancel', defaultMessage: 'Cancel' }), action: props.closeModal, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.admin.user.popup.switchuser.button.add', defaultMessage: 'Update' }), action: switchUserType, canProcess: true }} />

        return (
            <>
                <div>
                    <div>
                        {header}
                    </div>
                    <div className={style.grid50}>
                        <div className={style.theCombo}>
                            <ComboBox onChange={setNewUserType} label={intl.formatMessage({ id: 'newcasino.admin.user.popup.switchuser.combo.label', defaultMessage: '' })} values={[userType]} valueList={{ list: userTypeList, key: 'key', value: 'value' }} />
                        </div>
                    </div>
                    <div>
                        {footer}
                    </div>
                </div>
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default SwitchUserType;
