import React from 'react'
import style from './style.module.scss'
import Button from '../../../../Generic/Inputs/Button';
import DivClick from '../../../../Generic/Inputs/DivClick';
import { useIntl } from 'react-intl';
import { getImageUrl } from '../../../../Utils/uiUtil';

function SuccessPopup(props) {
  const intl = useIntl();

  try {
    return (
      <div className={style.successPop_mainGrid}>
        <div className={style.successPop_welcomeGrid}>
          <div className={style.successPop_welcomeTxt}>
            {intl.formatMessage({ id: 'newcasino.aff.regsuccess.title', defaultMessage: 'Welcome!' })}
          </div>
          <DivClick className={style.successPop_xClose} onClick={() => props.closeModal()}>
            X
          </DivClick>
        </div>
        <div className={style.successPop_logo}>
          <img className={style.successPop_logoImg} src={getImageUrl('newui/affiliates/Logo-Header_pur.png')} alt='' />
        </div>
        <div className={style.successPop_txt}>
          {intl.formatMessage({ id: 'newcasino.aff.regsuccess.msg', defaultMessage: 'Thank you for registering with us! One of your affiliate managers will get in touch with you within 48 hours.' })}
        </div>
        <div className={style.successPop_button}>
          <Button colorStyle='light' value={intl.formatMessage({ id: 'newcasino.aff.regsuccess.button', defaultMessage: 'Continue' })} onClick={() => props.closeModal()} />
        </div>
      </div>

    );

  } catch (error) {
    console.log('ERROR: ' + error.message);

  }
}

export default SuccessPopup
