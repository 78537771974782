import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import * as jwtBackendService from '../../../Services/JWTBackend/jwtBackendService';
import { useIntl } from 'react-intl';
import DivClick from '../../../Generic/Inputs/DivClick';
import ApiPersistence from '../../../Utils/ApiPersistence';
import { displayGenericErrorNotificationIntl, displayInfoNotificationIntl, getAmountTxtWithCoin } from '../../../Utils/uiUtil';
import Button from '../../../Generic/Inputs/Button';
import TextField from '../../../Generic/Inputs/TextField';
import moment from 'moment/moment';
import { getImageUrl } from '../../../Utils/uiUtil';

function StreamerDetailLine(props) {

  const COMM_STATUS_NEW = 0;
  const COMM_STATUS_PAID = 1;
  const COMM_STATUS_REFUSED = 2;

  const data = props.data;

  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  const [paymentDate, setPaymentDate] = useState('');
  const [exchangeRate, setExchangeRate] = useState(1);
  const [streamerCurrency] = useState(data.coin);
  const [displayedDataCurrency, setDisplayedDataCurrency] = useState(ApiPersistence.getInstance().getUser().coin);

  const [showDetail, setShowDetail] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [commStatus, setCommStatus] = useState(COMM_STATUS_NEW);

  const [useFixedAmount, setUseFixedAmount] = useState(false);
  const [usePercDeposit, setUsePercDeposit] = useState(false);
  const [useFdt, setUseFdt] = useState(false);
  const [usePercVault, setUsePercVault] = useState(false);
  const [usePercVaultGrid, setUsePercVaultGrid] = useState(false);
  const [useFtdRestriction, setUseFtdRestriction] = useState(false);

  const [commMonthlyFixedAmount, setCommMonthlyFixedAmount] = useState(0);
  const [commMonthlyFixedAmountConverted, setCommMonthlyFixedAmountConverted] = useState(0);
  const [commPercDeposit, setCommPercDeposit] = useState(0);
  const [commissionMinFTD, setCommissionMinFTD] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalDepositComm, setTotalDepositComm] = useState(0);
  const [vaultBalanceConverted, setVaultBalanceConverted] = useState(0);
  const [commissionPercVault, setCommissionPercVault] = useState(0);
  const [vaultBalanceComm, setVaultBalanceComm] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [ftd, setFtd] = useState(0);
  const [total, setTotal] = useState(0);
  const [commissionPaidInStreamerCurrency, setCommissionPaidInStreamerCurrency] = useState(0);
  const [commissionPaidCurrency, setCommissionPaidCurrency] = useState(0);

  const isAdmin = (ApiPersistence.getInstance().getUser().typeAccount === 4)

  useEffect(() => {
    doMaths()
  },// eslint-disable-next-line
    [])

  function doMaths() {
    if (data.commissionsInfo.results[0][0].status === 'new') {

      setCommStatus(COMM_STATUS_NEW);
      doMathsNewCommission()
      setLoaded(true);
    }
    else if (data.commissionsInfo.results[0][0].status === 'paid' || data.commissionsInfo.results[0][0].status === 'refused') {
      if (data.commissionsInfo.results[0][0].status === 'paid') {
        setCommStatus(COMM_STATUS_PAID);
      } else {
        setCommStatus(COMM_STATUS_REFUSED);
      }

      doMathsHistoricCommission()
      setLoaded(true);
    } else {
      displayGenericErrorNotificationIntl(intl)
    }
  }

  function doMathsHistoricCommission() {
    let historicalData = JSON.parse(data.commissionsInfo.results[0][0].history);

    setDisplayedDataCurrency(historicalData.currency);
    setPaymentDate(historicalData.paymentDate ? moment(historicalData.paymentDate).format('YYYY-MM-DD HH:mm:ss') : 'N/A');
    setExchangeRate(historicalData.exchangeRate);
    setUseFixedAmount(historicalData.fixedAmount)
    setUsePercDeposit(historicalData.percDeposit)
    setUsePercVault(historicalData.percVault)
    setUsePercVaultGrid(false)
    setUseFtdRestriction(historicalData.useFtdRestriction)
    setUseFdt(historicalData.useFtdRestriction)

    if (historicalData.fixedAmount) {
      setCommMonthlyFixedAmount(historicalData.fixedAmount.commission)
      setCommMonthlyFixedAmountConverted(historicalData.fixedAmount.commission)
    }

    if (historicalData.percDeposit) {
      setCommPercDeposit(historicalData.percDeposit.percentage)
      setTotalDeposit(historicalData.percDeposit.deposit)
      setTotalDepositComm(historicalData.percDeposit.commission)
    }

    if (historicalData.percVault) {
      setVaultBalanceConverted(historicalData.percVault.balance)
      setVaultBalanceComm(historicalData.percVault.commission)
      setCommissionPercVault(historicalData.percVault.percentage)
    }

    if (historicalData.useFtdRestriction) {
      setCommissionMinFTD(historicalData.ftdMin)
      setFtd(historicalData.ftdReal)
    }

    setSubTotal(historicalData.subTotal)
    setTotal(historicalData.total)
    setCommissionPaidInStreamerCurrency(historicalData.finalAmountPaid)
    setCommissionPaidCurrency(historicalData.finalAmountPaidCurrency)

  }

  function doMathsNewCommission() {
    let useFixedAmountWork = (data.commissionsInfo.results[3][0].commMonthlyFixedAmount > -1);
    setUseFixedAmount(useFixedAmountWork);
    let monthlyFixedAmountWork = useFixedAmountWork ? data.commissionsInfo.results[3][0].commMonthlyFixedAmount : 0;
    let monthlyFixedAmountConvertedWork = useFixedAmountWork ? data.commissionsInfo.results[3][0].commMonthlyFixedAmountConverted : 0;

    setCommMonthlyFixedAmount(monthlyFixedAmountWork);
    setCommMonthlyFixedAmountConverted(monthlyFixedAmountConvertedWork);

    let usePercDepositWork = (data.commissionsInfo.results[3][0].commPercDeposit > -1);
    setUsePercDeposit(usePercDepositWork);

    let commPercDepositWork = usePercDepositWork ? data.commissionsInfo.results[3][0].commPercDeposit : 0;
    setCommPercDeposit(commPercDepositWork);

    let useFdtWork = data.commissionsInfo.results[3][0].commissionMinFTD > -1;
    setUseFdt(useFdtWork);

    let commissionMinFTDWork = useFdtWork ? data.commissionsInfo.results[3][0].commissionMinFTD : 0;
    setCommissionMinFTD(commissionMinFTDWork);

    setTotalDeposit(data.commissionsInfo.results[2][0].totalDeposit);
    let totalDepositCommWork = data.commissionsInfo.results[2][0].totalDeposit * (commPercDepositWork / 100.0);
    setTotalDepositComm(totalDepositCommWork);

    let usePercVaultWork = (data.commissionsInfo.results[3][0].commissionPercVault > -1);
    setUsePercVault(usePercVaultWork)
    let usePercVaultGridWork = (data.commissionsInfo.results[3][0].commissionPercVault === -2);
    setUsePercVaultGrid(usePercVaultGridWork)

    setVaultBalanceConverted(data.commissionsInfo.results[2][0].vaultBalanceConverted);

    let percentage = 0;
    if (usePercVaultGridWork) {
      percentage = data.commissionsInfo.results[2][0].vaultBalanceGridPercentage ? data.commissionsInfo.results[2][0].vaultBalanceGridPercentage : 0
    }
    else if (usePercVaultWork) {
      percentage = data.commissionsInfo.results[3][0].commissionPercVault
    }

    setCommissionPercVault(percentage);

    let vaultBalanceCommWork = (data.commissionsInfo.results[2][0].vaultBalanceConverted * (percentage / 100.0));

    if (usePercVaultGridWork && vaultBalanceCommWork > data.commissionsInfo.results[2][0].vaultBalanceGridMaxWin) {
      vaultBalanceCommWork = data.commissionsInfo.results[2][0].vaultBalanceGridMaxWin;
    }

    setVaultBalanceComm(vaultBalanceCommWork);

    let subTotalWork = monthlyFixedAmountConvertedWork + vaultBalanceCommWork + totalDepositCommWork;
    setSubTotal(subTotalWork);

    let ftdWork = data.commissionsInfo.results[2][0].nbrNewFTDPlayer;
    setFtd(ftdWork);

    const useFtdRestrictionWork = ftdWork > -1;
    setUseFtdRestriction(useFtdRestrictionWork)
    setTotal((useFtdRestrictionWork) ? ((ftdWork >= commissionMinFTDWork) ? subTotalWork : 0) : subTotalWork);
  }

  function decision(trash) {
    if (isAdmin) {
      try {
        new Audio(getImageUrl("newui/sound/stamp.mp3")).play();
      } catch (err) { }
      let fixedAmount = {}
      let percDeposit = {}
      let percVault = {}

      if (useFixedAmount) {
        fixedAmount = {
          commission: commMonthlyFixedAmountConverted
        }
      }

      if (usePercDeposit) {
        percDeposit = {
          percentage: commPercDeposit,
          deposit: totalDeposit,
          commission: totalDepositComm,
        }
      }

      if (usePercVault || usePercVaultGrid) {
        percVault = {
          percentage: commissionPercVault,
          balance: vaultBalanceConverted,
          commission: vaultBalanceComm,
        }
      }



      let history = {
        fixedAmount: fixedAmount,
        currency: displayedDataCurrency,
        percDeposit: percDeposit,
        percVault: percVault,
        useFtdRestriction: useFtdRestriction,
        ftdMin: commissionMinFTD,
        ftdReal: ftd,
        subTotal: subTotal,
        total: total
      }
      setLoaded(false);
      props.spinner(true);
      jwtBackendService.payStreamer(trash, data.userId, total, displayedDataCurrency, props.month, props.year, history, trash ? trashSuccess : paySuccess, payFailure, navigate, location.pathname);
    }
  }
  function trashSuccess(data) {
    props.spinner(false);
    setLoaded(true);
    displayInfoNotificationIntl(intl, 'newcasino.aff.commissions.trash.success')
    props.reload();
  }

  function paySuccess(data) {
    props.spinner(false);
    setLoaded(true);
    displayInfoNotificationIntl(intl, 'newcasino.aff.commissions.pay.success')
    props.reload();
  }

  function payFailure(data) {
    props.spinner(false);
    setLoaded(true);
    displayGenericErrorNotificationIntl(intl);
  }

  function expend() {
    setShowDetail(!showDetail);
  }

  function showGrid() {
    props.showGrid();
  }

  try {
    if (loaded) {
      return (
        <div>
          {(!useFixedAmount && !usePercDeposit && !usePercVault && !usePercVaultGrid) &&
            <div className={style.theLine}>
              <DivClick className={style.thePointer} onClick={expend}><i className={showDetail ? "fa fa-arrow-up fa-2x" : "fa fa-arrow-down fa-2x"} aria-hidden="true"></i></DivClick>
              <div className={style.firstLineName} title={data.userId + ' | ' + data.email}>{data.fullName}</div>
              <div className={style.firstLineError}>{intl.formatMessage({ id: 'newcasino.aff.commissions.detail.no', defaultMessage: 'No Commission rule seems to be set for this user' })}</div>
            </div>
          }
          {!(!useFixedAmount && !usePercDeposit && !usePercVault && !usePercVaultGrid) &&
            <div className={style.theLine}>
              <DivClick className={style.thePointer} onClick={expend}><i className={showDetail ? "fa fa-arrow-up fa-2x" : "fa fa-arrow-down fa-2x"} aria-hidden="true"></i></DivClick>
              <div className={style.firstLineName} title={data.userId + ' | ' + data.email}>{data.fullName}</div>

              {(commStatus === COMM_STATUS_PAID || commStatus === COMM_STATUS_REFUSED) &&
                <div className={style.firstLine}><p>{intl.formatMessage({ id: 'newcasino.aff.commissions.paid.total', defaultMessage: 'Paid Commission:' })} <b>{getAmountTxtWithCoin(commissionPaidInStreamerCurrency, commissionPaidCurrency)} {commissionPaidCurrency}</b></p></div>
              }
              {(commStatus === COMM_STATUS_NEW) &&
                <div className={style.firstLine}><p>{intl.formatMessage({ id: 'newcasino.aff.commissions.pay.total', defaultMessage: 'Total Commission:' })} <b>{getAmountTxtWithCoin(total, displayedDataCurrency)}</b></p></div>
              }

              {(commStatus === COMM_STATUS_PAID) &&
                <div className={style.firstLineButton}><Button colorStyle='dark' value={intl.formatMessage({ id: 'newcasino.aff.commissions.paid.button', defaultMessage: 'Paid' })} /></div>
              }

              {(commStatus === COMM_STATUS_REFUSED) &&
                <div className={style.firstLineButton}><Button colorStyle='dark' value={intl.formatMessage({ id: 'newcasino.aff.commissions.refused.button', defaultMessage: 'Refused' })} /></div>
              }

              {(commStatus === COMM_STATUS_NEW && isAdmin) &&
                <div className={style.twoButtons}>
                  <div className={style.firstLineButtonAdmin}><Button width='50px' colorStyle='light' value={intl.formatMessage({ id: 'newcasino.aff.commissions.pay.button', defaultMessage: 'Pay' })} onClick={() => decision(false)} /></div>
                  <div className={style.firstLineButtonAdmin2}><Button width='95px' colorStyle='dark' value={intl.formatMessage({ id: 'newcasino.aff.commissions.nopay.button', defaultMessage: 'Trash' })} onClick={() => decision(true)} /></div>
                </div>
              }
              {(commStatus === COMM_STATUS_NEW && !isAdmin) &&
                <div className={style.firstLineButton}><Button colorStyle='light' value={intl.formatMessage({ id: 'newcasino.aff.commissions.notpaid.button', defaultMessage: 'Not Paid Yet' })} /></div>
              }
            </div>
          }
          {showDetail &&
            <>
              {(commStatus === COMM_STATUS_NEW) &&
                <div className={style.theDetail}>
                  {useFixedAmount &&
                    <div title={streamerCurrency}><TextField topTitleStyle={style.textField} label={intl.formatMessage({ id: 'newcasino.aff.commissions.pay.fixed', defaultMessage: 'Monthly Fixed Amount' })} readOnly={true} values={[getAmountTxtWithCoin(commMonthlyFixedAmount, streamerCurrency)]} /></div>
                  }
                  {usePercDeposit &&
                    <div><TextField topTitleStyle={style.textField} label={intl.formatMessage({ id: 'newcasino.aff.commissions.pay.percDeposit', defaultMessage: '% On Deposit' })} readOnly={true} values={[(commPercDeposit + ' %')]} /></div>
                  }
                  {(usePercVault || usePercVaultGrid) &&
                    <DivClick onClick={showGrid}><TextField topTitleStyle={style.textField} label={intl.formatMessage({ id: 'newcasino.aff.commissions.pay.percVault', defaultMessage: '% On Vault' })} readOnly={true} values={[usePercVaultGrid ? intl.formatMessage({ id: 'newcasino.aff.commissions.pay.usegrid', defaultMessage: 'Use Grid' }) : (commissionPercVault + ' %')]} /></DivClick>
                  }
                  {useFdt &&
                    <div><TextField topTitleStyle={style.textField} label={intl.formatMessage({ id: 'newcasino.aff.commissions.pay.minftd', defaultMessage: 'Min. FTD' })} readOnly={true} values={[commissionMinFTD]} /></div>
                  }
                </div>
              }
              <div className={(commStatus === COMM_STATUS_PAID) ? style.calculusbgpaid : (commStatus === COMM_STATUS_REFUSED) ? style.calculusbgrefused : style.calculusbg}>
                {(!useFixedAmount && !usePercDeposit && !usePercVault && !usePercVaultGrid) &&
                  <div className={style.calculusNo}>{intl.formatMessage({ id: 'newcasino.aff.commissions.detail.no', defaultMessage: 'No Commission rule seems to be set for this user' })}</div>
                }
                {!(commStatus === COMM_STATUS_NEW) &&
                  <div className={style.paidInformation}>
                    <div>{intl.formatMessage({ id: 'newcasino.aff.commissions.pay.paymDate', defaultMessage: 'Payment date:' })}</div><div className={style.paidInformationBold}>{paymentDate}</div>
                    <div>{intl.formatMessage({ id: 'newcasino.aff.commissions.pay.paymCurrency', defaultMessage: 'Calculation made in currency:' })}</div><div className={style.paidInformationBold}>{displayedDataCurrency} - {intl.formatMessage({ id: 'newcasino.aff.commissions.pay.convRate', defaultMessage: 'Conv. Rate' })} ({exchangeRate.toFixed(5)})</div>
                  </div>
                }

                {!(!useFixedAmount && !usePercDeposit && !usePercVault && !usePercVaultGrid) &&
                  <div className={!(commStatus === COMM_STATUS_NEW) ? style.calculusPaid : style.calculus}>
                    <div className={style.calculusBold}>{intl.formatMessage({ id: 'newcasino.aff.commissions.detail.type', defaultMessage: 'Commission Type' })}</div>
                    <div className={style.calculusBold}>{intl.formatMessage({ id: 'newcasino.aff.commissions.detail.perc', defaultMessage: 'Percentage' })}</div>
                    <div className={style.calculusBold}>{intl.formatMessage({ id: 'newcasino.aff.commissions.detail.amount', defaultMessage: 'Amount' })}</div>
                    <div className={style.calculusBold}>{intl.formatMessage({ id: 'newcasino.aff.commissions.detail.comm', defaultMessage: 'Commission' })}</div>

                    {useFixedAmount &&
                      <div className={style.calculusSpacer}>{intl.formatMessage({ id: 'newcasino.aff.commissions.detail.fixed', defaultMessage: 'Fixed Amount' })}</div>
                    }
                    {useFixedAmount &&
                      <div className={style.calculusSpacer}>-</div>
                    }
                    {useFixedAmount &&
                      <div className={style.calculusSpacer}>{getAmountTxtWithCoin(commMonthlyFixedAmountConverted, displayedDataCurrency)}</div>
                    }
                    {useFixedAmount &&
                      <div className={style.calculusSpacer}>{getAmountTxtWithCoin(commMonthlyFixedAmountConverted, displayedDataCurrency)}</div>
                    }

                    {usePercDeposit &&
                      <div className={style.calculusSpacer}>{intl.formatMessage({ id: 'newcasino.aff.commissions.detail.percdeposit', defaultMessage: '% Deposit' })}</div>
                    }
                    {usePercDeposit &&
                      <div className={style.calculusSpacer}>{commPercDeposit} %</div>
                    }
                    {usePercDeposit &&
                      <div className={style.calculusSpacer}>{getAmountTxtWithCoin(totalDeposit, displayedDataCurrency)}</div>
                    }
                    {usePercDeposit &&
                      <div className={style.calculusSpacer}>{getAmountTxtWithCoin(totalDepositComm, displayedDataCurrency)}</div>
                    }

                    {(usePercVault || usePercVaultGrid) &&
                      <div className={style.calculusSpacer}>{intl.formatMessage({ id: 'newcasino.aff.commissions.detail.percvault', defaultMessage: '% Vault' })}</div>
                    }
                    {(usePercVault || usePercVaultGrid) &&
                      <div className={style.calculusSpacer}>{commissionPercVault} %</div>
                    }
                    {(usePercVault || usePercVaultGrid) &&
                      <div className={style.calculusSpacer}>{getAmountTxtWithCoin(vaultBalanceConverted, displayedDataCurrency)}</div>
                    }
                    {(usePercVault || usePercVaultGrid) &&
                      <div className={style.calculusSpacer}>{getAmountTxtWithCoin(vaultBalanceComm, displayedDataCurrency)}</div>
                    }

                    <div className={style.calculusSpacer2}></div>
                    <div className={style.calculusSpacer2}></div>
                    <div className={style.calculusSpacer2}></div>
                    <div className={style.calculusSpacer2}>___________</div>

                    <div className={style.calculusSpacer}></div>
                    <div className={style.calculusSpacer}></div>
                    <div className={style.calculusSpacer}>{intl.formatMessage({ id: 'newcasino.aff.commissions.detail.subtotal', defaultMessage: 'Sub Total' })}</div>
                    <div className={style.calculusSpacer}>{getAmountTxtWithCoin(subTotal, displayedDataCurrency)}</div>

                    {useFdt &&
                      <div className={style.calculusSpacer}></div>
                    }
                    {useFdt &&
                      <div className={style.calculusSpacer}></div>
                    }
                    {useFdt &&
                      <div className={style.calculusSpacer}>{intl.formatMessage({ id: 'newcasino.aff.commissions.detail.ftd', defaultMessage: 'FTD' })} ({ftd} &gt;= {commissionMinFTD})</div>
                    }
                    {useFdt &&
                      <div className={(ftd >= commissionMinFTD) ? style.ftdGreen : style.ftdRed}>{(ftd >= commissionMinFTD) ? intl.formatMessage({ id: 'newcasino.aff.commissions.detail.ftd.yes', defaultMessage: 'YES' }) : intl.formatMessage({ id: 'newcasino.aff.commissions.detail.ftd.no', defaultMessage: 'NO' })}</div>
                    }

                    <div className={style.calculusSpacer3}></div>
                    <div className={style.calculusSpacer3}></div>
                    <div className={style.calculusSpacer3}></div>
                    <div className={style.calculusSpacer3}>___________</div>

                    <div className={style.calculusBold2}></div>
                    <div className={style.calculusBold2}></div>
                    <div className={style.calculusBold2}>{intl.formatMessage({ id: 'newcasino.aff.commissions.detail.total', defaultMessage: 'Total' })} </div>
                    <div className={style.calculusBold2}>{getAmountTxtWithCoin(total, displayedDataCurrency)}</div>
                  </div>
                }
              </div>
            </>
          }
        </div >
      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}

export default StreamerDetailLine