import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import TextField from '../../../../../../Generic/Inputs/TextField';
import { isEmail, isTextMin2Char } from '../../../../../../Utils/uiUtil';
import Checkbox from '../../../../../../Generic/Inputs/Checkbox';
import { FormattedMessage, useIntl } from 'react-intl';
import DivClick from '../../../../../../Generic/Inputs/DivClick';
import SpanClick from '../../../../../../Generic/Inputs/SpanClick';

export const LOGIN_STEP_EMAIL = 'email';
export const LOGIN_STEP_PASSWORD = 'password';
export const LOGIN_STEP_REMEMBERME = 'rememberme';

function ScreenLogin(props) {
  const intl = useIntl()
  const sourceData = props.value;
  const sourceEmail = sourceData.fields.get(LOGIN_STEP_EMAIL) ? sourceData.fields.get(LOGIN_STEP_EMAIL) : {};
  //const sourcePassword = sourceData.fields.get(LOGIN_STEP_PASSWORD) ? sourceData.fields.get(LOGIN_STEP_PASSWORD) : {};
  const sourceRememberMe = sourceData.fields.get(LOGIN_STEP_REMEMBERME) ? sourceData.fields.get(LOGIN_STEP_REMEMBERME) : {};

  const [rememberMe, setRememberMe] = useState(sourceRememberMe.value ? sourceRememberMe.value : false);
  const [email, setEmail] = useState(sourceEmail.value ? sourceEmail.value : '');
  const [password, setPassword] = useState('');
  const [emailValid, setEmailValid] = useState((sourceEmail.valid !== undefined) ? sourceEmail.valid : null);
  const [passwordValid, setPasswordValid] = useState(null);

  const message = props.message;

  useEffect(() => {
    passwordValidCallBack(isTextMin2Char(password), password)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function emailValidCallBack(isValid, value) {
    var data = new Map();
    data.set(LOGIN_STEP_EMAIL, { value: value, valid: isValid });
    data.set(LOGIN_STEP_PASSWORD, { value: password, valid: passwordValid });
    data.set(LOGIN_STEP_REMEMBERME, { value: rememberMe, valid: true });
    setEmailValid(isValid);
    setEmail(value)
    props.validatorInformer((passwordValid && isValid), data);
  }

  function passwordValidCallBack(isValid, value) {
    var data = new Map();
    data.set(LOGIN_STEP_EMAIL, { value: email, valid: emailValid });
    data.set(LOGIN_STEP_PASSWORD, { value: value, valid: isValid });
    data.set(LOGIN_STEP_REMEMBERME, { value: rememberMe, valid: true });
    setPasswordValid(isValid);
    setPassword(value);
    props.validatorInformer((emailValid && isValid), data);
  }

  function rememberMeCallBack(value) {
    var data = new Map();
    data.set(LOGIN_STEP_EMAIL, { value: email, valid: emailValid });
    data.set(LOGIN_STEP_PASSWORD, { value: password, valid: passwordValid });
    data.set(LOGIN_STEP_REMEMBERME, { value: value, valid: true });
    setRememberMe(value);
    props.validatorInformer((emailValid && passwordValid), data);
  }

  function forgotPassword() {
    props.onForgotPassword();
  }

  try {
    return (
      <div className={style.mainDiv}>
        <div className={style.content}>
          <div className={style.test}>
            {(message.text && message.action) &&
              <DivClick className={style.errorLink} onClick={() => props.msgCallback(message.action)}>
                {message.text}
              </DivClick>
            }
            {(message.text && !message.action) &&
              <div className={style.error}>
                {message.text}
              </div>
            }
            <div className={style.test2}>
              <TextField maxLength='100' autofocus='true' values={[email, emailValid]} hint={intl.formatMessage({ id: 'newcasino.auth.login.type.email', defaultMessage: 'Type your email here' })} label={intl.formatMessage({ id: 'newcasino.auth.login.email', defaultMessage: 'Email' })} validator={isEmail} validatorInformer={emailValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.auth.login.email.err', defaultMessage: 'Invalid Email' })} />
            </div>
            <div className={style.test2}>
              <TextField maxLength='100' onEnterPress={props.onEnterPress} values={[password, passwordValid]} hint={intl.formatMessage({ id: 'newcasino.auth.login.type.password', defaultMessage: 'Type your new password here' })} type='password' canSee='false' mandatory='true' label={intl.formatMessage({ id: 'newcasino.auth.login.password', defaultMessage: 'Password' })} validator={isTextMin2Char} showTitleError='false' showCheck='false' validatorInformer={passwordValidCallBack} validationMsg='' />
            </div>
            <div className={style.test2}>
              <div className={style.mainDivButton}>
                <div className={style.alignLeft}>
                  <Checkbox label={intl.formatMessage({ id: 'newcasino.auth.login.stayconnected', defaultMessage: 'Stay Connected' })} checked={rememberMe} onClick={rememberMeCallBack} />
                </div>
                <div className={style.alignRight}>
                  <div className={style.master_centerizeVertically}>
                    <SpanClick onClick={forgotPassword} className={style.forget}><FormattedMessage id="newcasino.auth.login.forgot_password" defaultMessage="Forgot Password?" /></SpanClick>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default ScreenLogin