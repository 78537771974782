import React from 'react'
import style from './style.module.scss'
import DivClick from '../../../Generic/Inputs/DivClick';
import ApiPersistence from '../../../Utils/ApiPersistence';
import Terms from '../../Public/Terms';
import Policies from '../../Public/Policies';
import ContactUs from '../../Public/ContactUs';
import { useIntl } from 'react-intl';


function Footer(props) {

  const intl = useIntl();
  const curLang = ApiPersistence.getInstance().getLanguage();

  var nextLang = 'Français';
  var nextLangCode = 'fr-FR';

  if (curLang === 'fr-FR') {
    nextLang = 'English';
    nextLangCode = 'en-US';
  }

  function displayTerms() {
    props.displayModalComponent(<Terms closeModal={props.closeModal} />, 'center', '#F4F3F6', '700px');
  }

  function displayPolicies() {
    props.displayModalComponent(<Policies closeModal={props.closeModal} />, 'center', '#F4F3F6', '700px');
  }

  function displayContactUs() {
    props.displayModalComponent(<ContactUs closeModal={props.closeModal} />, 'center', '#F4F3F6', '700px');
  }

  function changeLanguage(code) {
    props.setLanguage(-1, code);
  }

  try {
    return (
      <div className={style.footer_aff_mainGrid}>
        <div className={style.footer_aff_termLineGrid}>
          <DivClick className={style.cursorPointer} onClick={displayTerms}>{intl.formatMessage({ id: 'newcasino.aff.auth.footer.terms', defaultMessage: 'Terms & conditions' })}</DivClick>
          <div className={style.bar}>|</div>
          <DivClick className={style.cursorPointer} onClick={displayPolicies}>{intl.formatMessage({ id: 'newcasino.aff.auth.footer.policy', defaultMessage: 'Privacy policy' })}</DivClick>
          <div className={style.bar}>|</div>
          <DivClick className={style.cursorPointer} onClick={displayContactUs}>{intl.formatMessage({ id: 'newcasino.aff.auth.footer.contact', defaultMessage: 'Contact us' })}</DivClick>
          <div className={style.bar}>|</div>
          <DivClick className={style.cursorPointer} onClick={() => changeLanguage(nextLangCode)}>{nextLang}</DivClick>
        </div>
        <div className={style.footer_aff_copy}>{intl.formatMessage({ id: 'newcasino.aff.auth.footer.copy', defaultMessage: '© Copyright 2024 Numbers Game. All rights reserved.' })}</div>

      </div>

    );

  } catch (error) {
    console.log('ERROR: ' + error.message);

  }
}

export default Footer
