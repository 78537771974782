import React from 'react'
import style from './style.module.scss'

import { useLocation, useNavigate } from 'react-router-dom'
import DivClick from '../../../../Generic/Inputs/DivClick'
import * as jwtBackendService from '../../../../Services/JWTBackend/jwtBackendService'
import { FormattedMessage, useIntl } from 'react-intl'
import { displayErrorNotificationIntl, displayInfoNotificationIntl, getAmountTxt } from '../../../../Utils/uiUtil'
import { displayPage } from '../../../../Utils/routerUtil'
import * as constants from '../../../../constants.js'

function WalletsMenu(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();

    function toggleWalletsMenu(force) {
        props.toggleWalletsMenu(force);
    }

    function getIconClass(item) {
        var toReturn = "fa fa-square-o";

        if (item.walletState === 2) {
            toReturn = "";
        } else {
            if (item.currentWallet === 1) {
                toReturn = "fa fa-check-square";
            }
        }

        return toReturn;
    }

    function getWalletText(item) {
        let toReturn = '';
        if (item.walletType === 0) {
            toReturn = intl.formatMessage({
                id: 'newcasino.menu.wallets.mywallet',
                defaultMessage: 'My Money',
            }) + ': ' + getAmountTxt(item.balance);
        } else if (item.walletType === 1) {
            toReturn = intl.formatMessage({
                id: 'newcasino.menu.wallets.bonus',
                defaultMessage: 'Wager',
            }) + ': ' + getAmountTxt(item.balance) + ' / ' + getAmountTxt(item.wagerTarget);
        } else if (item.walletType === 2) {
            toReturn = toReturn = intl.formatMessage({
                id: 'newcasino.menu.wallets.streamer',
                defaultMessage: 'Streamer',
            }) + ': ' + getAmountTxt(item.balance) + ' / ' + getAmountTxt(item.wagerTarget);
        }

        return toReturn;
    }

    function isGamePlaying() {
        if (document.getElementById('game') !== null) {
            return true;
        }

        return false;
    }

    function walletMenuClicked(item) {

        if (item.currentWallet !== 1 && item.walletState === 1) {
            jwtBackendService.switchWallet(item.id, switchWalletSuccess, switchWalletFailure, navigate, location.pathname)

            if (isGamePlaying) {
                displayPage(constants.ROUTE_HOME, navigate, location.pathname);
            }
        }
    }

    function switchWalletSuccess(data) {
        try {
            displayInfoNotificationIntl(intl, 'newcasino.menu.wallets.switch.ok');
            props.forceReloadTopMenu();
        } catch (err) {
            console.log(err.message)
        }
    }

    function switchWalletFailure(data) {
        displayErrorNotificationIntl(intl, 'newcasino.menu.wallets.switch.ko')
    }


    if (props.wallets) {
        return (
            <div className={props.className ? props.className : style.bottomSpacer} onMouseLeave={() => toggleWalletsMenu(false)}>
                {(props.showTitle === undefined || props.showTitle === true) &&
                    <div className={style.menuTitle}><FormattedMessage id={'newcasino.menu.wallets.title'} defaultMessage={'Select the Wallet to use'} /></div>
                }
                {props.wallets.map((item, index) => (
                    <React.Fragment key={'wallet' + index}>
                        <DivClick className={`${style.walletLine}`} onClick={() => walletMenuClicked(item)}>
                            <div className={`${style.theGrid}`}>
                                <div><i className={getIconClass(item)} aria-hidden="true"></i></div>
                                <div>{getWalletText(item)}</div>
                            </div>
                        </DivClick>
                    </React.Fragment>

                ))}
            </div>
        );
    }
}

export default WalletsMenu