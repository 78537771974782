/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from '../../../../../Services/JWTBackend/jwtBackendService';
import Header from '../../../../../Casino/General/Modal/Header';
import Footer from '../../../../../Casino/General/Modal/Footer';
import { displayGenericErrorNotificationIntl, displayInfoNotificationIntl } from '../../../../../Utils/uiUtil';
import ComboBox from '../../../../../Generic/Inputs/ComboBox';


function SwitchStatus(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [status, setStatus] = useState(props.personalData.status);

    function switchUserStatus(data) {
        jwtBackendService.updateUserStatus(status, props.personalData.id, successSwitchUserStatus, failureSwitchUserStatus, navigate, location.pathname);
    }

    function successSwitchUserStatus(data) {
        displayInfoNotificationIntl(intl, 'newcasino.admin.user.popup.switchstatus.success');
        props.reload();
        props.closeModal();
    }

    function failureSwitchUserStatus(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function setNewStatus(newStatus) {
        setStatus(newStatus);
    }

    const userTypeList = [
        { key: '1', value: 'Active' },
        { key: '0', value: 'Inactive' }
    ]

    try {
        var header = null;
        var footer = null;

        header = <Header titleClass={style.headerTitle} title={intl.formatMessage({ id: 'newcasino.admin.user.popup.switchstatus.title', defaultMessage: '' })} subTitle={intl.formatMessage({ id: 'newcasino.admin.user.popup.switchstatus.subtitle', defaultMessage: '' })} closeModal={props.closeModal} />
        footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.admin.user.popup.switchstatus.button.cancel', defaultMessage: 'Cancel' }), action: props.closeModal, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.admin.user.popup.switchstatus.button.add', defaultMessage: 'Update' }), action: switchUserStatus, canProcess: true }} />

        return (
            <>
                <div>
                    <div>
                        {header}
                    </div>
                    <div className={style.grid50}>
                        <div className={style.theCombo}>
                            <ComboBox onChange={setNewStatus} label={intl.formatMessage({ id: 'newcasino.admin.user.popup.switchstatus.combo.label', defaultMessage: '' })} values={[status]} valueList={{ list: userTypeList, key: 'key', value: 'value' }} />
                        </div>
                    </div>
                    <div>
                        {footer}
                    </div>
                </div>
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default SwitchStatus;
