import React, { useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work

import DatePicker from '../../../../../Generic/Inputs/DatePicker';
import ComboBox from '../../../../../Generic/Inputs/ComboBox';
import UserListUpload from '../Generic/UserListUpload';
import PromoCash from '../Generic/PromoCash';
import PromoFreeSpin from '../Generic/PromoFreeSpin';
import PromoPngPoint from '../Generic/PromoPngPoint';
import PromoWager from '../Generic/PromoWager';
import TextField from '../../../../../Generic/Inputs/TextField';
import { isNumeric, isTextMin5Char } from '../../../../../Utils/uiUtil';
import Button from '../../../../../Generic/Inputs/Button';
import PromoDepositBonus from '../Generic/PromoDepositBonus';

function NewPromoCode(props) {

    const notifEnable = false;
    const WHO_TYPE_PLAYERS = 0;
    const WHO_TYPE_PLAYERS_VERIFIED = 1;
    const WHO_TYPE_PLAYERS_ALREADY_DEPOSIT = 2;
    const WHO_TYPE_SPECIFIC_LIST = 3;

    const WHAT_TYPE_CASH = 1;
    const WHAT_TYPE_PNG = 2;
    const WHAT_TYPE_FREESPIN = 3;
    const WHAT_TYPE_WAGER = 4;
    const WHAT_TYPE_DEPOSIT_UPGRADE = 5;

    const WHEN_TYPE_ON_USE = 1;
    const WHEN_TYPE_ON_DEPOSIT = 2;

    const intl = useIntl();

    const [startDate, setStartDate] = useState(null)
    const [startTime, setStartTime] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [startDateTimeValid, setStartDateTimeValid] = useState(false)
    const [endDateTimeValid, setEndDateTimeValid] = useState(false)

    const [reloadWhat, setReloadWhat] = useState(Math.random());
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeValid, setPromoCodeValid] = useState('');

    const [onDepositMinAmount, setOnDepositMinAmount] = useState('');
    const [onDepositMaxAmount, setOnDepositMaxAmount] = useState('');

    const [who, setWho] = useState(-1);
    const [whoValid, setWhoValid] = useState(false);
    const [whoLevel, setWhoLevel] = useState(0);

    const [whoListFile, setWhoListFile] = useState(null);

    const [when, setWhen] = useState(0);
    const [whenValid, setWhenValid] = useState(false);

    const [howMuch, setHowMuch] = useState(0);
    const [howMuchValid, setHowMuchValid] = useState(true);

    const [what, setWhat] = useState(-1);
    const [whatValid, setWhatValid] = useState(false);

    const [notification, setNotification] = useState(-1);
    const [notificationValid, setNotificationValid] = useState(true);

    const [details, setDetails] = useState(null);
    const whoList = [
        { key: -1, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.select', defaultMessage: 'Make a selection' }) },
        { key: WHO_TYPE_PLAYERS, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.allReg', defaultMessage: 'All Registred Players' }) },
        { key: WHO_TYPE_PLAYERS_VERIFIED, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.allVerif', defaultMessage: 'All Verified Players' }) },
        { key: WHO_TYPE_PLAYERS_ALREADY_DEPOSIT, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.allDeposit', defaultMessage: 'All players who have already deposited' }) },
        { key: WHO_TYPE_SPECIFIC_LIST, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.specific', defaultMessage: 'Specific List' }) }
    ]

    const whoLoyaltyLevelList = [
        { key: 1, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.level.0', defaultMessage: 'All (Basic Level)' }) },
        { key: 2, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.level.1', defaultMessage: 'Level 1' }) },
        { key: 3, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.level.2', defaultMessage: 'Level 2' }) },
        { key: 4, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.level.3', defaultMessage: 'Level 3' }) },
        { key: 5, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.level.4', defaultMessage: 'Level 4' }) },
        { key: 6, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.level.5', defaultMessage: 'Level 5' }) },
        { key: 7, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.level.6', defaultMessage: 'Level 6' }) },
        { key: 8, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.level.7', defaultMessage: 'Level 7' }) },
        { key: 9, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.level.8', defaultMessage: 'Level 8' }) },
        { key: 10, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.level.9', defaultMessage: 'Level 9' }) },
        { key: 11, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.who.level.10', defaultMessage: 'Level 10' }) },
    ]

    const whenList = [
        { key: -1, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.when.select', defaultMessage: 'Make a selection' }) },
        { key: WHEN_TYPE_ON_USE, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.when.use', defaultMessage: 'On use of promotional code' }) },
        { key: WHEN_TYPE_ON_DEPOSIT, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.when.deposit', defaultMessage: 'On deposit' }) },
    ]

    const whatListOnUse = [
        { key: -1, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.what.select', defaultMessage: 'Make a selection' }) },
        { key: WHAT_TYPE_CASH, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.what.cash', defaultMessage: 'Cash' }) },
        { key: WHAT_TYPE_PNG, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.what.png', defaultMessage: 'Png Point' }) },
        { key: WHAT_TYPE_FREESPIN, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.what.freespin', defaultMessage: 'Free Spins' }) },
        { key: WHAT_TYPE_WAGER, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.what.wager', defaultMessage: 'Wager' }) }
    ]

    const whatListOnDeposit = [
        { key: -1, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.what.select', defaultMessage: 'Make a selection' }) },
        { key: WHAT_TYPE_CASH, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.what.cash', defaultMessage: 'Cash' }) },
        { key: WHAT_TYPE_PNG, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.what.png', defaultMessage: 'Png Point' }) },
        { key: WHAT_TYPE_FREESPIN, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.what.freespin', defaultMessage: 'Free Spins' }) },
        { key: WHAT_TYPE_WAGER, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.what.wager', defaultMessage: 'Wager' }) },
        { key: WHAT_TYPE_DEPOSIT_UPGRADE, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.what.deposit.upgrade', defaultMessage: 'Deposit Upgrade' }) }
    ]

    const notificationList = [
        { key: 0, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.notif.none', defaultMessage: 'None' }) },
        { key: 1, value: intl.formatMessage({ id: 'newcasino.admin.promo.combo.notif.email', defaultMessage: 'Email' }) }
    ]

    function updatePromoCode(isValid, value) {
        setPromoCode(value);
        setPromoCodeValid(isValid);
    }

    function updateOnDepositMinAmount(isValid, value) {
        if (isValid) {
            setOnDepositMinAmount(Number(value))
        } else {
            setOnDepositMinAmount(Number(0));
        }

    }

    function updateOnDepositMaxAmount(isValid, value) {
        if (isValid) {
            setOnDepositMaxAmount(Number(value))
        } else {
            setOnDepositMaxAmount(Number(0));
        }

    }


    function updateHowMuch(isValid, value) {
        setHowMuchValid(isValid)

        if (isValid) {
            setHowMuch(Number(value))
        } else {
            setHowMuch(value)
        }

    }

    function changeWhoLevel(data) {
        setWhoLevel(Number(data));
    }

    function changeWho(data) {
        data = Number(data);
        setWho(Number(data));
        setWhoListFile(null)
        if (data === -1) {
            setWhoValid(false)
        } else if (data === WHO_TYPE_SPECIFIC_LIST && whoListFile === null) {
            setWhoValid(false)
        } else {
            setWhoValid(true);
        }
    }

    function changeWhen(data) {
        data = Number(data);
        setWhen(data);
        setWhat(-1)
        setReloadWhat(Math.random());

        if (data === -1) {
            setWhenValid(false)
        } else {
            setWhenValid(true);
        }
    }

    function changeWhat(data) {
        data = Number(data);
        setWhat(data);

        //if (data === -1) {
        setWhatValid(false)
        //}
    }

    function changeNotification(data) {
        setNotification(Number(data));
        setNotificationValid(true);
    }

    function updateStartDateTime(startDateValue, startTimeValue, endDateValue, endTimeValue) {
        setStartDate(startDateValue);
        setStartTime(startTimeValue);
        setStartDateTimeValid(true);

        setEndDate(endDateValue);
        setEndTime(endTimeValue);
        setEndDateTimeValid(true);
    }

    function userListFile(theFile) {
        if (theFile) {
            setWhoListFile(theFile);
            setWhoValid(true);
        }
    }

    function canProcess() {
        return startDateTimeValid && endDateTimeValid && whoValid && whenValid && whatValid && howMuchValid && notificationValid && promoCodeValid;
    }

    function sendDetails(details) {
        setDetails(details);

        if (details) {
            setWhatValid(true);
        } else {
            setWhatValid(false);
        }
    }

    function sendRequest() {
        let data = {
            startDate: startDate,
            startTime: startTime,
            endDate: endDate,
            endTime: endTime,
            code: promoCode,
            who: {
                type: who,
                minLevel: whoLevel
            },
            when: when,
            whenMin: onDepositMinAmount,
            whenMax: onDepositMaxAmount,
            what: {
                type: what,
                details: details
            },
            howMuch: howMuch,
            notification: notification
        }

        props.sendRequest(whoListFile, data);
    }

    try {
        return (
            <>
                <div className={style.lineDates}>
                    <div className={style.bottom}>
                        <DatePicker type='INTERVAL' minDate='TODAY' initDate='TODAY' initEndDate='MIDNIGHT' startTimeLabel={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.starttime', defaultMessage: 'Start Time' })} onChange={updateStartDateTime} startDateLabel={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.startdate', defaultMessage: 'Start Date' })} endTimeLabel={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.endtime', defaultMessage: 'End Time' })} endDateLabel={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.enddate', defaultMessage: 'End Date' })} />
                    </div>
                </div>
                <div className={style.section}>{intl.formatMessage({ id: 'newcasino.admin.promo.code.subtitle.promocode', defaultMessage: 'Promo Code' })}</div>
                <div className={style.childSection}>
                    <TextField maxLength='30' hint='' uppercase={true} label={intl.formatMessage({ id: 'newcasino.admin.promo.code.code', defaultMessage: 'Type the Promo Code Here' })} values={[promoCode]} validator={isTextMin5Char} validatorInformer={updatePromoCode} mandatory='true' showCheck='true' />
                </div>
                <div className={style.section}>{intl.formatMessage({ id: 'newcasino.admin.promo.code.subtitle.who', defaultMessage: 'Who' })}</div>
                <div className={style.childSection}>
                    <ComboBox label='' onChange={changeWho} values={[who]} valueList={{ list: whoList, key: 'key', value: 'value' }} />
                    {(who > -1 && who < WHO_TYPE_SPECIFIC_LIST) &&
                        <div className={style.topSpacer}>
                            <ComboBox label={intl.formatMessage({ id: 'newcasino.admin.promo.code.minimum.level', defaultMessage: 'Minimum Loyalty Level' })} onChange={changeWhoLevel} values={[whoLevel]} valueList={{ list: whoLoyaltyLevelList, key: 'key', value: 'value' }} />
                        </div>
                    }
                    {(who === WHO_TYPE_SPECIFIC_LIST) &&
                        <>
                            <UserListUpload onFileSelection={userListFile} />
                        </>
                    }
                </div>
                <div className={style.section}>{intl.formatMessage({ id: 'newcasino.admin.promo.code.subtitle.when', defaultMessage: 'When' })}</div>
                <div className={style.childSection}>
                    <ComboBox label='' onChange={changeWhen} values={[when]} valueList={{ list: whenList, key: 'key', value: 'value' }} />
                </div>
                {(when === WHEN_TYPE_ON_DEPOSIT) &&
                    <>
                        <div className={style.childSection2}>
                            <TextField maxLength='20' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.admin.promo.code.subtitle.min.deposit', defaultMessage: 'Minimum Deposit' })} values={[onDepositMinAmount]} validator={isNumeric} validatorInformer={updateOnDepositMinAmount} mandatory='false' showCheck='true' />
                            <TextField maxLength='20' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.admin.promo.code.subtitle.max.deposit', defaultMessage: 'Maximum Deposit' })} values={[onDepositMaxAmount]} validator={isNumeric} validatorInformer={updateOnDepositMaxAmount} mandatory='false' showCheck='true' />
                        </div>
                    </>
                }
                <div className={style.section}>{intl.formatMessage({ id: 'newcasino.admin.promo.code.subtitle.what', defaultMessage: 'What' })}</div>
                <div className={style.childSection}>
                    <ComboBox key={reloadWhat} label='' onChange={changeWhat} values={[what]} valueList={when === WHEN_TYPE_ON_DEPOSIT ? { list: whatListOnDeposit, key: 'key', value: 'value' } : { list: whatListOnUse, key: 'key', value: 'value' }} />
                    {(what === WHAT_TYPE_CASH) &&
                        <div className={style.whatSection}>
                            <PromoCash sendDetails={sendDetails} />
                        </div>
                    }
                    {(what === WHAT_TYPE_FREESPIN) &&
                        <div className={style.whatSection}>
                            <PromoFreeSpin sendDetails={sendDetails} />
                        </div>
                    }
                    {(what === WHAT_TYPE_PNG) &&
                        <div className={style.whatSection}>
                            <PromoPngPoint sendDetails={sendDetails} />
                        </div>
                    }
                    {(what === WHAT_TYPE_WAGER) &&
                        <div className={style.whatSection}>
                            <PromoWager sendDetails={sendDetails} />
                        </div>
                    }
                    {(what === WHAT_TYPE_DEPOSIT_UPGRADE) &&
                        <div className={style.whatSection}>
                            <PromoDepositBonus sendDetails={sendDetails} />
                        </div>
                    }
                </div>
                <div className={style.section}>{intl.formatMessage({ id: 'newcasino.admin.promo.code.subtitle.howmuch', defaultMessage: 'How Much' })}</div>
                <div className={style.childSection}>
                    <TextField maxLength='100' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.admin.promo.code.quantity', defaultMessage: 'Max Quantity (0 = Unlimited)' })} values={[howMuch]} validator={isNumeric} validatorInformer={updateHowMuch} mandatory='true' showCheck='true' />
                </div>
                {notifEnable &&
                    <>
                        <div className={style.section}>{intl.formatMessage({ id: 'newcasino.admin.promo.code.subtitle.notif', defaultMessage: 'Notification' })}</div>
                        <div className={style.childSection}>
                            <ComboBox label='' onChange={changeNotification} values={[notification]} valueList={{ list: notificationList, key: 'key', value: 'value' }} />
                        </div>
                    </>
                }
                <div className={style.buttonSpace}>
                    <Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.submit', defaultMessage: 'Submit' })} active={canProcess()} onClick={sendRequest} />
                </div>
            </>
        )
    } catch (error) {
        console.log(error);
    }
}

export default NewPromoCode;