/* eslint-disable */
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as jwtBackendService from '../../../../Services/JWTBackend/jwtBackendService'

function Logout(props) {

  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    jwtBackendService.logout(navigate, location.pathname);

    if (props.type === 440) {
      window.location.href = '/?sessionoff=true';
    } else {
      window.location.href = '/';
    }

  });
}

export default Logout

