import React from 'react'
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl';
import { getImageUrl } from 'newui/Utils/uiUtil';
import { setTheme } from 'newui/Utils/themeUtils';

function Banner(props) {

  return (
    <div className={style.bannerDiv}>
      <div className={style.centerize}>
        <div className={style.bannerText}>
          <div className={style.title_txt}>
            <FormattedMessage id="dotnet.banner.title" defaultMessage="" />
          </div>
          <img className={style.separator} alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/Raf/bottomline.png'))}></img>
          <div>
            <div className={style.subtitle_txt}>
              <FormattedMessage
                id='dotnet.banner.subtitle'
                defaultMessage=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default (Banner)