import React, { useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom'
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import { setTheme } from '../../../../Utils/themeUtils'
import { displayErrorNotificationIntl, displayGenericErrorNotificationIntl, displayInfoNotificationIntl, getImageUrl, isTextMin2Char } from '../../../../Utils/uiUtil';
import ComboBox from '../../../../Generic/Inputs/ComboBox';
import * as jwtBackendService from '../../../../Services/JWTBackend/jwtBackendService'

import DivClick from '../../../../Generic/Inputs/DivClick';
import { displayPage } from '../../../../Utils/routerUtil';
import * as constants from '../../../../constants.js'
import TextField from '../../../../Generic/Inputs/TextField';
import Button from '../../../../Generic/Inputs/Button';
import NewPromoCode from '../Types/NewPromoCode';
import NewInstantGift from '../Types/NewInstantGift';
import NewDepositBonus from '../Types/NewDepositBonus';

function NewPromotion(props) {

    const STEP_INITIAL = -1;
    const STEP_GIFT_TYPE = 1;
    const STEP_AUDIDENCE = 2;
    const STEP_DEPOSIT_BONUS_DETAIL = 3;
    const STEP_PROMO_CODE_DETAIL = 4;

    const PROMO_TYPE_NONE = -1;
    const PROMO_TYPE_DEPOSIT_BONUS = 1;
    const PROMO_TYPE_INSTANT_GIFT = 2;
    const PROMO_TYPE_CODE = 3;

    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    const [currentStep, setCurrentStep] = useState(STEP_INITIAL);
    const [promoType, setPromoType] = useState(PROMO_TYPE_NONE);

    const [inputPromName, setInputPromName] = useState('');
    const [isPromNameValidMaster, setIsPromNameValidMaster] = useState(false);

    const promoTypeChoices = {
        key: 'key',
        value: 'value',
        list: [
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.type.select', defaultMessage: 'Choose one...' }), key: PROMO_TYPE_NONE },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.type.deposit', defaultMessage: 'Deposit Bonus' }), key: PROMO_TYPE_DEPOSIT_BONUS },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.type.instant', defaultMessage: 'Instant Gift' }), key: PROMO_TYPE_INSTANT_GIFT },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.type.redeem.promo.code', defaultMessage: 'Promotion Code' }), key: PROMO_TYPE_CODE }
        ]
    }

    function switchPromoType(data) {
        setPromoType(Number(data));
    }

    function isNameValid(isValid, value) {
        setIsPromNameValidMaster(isValid);
        setInputPromName(value);
    }

    function canProcessNextStep() {
        let toReturn =
            isPromNameValidMaster && promoType > PROMO_TYPE_NONE;
        return toReturn;
    }

    function back() {
        displayPage(constants.ROUTE_ADMIN_PROMOTIONS, navigate, location.pathname);
    }

    function nextStep() {
        if (currentStep === STEP_INITIAL && promoType === PROMO_TYPE_INSTANT_GIFT) {
            setCurrentStep(STEP_GIFT_TYPE);
        }

        else if (currentStep === STEP_INITIAL && promoType === PROMO_TYPE_DEPOSIT_BONUS) {
            setCurrentStep(STEP_DEPOSIT_BONUS_DETAIL);
        }
        else if (currentStep === STEP_INITIAL && promoType === PROMO_TYPE_CODE) {
            setCurrentStep(STEP_PROMO_CODE_DETAIL);
        }
        else if (currentStep === STEP_GIFT_TYPE) {
            setCurrentStep(STEP_AUDIDENCE);
        }

    }

    function uploadSuccess(data) {
        if (data.status === 'KO') {
            if (data.statusError === 80) {
                displayErrorNotificationIntl(intl, 'newcasino.promotions.admin.prom.add.baduserlist');
                console.error(data);
            } else {
                displayGenericErrorNotificationIntl(intl);
            }

        } else {
            displayInfoNotificationIntl(intl, 'newcasino.promotions.admin.prom.add.success');
            back();
        }

    }

    function uploadFailure(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function sendRequest(userListFile, details) {

        const data = {
            promoType: promoType,
            promoName: inputPromName,
            details: details
        }

        jwtBackendService.addPromotion(userListFile, data, uploadSuccess, uploadFailure, navigate, location.pathname);
    }

    try {
        return (
            <div className={style.master_page_container}>
                <div className={style.topMainDiv}>
                    <div>
                        <div className={style.grid100}>
                            <div className={style.gridBack}>
                                <DivClick className={style.arrow} onClick={back}>
                                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                                </DivClick>

                                {currentStep === STEP_INITIAL &&
                                    <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.title', defaultMessage: 'New Promotion' })}</div>
                                }
                                {currentStep !== STEP_INITIAL &&
                                    <div className={style.case1TopText2}>{inputPromName}</div>
                                }
                            </div>
                            <div className={style.case1TopText3}>
                                <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                            </div>
                            {currentStep === STEP_INITIAL &&
                                <>
                                    <div className={style.bottom}><TextField maxLength='100' autofocus='true' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.name', defaultMessage: 'Promotion Name' })} values={[inputPromName]} validator={isTextMin2Char} validatorInformer={isNameValid} mandatory='true' showCheck='true' /></div>
                                    <div className={style.bottom}><ComboBox label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.type', defaultMessage: 'Choose A Promotion Type' })} values={[promoType]} valueList={promoTypeChoices} onChange={switchPromoType} /></div>
                                    <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.next', defaultMessage: 'Next Step' })} active={canProcessNextStep()} onClick={nextStep} /></div>
                                </>
                            }
                            {currentStep === STEP_GIFT_TYPE &&
                                <NewInstantGift sendRequest={sendRequest} />
                            }
                            {currentStep === STEP_DEPOSIT_BONUS_DETAIL &&
                                <NewDepositBonus sendRequest={sendRequest} />
                            }
                            {currentStep === STEP_PROMO_CODE_DETAIL &&
                                <NewPromoCode sendRequest={sendRequest} />
                            }
                        </div>
                    </div>
                </div>
            </div >
        )
    } catch (error) {
        console.log(error);
    }
}

export default NewPromotion;