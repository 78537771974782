import React, { useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work

import { inNotMinusOne, isTextMin2Char } from '../../../../../Utils/uiUtil';
import ComboBox from '../../../../../Generic/Inputs/ComboBox';
import TextField from '../../../../../Generic/Inputs/TextField';
import DatePicker from '../../../../../Generic/Inputs/DatePicker';
import Button from '../../../../../Generic/Inputs/Button';

function NewDepositBonus(props) {
    const PROMO_TYPE_DEPOSIT_XTIME_NONE = -1;
    const PROMO_TYPE_DEPOSIT_XTIME_1 = 1;
    const PROMO_TYPE_INSTANT_XTIME_2 = 2;

    const intl = useIntl();

    const [depositBonusStartDate, setDepositBonusStartDate] = useState('');
    const [depositBonusStartTime, setDepositBonusStartTime] = useState('');
    const [depositBonusEndDate, setDepositBonusEndDate] = useState('');
    const [depositBonusEndTime, setDepositBonusEndTime] = useState('');
    const [depositBonusAction, setDepositBonusAction] = useState(PROMO_TYPE_DEPOSIT_XTIME_NONE);
    const [depositBonusChance, setDepositBonusChance] = useState(PROMO_TYPE_DEPOSIT_XTIME_NONE);
    const [depositBonusMaxWinPerDeposit, setDepositBonusMaxWinPerDeposit] = useState('');
    const [depositBonusTotal, setDepositBonusTotal] = useState('');
    const [isDepositBonusStartDateValidMaster, setIsDepositBonusStartDateValidMaster] = useState(false);
    const [isDepositBonusStartTimeValidMaster, setIsDepositBonusStartTimeValidMaster] = useState(false);
    const [isDepositBonusEndDateValidMaster, setIsDepositBonusEndDateValidMaster] = useState(false);
    const [isDepositBonusEndTimeValidMaster, setIsDepositBonusEndTimeValidMaster] = useState(false);
    const [isDepositBonusActionValidMaster, setIsDepositBonusActionValidMaster] = useState(false);
    const [isDepositBonusChanceValidMaster, setIsDepositBonusChanceValidMaster] = useState(false);
    const [isDepositBonusMaxAmountPerDepositValidMaster, setIsDepositBonusMaxAmountPerDepositValidMaster] = useState(false);
    const [isDepositBonusTotalAmountGiftValidMaster, setIsDepositBonusTotalAmountGiftValidMaster] = useState(false);

    const depositXTimeChoices = {
        key: 'key',
        value: 'value',
        list: [
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.select', defaultMessage: 'Choose one...' }), key: PROMO_TYPE_DEPOSIT_XTIME_NONE },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.double', defaultMessage: 'Double' }), key: PROMO_TYPE_DEPOSIT_XTIME_1 },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.triple', defaultMessage: 'Triple' }), key: PROMO_TYPE_INSTANT_XTIME_2 }
        ]
    }

    const chancesToWinChoices = {
        key: 'key',
        value: 'value',
        list: [
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.select', defaultMessage: 'Choose one...' }), key: PROMO_TYPE_DEPOSIT_XTIME_NONE },
            { value: '5%', key: 20 },
            { value: '10%', key: 10 },
            { value: '20%', key: 5 },
            { value: '33%', key: 3 },
            { value: '50%', key: 2 },
            { value: '100%', key: 1 },
        ]
    }

    function updateStartDateTime(startDateValue, startTimeValue, endDateValue, endTimeValue) {
        setIsDepositBonusStartDateValidMaster(true);
        setDepositBonusStartDate(startDateValue);
        setIsDepositBonusStartTimeValidMaster(true);
        setDepositBonusStartTime(startTimeValue);

        setIsDepositBonusEndDateValidMaster(true);
        setDepositBonusEndDate(endDateValue);
        setIsDepositBonusEndTimeValidMaster(true);
        setDepositBonusEndTime(endTimeValue);
    }

    function isComboActionOk(isValid, value) {
        setIsDepositBonusActionValidMaster(isValid);
        setDepositBonusAction(Number(value))
    }

    function isChanceOk(isValid, value) {
        setIsDepositBonusChanceValidMaster(isValid);
        setDepositBonusChance(Number(value));
    }

    function switchChanceToWin(data) {
        setDepositBonusChance(Number(data));
    }

    function switchXTime(data) {
        setDepositBonusAction(Number(data));
    }

    function maxWinPerDepositInformer(isValid, value) {
        setIsDepositBonusMaxAmountPerDepositValidMaster(isValid);

        if (isValid) {
            setDepositBonusMaxWinPerDeposit(Number(value));
        } else {
            setDepositBonusMaxWinPerDeposit(value);
        }

    }

    function maxAmountInformer(isValid, value) {
        setIsDepositBonusTotalAmountGiftValidMaster(isValid);


        if (isValid) {
            setDepositBonusTotal(Number(value));
        } else {
            setDepositBonusTotal(value);
        }

    }

    function canProcess() {
        let toReturn =
            isDepositBonusStartDateValidMaster && isDepositBonusStartTimeValidMaster && isDepositBonusEndDateValidMaster && isDepositBonusEndTimeValidMaster &&
            isDepositBonusActionValidMaster && isDepositBonusChanceValidMaster && isDepositBonusMaxAmountPerDepositValidMaster && isDepositBonusTotalAmountGiftValidMaster;

        return toReturn;

    }

    function sendRequest() {
        let data = {
            startDate: depositBonusStartDate,
            startTime: depositBonusStartTime,
            endDate: depositBonusEndDate,
            endTime: depositBonusEndTime,
            action: depositBonusAction,
            chance: depositBonusChance,
            maxWin: depositBonusMaxWinPerDeposit,
            total: depositBonusTotal
        }

        props.sendRequest(null, data);
    }

    try {
        return (
            <>
                <div className={style.lineDates}>
                    <div className={style.bottom}>
                        <DatePicker type='INTERVAL' minDate='TODAY' initDate='TODAY' startTimeLabel={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.starttime', defaultMessage: 'Start Time' })} onChange={updateStartDateTime} startDateLabel={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.startdate', defaultMessage: 'Start Date' })} endTimeLabel={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.endtime', defaultMessage: 'End Time' })} endDateLabel={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.enddate', defaultMessage: 'End Date' })} />
                    </div>
                </div>
                <div className={style.lineDetails}>
                    <div className={style.bottom}><ComboBox label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.action', defaultMessage: 'Action on deposit amount' })} values={[depositBonusAction]} valueList={depositXTimeChoices} onChange={switchXTime} validator={inNotMinusOne} validatorInformer={isComboActionOk} /></div>
                    <div className={style.bottom}><ComboBox label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.chance', defaultMessage: 'Chance to win' })} values={[depositBonusChance]} valueList={chancesToWinChoices} onChange={switchChanceToWin} validator={inNotMinusOne} validatorInformer={isChanceOk} /></div>
                </div>
                <div className={style.lineDetailsEnd}>
                    <div className={style.bottom}><TextField type='digit' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.maxwin', defaultMessage: 'Max Win Per Deposit (EUR)' })} showCheck='true' validator={isTextMin2Char} validatorInformer={maxWinPerDepositInformer} values={[depositBonusMaxWinPerDeposit]} hint={''} /></div>
                    <div className={style.bottom}><TextField type='digit' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.total', defaultMessage: 'Total Amount Gift (EUR)' })} showCheck='true' validator={isTextMin2Char} validatorInformer={maxAmountInformer} values={[depositBonusTotal]} hint={''} /></div>
                </div>
                <Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.submit', defaultMessage: 'Submit' })} active={canProcess()} onClick={sendRequest} />
            </>
        )
    } catch (error) {
        console.log(error);
    }
}

export default NewDepositBonus;