import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';
import * as jwtBackendService from '../../../../Services/JWTBackend/jwtBackendService'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import { setTheme } from '../../../../Utils/themeUtils'
import { displayGenericErrorNotificationIntl, displayInfoNotificationIntl, getImageUrl } from '../../../../Utils/uiUtil';
import DivClick from '../../../../Generic/Inputs/DivClick';
import { displayPage } from '../../../../Utils/routerUtil';
import * as constants from '../../../../constants.js'
import TextField from '../../../../Generic/Inputs/TextField';
import Button from '../../../../Generic/Inputs/Button';
import Checkbox from '../../../../Generic/Inputs/Checkbox';
import NonMemoryList from '../../../../Generic/Inputs/NonMemoryList';
import { convertToMoney } from '../../../../Utils/converterUtil';

function PromotionViewer(props) {

    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    const [sortValue, setSortValue] = useState('email');
    const [sortOrder, setSortOrder] = useState(0);

    const [loading, setLoading] = useState(true);
    const [currentPromotion, setCurrentPromotion] = useState(null);
    const [statistics, setStatistics] = useState(null);
    const [statisticsList, setStatisticsList] = useState(null);
    const [statisticsListMeta, setStatisticsListMeta] = useState(null);

    const params = useParams();

    const PROMO_TYPE_DEPOSIT_BONUS = 1;
    const PROMO_TYPE_INSTANT_GIFT = 2;

    const columnsDefinition = [
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.wager.tx.col1', defaultMessage: 'Email' }), width: '*', hintColumn: 'userId', listColumn: 'email', canSort: true, sortValue: 'email' },
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.wager.tx.col2', defaultMessage: 'Coin' }), width: '100px', listColumn: 'coin', canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.wager.tx.col3', defaultMessage: 'Balance' }), width: '100px', listColumn: 'balance', transformerObj: true, transformer: toMoney, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.wager.tx.col4', defaultMessage: 'Target' }), width: '100px', listColumn: 'wagerTarget', transformerObj: true, transformer: toMoney, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.wager.tx.col5', defaultMessage: 'Prize' }), width: '100px', listColumn: 'wagerWinBalance', transformerObj: true, transformer: toMoney, canSort: true, sortValue: 'wagerWinBalance' }
    ];

    const depositBonusColumnsDefinition = [
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.depositbonus.tx.col1', defaultMessage: 'Email' }), width: '*', hintColumn: 'userId', listColumn: 'email', canSort: true, sortValue: 'email' },
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.depositbonus.tx.col2', defaultMessage: 'Coin' }), width: '100px', listColumn: 'coin', canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.prom.depositbonus.tx.col3', defaultMessage: 'Prize' }), width: '100px', listColumn: 'txAmount', transformerObj: true, transformer: toMoney, canSort: true, sortValue: 'txAmount' }
    ];

    useEffect(() => {
        let id = params.id
        getPromotionInformation(id, 1, null, null);
    },// eslint-disable-next-line
        [])

    function getPromotionInformation(id, pageNumber, sortColumn, sortAsc) {
        jwtBackendService.getPromotion(id, pageNumber, sortColumn, sortAsc, onSuccessPromotionList, onFailurePromotionList, navigate, location.pathname);
    }

    function onSuccessPromotionList(data) {
        setLoading(false)
        setCurrentPromotion(data[0][0]);

        if (data[0][0].type === 1) { //Bonus Deposit
            setStatistics(data[2][0]);
            setStatisticsList(data[3]);
            setStatisticsListMeta(data[4][0]);
        }

        else if (data[0][0].type === 2) { //GIFT
            if (data.length > 3) {
                setStatistics(data[2][0]);
            }
            if (data.length > 4) {
                setStatisticsList(data[3]);
                setStatisticsListMeta(data[4][0]);
            }
        }
    }

    function onFailurePromotionList(data) {
        setLoading(false)
        displayGenericErrorNotificationIntl(intl)
    }

    function getDetail(details, index) {
        return details.split(",")[index];
    }

    function getGiftType(details) {
        return Number(getDetail(details, 0));
    }

    function getGiftTypeTxt(details) {
        let type = Number(getDetail(details, 0));

        if (type === 1) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.cash', defaultMessage: 'Money' });
        } else if (type === 2) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.png', defaultMessage: 'Png Points' });
        } else if (type === 3) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.freespin', defaultMessage: 'Freespins' });
        } else if (type === 4) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager', defaultMessage: 'Wager' });
        }

        return ' - ';
    }

    function getDepositAction(details) {
        return Number(getDetail(details, 0));
    }

    function getAudience(audience) {
        if (audience === 0) {
            return ' - ';
        } else if (audience === 1) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.audience.all', defaultMessage: 'All Players (Except Banned)' });
        } else if (audience === 2) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.audience.list', defaultMessage: 'CSV List' });
        }

        return 'N/A';
    }

    function getPromoType(type) {
        if (type === 1) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.promo.deposit', defaultMessage: 'Deposit Bonus' });
        } else if (type === 2) {
            return intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.promo.gift', defaultMessage: 'Instant Gift' });
        }

        return 'N/A';
    }

    function getChance(stringText) {
        try {
            let nbr = Number(stringText)
            return (100 / nbr).toFixed(0) + "%";
        } catch (err) {
            return 'N/A'
        }
    }

    function callOnSuccess(data) {
        displayInfoNotificationIntl(intl, 'newcasino.promotions.admin.prom.mod.success');
        back();
    }

    function callOnFailed(data) {
        displayGenericErrorNotificationIntl(intl)
    }

    function deleteProm() {
        jwtBackendService.deletePromotion(currentPromotion.id, callOnSuccess, callOnFailed, navigate, location.pathname);
    }

    function deactivateProm() {
        jwtBackendService.deactivatePromotion(currentPromotion.id, callOnSuccess, callOnFailed, navigate, location.pathname);
    }

    function activateProm() {
        jwtBackendService.activatePromotion(currentPromotion.id, callOnSuccess, callOnFailed, navigate, location.pathname);
    }

    function back() {
        displayPage(constants.ROUTE_ADMIN_PROMOTIONS, navigate, location.pathname);
    }

    function successExportToCSV(data) { }

    function failureExportToCSV(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function downloadOri() {
        jwtBackendService.exportPromotionCSV(currentPromotion.id, 1, successExportToCSV, failureExportToCSV, navigate, location.pathname);
    }

    function downloadResult() {
        jwtBackendService.exportPromotionCSV(currentPromotion.id, 2, successExportToCSV, failureExportToCSV, navigate, location.pathname);
    }

    function toMoney(data, obj) {
        return convertToMoney(data, obj.coin);
    }

    function sort(columnDef, order) {
        let newOrder = (sortValue === columnDef.listColumn) ? (sortOrder === 0 ? 1 : 0) : 0;
        let newValue = columnDef.listColumn;
        setSortOrder(newOrder);
        setSortValue(newValue);
        let id = params.id
        getPromotionInformation(id, 1, newValue, newOrder);
    }

    function doNothing() { }

    function changePage(change) {
        let id = params.id
        getPromotionInformation(id, change, sortValue, sortOrder);
    }

    try {
        return (loading === false && currentPromotion) ? (
            <div className={style.master_page_container}>
                <div className={style.topMainDiv}>
                    <div>
                        <div className={style.grid100}>
                            <div className={style.gridBack}>
                                <DivClick className={style.arrow} onClick={back}>
                                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                                </DivClick>
                                <div className={style.case1TopText2}>{currentPromotion.name}</div>
                            </div>
                            <div className={style.case1TopText3}>
                                <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                            </div>
                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.type', defaultMessage: 'Promotion Type' })} values={[getPromoType(currentPromotion.type)]} /></div>
                            <div className={style.bottom}><Checkbox checked={currentPromotion.active === 1} label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.active', defaultMessage: 'Active' })} readOnly={true} /></div>
                            {(currentPromotion.type === PROMO_TYPE_DEPOSIT_BONUS) &&
                                <>
                                    {(getDepositAction(currentPromotion.details) === 1) &&
                                        <>
                                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.deposit.start', defaultMessage: 'Start Time (London Time)' })} values={[currentPromotion.startDate]} /></div>
                                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.deposit.end', defaultMessage: 'End Time (London Time)' })} values={[currentPromotion.endDate]} /></div>
                                            <div className={style.case1TopText3}>
                                                <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                            </div>
                                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.deposit.chance', defaultMessage: 'Chance to Win (%)' })} values={[getChance(getDetail(currentPromotion.details, 1))]} /></div>
                                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.deposit.max', defaultMessage: 'Max Gain Per Deposit (Euro)' })} values={[convertToMoney(getDetail(currentPromotion.details, 2), 'EUR')]} /></div>
                                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.deposit.total', defaultMessage: 'Max Money To Give (Euro)' })} values={[convertToMoney(getDetail(currentPromotion.details, 3), 'EUR')]} /></div>
                                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.deposit.stats.wineuro', defaultMessage: 'Cash Gived' })} values={[convertToMoney(statistics.winSoFarEuro, 'EUR')]} /></div>
                                            {(statisticsList) &&
                                                <div className={style.borderBottom}>
                                                    <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.admin.prom.depositbonus.winners', defaultMessage: 'Winners' })}</div>
                                                    <NonMemoryList key={'DETAILSDep_' + Math.random()} customHeaderSorted={style.listWalletHeaderSorted} customHeader={style.listWalletHeader} customRowCellLight={style.listWalletLight} onRowClick={doNothing} customRowCellDark={style.listWalletDark} list={statisticsList} changePage={changePage} nbrItemPerPage={20} currentPage={statisticsListMeta.currentPage} nbrPage={statisticsListMeta.nbrPage} columns={depositBonusColumnsDefinition} sortedColumn={sortValue} sortedOrder={sortOrder} onSort={sort} />
                                                </div>
                                            }
                                        </>
                                    }
                                </>
                            }
                            {(currentPromotion.type === PROMO_TYPE_INSTANT_GIFT) &&
                                <>
                                    <div className={style.bottom}><Checkbox checked={currentPromotion.cronned === 1} label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.cronned', defaultMessage: 'Executed (Cron Job Passed)' })} readOnly={true} /></div>
                                    <div className={style.three}>
                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.gift', defaultMessage: 'Gift Type' })} values={[getGiftTypeTxt(currentPromotion.details)]} /></div>
                                    </div>
                                    <div className={style.three}>
                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.audience', defaultMessage: 'Receivers' })} values={[getAudience(currentPromotion.audience)]} /></div>

                                        {(currentPromotion.audience === 2) &&
                                            <div className={style.bottomButton}><Button width='200px' value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.button.download.original', defaultMessage: 'Download User List' })} onClick={downloadOri} /></div>
                                        }

                                        {(currentPromotion.audience === 2 && currentPromotion.cronned === 1) &&
                                            <div className={style.bottomButton}><Button width='200px' value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.button.download.result', defaultMessage: 'Download Results List' })} onClick={downloadResult} /></div>
                                        }
                                    </div>
                                    <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.email.template', defaultMessage: 'Email Template' })} values={[currentPromotion.emailTemplate]} /></div>
                                    {(getGiftType(currentPromotion.details) === 1) &&
                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.cash.nbr', defaultMessage: 'Cash (Euro)' })} values={[getDetail(currentPromotion.details, 1)]} /></div>
                                    }
                                    {(getGiftType(currentPromotion.details) === 2) &&
                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.png.nbr', defaultMessage: 'PNG Point' })} values={[getDetail(currentPromotion.details, 1)]} /></div>
                                    }
                                    {(getGiftType(currentPromotion.details) === 3) &&
                                        <>
                                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.freespins.nbr', defaultMessage: 'FreeSpins' })} values={[getDetail(currentPromotion.details, 1)]} /></div>
                                            <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.freespins.expiration', defaultMessage: 'Expiration In Days' })} values={[getDetail(currentPromotion.details, 2)]} /></div>
                                        </>
                                    }
                                    {(getGiftType(currentPromotion.details) === 4) &&
                                        <>
                                            <div className={style.three}>
                                                <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.nbr', defaultMessage: 'Wager Amount' })} values={[getDetail(currentPromotion.details, 1)]} /></div>
                                                <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.x', defaultMessage: 'Wager xTimes' })} values={[getDetail(currentPromotion.details, 2)]} /></div>
                                                <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.maxwin', defaultMessage: 'Wager Max Win' })} values={[getDetail(currentPromotion.details, 3)]} /></div>
                                            </div>
                                            <div className={style.one}>
                                                <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.expDate', defaultMessage: 'Wager Expiration Date' })} values={[getDetail(currentPromotion.details, 4)]} /></div>
                                            </div>
                                            {
                                                statistics &&
                                                <>
                                                    <div className={style.case1TopText3}>
                                                        <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                                    </div>
                                                    <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.stats.title', defaultMessage: 'Statistics' })}</div>
                                                    <div className={style.five}>
                                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.stats.nbr', defaultMessage: 'Wager Gived' })} values={[statistics.wagerTotal]} /></div>
                                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.stats.inprogress', defaultMessage: 'In Progress' })} values={[statistics.wagerInProgress]} /></div>
                                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.stats.winners', defaultMessage: 'Nbr Winners' })} values={[statistics.wagerWinners]} /></div>
                                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.stats.looser', defaultMessage: 'Nbr Loosers' })} values={[statistics.wagerLosers]} /></div>
                                                        <div className={style.bottom}><TextField readOnly={true} hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.wager.stats.wineuro', defaultMessage: 'Total Wins Euro' })} values={[convertToMoney(statistics.winSoFarEuro, 'EUR')]} /></div>
                                                    </div>
                                                </>
                                            }
                                            {(statisticsList) &&
                                                <>
                                                    <NonMemoryList key={'DETAILS_' + Math.random()} customHeaderSorted={style.listWalletHeaderSorted} customHeader={style.listWalletHeader} customRowCellLight={style.listWalletLight} onRowClick={doNothing} customRowCellDark={style.listWalletDark} list={statisticsList} changePage={changePage} nbrItemPerPage={20} currentPage={statisticsListMeta.currentPage} nbrPage={statisticsListMeta.nbrPage} columns={columnsDefinition} sortedColumn={sortValue} sortedOrder={sortOrder} onSort={sort} />
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                            <div className={style.two}>
                                <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.button.delete', defaultMessage: 'Delete' })} onClick={deleteProm} /></div>
                                {(currentPromotion.active === 0) &&
                                    <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.button.activate', defaultMessage: 'Activate' })} onClick={activateProm} /></div>
                                }
                                {(currentPromotion.active === 1) &&
                                    <div className={style.bottom}><Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.viewer.button.deactivate', defaultMessage: 'Deactivate' })} onClick={deactivateProm} /></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100vh',
                }}
            >
                <i className="fa fa-circle-o-notch fa-spin fa-5x fa-fw" style={{ color: '#b9996a' }}></i>
            </div>
        )
    } catch (error) {
        console.log(error);
    }
}

export default PromotionViewer;