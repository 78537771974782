import React, { useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import { inNotMinusOne, isTextMin2Char } from '../../../../../../Utils/uiUtil';

import TextField from '../../../../../../Generic/Inputs/TextField';
import ComboBox from 'newui/Generic/Inputs/ComboBox';

function PromoDepositBonus(props) {

    const PROMO_TYPE_DEPOSIT_XTIME_NONE = -1;
    const PROMO_TYPE_DEPOSIT_XTIME_1 = 1;
    const PROMO_TYPE_INSTANT_XTIME_2 = 2;

    const intl = useIntl();

    const [depositBonusAction, setDepositBonusAction] = useState(PROMO_TYPE_DEPOSIT_XTIME_NONE);
    const [depositBonusMaxWinPerDeposit, setDepositBonusMaxWinPerDeposit] = useState('');

    const [isDepositBonusActionValidMaster, setIsDepositBonusActionValidMaster] = useState(false);
    const [isDepositBonusMaxAmountPerDepositValidMaster, setIsDepositBonusMaxAmountPerDepositValidMaster] = useState(false);

    const depositXTimeChoices = {
        key: 'key',
        value: 'value',
        list: [
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.select', defaultMessage: 'Choose one...' }), key: PROMO_TYPE_DEPOSIT_XTIME_NONE },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.double', defaultMessage: 'Double' }), key: PROMO_TYPE_DEPOSIT_XTIME_1 },
            { value: intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.triple', defaultMessage: 'Triple' }), key: PROMO_TYPE_INSTANT_XTIME_2 }
        ]
    }

    function isComboActionOk(isValid, value) {
        setIsDepositBonusActionValidMaster(isValid);
        setDepositBonusAction(Number(value))

        if (isValid && isDepositBonusMaxAmountPerDepositValidMaster) {
            props.sendDetails({ action: value, maxPerDeposit: depositBonusMaxWinPerDeposit });
        } else {
            props.sendDetails(null);
        }
    }

    function maxWinPerDepositInformer(isValid, value) {
        setIsDepositBonusMaxAmountPerDepositValidMaster(isValid);

        if (isValid) {
            setDepositBonusMaxWinPerDeposit(Number(value));
        } else {
            setDepositBonusMaxWinPerDeposit(value);
        }

        if (isValid && isDepositBonusActionValidMaster) {
            props.sendDetails({ action: depositBonusAction, maxPerDeposit: value });
        } else {
            props.sendDetails(null);
        }
    }

    try {
        return (
            <>
                <div className={style.lineDetails}>
                    <div className={style.bottom}><ComboBox label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.action', defaultMessage: 'Action on deposit amount' })} values={[depositBonusAction]} valueList={depositXTimeChoices} validator={inNotMinusOne} validatorInformer={isComboActionOk} /></div>
                    <div className={style.bottom}><TextField type='digit' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.deposit.maxwin', defaultMessage: 'Max Win Per Deposit (EUR)' })} showCheck='true' validator={isTextMin2Char} validatorInformer={maxWinPerDepositInformer} values={[depositBonusMaxWinPerDeposit]} hint={''} /></div>
                </div>
            </>
        )
    } catch (error) {
        console.log(error);
    }
}

export default PromoDepositBonus;