import React, { useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../../../Generic/Inputs/Button';
import { displayErrorNotificationIntl, displayGenericErrorNotificationIntl, displayInfoNotificationIntl, isPasswordOk } from '../../../../Utils/uiUtil';
import { useLocation, useNavigate } from 'react-router-dom'
import * as jwtBackendService from '../../../../Services/JWTBackend/jwtBackendService'
import { useParams } from 'react-router-dom'
import TextField from '../../../../Generic/Inputs/TextField';
import { displayPage } from '../../../../Utils/routerUtil';
import * as constants from '../../../../constants';


function SystemResetPassword(props) {

    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const { hash } = useParams()

    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(null);

    function passwordValidCallBack(isValid, value) {
        setPasswordValid(isValid);
        setPassword(value);
    }

    function resetPassword() {
        let data = {
            hashPassword: hash,
            newPassword: password
        }

        jwtBackendService.resetPasswordWithHash(data, successResetPassword, failureResetPassword, navigate, location.pathname);
    }

    function successResetPassword(data) {
        if (data.result === 'OK') {
            displayInfoNotificationIntl(intl, 'newcasino.reset.password.success')
            displayPage(constants.ROUTE_HOME, navigate, location.pathname);

        } else {
            displayErrorNotificationIntl(intl, 'newcasino.reset.password.failure');
        }

    }

    function failureResetPassword(data) {
        displayGenericErrorNotificationIntl(intl);
    }


    return (
        <div className={style.master_page_container}>
            {props.header !== false &&
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.reset.password.title"
                        defaultMessage="Reset Password"
                    />
                </div>
            }

            <TextField maxLength='100' values={[password, passwordValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.cred.password.hint', defaultMessage: 'Type your new password here' })} type='password' canSee='true' mandatory='true' label={intl.formatMessage({ id: 'newcasino.reset.password.password.label', defaultMessage: 'Password' })} validator={isPasswordOk} showTitleError='true' showCheck='true' validatorInformer={passwordValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.cred.password.error', defaultMessage: 'You need at less 8 characters with a mix of uppercase and lowercase' })} />
            <div className={style.spacer}><Button value={intl.formatMessage({ id: 'newcasino.auth.registration.cred.password.button.submit', defaultMessage: 'Submit' })} onClick={resetPassword} active={passwordValid} /></div>
        </div>
    );
}

export default SystemResetPassword;
