import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import Login from './Login';
import Registration from './Registration';
import Footer from './Footer';
import ForgetPassword from './ForgetPassword';
import { displayInfoNotification, getImageUrl } from '../../Utils/uiUtil';
import { useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

function Authentication(props) {

  const VIEW_LOGIN = 'VIEW_LOGIN'
  const VIEW_REGISTRATION = 'VIEW_REGISTRATION'
  const VIEW_FORGETPASSWORD = 'VIEW_FORGETPASSWORD'

  const [view, setView] = useState(VIEW_LOGIN);

  const intl = useIntl()
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams && searchParams.getAll('sessionoff') && searchParams.getAll('sessionoff').length > 0) {
      displayInfoNotification(intl.formatMessage({ id: 'newcasino.session.inactive.title', defaultMessage: 'Session timeout' }), intl.formatMessage({ id: 'newcasino.session.inactive.msg', defaultMessage: 'Your session has timed out. Please login.' }))
    }
  },// eslint-disable-next-line
    [])



  try {
    return (
      <div className={style.affiliated_login_mainGrid}>
        <div className={style.affiliated_login_centerBGImage}>
          <img className={style.affiliated_login_logo} src={getImageUrl('newui/affiliates/logo2x.png')} alt='' />
        </div>
        <div className={style.divAllHeight}>
          <div className={style.affiliated_login_centerLoginForm}>
            <div>
              {view === VIEW_LOGIN &&
                <Login spinner={props.spinner} showForgetPassword={() => setView(VIEW_FORGETPASSWORD)} showRegistration={() => setView(VIEW_REGISTRATION)} />
              }
              {view === VIEW_REGISTRATION &&
                <Registration spinner={props.spinner} closeModal={props.closeModal} displayModalComponent={props.displayModalComponent} showLogin={() => setView(VIEW_LOGIN)} />
              }
              {view === VIEW_FORGETPASSWORD &&
                <ForgetPassword spinner={props.spinner} showLogin={() => setView(VIEW_LOGIN)} />
              }
            </div>
          </div>
          <div className={style.divCopy}>
            <Footer spinner={props.spinner} closeModal={props.closeModal} displayModalComponent={props.displayModalComponent} setLanguage={props.setLanguage} />
          </div>
        </div>
      </div >
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}

export default Authentication
