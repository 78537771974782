import React, { useState } from 'react'
import style from './style.module.scss'
import TextField from '../../../../../../Generic/Inputs/TextField';
import { isDateStringOver18, isTextMin2Char } from '../../../../../../Utils/uiUtil';
import BirthDay from '../../../../../../Generic/Inputs/Birthday';
import { useIntl } from 'react-intl';

export const DATA_USERGENERALINFO_LASTNAME = 'lastname';
export const DATA_USERGENERALINFO_FIRSTNAME = 'firstname';
export const DATA_USERGENERALINFO_BIRTHDAY = 'birthday';

function UserGeneralInfo(props) {
  const intl = useIntl();
  const sourceData = props.value;

  const sourceFirstName = sourceData.fields.get(DATA_USERGENERALINFO_FIRSTNAME) ? sourceData.fields.get(DATA_USERGENERALINFO_FIRSTNAME) : {};
  const sourceLastName = sourceData.fields.get(DATA_USERGENERALINFO_LASTNAME) ? sourceData.fields.get(DATA_USERGENERALINFO_LASTNAME) : {};
  const sourceBirthDay = sourceData.fields.get(DATA_USERGENERALINFO_BIRTHDAY) ? sourceData.fields.get(DATA_USERGENERALINFO_BIRTHDAY) : {};

  const [firstName, setFirstname] = useState(sourceFirstName.value ? sourceFirstName.value : '');
  const [lastName, setLastName] = useState(sourceLastName.value ? sourceLastName.value : '');
  const [birthDay, setBirthDay] = useState(sourceBirthDay.value ? sourceBirthDay.value : '');
  const [firstNameValid, setFirstNameValid] = useState((sourceFirstName.valid !== undefined) ? sourceFirstName.valid : null);
  const [lastNameValid, setLastNameValid] = useState((sourceLastName.valid !== undefined) ? sourceLastName.valid : null);
  const [birthDayValid, setBirthDayValid] = useState((sourceBirthDay.valid !== undefined) ? sourceBirthDay.valid : null);


  function firstNameValidCallBack(isValid, value) {
    var data = new Map();
    data.set(DATA_USERGENERALINFO_FIRSTNAME, { value: value, valid: isValid });
    data.set(DATA_USERGENERALINFO_LASTNAME, { value: lastName, valid: lastNameValid });
    data.set(DATA_USERGENERALINFO_BIRTHDAY, { value: birthDay, valid: birthDayValid });
    setFirstNameValid(isValid);
    setFirstname(value)
    props.validatorInformer((lastNameValid && birthDayValid && isValid), data);
  }

  function lastNameValidCallBack(isValid, value) {
    var data = new Map();
    data.set(DATA_USERGENERALINFO_FIRSTNAME, { value: firstName, valid: firstNameValid });
    data.set(DATA_USERGENERALINFO_LASTNAME, { value: value, valid: isValid });
    data.set(DATA_USERGENERALINFO_BIRTHDAY, { value: birthDay, valid: birthDayValid });
    setLastNameValid(isValid);
    setLastName(value);

    props.validatorInformer((firstNameValid && birthDayValid && isValid), data);
  }

  function birthdayValidCallBack(isValid, value) {
    var data = new Map();
    data.set(DATA_USERGENERALINFO_FIRSTNAME, { value: firstName, valid: firstNameValid });
    data.set(DATA_USERGENERALINFO_LASTNAME, { value: lastName, valid: lastNameValid });
    data.set(DATA_USERGENERALINFO_BIRTHDAY, { value: value, valid: isValid });
    setBirthDayValid(isValid);
    setBirthDay(value);

    props.validatorInformer((firstNameValid && lastNameValid && isValid), data);
  }

  try {
    return (
      <div className={style.mainDiv}>
        <div className={style.content}>
          <div className={style.test}>
            <div className={style.test2}>
              <TextField maxLength='100' autofocus='true' values={[firstName, firstNameValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.firstname.hint', defaultMessage: 'Type your first name here' })} label={intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.firstname.label', defaultMessage: 'First Name' })} validator={isTextMin2Char} validatorInformer={firstNameValidCallBack} mandatory='true' showTitleError='true' showCheck='true' validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.firstname.error', defaultMessage: 'Your first name is too short' })} />
            </div>
            <div className={style.test2}>
              <TextField maxLength='100' values={[lastName, lastNameValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.lastname.hint', defaultMessage: 'Type your last name here' })} mandatory='true' label={intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.lastname.label', defaultMessage: 'Last Name' })} validator={isTextMin2Char} showTitleError='true' showCheck='true' validatorInformer={lastNameValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.lastname.error', defaultMessage: 'Your last name is too short' })} />
            </div>
            <div className={style.test2}>
              <BirthDay maxLength='100' values={[birthDay, birthDayValid]} hint={intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.birthday.hint', defaultMessage: 'Ex.: dd/mm/yyyy' })} mandatory='true' label={intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.birthday.label', defaultMessage: 'Birthday' })} validator={isDateStringOver18} showTitleError='true' showCheck='true' validatorInformer={birthdayValidCallBack} validationMsg={intl.formatMessage({ id: 'newcasino.auth.registration.userinfo.birthday.error', defaultMessage: 'Invalid Birthday - 18+' })} />
            </div>
          </div>
        </div>
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default UserGeneralInfo