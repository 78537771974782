/* eslint-disable */
import React, { useEffect, useState } from 'react'
import * as constants from '../../../../constants.js'
import moment from 'moment'
import { displayPage } from '../../../../Utils/routerUtil'
import { useLocation, useNavigate } from 'react-router-dom'
import MemoryList from '../../../../Generic/Inputs/MemoryList'
import Button from '../../../../Generic/Inputs/Button'
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import * as backendService from '../../../../Services/Backend/backendService';
import { setTheme } from '../../../../Utils/themeUtils'
import { displayGenericErrorNotificationIntl } from '../../../../Utils/uiUtil'
import { getImageUrl } from '../../../../Utils/uiUtil';

function SMSList(props) {

  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [allSms, setAllSms] = useState([]);

  const columnsDefinition = [
    { name: intl.formatMessage({ id: 'newcasino.sms.admin.list.col1', defaultMessage: 'Date' }), width: '34%', listColumn: 'smsDateFormated', canSort: true, sortField: 'smsDateMillis' },
    { name: intl.formatMessage({ id: 'newcasino.sms.admin.list.col2', defaultMessage: 'Name' }), width: '33%', listColumn: 'smsName', canSort: true },
    { name: intl.formatMessage({ id: 'newcasino.sms.admin.list.col3', defaultMessage: 'Send' }), width: '33%', listColumn: 'smsStatus', canSort: true }
  ];

  useEffect(() => {
    loadSmsList();
  }, []);

  /*******************************************
   * convertSmsFromBackend
   *******************************************/
  function convertSmsFromBackend(res) {
    var toReturn = [];

    for (let i = 0; i < res.length; i++) {
      var currentSms = res[i];

      toReturn.push(
        {
          'smsName': currentSms.smsName,
          'smsId': currentSms.smsId,
          'smsStatus': (currentSms.smsStatus === 1) ? intl.formatMessage({ id: 'newcasino.sms.admin.list.yes', defaultMessage: 'Yes' }) : intl.formatMessage({ id: 'newcasino.sms.admin.list.no', defaultMessage: 'No' }),
          'smsDate': currentSms.smsDate,
          'smsDateFormated': moment(currentSms.smsDate).locale(intl.locale).format('DD MMMM YYYY'),
          'smsDateMillis': moment(currentSms.smsDate).format('x')
        }

      )
    }
    return toReturn;
  }

  /*******************************************
   * loadSmsList
   *******************************************/
  function loadSmsList() {
    backendService.loadSmsList(successLoadList, failureLoadList, navigate, location.pathname)
  }

  function successLoadList(data) {
    var convertedData = convertSmsFromBackend(data[0])
    setAllSms(convertedData);
    setLoading(false)
  }

  function failureLoadList(data) {
    displayGenericErrorNotificationIntl(intl);
    setLoading(false);
  }

  function displayDetail(item) {
    displayPage(constants.ROUTE_SMS_DETAIL_BASE + item.smsId, navigate)
  }

  try {
    return loading == false ? (
      <div className={style.master_page_container}>
        <div className={style.topMainDiv}>
          <div>
            <div className={style.grid100}>
              <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.sms.admin.list.title', defaultMessage: 'Text Messaging' })}</div>
              <div className={style.case1TopText3}>
                <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
              </div>
              <div className={style.buttonDiv}>
                <Button width='200px' value={intl.formatMessage({ id: 'newcasino.sms.admin.list.add.button', defaultMessage: 'New Message' })} onClick={() => displayPage(constants.ROUTE_SMS_DETAIL_BASE + 'NEW', navigate)} />
              </div>
              <MemoryList list={allSms} nbrItemPerPage={10} columns={columnsDefinition} onRowClick={displayDetail} onRowClickParam='smsId' />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <i className="fa fa-circle-o-notch fa-spin fa-5x fa-fw" style={{ color: '#b9996a' }}></i>
      </div>
    )
  } catch (error) {
    console.log(error);
  }
}
export default SMSList