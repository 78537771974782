/* eslint-disable */
import React, { useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from '../../../../Services/JWTBackend/jwtBackendService';
import { displayGenericErrorNotificationIntl } from '../../../../Utils/uiUtil';
import Button from '../../../../Generic/Inputs/Button';


function UseFreeSpin(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    function doAction() {
        jwtBackendService.useFreeSpins(props.gameId, props.nbrFreeSpins, successAction, failureAction, navigate, location.pathname);
    }

    function successAction(data) {
        props.callback(props.gameId)
    }

    function failureAction(data) {
        displayGenericErrorNotificationIntl(intl);
        props.callback(props.gameId)
    }

    function addFreeSpin(data) {
        if (data) {
            doAction();
        } else {
            props.callback(props.gameId)
        }
    }

    try {

        return (
            <>
                <div className={style.headerTitle}>
                    <FormattedMessage id='newcasino.player.use.freespin.title' defaultMessage='FreeSpins Available' />
                </div>
                <div className={style.headerSubTitle}>
                    <FormattedMessage id='newcasino.player.use.freespin.txt1' defaultMessage='You have' /> <b>{props.nbrFreeSpins}</b> <FormattedMessage id='newcasino.player.use.freespin.txt2' defaultMessage='freespins available. Do you want to use them on this game?' />
                </div>
                <div className={style.grid50}>
                    <div className={style.buttonResponse}><Button value={intl.formatMessage({ id: 'newcasino.player.use.freespin.button.yes' })} onClick={() => addFreeSpin(true)} /></div>
                    <Button value={intl.formatMessage({ id: 'newcasino.player.use.freespin.button.no' })} onClick={() => addFreeSpin(false)} />
                </div>
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default UseFreeSpin;
