import React, { useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import { isNumeric } from '../../../../../../Utils/uiUtil';

import TextField from '../../../../../../Generic/Inputs/TextField';

function PromoCash(props) {

    const intl = useIntl();

    // eslint-disable-next-line no-unused-vars
    const [amount, setAmount] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [isAmountValidMaster, setIsAmountValidMaster] = useState(false);

    function isAmountValid(isValid, value) {
        setIsAmountValidMaster(isValid);

        if (isValid) {
            value = Number(value)
            setAmount(value);
            props.sendDetails({ amount: value });
        } else {
            setAmount(value);
            props.sendDetails(null);
        }
    }

    try {
        return (
            <>
                <div className={style.onefield}>
                    <div className={style.bottom}><TextField maxLength='100' autofocus='true' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.cash.amount', defaultMessage: 'Amount In Euro' })} values={['']} validator={isNumeric} validatorInformer={isAmountValid} mandatory='true' showCheck='true' /></div>
                </div>
            </>
        )
    } catch (error) {
        console.log(error);
    }
}

export default PromoCash;