import React, { useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import * as constants from '../../../../../constants.js'
import { convertTDate, convertToMoney } from '../../../../../Utils/converterUtil';
import MemoryList from '../../../../../Generic/Inputs/MemoryList';
import Button from '../../../../../Generic/Inputs/Button';
import TextField from '../../../../../Generic/Inputs/TextField';
import AddPngPoints from '../AddPngPoints';

function PNGPoints(props) {
    const intl = useIntl();

    const [realMoneyWallet] = useState(props.realMoneyWallet);
    const [pngPointHistoric] = useState(props.pngPointHistoric);
    const [pngDailyHistoric] = useState(props.pngDailyHistoric);


    const definition = [
        { name: intl.formatMessage({ id: 'newcasino.admin.user.pngpoint.hist.col1', defaultMessage: 'Date' }), width: '25%', listColumn: 'saleDate', transformer: convertTDate, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.pngpoint.hist.col2', defaultMessage: 'Type' }), width: '25%', listColumn: 'elementKey', transformer: fromIntl, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.pngpoint.hist.col3', defaultMessage: 'Detail' }), width: '25%', listColumn: 'elementDetail', transformer: detailToText, transformerObj: true, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.pngpoint.hist.col4', defaultMessage: 'Cost' }), width: '25%', listColumn: 'pointPaid', canSort: false }
    ];

    const definition2 = [
        { name: intl.formatMessage({ id: 'newcasino.admin.user.pngpoint.hist.col1', defaultMessage: 'Date' }), width: '33%', listColumn: 'winDate', transformer: convertTDate, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.pngpoint.hist.col2', defaultMessage: 'Type' }), width: '33%', listColumn: 'type', transformer: fromType, canSort: false },
        { name: intl.formatMessage({ id: 'newcasino.admin.user.pngpoint.hist.col3', defaultMessage: 'Detail' }), width: '33%', listColumn: 'detail1', transformer: dailyDetailBuilder, transformerObj: true, canSort: false },
    ];

    function dailyDetailBuilder(value, obj) {
        try {
            if (obj.type === 1) {
                return convertToMoney(Number(obj.detail1), props.coin);
            }
            else if (obj.type === 2) {
                return convertToMoney(Number(obj.detail1), props.coin) + ' - ' + obj.detail2 + 'x';
            }
            else if (obj.type === 3 || obj.type === 5) {
                return obj.detail1;
            }
            else if (obj.type === 4) {
                return obj.detail1;
            }

        } catch (err) {
            console.log(err.message)
        }
        return 'Unknown';
    }

    function detailToText(value, obj) {
        let toReturn = value;
        if (obj.elementType === 1) {
            //CASH
            toReturn = value + ' Euros';
        }
        else if (obj.elementType === 2) {
            //WAGER
            try {
                let data = value.split(",");
                toReturn = data[0] + ' Euros ' + data[1] + 'x';
            } catch (err) { }


        }
        else if (obj.elementType === 3 || obj.elementType === 5) {
            toReturn = value + ' Spins';
            //FREESPIN
        }

        return toReturn;
    }

    function fromIntl(value) {
        return intl.formatMessage({ id: value, defaultMessage: value })
    }

    function fromType(value) {
        if (value === 1) {
            return 'Cash'
        }
        else if (value === 2) {
            return 'Wager'
        }
        else if (value === 3) {
            return 'FreeSpin'
        }
        else if (value === 4) {
            return 'PNG Points'
        }
        else if (value === 5) {
            return 'FreeSpin/Birthday'
        }
        return 'Unknown';
    }

    function addPngPoints() {
        const theMsg = {
            component: <AddPngPoints key={Math.random()} reload={props.reload} coin={props.personalData.coin} walletId={realMoneyWallet.id} userId={props.personalData.id} isStreamer={props.personalData.typeAccount === 5} />,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)
    }

    return (
        <>
            <div className={style.grid3}>
                <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.pngpoints.available', defaultMessage: 'Loyalty Points Available' })} readOnly={true} values={[Math.floor(realMoneyWallet.loyaltyPoints - realMoneyWallet.loyaltyPointsUsed)]} />
                <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.pngpoints.level', defaultMessage: 'Loyalty Level' })} readOnly={true} values={[intl.formatMessage({ id: 'newcasino.loyalty.level.' + props.loyaltyLevel, defaultMessage: '' })]} />
                <div className={style.butSpacer}><Button value={intl.formatMessage({ id: 'newcasino.admin.user.pngpoints.add.button', defaultMessage: '+/- Points' })} onClick={addPngPoints} /></div>
            </div>

            {
                (pngPointHistoric && pngPointHistoric.length > 0) &&
                <>
                    <div className={style.labelTitle}>{intl.formatMessage({ id: 'newcasino.admin.user.pngpoints.purchase.list.title', defaultMessage: 'Purchase details' })}</div>
                    <div><MemoryList key={'HIST'} nbrItemPerPage={10} list={pngPointHistoric} columns={definition} /></div>
                </>
            }
            {
                (pngDailyHistoric && pngDailyHistoric.length > 0) &&
                <>
                    <div className={style.labelTitle}>{intl.formatMessage({ id: 'newcasino.admin.user.pngpoints.daily.list.title', defaultMessage: 'Daily Gift details' })}</div>
                    <div><MemoryList key={'HISTDAILY'} nbrItemPerPage={10} list={pngDailyHistoric} columns={definition2} /></div>
                </>
            }

        </>
    );
}

export default PNGPoints;
