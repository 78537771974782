/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from '../../../Services/JWTBackend/jwtBackendService';
import Button from '../../../Generic/Inputs/Button';
import NonMemoryList from '../../../Generic/Inputs/NonMemoryList';
import { displayGenericErrorNotificationIntl } from '../../../Utils/uiUtil';
import ApiPersistence from '../../../Utils/ApiPersistence';

function NewsLetterManagement(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();
    const [loaded, setLoaded] = useState(false)

    const [refresh, setRefresh] = useState(Math.random())

    const [searchText, setSearchText] = useState(ApiPersistence.getInstance().getNewsletterMemberManagementLastSearch().searchText) //''
    const [currentPage, setCurrentPage] = useState(0)
    const [nbrPage, setNbrPage] = useState(0)
    const [nbrTotalElement, setNbrTotalElement] = useState(0)

    const [sortedColumn, setSortedColumn] = useState(ApiPersistence.getInstance().getNewsletterMemberManagementLastSearch().sortedColumn); //name 1
    const [sortedOrder, setSortedOrder] = useState(ApiPersistence.getInstance().getNewsletterMemberManagementLastSearch().sortedOrder);  //asc 0

    const [users, setUsers] = useState();

    const columnsDefinition = [
        { name: intl.formatMessage({ id: 'newcasino.admin.newsletter.list.col1', defaultMessage: 'Registration Date' }), width: '30%', listColumn: 'registrationDate', canSort: true, sortValue: 1 },
        { name: intl.formatMessage({ id: 'newcasino.admin.newsletter.list.col2', defaultMessage: 'Email' }), width: '60%', listColumn: 'email', canSort: true, sortValue: 2 },
        //{ name: intl.formatMessage({ id: 'newcasino.admin.newsletter.list.col3', defaultMessage: 'Options' }), width: '10%', listColumn: 'id', canSort: true, sortValue: 2 },
        { name: intl.formatMessage({ id: 'newcasino.admin.newsletter.list.col3', defaultMessage: 'Options' }), width: '10%', listColumn: 'id', condition: 'none', icon: 'fa fa-trash', function: deleteUser, canSort: false }
    ];

    useEffect(() => {
        fetchData()
    }, [])

    function deleteUser(data) {
        jwtBackendService.deleteNewsLetterMember(data.id, successDelete, successDelete, navigate, location.pathname);
    }

    function successDelete() {
        fetchData();
    }

    function fetchData() {
        jwtBackendService.getNewsLetterUserListWithFilter(1, 10, searchText, 1, 0, successNewsUserList, failureNewsUserList, navigate, location.pathname);
    }

    function successNewsUserList(data) {
        setUsers(data[0]);
        setCurrentPage(data[1][0].currentPage)
        setNbrPage(data[1][0].nbrPage)
        setNbrTotalElement(data[1][0].nbrElementTotal)
        setRefresh(Math.random())
        setLoaded(true);
    }

    function failureNewsUserList(data) {
        setLoaded(true);
    }

    function sort(columnDef, order) {
        let theOrder = 0;
        let theColumn = columnDef.sortValue
        if (sortedColumn === columnDef.sortValue) {
            theOrder = (sortedOrder === 0) ? 1 : 0;
        }

        setSortedColumn(theColumn);
        setSortedOrder(theOrder);
        ApiPersistence.getInstance().setNewsletterMemberManagementLastSearch(searchText, theColumn, theOrder);
        jwtBackendService.getNewsLetterUserListWithFilter(1, 10, searchText, theColumn, theOrder, successNewsUserList, failureNewsUserList, navigate, location.pathname);

    }

    function exportToCSV() {
        jwtBackendService.exportNewsLetterMembersWithFilter(searchText, sortedColumn, sortedOrder, successExportToCSV, failureExportToCSV, navigate, location.pathname);
    }

    function successExportToCSV(data) {
        //jwtBackendService.getUserListCSVFile(successDownload, failureDownload, navigate, location.pathname);
    }

    function failureExportToCSV(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function changePage(change) {
        let curPage = (change);
        jwtBackendService.getNewsLetterUserListWithFilter(curPage, 10, searchText, sortedColumn, sortedOrder, successNewsUserList, failureNewsUserList, navigate, location.pathname);
    }


    function setNewSearchTxt(event) {
        setSearchText(event.currentTarget.value);
    }

    function userSearch(event) {
        if (event.key === 'Enter') {
            setSearchText(event.currentTarget.value);
            setSortedColumn(1);
            setSortedOrder(0);
            ApiPersistence.getInstance().setNewsletterMemberManagementLastSearch(event.currentTarget.value, 1, 0);
            jwtBackendService.getNewsLetterUserListWithFilter(1, 10, event.currentTarget.value, 1, 0, successNewsUserList, failureNewsUserList, navigate, location.pathname);
        }
    }

    if (loaded) {
        return (
            <div id='contestWinnerPage' className={style.master_page_container}>
                <div className={style.mainTitle}>
                    <FormattedMessage
                        id="newcasino.admin.newsletter.title"
                        defaultMessage="Newsletter Members"
                    />
                </div>

                <div className={style.mainDiv}>
                    <div className={`${style.borderMenu_row}`}>
                        <div className={`${style.borderMenu_searchBar} ${style.borderMenu_search_grid}`}>
                            <div className={`${style.borderMenu_searchBar_input}`}>
                                <input value={searchText} onChange={setNewSearchTxt} maxLength={50} className={`${style.borderMenu_searchBar_input_tag}`} placeholder={intl.formatMessage({ id: 'newcasino.admin.newsletter.search', defaultMessage: 'Search Members' })} onKeyUp={(e) => userSearch(e)} />
                            </div>
                            <div className={`${style.borderMenu_searchBar_search}`}><i className="fa fa-search" aria-hidden="true"></i></div>
                        </div>
                    </div>

                    <div className={style.labelTitle}>{intl.formatMessage({ id: 'newcasino.admin.newsletter.members', defaultMessage: 'Members' })} ({nbrTotalElement})</div>
                    <NonMemoryList key={refresh} noResultTxt={intl.formatMessage({ id: 'newcasino.admin.newsletter.list.noresult', defaultMessage: 'No Result' })} list={users} changePage={changePage} nbrItemPerPage={10} currentPage={currentPage} nbrPage={nbrPage} columns={columnsDefinition} sortedColumn={sortedColumn} sortedOrder={sortedOrder} onSort={sort} onRowClick={() => { }} onRowClickParam='id' />
                    {(nbrPage > 0) &&
                        <Button value={intl.formatMessage({ id: 'newcasino.admin.newsletter.export', defaultMessage: 'Export to CSV' })} onClick={exportToCSV} />
                    }
                </div>
            </div >
        );
    }
}

export default NewsLetterManagement;
