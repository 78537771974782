import React, { useState } from 'react'
import style from './style.module.scss'
import DivClick from 'newui/Generic/Inputs/DivClick';
import ApiPersistence from 'newui/Utils/ApiPersistence';

function Games(props) {
  const [games] = useState(props.gameList)
  const [currency] = useState('USD')

  function setLanguageAndCurrency(url, langFormat) {
    let language = ApiPersistence.getInstance().getLanguage().split('-');
    let lang = (language.length > 1) ? language[0] : 'en';

    if (langFormat && langFormat === 4) {
      if (language.length > 1) {
        lang = lang + '_' + language[1].toUpperCase();
      } else {
        lang = lang + '_US';
      }
    }

    let toReturn = url.replace("{LANG_CODE}", lang);
    toReturn = toReturn.replace("{CUR_CODE}", currency);

    return toReturn;
  }

  function startGame(event, item, index) {

    const popupContainer = document.getElementById('popup-container');
    const popupIframe = document.getElementById('popup-iframe');

    popupIframe.src = setLanguageAndCurrency(item.url, item.langFormat); // Définit l'URL de l'iframe
    popupContainer.style.display = 'block'; // Affiche le popup

    //event.stopPropagation();    
  }

  return (
    <div className={style.centerize}>
      < div className={`${style.GameGrid}`} >
        {games.map((item, index) => (
          <DivClick key={'sampleGame_' + index} className={`${style.GameGridElement_div}`} stopPropagation={true} onClick={(event) => startGame(event, item, index)}>
            <div className={style.centerHor}>
              <div className={`${style.GameGridElement}`} style={{
                backgroundImage: 'URL(' + item.img + ')'
              }}>
                <img className={`${style.GameGridElement_img}`} src={item.img} alt='' />
              </div>
            </div>
          </DivClick>
        ))}
      </div>
    </div>
  );
}

export default (Games)