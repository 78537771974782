import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import * as constants from '../../constants.js'
import { displayPage } from '../../Utils/routerUtil'
import { useNavigate, useLocation } from 'react-router-dom'
import ApiPersistence from '../../Utils/ApiPersistence'
import DivClick from '../../Generic/Inputs/DivClick';
import LanguageMenu from '../../Casino/General/Menus/LanguageMenu'
import { isMobile } from '../../Utils/uiUtil'

function TopStickyMenu(props) {

  const navigate = useNavigate();
  const location = useLocation();

  const menuLanguages = props.languages;
  const logo = props.definition.logo;
  const user = ApiPersistence.getInstance().getUser();

  var menuUser = null;


  if (user.role !== '') {
    menuUser = props.definition.userMenu;
  }

  let langIndex = 0;

  if (menuLanguages) {
    for (let i = 0; i < menuLanguages.length; i++) {
      var currentLang = menuLanguages[i];
      if (currentLang.code === ApiPersistence.getInstance().getLanguage()) {
        langIndex = i;
        break;
      }
    }
  }

  const [selectedLanguage, setSelectedLanguage] = useState(langIndex);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);

  const [currentUserEmail, setCurrentUserEmail] = useState(-1);


  // eslint-disable-next-line
  useEffect(() => {
    if (user && user.email.trim().length > 2 && user.email.trim() !== currentUserEmail) {
      setCurrentUserEmail(user.email);
    }
  })



  function getLanguageMenuStyle() {
    if (showLanguageMenu) {
      return style.flag_dropdown_show;
    } else {
      return style.flag_dropdown;
    }
  }


  function getLanguage() {
    return menuLanguages[selectedLanguage];
  }



  function logoClicked() {
    displayPage(constants.ROUTE_HOME, navigate, location.pathname);
  }



  function changeLanguage(index) {
    toggleLanguageMenu();
    setSelectedLanguage(index);
    const value = menuLanguages[index].code;
    props.setLanguage(index, value);
  }



  function toggleUserMenu(open) {
    setShowLanguageMenu(false);
    if (props.toggleUserMenu) {
      props.toggleUserMenu(open);
    }
  }

  function toggleLanguageMenu() {
    props.toggleUserMenu(false);
    setShowLanguageMenu(!showLanguageMenu);
  }



  try {
    return (
      ApiPersistence.getInstance().isLogged() &&
      <React.Fragment key={Math.random}>
        <div className={`${style.menuTop_sticky}`}>
          <div className={`${style.menuTop_sticky} ${style.menuTop_mainDiv} ${style.menuTop_grid_logged}`}>
            <div className={`${style.menuTop_left}`}>
              <DivClick onClick={() => logoClicked()} className={`${style.menuTop_logo}`}
                style={{
                  backgroundImage: logo.image
                }}
              />
            </div>
            <div className={style.menuTop_right_logged}>
              <div className={`${style.menuTop_userBox}`}>
                <DivClick className={`${style.dot_trans}`} onClick={() => toggleUserMenu()} style={{
                  backgroundImage: menuUser.image
                }}>
                </DivClick>
                {(!(isMobile())) &&
                  <div className={`${style.menuTop_userName}`}>{props.userIdentification}</div>
                }
                {(!(isMobile())) &&
                  <DivClick onClick={() => toggleUserMenu()} className={`${style.menuTop_chevron_user}`} />
                }
                {(!(isMobile())) &&
                  <DivClick className={`${style.menuTop_arrowDown} ${style.menuTop_chevron_user}`} onClick={() => toggleUserMenu()}><i className="fa fa-chevron-down" aria-hidden="true"></i></DivClick>
                }

              </div>

              <div className={`${style.menuTop_grayVertLine}`}></div>
              <div className={`${style.menuTop_langBox}`}>
                <DivClick className={`${style.dot}`} onClick={() => toggleLanguageMenu()} style={{
                  backgroundImage: getLanguage().image
                }}>
                </DivClick>
                <DivClick onClick={() => toggleLanguageMenu()} />
                <DivClick className={`${style.menuTop_arrowDown}`} onClick={() => toggleLanguageMenu()}><i className="fa fa-chevron-down" aria-hidden="true"></i></DivClick>
                <div className={getLanguageMenuStyle()} onMouseLeave={() => toggleLanguageMenu()}>
                  <LanguageMenu className={style.languageMenu} changeLanguage={changeLanguage} languages={menuLanguages} />
                </div>
              </div>
            </div >
          </div >
        </div>
      </React.Fragment>
    );

  } catch (error) {
    console.log(error)
    console.log('ERROR: ' + error.message);
  }
}

export default TopStickyMenu
