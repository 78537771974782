import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import * as jwtBackendService from '../../Services/JWTBackend/jwtBackendService';
import { useIntl } from 'react-intl';
import DivClick from '../../Generic/Inputs/DivClick';
import { displayErrorNotificationIntl, getAmountTxtWithCoin } from '../../Utils/uiUtil';
import StreamerDetailLine from './StreamerDetailLine';

function Commission(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  const currentDate = new Date();
  const PERIOD_1 = 1;
  const PERIOD_2 = 2;
  const PERIOD_3 = 3;
  const PERIOD_4 = 4;
  const PERIOD_5 = 5;
  const PERIOD_6 = 6;
  const PERIOD_7 = 7;
  const PERIOD_8 = 8;
  const PERIOD_9 = 9;
  const PERIOD_10 = 10;
  const PERIOD_11 = 11;
  const PERIOD_12 = 12;


  const [period, setPeriod] = useState((currentDate.getMonth() + 1));
  const [year, setYear] = useState((currentDate.getFullYear()));

  const [lineRefresh, setLineRefresh] = useState(Math.random());
  const [gridDisplayed, setGridDisplayed] = useState(false);
  const [grid, setGrid] = useState(null);

  const [streamerList, setStreamerList] = useState([]);

  const yearList = [];

  for (let i = 2024; i <= currentDate.getFullYear(); i++) {
    yearList.push(i);
  }


  useEffect(() => {
    getData(currentDate.getFullYear(), period, props.user);
  },// eslint-disable-next-line
    [])


  function reload() {
    getData(year, period, props.user)
  }

  function getData(theYear, thePeriod, theUserEmail) {
    props.spinner(true);
    jwtBackendService.getAffiliateCommissions(theYear, thePeriod, theUserEmail, successAffiliateCommissions, failureAffiliateCommissions, navigate, location.pathname);
  }

  function setSelectedPeriod(thePeriod) {
    setPeriod(thePeriod);
    getData(year, thePeriod, props.user);
  }

  function successAffiliateCommissions(data) {
    props.spinner(false);
    setGrid(data.grid);
    setStreamerList(data.data);
    setLineRefresh(Math.random())
  }

  function failureAffiliateCommissions(data) {
    displayErrorNotificationIntl(intl, 'newcasino.aff.commissions.load.fail')
    props.spinner(false);
  }

  function updateYear(theYear) {
    setPeriod(PERIOD_1);
    setYear(theYear);
    getData(theYear, PERIOD_1, props.user);
  }

  function showGrid() {
    setGridDisplayed(!gridDisplayed);
  }

  function showMonth(month) {
    if (year < 2024) {
      return false;
    }


    if (year === 2024 && (month) < 4) {
      return false;
    }

    if (year < currentDate.getFullYear()) {
      return true;
    }

    if (currentDate.getMonth() + 1 >= month) {
      return true;
    }

    return false;
  }

  try {
    return (
      <div className={style.topDiv}>
        <div className={style.title}>
          {intl.formatMessage({ id: 'newcasino.aff.commissions.title', defaultMessage: 'Commissions' })}
          {props.user &&
            '  (' + props.user + ')'}
        </div>

        <div className={style.topSection}>
          <div className={style.menuTopYear}>
            {yearList.map((item, index) => (
              <DivClick key={'YEARS_' + index} onClick={() => updateYear(item)} className={(year === item) ? style.menuTopElemSelected : style.menuTopElem}>
                {item}
              </DivClick>
            ))}
          </div>
          <DivClick className={style.gridIcon} onClick={showGrid}>
            <i className="fa fa-th fa-2x" aria-hidden="true"></i>
          </DivClick>
        </div>
        <div className={style.menuTop}>
          {showMonth(PERIOD_1) &&
            <DivClick onClick={() => setSelectedPeriod(PERIOD_1)} className={(period === PERIOD_1) ? style.menuTopElemSelected : style.menuTopElem}>
              {intl.formatMessage({ id: 'newcasino.aff.commissions.calendar.1', defaultMessage: 'January' })}
            </DivClick>
          }
          {showMonth(PERIOD_2) &&
            <DivClick onClick={() => setSelectedPeriod(PERIOD_2)} className={(period === PERIOD_2) ? style.menuTopElemSelected : style.menuTopElem}>
              {intl.formatMessage({ id: 'newcasino.aff.commissions.calendar.2', defaultMessage: 'February' })}
            </DivClick>
          }
          {showMonth(PERIOD_3) &&
            <DivClick onClick={() => setSelectedPeriod(PERIOD_3)} className={(period === PERIOD_3) ? style.menuTopElemSelected : style.menuTopElem}>
              {intl.formatMessage({ id: 'newcasino.aff.commissions.calendar.3', defaultMessage: 'March' })}
            </DivClick>
          }
          {showMonth(PERIOD_4) &&
            <DivClick onClick={() => setSelectedPeriod(PERIOD_4)} className={(period === PERIOD_4) ? style.menuTopElemSelected : style.menuTopElem}>
              {intl.formatMessage({ id: 'newcasino.aff.commissions.calendar.4', defaultMessage: 'April' })}
            </DivClick>
          }
          {showMonth(PERIOD_5) &&
            <DivClick onClick={() => setSelectedPeriod(PERIOD_5)} className={(period === PERIOD_5) ? style.menuTopElemSelected : style.menuTopElem}>
              {intl.formatMessage({ id: 'newcasino.aff.commissions.calendar.5', defaultMessage: 'Mai' })}
            </DivClick>
          }
          {showMonth(PERIOD_6) &&
            <DivClick onClick={() => setSelectedPeriod(PERIOD_6)} className={(period === PERIOD_6) ? style.menuTopElemSelected : style.menuTopElem}>
              {intl.formatMessage({ id: 'newcasino.aff.commissions.calendar.6', defaultMessage: 'June' })}
            </DivClick>
          }
          {showMonth(PERIOD_7) &&
            <DivClick onClick={() => setSelectedPeriod(PERIOD_7)} className={(period === PERIOD_7) ? style.menuTopElemSelected : style.menuTopElem}>
              {intl.formatMessage({ id: 'newcasino.aff.commissions.calendar.7', defaultMessage: 'July' })}
            </DivClick>
          }
          {showMonth(PERIOD_8) &&
            <DivClick onClick={() => setSelectedPeriod(PERIOD_8)} className={(period === PERIOD_8) ? style.menuTopElemSelected : style.menuTopElem}>
              {intl.formatMessage({ id: 'newcasino.aff.commissions.calendar.8', defaultMessage: 'August' })}
            </DivClick>
          }
          {showMonth(PERIOD_9) &&
            <DivClick onClick={() => setSelectedPeriod(PERIOD_9)} className={(period === PERIOD_9) ? style.menuTopElemSelected : style.menuTopElem}>
              {intl.formatMessage({ id: 'newcasino.aff.commissions.calendar.9', defaultMessage: 'September' })}
            </DivClick>
          }
          {showMonth(PERIOD_10) &&
            <DivClick onClick={() => setSelectedPeriod(PERIOD_10)} className={(period === PERIOD_10) ? style.menuTopElemSelected : style.menuTopElem}>
              {intl.formatMessage({ id: 'newcasino.aff.commissions.calendar.10', defaultMessage: 'October' })}
            </DivClick>
          }
          {showMonth(PERIOD_11) &&
            <DivClick onClick={() => setSelectedPeriod(PERIOD_11)} className={(period === PERIOD_11) ? style.menuTopElemSelected : style.menuTopElem}>
              {intl.formatMessage({ id: 'newcasino.aff.commissions.calendar.11', defaultMessage: 'November' })}
            </DivClick>
          }
          {showMonth(PERIOD_12) &&
            <DivClick onClick={() => setSelectedPeriod(PERIOD_12)} className={(period === PERIOD_12) ? style.menuTopElemSelected : style.menuTopElem}>
              {intl.formatMessage({ id: 'newcasino.aff.commissions.calendar.12', defaultMessage: 'December' })}
            </DivClick>
          }
        </div>


        {gridDisplayed &&
          <div>
            <div className={style.bubble}>
              <div className={style.gridTopLayout}>
                <div className={style.gridTopLayout_title}>{intl.formatMessage({ id: 'newcasino.aff.commissions.grid.title', defaultMessage: 'Vault Payment Percentage based on deposit' })}</div>
                <div className={style.gridTopLayout_mainline}>

                  <div className={style.gridTopLayout_lineall}>
                    {grid.b1Start &&
                      <div className={style.gridTopLayout_line}><div>{getAmountTxtWithCoin(grid.b1Start, grid.currency)} - {grid.b1End !== -1 ? getAmountTxtWithCoin(grid.b1End, grid.currency) : intl.formatMessage({ id: 'newcasino.aff.commissions.grid.unlimited', defaultMessage: 'Unlimited' })}</div><div>: {grid.b1Percent} %</div></div>
                    }
                    {grid.b2Start &&
                      <div className={style.gridTopLayout_line}><div>{getAmountTxtWithCoin(grid.b2Start, grid.currency)} - {grid.b2End !== -1 ? getAmountTxtWithCoin(grid.b2End, grid.currency) : intl.formatMessage({ id: 'newcasino.aff.commissions.grid.unlimited', defaultMessage: 'Unlimited' })}</div><div>: {grid.b2Percent} %</div></div>
                    }
                    {grid.b3Start &&
                      <div className={style.gridTopLayout_line}><div>{getAmountTxtWithCoin(grid.b3Start, grid.currency)} - {grid.b3End !== -1 ? getAmountTxtWithCoin(grid.b3End, grid.currency) : intl.formatMessage({ id: 'newcasino.aff.commissions.grid.unlimited', defaultMessage: 'Unlimited' })}</div><div>: {grid.b3Percent} %</div></div>
                    }
                    {grid.b4Start &&
                      <div className={style.gridTopLayout_line}><div>{getAmountTxtWithCoin(grid.b4Start, grid.currency)} - {grid.b4End !== -1 ? getAmountTxtWithCoin(grid.b4End, grid.currency) : intl.formatMessage({ id: 'newcasino.aff.commissions.grid.unlimited', defaultMessage: 'Unlimited' })}</div><div>: {grid.b4Percent} %</div></div>
                    }
                    {grid.b5Start &&
                      <div className={style.gridTopLayout_line}><div>{getAmountTxtWithCoin(grid.b5Start, grid.currency)} - {grid.b5End !== -1 ? getAmountTxtWithCoin(grid.b5End, grid.currency) : intl.formatMessage({ id: 'newcasino.aff.commissions.grid.unlimited', defaultMessage: 'Unlimited' })}</div><div>: {grid.b5Percent} %</div></div>
                    }
                    {grid.b6Start &&
                      <div className={style.gridTopLayout_line}><div>{getAmountTxtWithCoin(grid.b6Start, grid.currency)} - {grid.b6End !== -1 ? getAmountTxtWithCoin(grid.b6End, grid.currency) : intl.formatMessage({ id: 'newcasino.aff.commissions.grid.unlimited', defaultMessage: 'Unlimited' })}</div><div>: {grid.b6Percent} %</div></div>
                    }
                    {grid.b7Start &&
                      <div className={style.gridTopLayout_line}><div>{getAmountTxtWithCoin(grid.b7Start, grid.currency)} - {grid.b7End !== -1 ? getAmountTxtWithCoin(grid.b7End, grid.currency) : intl.formatMessage({ id: 'newcasino.aff.commissions.grid.unlimited', defaultMessage: 'Unlimited' })}</div><div>: {grid.b7Percent} %</div></div>
                    }
                    {grid.b8Start &&
                      <div className={style.gridTopLayout_line}><div>{getAmountTxtWithCoin(grid.b8Start, grid.currency)} - {grid.b8End !== -1 ? getAmountTxtWithCoin(grid.b8End, grid.currency) : intl.formatMessage({ id: 'newcasino.aff.commissions.grid.unlimited', defaultMessage: 'Unlimited' })}</div><div>: {grid.b8Percent} %</div></div>
                    }
                    {grid.b9Start &&
                      <div className={style.gridTopLayout_line}><div>{getAmountTxtWithCoin(grid.b9Start, grid.currency)} - {grid.b9End !== -1 ? getAmountTxtWithCoin(grid.b9End, grid.currency) : intl.formatMessage({ id: 'newcasino.aff.commissions.grid.unlimited', defaultMessage: 'Unlimited' })}</div><div>: {grid.b9Percent} %</div></div>
                    }
                    {grid.b10Start &&
                      <div className={style.gridTopLayout_line}><div>{getAmountTxtWithCoin(grid.b10Start, grid.currency)} - {grid.b10End !== -1 ? getAmountTxtWithCoin(grid.b10End, grid.currency) : intl.formatMessage({ id: 'newcasino.aff.commissions.grid.unlimited', defaultMessage: 'Unlimited' })}</div><div>: {grid.b10Percent} %</div></div>
                    }
                  </div>
                </div>
                <div className={style.gridTopLayout_max}>{intl.formatMessage({ id: 'newcasino.aff.commissions.grid.maxWin', defaultMessage: 'Max Win Total:' })} {getAmountTxtWithCoin(grid.maxWin, grid.currency)}</div>
                <div className={style.gridTopLayout_currency}>{intl.formatMessage({ id: 'newcasino.aff.commissions.grid.currency', defaultMessage: 'Grid Currency:' })} {grid.currency}</div>
              </div>
            </div>
          </div>
        }
        {streamerList.map((item, index) => (
          <div key={'BUB_' + index} className={style.bubble}>
            <StreamerDetailLine key={'Line_' + lineRefresh} data={item} spinner={props.spinner} reload={reload} showGrid={showGrid} month={period} year={year} />
          </div>
        ))}


      </div>
    );
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}

export default Commission