/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import * as constants from '../../../constants.js'
import * as backendService from '../../../Services/JWTBackend/jwtBackendService';
import { displayGenericErrorNotificationIntl, displayInfoNotification, displayInfoNotificationIntl, isMobile } from '../../../Utils/uiUtil';
import { setTheme } from '../../../Utils/themeUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import DailyGift from './DailyGift';
import Countdown from 'react-countdown';
import ProgressBar from './ProgressBar';
import DivClick from '../../../Generic/Inputs/DivClick';
import PNGShop from './PNGShop';
import ApiPersistence from '../../../Utils/ApiPersistence';
import { convertToMoney } from '../../../Utils/converterUtil';
import LoyaltyHowPopup from '../../../Casino/General/LoyaltyHowPopup';
import GiftConfirmation from './GiftConfirmation';

function Loyalty(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const [loyaltyInfo, setLoyaltyInfo] = useState(null);
    const [levelsInfo, setLevelsInfo] = useState(null);
    const [sectionToShow] = useState(props.sectionToShow);

    const STATUS_DONE = 0;
    const STATUS_CURRENT = 1;
    const STATUS_FUTURE = 2;

    const intl = useIntl()

    const userCoin = ApiPersistence.getInstance().getUser().coin;

    useEffect(() => {
        loadData();
    }, []);

    function loadData() {
        backendService.getLoyaltyInformation(loyaltySuccess, loyaltyFailure, navigate, location.pathname)
    }

    function loyaltySuccess(data) {
        setLoyaltyInfo(data[0][0]);
        setLevelsInfo(data[1]);
    }

    function previousButtonClicked(scrollname) {
        let left = -450;
        if (isMobile()) {
            left = -280;
        }

        document.getElementById(scrollname).scrollBy({
            top: 0,
            left: left,
            behavior: "smooth",
        });
    }

    function nextButtonClicked(scrollname) {
        let left = 450;
        if (isMobile()) {
            left = 280;
        }

        document.getElementById(scrollname).scrollBy({
            top: 0,
            left: left,
            behavior: "smooth",
        });
    }

    function loyaltyFailure(data) {

    }

    function getNextLevelProgression() {
        let toReturn = '0%';
        let pointsTotal = loyaltyInfo.loyaltyPoints;

        for (let i = 0; i < levelsInfo.length; i++) {
            if (levelsInfo[i].active === 1) {
                toReturn = levelsInfo[i].level;
                if ((i + 1) < levelsInfo.length) {
                    let interv = levelsInfo[i + 1].ptsMin - levelsInfo[i].ptsMin;
                    let curr = levelsInfo[i + 1].ptsMin - pointsTotal;
                    toReturn = (100 - Math.ceil((curr / interv) * 100)) + '%';
                }
            }
        }
        return toReturn;
    }

    function getLevel(inText) {
        let toReturn = 1;
        if (inText) {
            toReturn = '-';
        }

        for (let i = 0; i < levelsInfo.length; i++) {
            if (levelsInfo[i].active === 1) {
                if (inText) {
                    toReturn = intl.formatMessage({ id: 'newcasino.loyalty.level.' + levelsInfo[i].level, defaultMessage: '' })
                } else {
                    toReturn = levelsInfo[i].level;
                }
            }
        }
        return toReturn;
    }

    function dailyEnabled() {
        return (loyaltyInfo.loyaltyDailyStatus === 0 || loyaltyInfo.loyaltyDailyStatus === 1 || loyaltyInfo.loyaltyDailyStatus === 3);
    }

    function isKYCApproved() {
        return loyaltyInfo.kycDone;
    }


    function isFirstTime() {
        return (loyaltyInfo.loyaltyDailyStatus === 0);
    }

    function isCloseForTheMonth() {
        return (loyaltyInfo.loyaltyDailyStatus === 2);
    }

    function getStatus(day) {
        let toReturn = STATUS_FUTURE;

        if (!dailyEnabled()) {
            toReturn = STATUS_FUTURE
        }
        else if (((loyaltyInfo.loyaltyConsecutive + 1) === day && loyaltyInfo.canPickDaily) || (loyaltyInfo.loyaltyConsecutive === 7 && day === 1 && loyaltyInfo.canPickDaily)) {
            toReturn = STATUS_CURRENT;
        } else if (((loyaltyInfo.loyaltyConsecutive) >= day) && ((loyaltyInfo.loyaltyConsecutive) < 7)) {
            toReturn = STATUS_DONE
        }

        return toReturn;
    }

    function getPointsLeft() {
        let toReturn = 0;

        if ((loyaltyInfo.loyaltyPoints - loyaltyInfo.loyaltyPointsUsed) > 0) {
            toReturn = Math.floor((loyaltyInfo.loyaltyPoints - loyaltyInfo.loyaltyPointsUsed));
        }
        return toReturn;
    }

    function giftClicked(status) {
        if (status === STATUS_CURRENT) {
            if (!isKYCApproved()) {
                displayInfoNotificationIntl(intl, 'newcasino.loyalty.daily.no.kyc');
            } else {
                backendService.loyaltyClaimDaily(claimLoyaltyDailySuccess, claimLoyaltyDailyFailure, navigate, location.pathname)
            }
        }
    }

    function claimLoyaltyDailySuccess(data) {
        backendService.getLoyaltyInformation(loyaltySuccess, loyaltyFailure, navigate, location.pathname)
        let prizeDetails = data[0][0];
        let prize = data[1][0];

        if (prize.type === 'NONE') {
            showDailyRewardPopup(intl.formatMessage({ id: 'newcasino.loyalty.claim.success.none' }));
        }
        else if (prize.type === 'LOYALTY') {
            showDailyRewardPopup(intl.formatMessage({ id: 'newcasino.loyalty.claim.success.loyalty' }, { QTE: (prizeDetails.loyaltyPointsAdded) }));
        }
        else if (prize.type === 'WAGER') {
            showDailyRewardPopup(intl.formatMessage({ id: 'newcasino.loyalty.claim.success.wager' }, { AMOUNT: convertToMoney(prizeDetails.balance, userCoin), X: (prize.wagerX), MAXWIN: convertToMoney(prizeDetails.maxWin, userCoin) }));
            props.refreshWallets();
        }
        else if (prize.type === 'CASH') {
            showDailyRewardPopup(intl.formatMessage({ id: 'newcasino.loyalty.claim.success.money' }, { AMOUNT: (convertToMoney(prizeDetails.txAmount, userCoin)) }));
            props.refreshWallets();
        }
        else if (prize.type === 'FREESPIN') {
            showDailyRewardPopup(intl.formatMessage({ id: 'newcasino.loyalty.claim.success.freespin' }, { QTE: (prizeDetails.addedFreeSpins) }), 'fslocation.png');
            props.refreshWallets();
        }
    }

    function claimLoyaltyDailyFailure(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    function showDailyRewardPopup(text, img) {
        const theMsg = {
            component: <GiftConfirmation key={Math.random()} image={img} txt={text} closeModal={props.closeModal} />,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)
    }



    function getMonthlyPercentage() {
        let value = Math.floor(loyaltyInfo.loyaltyPointsForNextMonth) / loyaltyInfo.loyaltyPointsNeededForNextMonth;

        if (value > 1) {
            return 1;
        } else if (value <= 0) {
            return 0;
        } else if (value < 0.05) {
            return 0.05;
        }

        return value;
    }
    function openShop(cat) {
        const theMsg = {
            component: <PNGShop key={Math.random()} kycApproved={isKYCApproved()} category={cat} reloadParent={loadData} nbrPoints={getPointsLeft()} level={getLevel(false)} levelTxt={getLevel(true)} refreshWallets={props.refreshWallets} reload={props.reload} closeModal={props.closeModal} />,
            id: Math.random(),
            body: {
                title: constants.STRING_EMPTY,
                message: constants.STRING_EMPTY,
                image: constants.STRING_EMPTY
            },
            callback: props.closeModal,
            showClose: 'false',
            width: '640px' //DESKTOP
        };
        props.displayGenericModal(theMsg)
    }

    function displayHow() {
        props.displayFullScreenModalComponent(<LoyaltyHowPopup closeModal={props.closeModal} />);
    }

    function renderer({ hours, minutes, seconds, completed }) {
        if (completed) {
            // Render a completed state
            backendService.getLoyaltyInformation(loyaltySuccess, loyaltyFailure, navigate, location.pathname)
            return '';
        } else {
            // Render a countdown
            let hoursTxt = hours;
            let minutesTxt = minutes;
            let secondsTxt = seconds;

            if (hours < 10) {
                hoursTxt = '0' + hours;
            }

            if (minutes < 10) {
                minutesTxt = '0' + minutes;
            }

            if (seconds < 10) {
                secondsTxt = '0' + seconds;
            }

            return <span>{hoursTxt}:{minutesTxt}:{secondsTxt}</span>;
        }
    }

    try {
        if (loyaltyInfo && levelsInfo) {
            return (
                <>
                    <div className={style.master_page_container}>
                        <div className={style.topMainDiv}>
                            <div className={style.titleBar}>
                                <div className={style.titleBar_title}>{intl.formatMessage({ id: 'newcasino.loyalty.title', defaultMessage: 'PNG Reward' })}</div>
                                <div className={style.titleBar_titleValueDesktop}>
                                    <div className={style.titleBar_titleValue_t}>{intl.formatMessage({ id: 'newcasino.loyalty.mypoint', defaultMessage: 'My PNG Rewards PTS' })}</div>
                                    <div className={style.titleBar_points}><div className={style.titleBar_titleValue_img}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Points.svg')} alt='' /></div><div className={style.titleBar_titleValue_v}>{getPointsLeft()}</div></div>
                                </div>
                                <div className={style.titleBar_titleValueDesktop}>
                                    <div className={style.titleBar_titleValue_t}>{intl.formatMessage({ id: 'newcasino.loyalty.mylevel', defaultMessage: 'My Level' })}</div>
                                    <div className={style.titleBar_points}><div className={style.titleBar_titleValue_img}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Level.svg')} alt='' /></div><div className={style.titleBar_titleValue_v}>{getLevel(true)}</div></div>
                                </div>
                                <div className={style.horbar} />
                                <div className={style.titleBar_titleValue}>
                                    <DivClick className={style.titleBar_how} onClick={displayHow}><div className={style.titleBar_titleValue_fa}><i className="fa fa-question-circle" aria-hidden="true"></i></div><div className={style.titleBar_titleValue_vDesktop} style={{ cursor: 'pointer' }}>{intl.formatMessage({ id: 'newcasino.loyalty.how', defaultMessage: 'How does it works?' })}</div></DivClick>
                                </div>
                            </div>

                            <div className={style.titleBarMobile}>
                                <div className={style.titleBar_titleValueMobile}>
                                    <div className={style.titleBar_titleValue_t}>{intl.formatMessage({ id: 'newcasino.loyalty.mypoint', defaultMessage: 'My PNG Rewards PTS' })}</div>
                                    <div className={style.titleBar_points}><div className={style.titleBar_titleValue_img}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Points.svg')} alt='' /></div><div className={style.titleBar_titleValue_v}>{getPointsLeft()}</div></div>
                                </div>
                                <div className={style.titleBar_titleValueMobile}>
                                    <div className={style.titleBar_titleValue_t}>{intl.formatMessage({ id: 'newcasino.loyalty.mylevel', defaultMessage: 'My Level' })}</div>
                                    <div className={style.titleBar_points}><div className={style.titleBar_titleValue_img}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Level.svg')} alt='' /></div><div className={style.titleBar_titleValue_v}>{getLevel(true)}</div></div>
                                </div>
                            </div>

                            {sectionToShow === 'DAILY' &&
                                <>
                                    <div className={style.daily_separator_mid}>
                                        <div className={style.daily_separator_group}>
                                            <div className={style.daily_separator}>
                                                <div className={style.daily_separator_1}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Sep-Circles-left.png')} alt='' /></div>
                                                <div className={style.daily_separator_2}>{intl.formatMessage({ id: 'newcasino.loyalty.daily.title', defaultMessage: 'Daily Consecutive Login Reward' })}</div>
                                                <div className={style.daily_separator_3}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Sep-Circles-right.png')} alt='' /></div>
                                            </div>
                                        </div>
                                        <div className={style.separator_mobile}>
                                            <div className={style.separator_mobile1}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_bar.png')} alt='' /></div>
                                            <div className={style.separator_mobile2}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_point.png')} alt='' /></div>
                                        </div>
                                        <div className={style.countermid}>
                                            {(isFirstTime()) &&
                                                <>
                                                    <div className={style.firstTimeLine1}>{intl.formatMessage({ id: 'newcasino.loyalty.daily.first', defaultMessage: 'The first month is open to everybody. You can pickup daily gift starting now by clicking it.' })}</div>
                                                    <div className={style.firstTimeLine2}>{intl.formatMessage({ id: 'newcasino.loyalty.daily.continue', defaultMessage: 'To continue opening daily gift in the next months, you will have to get the minimum' })} <b>{loyaltyInfo.loyaltyPointsNeededForNextMonth}</b> {intl.formatMessage({ id: 'newcasino.loyalty.daily.points', defaultMessage: 'points' })}</div>
                                                </>
                                            }
                                            {(isCloseForTheMonth()) &&
                                                <>
                                                    <div className={style.firstTimeLine1}>{intl.formatMessage({ id: 'newcasino.loyalty.daily.na', defaultMessage: 'Your daily gift reward is not available this month.' })}</div>
                                                    <div className={style.firstTimeLine2}>{intl.formatMessage({ id: 'newcasino.loyalty.daily.minimum', defaultMessage: 'You need to reach the minimum' })} <b>{loyaltyInfo.loyaltyPointsNeededForNextMonth}</b> {intl.formatMessage({ id: 'newcasino.loyalty.daily.enable', defaultMessage: 'PNG points to enable this feature.' })}</div>
                                                </>
                                            }
                                            {(loyaltyInfo.secondsBeforeNextClaim > 0) &&
                                                <div className={style.timer}>{intl.formatMessage({ id: 'newcasino.loyalty.daily.nextin', defaultMessage: 'Next Gift available in:' })} <Countdown date={Date.now() + (loyaltyInfo.secondsBeforeNextClaim * 1000)} renderer={renderer} /></div>
                                            }
                                            {(loyaltyInfo.secondsLeftToClaim > 0) &&
                                                <div className={style.timer}>{intl.formatMessage({ id: 'newcasino.loyalty.daily.timeleft', defaultMessage: 'Time left to Claim:' })} <Countdown date={Date.now() + (loyaltyInfo.secondsLeftToClaim * 1000)} renderer={renderer} /></div>
                                            }
                                        </div>
                                    </div>
                                    <div className={style.daily_gifts}>

                                        <div className={style.daily_gifts_fifty}>
                                            <DailyGift status={getStatus(1)} value={intl.formatMessage({ id: 'newcasino.loyalty.day1', defaultMessage: 'Day 1' })} onClick={giftClicked} />
                                        </div>
                                        <div className={style.daily_gifts_dot}></div>
                                        <div className={style.daily_gifts_fifty}>
                                            <DailyGift status={getStatus(2)} value={intl.formatMessage({ id: 'newcasino.loyalty.day2', defaultMessage: 'Day 2' })} onClick={giftClicked} />
                                        </div>
                                        <div className={style.daily_gifts_dot}></div>

                                        <div className={style.daily_gifts_fifty}>
                                            <DailyGift status={getStatus(3)} value={intl.formatMessage({ id: 'newcasino.loyalty.day3', defaultMessage: 'Day 3' })} onClick={giftClicked} />
                                        </div>
                                        <div className={style.daily_gifts_dot}></div>

                                        <div className={style.daily_gifts_fifty}>
                                            <DailyGift status={getStatus(4)} value={intl.formatMessage({ id: 'newcasino.loyalty.day4', defaultMessage: 'Day 4' })} onClick={giftClicked} />
                                        </div>
                                        <div className={style.daily_gifts_dot}></div>

                                        <div className={style.daily_gifts_fifty}>
                                            <DailyGift status={getStatus(5)} value={intl.formatMessage({ id: 'newcasino.loyalty.day5', defaultMessage: 'Day 5' })} onClick={giftClicked} />
                                        </div>
                                        <div className={style.daily_gifts_dot}></div>

                                        <div className={style.daily_gifts_fifty}>
                                            <DailyGift status={getStatus(6)} value={intl.formatMessage({ id: 'newcasino.loyalty.day6', defaultMessage: 'Day 6' })} onClick={giftClicked} />
                                        </div>
                                        <div className={style.daily_gifts_dot}></div>
                                        <div className={style.daily_gifts_seven}>
                                            <DailyGift status={getStatus(7)} value={intl.formatMessage({ id: 'newcasino.loyalty.day7', defaultMessage: 'Day 7' })} onClick={giftClicked} />
                                        </div>

                                    </div>
                                    <div className={style.monthly_reward}>
                                        <div className={style.monthlyBubbleDesktop}>
                                            <div className={style.monthly_image}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Reward.png')} alt='' /></div>

                                            <div className={style.monthlyBubbleData}>
                                                <div className={style.monthly_bar}>
                                                    <div className={style.monthly_bar_title}>{intl.formatMessage({ id: 'newcasino.loyalty.progression', defaultMessage: 'PNG Points progression to enable next month’s daily rewards' })}</div>
                                                    <div className={style.monthly_bar_progress}><ProgressBar percentage={getMonthlyPercentage()} /></div>
                                                </div>
                                                <div className={style.monthly_ratio}>
                                                    <div className={style.monthly_ratio1}>{((Math.floor(loyaltyInfo.loyaltyPointsForNextMonth)) > loyaltyInfo.loyaltyPointsNeededForNextMonth) ? loyaltyInfo.loyaltyPointsNeededForNextMonth : Math.floor(loyaltyInfo.loyaltyPointsForNextMonth)}</div>
                                                    <div className={style.monthly_ratio2}>/{loyaltyInfo.loyaltyPointsNeededForNextMonth}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={style.monthlyBubbleMobile}>

                                            <div className={style.monthlyBubbleMobile50}>
                                                <div className={style.monthly_image}><img className={style.tropheeImg} src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Reward.png')} alt='' /></div>
                                                <div className={style.monthly_bar_title}>{intl.formatMessage({ id: 'newcasino.loyalty.progression', defaultMessage: 'PNG Points progression to enable next month’s daily rewards' })}</div>
                                            </div>
                                            <div className={style.monthly_bar_progress_wrapper}>
                                                <div className={style.monthly_bar_progress}><ProgressBar percentage={getMonthlyPercentage()} /></div>
                                            </div>
                                            <div className={style.monthly_ratio_wrapper}>

                                                <div className={style.monthly_ratiodiv}>
                                                    <div className={style.monthly_ratio}>
                                                        <div className={style.monthly_ratio1}>{((Math.floor(loyaltyInfo.loyaltyPointsForNextMonth)) > loyaltyInfo.loyaltyPointsNeededForNextMonth) ? loyaltyInfo.loyaltyPointsNeededForNextMonth : Math.floor(loyaltyInfo.loyaltyPointsForNextMonth)}</div>
                                                        <div className={style.monthly_ratio2}>/{loyaltyInfo.loyaltyPointsNeededForNextMonth}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                    {sectionToShow === 'SHOP' &&
                        <div className={style.shop} style={{ backgroundImage: setTheme('url("https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Background.png")') }}>
                            <div className={style.shop_separator_mid}>
                                <div className={style.shop_separator}>
                                    <div className={style.daily_separator_1}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Sep-Circles-left.png')} alt='' /></div>
                                    <div className={style.daily_separator_2}>{intl.formatMessage({ id: 'newcasino.loyalty.shop.title', defaultMessage: 'PNG Point Shop' })}</div>
                                    <div className={style.daily_separator_3}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Sep-Circles-right.png')} alt='' /></div>
                                </div>
                            </div>
                            <div className={style.separator_mobile}>
                                <div className={style.separator_mobile1}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_bar.png')} alt='' /></div>
                                <div className={style.separator_mobile2}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_point.png')} alt='' /></div>
                            </div>
                            <div className={style.shop_elements}>
                                <div className={style.shop_elementsTop}>

                                    <DivClick onClick={() => openShop(3)} className={style.shop_elementl}>
                                        <div className={style.shop_element_img}>
                                            <img className={style.shop_element_img2} src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Free-Spins.png')} alt='' />
                                        </div>
                                        <div className={style.shop_element_wrapper}>
                                            <div className={style.shop_element_title}>{intl.formatMessage({ id: 'newcasino.loyalty.shop.mainpage.title.1', defaultMessage: 'Freespins' })}</div>
                                            <div className={style.shop_element_desc}>{intl.formatMessage({ id: 'newcasino.loyalty.shop.mainpage.txt.1', defaultMessage: 'Yes!!! You can buy freespins with your PNG Points' })}</div>
                                        </div>
                                    </DivClick>
                                    <DivClick onClick={() => openShop(1)} className={style.shop_elementl}>
                                        <div className={style.shop_element_img}>
                                            <img className={style.shop_element_img2} src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Money-01.png')} alt='' />
                                        </div>
                                        <div className={style.shop_element_wrapper}>
                                            <div className={style.shop_element_title}>{intl.formatMessage({ id: 'newcasino.loyalty.shop.mainpage.title.2', defaultMessage: 'Cash' })}</div>
                                            <div className={style.shop_element_desc}>{intl.formatMessage({ id: 'newcasino.loyalty.shop.mainpage.txt.2', defaultMessage: 'You can convert your points to real cash!!!' })}</div>
                                        </div>
                                    </DivClick>
                                    <DivClick onClick={() => openShop(2)} className={style.shop_element}>
                                        <div className={style.shop_element_img}>
                                            <img className={style.shop_element_img2} src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Money-02.png')} alt='' />
                                        </div>
                                        <div className={style.shop_element_wrapper}>
                                            <div className={style.shop_element_title}>{intl.formatMessage({ id: 'newcasino.loyalty.shop.mainpage.title.3', defaultMessage: 'Wagers' })}</div>
                                            <div className={style.shop_element_desc}>{intl.formatMessage({ id: 'newcasino.loyalty.shop.mainpage.txt.3', defaultMessage: 'Wager wallets are also available. The xTime factor decrease at each Loyalty Level.' })}</div>
                                        </div>
                                    </DivClick>
                                </div>
                                <div className={style.buttonShopMid}>
                                    <div className={style.buttonShop} onClick={() => openShop(0)}>
                                        {intl.formatMessage({ id: 'newcasino.loyalty.shop.mainpage.button', defaultMessage: 'Shop Now!' })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {sectionToShow === 'PROGRESS' &&
                        <div className={style.master_page_container_base}>
                            <div className={style.topMainDiv}>
                                <div className={style.daily_separator_mid}>
                                    <div className={style.daily_separator}>
                                        <div className={style.daily_separator_1}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Sep-Circles-left.png')} alt='' /></div>
                                        <div className={style.daily_separator_2}>{intl.formatMessage({ id: 'newcasino.loyalty.level.title', defaultMessage: 'Number Game VIP Level' })}</div>
                                        <div className={style.daily_separator_3}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_Sep-Circles-right.png')} alt='' /></div>
                                    </div>
                                    <div className={style.progression}>{intl.formatMessage({ id: 'newcasino.loyalty.progression.percent', defaultMessage: 'Next Level Progresion: ' })}: <span className={style.progpercent}><b>{getNextLevelProgression()}</b></span></div>
                                </div>
                                <div className={style.separator_mobile}>
                                    <div className={style.separator_mobile1}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_bar.png')} alt='' /></div>
                                    <div className={style.separator_mobile2}><img src={setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/Loyalty/IMG_point.png')} alt='' /></div>
                                </div>
                            </div>
                        </div>
                    }
                    {sectionToShow === 'PROGRESS' &&
                        <div className={style.master_page_container_grid}>
                            <DivClick className={style.chevronLeft} onClick={() => previousButtonClicked('scrollbarLoyalty')}><i className="fa fa-chevron-left" aria-hidden="true"></i></DivClick>
                            <div className={style.topMainDiv}>
                                <div id={'scrollbarLoyalty'} className={`${style.GameSelection_scrollTestContainer} ${style.GameSelection_scrollTestContainer_paddingbottom}`}>
                                    <ul className={`${style.GameSelection_ul}`}>
                                        <div className={style.stars}>
                                            {
                                                levelsInfo.map((itemProvider, indexProvider) => (
                                                    <div key={'star_' + indexProvider} className={style.starElement}>
                                                        <div></div>
                                                        <div>
                                                            <div className={style.star}>
                                                                <div className={style.innerStar} style={{ background: (itemProvider.reached === 1) ? intl.formatMessage({ id: ('newcasino.loyalty.level.' + itemProvider.level + '.color'), defaultMessage: '#FFFFFF' }) + ' 0% 0% no-repeat padding-box' : '', color: (itemProvider.reached === 1) ? '#FFFFFF' : intl.formatMessage({ id: ('newcasino.loyalty.level.' + itemProvider.level + '.color'), defaultMessage: '#FFFFFF' }) }}><i className="fa fa-star" aria-hidden="true"></i></div>
                                                            </div>
                                                        </div>
                                                        <div className={((indexProvider + 1) === levelsInfo.length) ? '' : (levelsInfo[indexProvider + 1].reached === 1) ? style.transitionRight : style.transitionDefault} style={((indexProvider + 1) === levelsInfo.length) ? {} : (levelsInfo[indexProvider + 1].reached === 1) ? { background: 'transparent linear-gradient(90deg, ' + intl.formatMessage({ id: ('newcasino.loyalty.level.' + itemProvider.level + '.color'), defaultMessage: '#FFFFFF' }) + ' 0%, ' + intl.formatMessage({ id: ('newcasino.loyalty.level.' + levelsInfo[indexProvider + 1].level + '.color'), defaultMessage: '#FFFFFF' }) + ' 100%) 0% 0% no-repeat padding-box' } : {}}></div>
                                                    </div>
                                                ))}
                                        </div>
                                        <div>
                                            {
                                                levelsInfo.map((itemProvider, indexProvider) => (
                                                    <li key={'item_' + indexProvider}>
                                                        <div className={`${style.GameSelection_showAllGridElementForceSize}`}>
                                                            <div className={`${style.GameSelection_showAllGridElement_div}`}>
                                                                <div>
                                                                    <div className={`${style.GameSelection_showAllGridElement}`}>
                                                                        <div className={(itemProvider.active === 1) ? style.vipboxActive : style.vipbox}>
                                                                            {(itemProvider.active === 1) ?
                                                                                <div className={style.vipbox_headerActive} style={{ color: '#FFFFFF', background: intl.formatMessage({ id: ('newcasino.loyalty.level.' + itemProvider.level + '.color'), defaultMessage: '#FFFFFF' }) + ' 0% 0% no-repeat padding-box' }}>
                                                                                    {intl.formatMessage({ id: ('newcasino.loyalty.level.' + itemProvider.level), defaultMessage: '' })}
                                                                                </div>
                                                                                :
                                                                                <div className={style.vipbox_header} style={{ color: intl.formatMessage({ id: ('newcasino.loyalty.level.' + itemProvider.level + '.color'), defaultMessage: '#FFFFFF' }) }}>
                                                                                    {intl.formatMessage({ id: ('newcasino.loyalty.level.' + itemProvider.level), defaultMessage: '' })}
                                                                                </div>
                                                                            }
                                                                            <div className={style.vipbox_points}>{itemProvider.ptsMin}</div>
                                                                            <div className={style.vipbox_pointsLabel} style={{ color: intl.formatMessage({ id: ('newcasino.loyalty.level.' + itemProvider.level + '.color'), defaultMessage: '#FFFFFF' }) }}>Points needed</div>
                                                                            <div className={style.vipbox_mainDesc}>
                                                                                {
                                                                                    itemProvider.intlFeatures.split(',').map((feature, featureIndex) => (
                                                                                        <div key={'vip2_' + featureIndex} className={style.vipbox_desc}>
                                                                                            <div className={style.vipbox_desc_1} style={{ color: intl.formatMessage({ id: ('newcasino.loyalty.level.' + itemProvider.level + '.color'), defaultMessage: '#FFFFFF' }) }}><i className="fa fa-star" aria-hidden="true"></i></div>
                                                                                            <div className={style.vipbox_desc_2}>{intl.formatMessage({ id: 'newcasino.loyalty.vip.features.' + feature, defaultMessage: '' })}</div>
                                                                                        </div>))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <DivClick className={style.chevronRight} onClick={() => nextButtonClicked('scrollbarLoyalty')}><i className="fa fa-chevron-right" aria-hidden="true"></i></DivClick>
                        </div>
                    }
                </>
            );
        }
    } catch (err) {
        console.log(err.message)
    }
}
export default Loyalty;
