import React, { useEffect, useState } from 'react'
import GameBoard from './GameBoard';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as backend from '../../../Services/Backend/backendService'
import * as constants from '../../../constants'
import { displayPage } from '../../../Utils/routerUtil';
import { displayErrorNotification } from '../../../Utils/uiUtil';
import { useIntl } from 'react-intl';
import ApiPersistence from '../../../Utils/ApiPersistence';

function PlayGame(props) {
  const intl = useIntl()
  var navigate = useNavigate();
  const location = useLocation();
  var params = useParams();

  const [gameLoad, setGameLoad] = useState(false);
  const [gameUrl, setGameUrl] = useState(null);

  useEffect(() => {
    loadGame(params.id);
  }, // eslint-disable-next-line
    []);

  function loadGame(gameId) {
    if (ApiPersistence.getInstance().isLogged()) {
      ApiPersistence.getInstance().startAlarm();
    }

    backend.getGameData(gameId, ApiPersistence.getInstance().getLanguage(), loadSuccess, loadFailed, navigate, location.pathname)
  }

  function loadSuccess(data) {
    if (data.error !== undefined) {
      loadFailed(data);
      return;
    }

    setGameUrl(data.url);
    setGameLoad(true);
  }

  function loadFailed(data) {
    displayPage(constants.ROUTE_HOME, navigate);
    displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.game.not.available', defaultMessage: 'Game not available' }), intl.formatMessage({ id: 'newcasino.error.game.not.available.msg', defaultMessage: 'This game cannot be access at this time' }));
  }

  try {
    if (gameLoad) {
      return (
        <GameBoard isSimulatedFullScreen={props.isSimulatedFullScreen} gameUrl={gameUrl} />
      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}

export default PlayGame;
