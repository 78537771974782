/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import * as backendService from '../../../Services/Backend/backendService';
import TextField from '../../../Generic/Inputs/TextField';
import { displayErrorNotification, displayInfoNotification, isTextMin2Char } from '../../../Utils/uiUtil';
import Button from '../../../Generic/Inputs/Button';
import Checkbox from '../../../Generic/Inputs/Checkbox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as constants from '../../../constants.js'
import { displayPage } from '../../../Utils/routerUtil';
import ComboBox from '../../../Generic/Inputs/ComboBox';
import TabGroup from '../../../Generic/Inputs/TabGroup';
import Tab from '../../../Generic/Inputs/TabGroup/Tab';
import { setTheme } from '../../../Utils/themeUtils';
import { getImageUrl } from '../../../Utils/uiUtil';
import { uploadBlogImageFile } from '../../../Services/JWTBackend/jwtBackendService';

function EditBlog(props) {
    const TAB_ENGLISH = 'english'
    const TAB_FRENCH = 'french'
    const TAB_PORTUGUESE = 'portuguese'

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [blog, setBlog] = useState();

    const [tabGroupKey, setTabGroupKey] = useState(Math.random());
    const [tabSelected, setTabSelected] = useState(TAB_ENGLISH);
    const [resetTabHeader, setResetTabHeader] = useState(Math.random());

    const [genActive, setGenActive] = useState(false);
    const [genInternalName, setGenInternalName] = useState(constants.STRING_EMPTY);
    const [genInternalNameValid, setGenInternalNameValid] = useState(false);

    const [genTitle, setGenTitle] = useState(constants.STRING_EMPTY);
    const [genTitleValid, setGenTitleValid] = useState(false);
    const [genTitleFr, setGenTitleFr] = useState(constants.STRING_EMPTY);
    const [genTitleFrValid, setGenTitleFrValid] = useState(false);
    const [genTitlePt, setGenTitlePt] = useState(constants.STRING_EMPTY);
    const [genTitlePtValid, setGenTitlePtValid] = useState(false);

    const [genCategory, setGenCategory] = useState(0);
    const [genBody, setGenBody] = useState(constants.STRING_EMPTY);
    const [genBodyValid, setGenBodyValid] = useState(false);
    const [genBodyFr, setGenBodyFr] = useState(constants.STRING_EMPTY);
    const [genBodyFrValid, setGenBodyFrValid] = useState(false);
    const [genBodyPt, setGenBodyPt] = useState(constants.STRING_EMPTY);
    const [genBodyPtValid, setGenBodyPtValid] = useState(false);

    const [forceReload, setForceReload] = useState(Math.random());
    const [genImageUrl, setGenImageUrl] = useState(constants.STRING_EMPTY);


    const isNew = (params.id === undefined || params.id === null || params.id === '-1' || params.id === -1 || params.id === 'NEW')
    const intl = useIntl();

    useEffect(() => {
        if (!isNew) {
            let id = params.id
            backendService.getBlogData(id, successGetData, failureGetData, navigate, location.pathname)
        } else {
            setBlog({
                id: -1,
                internalName: constants.STRING_EMPTY,
                title: { en: constants.STRING_EMPTY, fr: constants.STRING_EMPTY, pt: constants.STRING_EMPTY },
                body: { en: constants.STRING_EMPTY, fr: constants.STRING_EMPTY, pt: constants.STRING_EMPTY },
                category: 0,
                active: false,
            })
        }
    }, []);

    function successGetData(data) {
        if (data.result !== 'OK') {
            failureGetData(data.data);
            return;
        }
        data = data.data;

        setGenInternalName(data.internalName);
        setGenImageUrl(data.imageUrl);
        setGenTitle(data.title.en);
        setGenTitleFr(data.title.fr);
        setGenTitlePt(data.title.pt);
        setGenBody(data.body.en)
        setGenBodyFr(data.body.fr);
        setGenBodyPt(data.body.pt);
        setGenActive(data.active);
        setGenCategory(data.category)
        setBlog(data);
    }


    function isInternalNameValid(isValid, value) {
        setGenInternalNameValid(isValid);
        setGenInternalName(value);
    }

    function isTitleValid(isValid, value) {
        setGenTitleValid(isValid);
        setGenTitle(value);
    }

    function isTitleFrValid(isValid, value) {
        setGenTitleFrValid(isValid);
        setGenTitleFr(value);
    }

    function isTitlePtValid(isValid, value) {
        setGenTitlePtValid(isValid);
        setGenTitlePt(value);
    }

    function isBodyValid(isValid, value) {
        setGenBodyValid(isValid);
        setGenBody(value);
    }

    function isBodyFrValid(isValid, value) {
        setGenBodyFrValid(isValid);
        setGenBodyFr(value);
    }

    function isBodyPtValid(isValid, value) {
        setGenBodyPtValid(isValid);
        setGenBodyPt(value);
    }

    function setIsActive(value) {
        setGenActive(value)
    }

    function failureGetData(data) {
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.fetching.blog', defaultMessage: 'Error trying to get blog information' }));
    }

    function deleteBlog() {
        var clone = JSON.parse(JSON.stringify(blog));
        backendService.updateBlog(true, clone, successDelete, failureDelete, navigate, location.pathname);
    }

    function successDelete() {
        displayPage(constants.ROUTE_INFO_BLOG, navigate)
        displayInfoNotification(intl.formatMessage({ id: 'newcasino.success.title', defaultMessage: 'Success' }), intl.formatMessage({ id: 'newcasino.success.delete.blog', defaultMessage: 'Article deleted' }));
    }

    function failureDelete() {
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.delete.blog', defaultMessage: 'Operation Failed' }));
    }

    function save() {

        var clone = JSON.parse(JSON.stringify(blog));

        clone.active = genActive;
        clone.internalName = genInternalName;
        clone.title.en = genTitle;
        clone.title.fr = genTitleFr;
        clone.title.pt = genTitlePt;
        clone.body.en = genBody;
        clone.body.fr = genBodyFr;
        clone.body.pt = genBodyPt;
        clone.category = genCategory;
        clone.imageUrl = genImageUrl;

        backendService.updateBlog(false, clone, successSave, failureSave, navigate, location.pathname);
    }

    function successSave(data) {
        if (data.result === 'KO') {
            failureSave(data);
        } else {
            displayPage(constants.ROUTE_INFO_BLOG, navigate)
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.success.title', defaultMessage: 'Success' }), intl.formatMessage({ id: 'newcasino.success.save.blog', defaultMessage: 'Article saved' }));
        }

    }

    function failureSave(data) {
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.save.blog', defaultMessage: 'Operation Failed' }));
    }

    function isFormValid() {
        var toReturn = genInternalNameValid && genBodyValid;
        return toReturn;
    }

    function onChangeTab(internalId) {
        setTabSelected(internalId)
        setTabGroupKey(Math.random())
    }

    function handleFileUpload(event) {
        event.preventDefault();

        if (event.dataTransfer && event.dataTransfer.items) {
            if (event.dataTransfer.items.length > 1) {
                return;
            }

            if (event.dataTransfer.items[0].kind === 'file') {
                const file = event.dataTransfer.items[0];
                upload(file.getAsFile())
            }
        } else if (event.target.files) {
            if (event.target.files.length > 1) {
                return;
            }

            const file = event.target.files[0];
            upload(file)
        }

    }

    function upload(theImage) {
        var formData = new FormData()
        var extension = theImage.name.substr(theImage.name.lastIndexOf('.')).toLowerCase();
        formData.append("document", theImage);
        formData.append("extension", extension);
        formData.append("type", theImage.type);

        uploadBlogImageFile(formData, uploadSuccess, uploadFailure)
    }

    function uploadSuccess(data) {
        setGenImageUrl(data.url);
        setForceReload(Math.random());
    }

    function uploadFailure(data) {
    }

    function switchCategory(data) {
        setGenCategory(data);
    }

    const categories = {
        key: 'value',
        value: 'label',
        list: [
            {
                label: intl.formatMessage({ id: 'newcasino.blog.category.general', defaultMessage: 'General' }),
                value: 0
            },
            {
                label: intl.formatMessage({ id: 'newcasino.blog.category.game', defaultMessage: 'Game Reviews' }),
                value: 1
            },
            {
                label: intl.formatMessage({ id: 'newcasino.blog.category.casino', defaultMessage: 'Casino' }),
                value: 2
            },
            {
                label: intl.formatMessage({ id: 'newcasino.blog.category.live', defaultMessage: 'Live Dealers' }),
                value: 3
            },
            {
                label: intl.formatMessage({ id: 'newcasino.blog.category.sport', defaultMessage: 'Sports Betting' }),
                value: 4
            }
        ]
    }

    if (blog) {
        return (
            <>
                <div className={style.master_page_container}>

                    <div className={style.topMainDiv}>
                        <div>
                            <div className={style.grid100}>
                                <div className={style.case1TopText2}>{genInternalName ? genInternalName : intl.formatMessage({ id: 'newcasino.blog.admin.detail.header.title.missing', defaultMessage: '???' })}</div>
                                <div key={forceReload} className={style.case1TopText3}>
                                    <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                </div>
                                <div className={style.grid50_50}>
                                    <div className={style.grid50_50}>
                                        <TextField maxLength='100' validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.blog.admin.detail.internal.name', defaultMessage: 'Internal Name' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isInternalNameValid} values={genInternalName ? [genInternalName] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.blog.admin.detail.internal.name.hint', defaultMessage: 'Type the Internal Name' })} />
                                    </div>
                                    <div />
                                    <div className={style.grid50_50}>
                                        <ComboBox key={Math.random()} label={intl.formatMessage({ id: 'newcasino.blog.category', defaultMessage: 'Choose a category' })} values={[genCategory]} valueList={categories} onChange={switchCategory} />
                                    </div>
                                    <div />
                                    <div className={style.grid50_50}>
                                        <Checkbox validateOnStart={!isNew} marginTop='24px' label={intl.formatMessage({ id: 'newcasino.blog.admin.detail.active', defaultMessage: 'Active' })} checked={genActive} onClick={setIsActive} />
                                    </div>
                                </div>
                                <div className={style.case1TopText4}>
                                    <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                </div>
                                <div className={style.case1TopText5}>
                                    <div>{intl.formatMessage({ id: 'newcasino.blog.admin.detail.image.url', defaultMessage: 'Image' })} (600px x 300px)</div>
                                    {(genImageUrl && genImageUrl.trim().length > 0) &&
                                        <img className={style.blogImage} alt='' src={genImageUrl} />
                                    }
                                    <Button colorStyle='light' width='120px' value={intl.formatMessage({ id: 'newcasino.blog.admin.detail.button.upload', defaultMessage: 'Upoad' })} marginTop='24px' onClick={() => document.getElementById('uploadMyFile').click()} />
                                    <input className={style.fileInput} type="file" id="uploadMyFile" name="myfile" onChange={(e) => handleFileUpload(e)} />
                                </div>
                                <div className={style.case1TopText4}>
                                    <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                </div>


                                <div className={style.tabGroup}>
                                    <TabGroup tabsValidation={[(genBodyValid && genTitleValid), (genBodyFrValid && genTitleFrValid), (genBodyPtValid && genTitlePtValid)]} key={tabGroupKey} resetHeader={resetTabHeader} value={tabSelected} onChangeTab={onChangeTab}>
                                        <Tab label={intl.formatMessage({ id: 'newcasino.blog.admin.detail.tab.english', defaultMessage: 'English' })} internalId={TAB_ENGLISH}>
                                            {/*ENGLISH */}

                                            <div className={style.grid50_50}>
                                                <TextField maxLength='100' key='title_english' validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.blog.admin.detail.title.en', defaultMessage: 'Title English' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isTitleValid} values={genTitle ? [genTitle] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.blog.admin.detail.title.en.hint', defaultMessage: 'Type the title in English' })} />
                                                <div />
                                            </div>
                                            <div className={style.grid100}>
                                                <TextField maxLength='9999' key='body_english' validateOnStart={!isNew} rows='15' type='textarea' mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isBodyValid} values={genBody ? [genBody] : [constants.STRING_EMPTY]} label={intl.formatMessage({ id: 'newcasino.blog.admin.detail.body.en', defaultMessage: 'Content in English' })} hint={intl.formatMessage({ id: 'newcasino.blog.admin.detail.body.en.hint', defaultMessage: 'Type the content in English' })} />
                                            </div>
                                        </Tab>
                                        <Tab label={intl.formatMessage({ id: 'newcasino.blog.admin.detail.tab.french', defaultMessage: 'French' })} internalId={TAB_FRENCH}>
                                            {/*FRENCH */}
                                            <div className={style.grid50_50}>
                                                <TextField maxLength='100' key='title_french' validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.blog.admin.detail.title.fr', defaultMessage: 'Title French' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isTitleFrValid} values={genTitleFr ? [genTitleFr] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.blog.admin.detail.title.fr.hint', defaultMessage: 'Type the title in French' })} />
                                                <div />
                                            </div>
                                            <div className={style.grid100}>
                                                <TextField maxLength='9999' key='body_french' validateOnStart={!isNew} rows='15' type='textarea' mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isBodyFrValid} values={genBodyFr ? [genBodyFr] : [constants.STRING_EMPTY]} label={intl.formatMessage({ id: 'newcasino.blog.admin.detail.body.fr', defaultMessage: 'Content in French' })} hint={intl.formatMessage({ id: 'newcasino.blog.admin.detail.body.fr.hint', defaultMessage: 'Type the content in French' })} />
                                            </div>
                                            {/*END */}
                                        </Tab>
                                        <Tab label={intl.formatMessage({ id: 'newcasino.blog.admin.detail.tab.portuguese', defaultMessage: 'Portuguese' })} internalId={TAB_PORTUGUESE}>
                                            {/*PORTUGUESE */}

                                            <div className={style.grid50_50}>
                                                <TextField maxLength='100' key='title_portuguese' validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.blog.admin.detail.title.pt', defaultMessage: 'Title Portuguese' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isTitlePtValid} values={genTitlePt ? [genTitlePt] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.blog.admin.detail.title.pt.hint', defaultMessage: 'Type the title in Portuguese' })} />
                                                <div />
                                            </div>

                                            <div className={style.grid100}>
                                                <TextField maxLength='9999' key='body_portuguese' validateOnStart={!isNew} rows='15' type='textarea' mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isBodyPtValid} values={genBodyPt ? [genBodyPt] : [constants.STRING_EMPTY]} label={intl.formatMessage({ id: 'newcasino.blog.admin.detail.body.pt', defaultMessage: 'Content in Portuguese' })} hint={intl.formatMessage({ id: 'newcasino.blog.admin.detail.body.pt.hint', defaultMessage: 'Type the content in Portuguese' })} />
                                            </div>
                                            {/*END */}
                                        </Tab>
                                    </TabGroup>
                                </div>
                                <div className={style.grid50_50}>
                                    <div className={style.grid50_50}>
                                        <Button colorStyle='light' active={isFormValid()} width='120px' value={intl.formatMessage({ id: 'newcasino.blog.admin.detail.button.save', defaultMessage: 'Save' })} marginTop='24px' onClick={save} />
                                        {!isNew &&
                                            <Button colorStyle='dark' active={!isNew} width='100px' value={intl.formatMessage({ id: 'newcasino.blog.admin.detail.button.delete', defaultMessage: 'Delete' })} marginTop='24px' onClick={deleteBlog} />
                                        }
                                    </div>
                                    <div />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}
export default EditBlog;