import React from 'react'
import style from './style.module.scss'
import { FormattedMessage } from 'react-intl'
import { getImageUrl } from 'newui/Utils/uiUtil';

function Footer(props) {

  function kgcClicked() {
    window.open('https://certificates.gamingcommission.ca/KGCCertificates.aspx?id=0b29be10-ba25-4522-bfc7-af5dc330dcd8', 'BrandNameCertificates', 'location=0,status=0,scrollbars=1');
  }

  try {
    return (
      <div className={style.background}>
        <div className={style.centerize}>
          <div className={`${style.mainGrid}`}>
            <div>
              <div>
                <img className={`${style.logoImg}`} src={getImageUrl('newui/footer/IMG_Logo-Header.png')} alt='' />
              </div>
            </div>
            <div className={`${style.textCell}`}>
              <div>
                <div className={`${style.textTitle}`}>
                  <FormattedMessage id="newcasino.aff.contactus.title" defaultMessage="Contact us" />
                </div>
                <div className={`${style.textContent}`}>
                  <FormattedMessage id="newcasino.aff.contactus.add1" defaultMessage="" /><br />
                  <FormattedMessage id="newcasino.aff.contactus.add2" defaultMessage="" /><br />
                  <FormattedMessage id="newcasino.aff.contactus.add3" defaultMessage="" />
                </div>
              </div>
              <div className={`${style.horizontalBar} ${style.showOnlyMobile}`} />
            </div>
            <div className={`${style.textCell}`}>
              <div className={`${style.textTitle}`}>
                <FormattedMessage id="newcasino.footer.col2.title" defaultMessage="About us" />
              </div>
              <div className={`${style.textContent}`}>
                <FormattedMessage id="newcasino.footer.col2.text" defaultMessage="We are Numbers Game, deeply inhabited by uncompromising freedom, we believe in free will and in the relevance of everyone's decisions to have their life as they see fit. Discretion and trust are the values that elevate us. place your bets here in cryptocurrencies or with the method of deposit you prefer and enjoy your journey" />
              </div>
            </div>
          </div>
          <div className={`${style.horizontalBar}`} />
          <div className={`${style.logosGrid}`}>
            <div className={style.centerHor}>
              <img className={style.bottomImage} src={getImageUrl('newui/footer/IMG_GameCare.svg')} alt='' />
            </div>
            <div className={style.centerHor}>
              <a className={style.kgcLink} href='#' onClick={kgcClicked}>
                <img className={style.kgcImage} src='https://certificates.gamingcommission.ca/Members/Pages/Certificates/GeneratedCertificates/logo.gif' alt='' />
              </a>
            </div>
            <div className={style.centerHor}>
              <img className={style.bottomImage} src={getImageUrl('newui/footer/IMG_18-Plus.svg')} alt='' />
            </div>
          </div>
          <div className={`${style.copyright}`}>
            <FormattedMessage id="newcasino.footer.copy.line1" defaultMessage="Numbers Games is owned by GAC Group Limited and operated under the Kahnawake Gaming License of GAC Group Limited, a company incorporated in the Isle of Man." />
            <br />
            <FormattedMessage id="newcasino.footer.copy.line2" defaultMessage="© Copyright 2025 Numbers Game. All rights reserved." />
          </div>
        </div>
      </div>
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}

export default Footer