/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import style from './style.module.scss'
import { isMobile } from '../../../../Utils/uiUtil'

function GameBoard(props) {
  const [gameZoneStyle, setGameZoneStyle] = useState(isMobile() ? style.gameZone : style.gameZone);

  const [resizeEventListener, setResizeEventListener] = useState(null);
  //const isSimulatedFullScreen = props.isSimulatedFullScreen ? props.isSimulatedFullScreen : false

  useEffect(() => {
    addEventListener("resize", (event) => checkSize(event));
  }, []);


  useEffect(() => {
    checkSize();
  });

  /*
  useEffect(() => {
    if (resizeEventListener !== null) {
      removeEventListener("resize", resizeEventListener);
      addEventListener("resize", (event) => checkSize(event));
    }

  });
*/



  function checkSize(evt) {

    if (document.getElementById('divGamePlay')) {
      var isSimulatedFull = document.getElementById('isSimulatedFullScreen') ? document.getElementById('isSimulatedFullScreen').value : 'false'



      var windowH = window.innerHeight;

      if (isSimulatedFull === 'false') {
        if (isMobile()) {
          windowH = windowH - 142;
        } else {
          windowH = windowH - 64;
        }
        document.getElementById('divGamePlay').style.height = windowH + 'px'
      } else {
        document.getElementById('divGamePlay').style.height = window.innerHeight + 'px'
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else {
      removeEventListener("resize", (event) => checkSize(event));
    }
  }

  function iFrameLoaded() {
    //console.log('GAME LOADED');
  }

  return (
    <>
      <input type='hidden' id='isSimulatedFullScreen' value={props.isSimulatedFullScreen} />

      <div id='divGamePlay' className={gameZoneStyle}>
        <iframe className={style.boardIframe}
          src={props.gameUrl}
          id="game"
          frameBorder="0"
          onLoad={iFrameLoaded}
          allowFullScreen
        />
      </div>
    </>
  );
  /*
    return (
      <div>
        {loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              alignItems: 'center',
              width: '100%',
              height: '70vh',
            }}
          >
            <i
              className="fa fa-circle-o-notch fa-spin fa-5x fa-fw"
              style={{ color: '#b9996a' }}
            ></i>
          </div>
        )}
        <div>
          <Helmet
            title={intl.formatMessage({
              id: 'casino.iframegame.title',
              defaultMessage: 'CASINO',
            })}
          />
          <div id="GAME" className={style.board}>
            {is_user_login === true ? (
              <div>
                <div dangerouslySetInnerHTML={{ __html: error_html }} />
                <iframe className={style.boardIframe}
                  src={Gameurl}
                  id="game"
                  frameBorder="0"
                  onLoad={Loaded}
                  allowFullScreen
                >
                </iframe>
              </div>
            ) : (
              <Col
                xs="12"
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ backgroundColor: 'rgb(246 246 251 / 15%)', height: '80vh' }}
              >
                <div>
                  <h5 className="text-center">
                    Log in or register to access live casino games.
                  </h5>
                  <div className="login-area text-center">
                    <Link
                      to="/auth/login"
                      className="me-3 btn login-btn"
                      style={{ marginRight: '5px' }}
                    >
                      {' '}
                      Login{' '}
                    </Link>
                    <Link to="/auth/register" className="me-3 btn register-btn">
                      {' '}
                      Register{' '}
                    </Link>
                  </div>
                </div>
              </Col>
            )}

          </div>
        </div>
      </div>
    )
    */
}

export default injectIntl(GameBoard)
