import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../../Generic/Inputs/Button';
import * as constants from '../../../constants';
import * as jwtBackendService from '../../../Services/JWTBackend/jwtBackendService'
import { useLocation, useNavigate } from 'react-router-dom'
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work
import { setTheme } from '../../../Utils/themeUtils'
import { displayGenericErrorNotificationIntl, getImageUrl } from '../../../Utils/uiUtil';
import { displayPage } from '../../../Utils/routerUtil'
import MemoryList from '../../../Generic/Inputs/MemoryList'
import moment from 'moment'
import DivClick from '../../../Generic/Inputs/DivClick';

function Promotions(props) {

    const TAB_PROMOTIONS = 0;
    const TAB_PROMOTIONS_CODE = 1;

    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    const [showedTab, setShowedTab] = useState(TAB_PROMOTIONS);
    const [loading, setLoading] = useState(true);
    const [allPromotions, setAllPromotions] = useState([]);
    const [allPromotionCodes, setAllPromotionCodes] = useState([]);

    const columnsCodeDefinition = [
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col0', defaultMessage: 'In Progress' }), width: '150px', listColumn: 'inProgress', canSort: true, colorTrue: '#0FED0E', colorFalse: '#ED1C24', transformer: booleanFormater },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col1', defaultMessage: 'Promotion Name' }), width: '250px', listColumn: 'name', canSort: true },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col2', defaultMessage: 'Active' }), width: '100px', listColumn: 'active', canSort: true, colorTrue: '#0FED0E', colorFalse: '#ED1C24', transformer: booleanFormater },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col5', defaultMessage: 'Code' }), width: '200px', listColumn: 'code', canSort: true },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col3', defaultMessage: 'Start Date' }), width: '275px', listColumn: 'startDate', canSort: false, transformer: dateFormater },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col4', defaultMessage: 'End Date' }), width: '275px', listColumn: 'endDate', canSort: false, transformer: dateFormater },
    ];

    const columnsDefinition = [
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col0', defaultMessage: 'In Progress' }), width: '150px', listColumn: 'inProgress', canSort: true, colorTrue: '#0FED0E', colorFalse: '#ED1C24', transformer: booleanFormater },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col1', defaultMessage: 'Promotion Name' }), width: '300px', listColumn: 'name', canSort: true },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col2', defaultMessage: 'Active' }), width: '100px', listColumn: 'active', canSort: true, colorTrue: '#0FED0E', colorFalse: '#ED1C24', transformer: booleanFormater },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col3', defaultMessage: 'Start Date' }), width: '300px', listColumn: 'startDate', canSort: false, transformer: dateFormater },
        { name: intl.formatMessage({ id: 'newcasino.promotions.admin.list.col4', defaultMessage: 'End Date' }), width: '300px', listColumn: 'endDate', canSort: false, transformer: dateFormater },
    ];

    useEffect(() => {
        getPromotionsInformation();
    },// eslint-disable-next-line
        [])

    function dateFormater(data) {
        try {
            return moment(data).format('DD MMMM YYYY - HH:mm');
        } catch (err) {
            return '-';
        }
    }

    function booleanFormater(data) {
        if (data === 1) {
            return intl.formatMessage({ id: 'newcasino.general.true', defaultMessage: 'True' })
        }

        return intl.formatMessage({ id: 'newcasino.general.false', defaultMessage: 'False' })
    }


    function displayCodeDetail(item) {
        displayPage(constants.ROUTE_ADMIN_PROMOTION_CODE_DETAIL_BASE + item.id, navigate)
    }

    function displayDetail(item) {
        displayPage(constants.ROUTE_ADMIN_PROMOTION_DETAIL_BASE + item.id, navigate)
    }



    function getPromotionsInformation() {
        jwtBackendService.getPromotionsList(onSuccessPromotionList, onFailurePromotionList, navigate, location.pathname);
    }

    function onSuccessPromotionList(data) {
        try {
            setAllPromotions(data[0][0])
            setAllPromotionCodes(data[1][0])
        } catch (error) {
            console.log(error.message)
        }
        setLoading(false)

    }

    function onFailurePromotionList(data) {
        displayGenericErrorNotificationIntl(intl)
        setLoading(false);
    }

    function newPromotion() {
        displayPage(constants.ROUTE_ADMIN_PROMOTION_NEW, navigate, location.pathname)
    }

    try {

        return loading === false ? (
            <div className={style.master_page_container}>
                <div className={style.topMainDiv}>
                    <div>
                        <div className={style.grid100}>
                            <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.promotions.admin.list.title', defaultMessage: 'Promotions' })}</div>
                            <div className={style.case1TopText3}>
                                <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                            </div>
                            <div className={style.buttonDiv}>
                                <Button value={intl.formatMessage({ id: 'newcasino.promotions.admin.list.add.button', defaultMessage: 'Add New Promotion' })} onClick={newPromotion} />
                            </div>
                            <div className={style.grid100}>
                                <div className={style.case1TopText3}>
                                    <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                </div>
                            </div>
                            <div>
                                <div className={style.tabLine}>
                                    <DivClick className={(showedTab === TAB_PROMOTIONS) ? style.tabSelected : style.tabNotSelected} onClick={() => setShowedTab(TAB_PROMOTIONS)}>
                                        <FormattedMessage id="newcasino.promotions.admin.list.tab.promo" defaultMessage="Promotions" /></DivClick>
                                    <div className={style.pipe} />
                                    <DivClick className={(showedTab === TAB_PROMOTIONS_CODE) ? style.tabSelected : style.tabNotSelected} onClick={() => setShowedTab(TAB_PROMOTIONS_CODE)}>
                                        <FormattedMessage id="newcasino.promotions.admin.list.tab.promocode" defaultMessage="Promotion Codes" />
                                    </DivClick>
                                </div>
                            </div>
                            {showedTab === TAB_PROMOTIONS &&
                                <MemoryList list={allPromotions} nbrItemPerPage={10} columns={columnsDefinition} onRowClick={displayDetail} onRowClickParam='id' />
                            }
                            {showedTab === TAB_PROMOTIONS_CODE &&
                                <MemoryList list={allPromotionCodes} nbrItemPerPage={10} columns={columnsCodeDefinition} onRowClick={displayCodeDetail} onRowClickParam='id' />
                            }
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100vh',
                }}
            >
                <i className="fa fa-circle-o-notch fa-spin fa-5x fa-fw" style={{ color: '#b9996a' }}></i>
            </div>
        )
    } catch (error) {
        console.log(error);
    }
}

export default Promotions;