import ApiPersistence from "../../Utils/ApiPersistence";
import * as rsaService from './crypto/rsaService';
import * as aesService from './crypto/aesService';
import { displayPage } from "../../Utils/routerUtil";
import * as constants from '../../constants.js'
import browserSignature from 'browser-signature';
import { displayInfoNotification, getInterText } from "../../Utils/uiUtil";

/**********************************************************************
 * cleanUp - Called on Logout
 *********************************************************************/
export function cleanUp() {
    rsaService.cleanUp();
    aesService.cleanUp();
    ApiPersistence.getInstance().deleteUser();
}

function displayMsgToUser(title, message) {
    if (!(ApiPersistence.getInstance().isSameAsLastMessage(title, message))) {
        displayInfoNotification(title, message);
        ApiPersistence.getInstance().setLastMessage(title, message)
    }
}

/**********************************************************************
 * manageErrorCode:
 * 401 -> Not Authorized
 * 440 -> Innactivity
 * 503 -> Maintenance
 *********************************************************************/
function manageErrorCode(err, errorCallback, navigate, currentPathname) {
    if (err.statusCode === 401 || err.statusCode === 440) {
        ApiPersistence.getInstance().deleteUser();
        if (err.statusCode === 440) {
            //let msg = 'Your session is expired for inactivity. Your security is our concern, please login again.'
            //let title = 'Session Expired'

            //displayMsgToUser(getInterText('newcasino.session.inactivity.title', title), getInterText('newcasino.session.inactivity', msg));
            //displayPage(constants.ROUTE_HOME, navigate, currentPathname);
            displayPage('EVENT_440', navigate, currentPathname);
        }
        if (err.statusCode === 401) {
            let msg = 'Your don\'t have access to this page. Please login again.'
            let title = 'Error'

            displayMsgToUser(getInterText('newcasino.session.fourOone.title', title), getInterText('newcasino.session.fourOone', msg));
            displayPage('EVENT_LOGOUT', navigate, currentPathname);
        }

    } else if (err.statusCode === 503) {
        let msg = 'The Website is currently in maintenance, It won\'t be long, come back in a few moments'
        let title = 'Upgrade in progress'

        displayMsgToUser(getInterText('newcasino.session.maintenance.title', title), getInterText('newcasino.session.maintenance', msg));
        displayPage(constants.ROUTE_MAINTENANCE, navigate, currentPathname);
    } else {
        errorCallback({ error: 'UNKNOWN_ERROR', data: err.message })
    }
}

/**********************************************************************
 * asyncUploadFile
 *********************************************************************/
export function asyncUploadFile(endPoint, formData, includeJwtToken, useEncryption, successCallback, errorCallback, navigate, currentPathname, otherInfos) {

    var theHeader = {}

    if (includeJwtToken === true) {
        theHeader = {
            'Authorization': rsaService.getStoredToken(),
            'Source': browserSignature()
        }
    }

    asyncInternalPost(theHeader, endPoint, false, formData, useEncryption, successCallback, errorCallback, navigate, currentPathname, otherInfos);
}

/**********************************************************************
 * asyncPost
 *********************************************************************/
export function asyncPost(endPoint, jsonData, includeJwtToken, useEncryption, successCallback, errorCallback, navigate, currentPathname, otherInfos) {
    var theHeader = {
        'Content-type': 'application/json; charset=UTF-8',
        'Source': browserSignature()
    }

    if (includeJwtToken === true) {
        theHeader = {
            'Content-type': 'application/json; charset=UTF-8',
            'Source': browserSignature(),
            'Authorization': rsaService.getStoredToken()
        }
    }

    asyncInternalPost(theHeader, endPoint, true, jsonData, useEncryption, successCallback, errorCallback, navigate, currentPathname, otherInfos);
}

/**********************************************************************
 * asyncInternalPost
 *********************************************************************/
async function asyncInternalPost(theHeader, endPoint, isJson, payload, useEncryption, successCallback, errorCallback, navigate, currentPathname, otherInfos) {

    var theBody = '';

    if (useEncryption === true) {
        var encKey = aesService.getEncryptionKey();

        try {
            var encryptedBody = aesService.encryptJson(payload, encKey);
        } catch (error) {
            console.log('Encryption Error :' + error.message);
        }

        var jsonBody = {
            data: encryptedBody
        }

        theBody = JSON.stringify(jsonBody);
    } else {
        if (isJson) {
            theBody = JSON.stringify(payload);
        } else {
            theBody = payload;
        }

    }

    fetch(endPoint, {
        method: 'POST',
        body: theBody,
        credentials: "same-origin",
        headers: theHeader,
    })
        .then(response => {
            if (!response.ok) {
                var error = new Error('Bad Return Code');
                error.statusCode = response.status;
                throw error;
            }
            return response;
        })
        .then(response => response.json())
        .then(async (enveloppe) => {
            if (enveloppe.status === 'OK') {
                var isLogin = false;
                if (enveloppe?.jwtToken) {
                    isLogin = await rsaService.dealWithReceivedJWToken(enveloppe?.jwtToken, enveloppe?.nbg, otherInfos)
                }
                if (useEncryption === true) {
                    var decryptedBody = {};
                    if (enveloppe.data) {
                        decryptedBody = aesService.decryptJson(enveloppe.data, encKey);
                    }
                    successCallback(decryptedBody);
                } else {
                    if (!isLogin) {
                        var decoded = '';
                        if (enveloppe.data) {
                            decoded = Buffer.from(enveloppe.data, 'base64').toString('utf8')
                            decoded = JSON.parse(decoded);
                        }
                        successCallback(decoded)
                    } else {
                        successCallback({ result: 'OK', freespinavailable: enveloppe.freespinavailable, alertIntervalMin: enveloppe.alertIntervalMin });
                    }
                }
            } else {
                errorCallback({ error: 'REQUEST_FAILED', data: enveloppe.error })
            }
        })
        .catch((err) => {
            manageErrorCode(err, errorCallback, navigate, currentPathname);
        });
}


/**********************************************************************
 * asyncSecurePostDownload
 *********************************************************************/
export function asyncSecurePostDownload(endPoint, data, includeJwtToken, successCallback, errorCallback, navigate, currentPathname) {
    var theHeader = {
        'Content-type': 'application/json; charset=UTF-8',
        'Source': browserSignature()
    }

    if (includeJwtToken === true) {
        theHeader = {
            'Content-type': 'application/json; charset=UTF-8',
            'Source': browserSignature(),
            'Authorization': ApiPersistence.getInstance().get('JWT_TOKEN')
        }
    }

    var encKey = aesService.getEncryptionKey();

    try {
        var encryptedBody = aesService.encryptJson(data, encKey);
    } catch (error) {
        console.log('Encryption Error :' + error.message);
    }

    var jsonBody = {
        data: encryptedBody
    }

    let theBody = JSON.stringify(jsonBody);



    fetch(endPoint, {
        method: 'POST',
        body: theBody,
        credentials: "same-origin",
        headers: theHeader,
    })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                'result.csv',
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            successCallback('download');
        }).catch((err) => {
            manageErrorCode(err, errorCallback, navigate, currentPathname);
        });

}


export function asyncDownload(endPoint, includeJwtToken, successCallback, errorCallback, navigate, currentPathname) {
    var theHeader = {
        'Content-type': 'application/json; charset=UTF-8',
        'Source': browserSignature()
    }

    if (includeJwtToken === true) {
        theHeader = {
            'Content-type': 'application/json; charset=UTF-8',
            'Source': browserSignature(),
            'Authorization': ApiPersistence.getInstance().get('JWT_TOKEN')
        }
    }

    fetch(endPoint, {
        method: 'GET',
        credentials: "same-origin",
        headers: theHeader,
    })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                'result.csv',
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            successCallback('download');
        }).catch((err) => {
            manageErrorCode(err, errorCallback, navigate, currentPathname);
        });

}

/**********************************************************************
 * asyncGet
 *********************************************************************/
export function asyncGet(endPoint, includeJwtToken, useEncryption, successCallback, errorCallback, navigate, currentPathname) {
    var theHeader = {
        'Content-type': 'application/json; charset=UTF-8',
        'Source': browserSignature()
    }

    if (includeJwtToken === true) {
        theHeader = {
            'Content-type': 'application/json; charset=UTF-8',
            'Source': browserSignature(),
            'Authorization': ApiPersistence.getInstance().get('JWT_TOKEN')
        }
    }

    fetch(endPoint, {
        method: 'GET',
        credentials: "same-origin",
        headers: theHeader,
    })
        .then(response => {
            if (!response.ok) {
                var error = new Error('Bad Return Code');
                error.statusCode = response.status;
                throw error;
            }
            return response;
        })
        .then(response => response.json())
        .then(async (enveloppe) => {
            if (enveloppe?.jwtToken) {
                await rsaService.dealWithReceivedJWToken(enveloppe?.jwtToken)
            }
            if (enveloppe.status === 'OK') {
                var toReturn = ''
                if (useEncryption === true) {
                    if (enveloppe.data) {
                        var encKey = aesService.getEncryptionKey();
                        toReturn = aesService.decryptJson(enveloppe.data, encKey);
                    }
                } else {
                    if (enveloppe.data) {
                        toReturn = JSON.parse(Buffer.from(enveloppe.data, 'base64').toString('utf8'));
                    }
                }
                successCallback(toReturn);
            } else {
                errorCallback({ error: 'REQUEST_FAILED', data: enveloppe.error })
            }
        }

        )
        .catch((err) => {
            manageErrorCode(err, errorCallback, navigate, currentPathname);
        });
}

/**********************************************************************
 * asyncGetPlainJson
 *********************************************************************/
export function asyncGetPlainJson(endPoint, successCallback, errorCallback) {
    var theHeader = {
        'Content-type': 'application/json; charset=UTF-8',
    }

    fetch(endPoint, {
        method: 'GET',
        credentials: "same-origin",
        headers: theHeader,
    })
        .then(async (response) => {
            if (response?.jwtToken) {
                await rsaService.dealWithReceivedJWToken(response?.jwtToken)
            }
            if (!response.ok) {
                var error = new Error('Bad Return Code');
                error.statusCode = response.status;
                throw error;
            }
            return response;
        })
        .then(response => response.json())
        .then(enveloppe => {
            successCallback(enveloppe);
        }

        )
        .catch((err) => {
            errorCallback(err.message)
        });
}