/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as jwtBackendService from '../../../../../Services/JWTBackend/jwtBackendService';
import Header from '../../../../../Casino/General/Modal/Header';
import Footer from '../../../../../Casino/General/Modal/Footer';
import TextField from '../../../../../Generic/Inputs/TextField';
import Checkbox from '../../../../../Generic/Inputs/Checkbox';
import { displayGenericErrorNotificationIntl, displayInfoNotification, getAmountTxtWithCoin, isDateString, isDateStringInFuture, listToCSV } from '../../../../../Utils/uiUtil';
import DatePicker from '../../../../../Generic/Inputs/DatePicker';

function AddWager(props) {

    const intl = useIntl()
    const navigate = useNavigate();
    const location = useLocation();

    const [amount, setAmount] = useState('');
    const [x, setX] = useState('');
    const [userList, setUserList] = useState('');
    const [maxWin, setMaxWin] = useState('');
    const [expiration, setExpiration] = useState('');
    const [isStreamerWager, setIsStreamerWager] = useState(props.isStreamer);
    const [isStreamer] = useState(props.isStreamer);

    const isBatch = (props.isBatch === true) ? true : false;

    function setIsStreamerWagerValue() {
        setIsStreamerWager(!isStreamerWager);
    }

    function addWagerAction() {
        let max = maxWin.trim().length > 0 ? maxWin : -1;

        if (isBatch) {
            let theList = listToCSV(userList);
            jwtBackendService.wagerBatch(x, amount, max, expiration, theList, successwagerBatch, failurewagerBatch, navigate, location.pathname);
        } else {
            jwtBackendService.manageBalance(isStreamerWager ? 1 : 0, x, amount, max, isStreamerWager ? '' : expiration, props.userId, successManageBalanceSingleUser, failureManageBalanceSingleUser, navigate, location.pathname);
        }

    }
    function successwagerBatch(data) {
        props.closeModal();
    }

    function failurewagerBatch(data) {
        displayGenericErrorNotificationIntl(intl);
    }


    function successManageBalanceSingleUser(data) {
        let title = '';
        let msg = '';
        displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title' }), intl.formatMessage({ id: 'newcasino.admin.wager.add.success' }, { AMOUNT: (getAmountTxtWithCoin(amount, props.coin)), X: x }));

        props.reload();
        props.closeModal();

    }

    function canProcess() {
        if (isBatch) {

        } else {

        }
    }

    function failureManageBalanceSingleUser(data) {
        displayGenericErrorNotificationIntl(intl);
    }

    try {
        var header = null;
        var footer = null;

        header = <Header title={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.title', defaultMessage: 'Add Wager' })} subTitle={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.subtitle', defaultMessage: 'Provide Wager Information' })} closeModal={props.closeModal} />
        footer = <Footer buttonLeft={{ title: intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.button.cancel', defaultMessage: 'Cancel' }), action: props.closeModal, chevron: 'left' }} buttonRight={{ title: intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.button.add', defaultMessage: 'Add' }), action: addWagerAction, canProcess: true }} />

        return (
            <>
                <div>
                    <div>
                        {header}
                    </div>
                    <div className={style.grid50}>
                        <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.amount.label', defaultMessage: 'Amount' })} hint={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.amount.hint', defaultMessage: 'Amount' })} values={[amount]} onChange={setAmount} type='digit' />
                        <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.x.label', defaultMessage: 'Money Turnover' })} hint={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.x.hint', defaultMessage: 'X Times' })} values={[x]} onChange={setX} type='digit' />
                    </div>
                    <div className={style.grid50}>
                        <TextField label={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.max.label', defaultMessage: 'Maximum Win' })} hint={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.max.hint', defaultMessage: 'Maximum Win' })} values={[maxWin]} onChange={setMaxWin} type='digit' />

                        {!isStreamerWager &&
                            <DatePicker minDate='TODAY' initDate='TODAY' label={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.expiration', defaultMessage: 'Expiration Date' })} maxLength='10' values={[expiration]} hint={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.expiration', defaultMessage: 'Ex.: dd/mm/yyyy' })} onChange={setExpiration} validator={isDateStringInFuture} />
                        }
                        {isStreamerWager && <div className={style.spacer}></div>}
                        <div className={style.grid50spacer}>
                            {isStreamer &&
                                <Checkbox label={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.streamer', defaultMessage: 'Is a streamer wager' })} checked={isStreamerWager} onClick={setIsStreamerWagerValue} />
                            }
                        </div>
                    </div>

                    {isBatch &&
                        <div className={style.grid100}>
                            <TextField type='textarea' onChange={setUserList} hint={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.list.hint', defaultMessage: 'One Email/UserId per line' })} label={intl.formatMessage({ id: 'newcasino.admin.user.popup.wager.list.label', defaultMessage: 'Email/UserId List' })} />
                        </div>
                    }
                    <div>
                        {footer}
                    </div>
                </div >
            </>
        );

    } catch (error) {
        console.log('ERROR: ' + error.message);
        return '';
    }
}

export default AddWager;
