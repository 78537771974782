import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import * as constants from '../../../../constants.js'
import { FormattedMessage, useIntl } from 'react-intl'
import Websocket from "react-websocket";
import CountUp from 'react-countup'
import { canAccess } from '../../../../Utils/authorizationUtils'
import LanguageMenu from '../LanguageMenu'
import { displayPage } from '../../../../Utils/routerUtil'
import { useNavigate, useLocation } from 'react-router-dom'
import ApiPersistence from '../../../../Utils/ApiPersistence'
import { displayErrorNotification, displayErrorNotificationIntl, displayInfoNotification, displayInfoNotificationIntl, getAmountTxt, getCoin } from '../../../../Utils/uiUtil'
import { setUserDetailInChat } from '../../../../Services/ThirdParty/intercom';
import DivClick from '../../../../Generic/Inputs/DivClick';
import * as jwtBackendService from '../../../../Services/JWTBackend/jwtBackendService'

function TopStickyMenu(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  const [gamePlaying, setGamePlaying] = useState(false);
  const [selectedElement, setSelectedElement] = useState(figureOutShowedTab(location.pathname, props.definition, props.definition.defaultElement));

  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState(-1);
  const [wallets, setWallets] = useState([]);
  const user = ApiPersistence.getInstance().getUser();

  const menuElements = props.definition.elements;
  const menuLanguages = props.languages;
  const logo = props.definition.logo;

  var maintenance = props.maintenance;
  var maintenanceAllowLogin = props.maintenanceAllowLogin;
  var menuButtons = props.definition.buttons.public;
  var menuUser = null;
  let isLogged = false;

  if (user.role !== '') {
    isLogged = true;
    menuButtons = props.definition.buttons.logged;
    menuUser = props.definition.userMenu;
  }

  let langIndex = 0;

  if (menuLanguages) {
    for (let i = 0; i < menuLanguages.length; i++) {
      var currentLang = menuLanguages[i];
      if (currentLang.code === ApiPersistence.getInstance().getLanguage()) {
        langIndex = i;
        break;
      }
    }
  }

  const [selectedLanguage, setSelectedLanguage] = useState(langIndex);
  // eslint-disable-next-line
  useEffect(() => {
    if (!isMaintenance()) {
      setTimeout(checkGamePlaying, 1500);
      if (user && user.email.trim().length > 2 && user.email.trim() !== currentUserEmail) {
        updateMoneyBalance();
        setCurrentUserEmail(user.email);
      }
    }
  })


  /**********************************************
   * isMaintenance()
   *********************************************/
  function isMaintenance() {
    var toReturn = maintenance;

    if (maintenance && maintenanceAllowLogin) {
      toReturn = false;
    }

    return toReturn;
  }


  /**********************************************
   * checkGamePlaying()
   *********************************************/
  function checkGamePlaying() {
    if (!gamePlaying && document.getElementById('game') !== null) {
      setGamePlaying(true);
    } else if (gamePlaying && document.getElementById('game') === null) {
      setGamePlaying(false);
    }
  }


  /**********************************************
  * fullscreen()
  *********************************************/
  function fullscreen() {
    try {
      let elem = document.getElementById('game')

      if (elem) {
        if (elem.requestFullScreen) {
          elem.requestFullScreen()
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen()
        } else {
          props.simulateFullScreen();
        }
      }

    } catch (error) {
    }
  }

  /**********************************************
  * figureOutShowedTab()
  *********************************************/
  function figureOutShowedTab(currentRoute, definition, defaultValue) {

    for (let i = 0; i < definition.elements.length; i++) {
      if (definition.elements[i].action === currentRoute) {
        return i;
      }
    }

    return -1;
  }

  /**********************************************
  * updateMoneyBalance()
  *********************************************/
  function updateMoneyBalance() {
    if (isLogged && (!isMaintenance())) {
      jwtBackendService.casino_get_wallet_total(updateMoneyBalanceSuccess, updateMoneyBalanceFailure, navigate, location.pathname)
    }
  }

  /**********************************************
  * getCurrentBalance()
  *********************************************/
  function getCurrentBalance() {
    var toReturn = 0;
    if (wallets && wallets.length > 0) {
      for (let i = 0; i < wallets.length; i++) {
        if (wallets[i].currentWallet === 1) {
          toReturn = wallets[i].balance;
          break;
        }
      }
    }
    return toReturn;
  }

  /**********************************************
  * updateMoneyBalanceSuccess()
  *********************************************/
  function updateMoneyBalanceSuccess(data) {
    setWallets(data);
    props.setWallets(data);
  }

  /**********************************************
  * updateMoneyBalanceFailure()
  *********************************************/
  function updateMoneyBalanceFailure(data) {
    console.log(data, 'updateMoneyBalanceFailure')
  }

  /**********************************************
  * receivedEmit()
  *********************************************/
  function receivedEmit(message) {
    message = JSON.parse(message);

    console.log(message);

    if (message.key === "wallets") {
      updateMoneyBalanceSuccess(message.value);
    } else if (message.key === "popup") {
      if (message.value && message.value.startsWith('EXTRA_MONEY_ON_DEPOSIT:')) {
        displayInfoNotification('Info', intl.formatMessage({ id: 'newcasino.game.popup.EXTRA_MONEY_ON_DEPOSIT' }, { AMOUNT: (getAmountTxt(message.value.split(":")[1])) }));
      }
      else {
        displayInfoNotificationIntl(intl, 'newcasino.game.popup.' + message.value);
      }

    } else if (message.key === "popupError") {
      if (message.value && message.value.startsWith('BET_TOO_HIGH:')) {
        var msg = intl.formatMessage({ id: 'newcasino.game.popup.BET_TOO_HIGH' }, { MAXBET: (getAmountTxt(message.value.split(":")[1])) });
        displayErrorNotification('Error', msg);
      }
      else {
        displayErrorNotificationIntl(intl, 'newcasino.game.popup.' + message.value);
      }

    } else if (message.key === "popupEndGame") {
      if (message.value === 'MAINTENANCE') {
        displayPage(constants.ROUTE_MAINTENANCE, navigate, location.pathname);
      } else {
        displayPage(constants.ROUTE_HOME, navigate, location.pathname);
      }

      displayInfoNotificationIntl(intl, 'newcasino.game.popup.' + message.value);
    } else if (message.key === "info") {
      console.log(message);
    } else {
      console.log('RECEIVED A UNKNOWN EMIT MSG: ' + message.key + ' : ' + message.value);
    }
  }

  /**********************************************
  * getLanguageMenuStyle()
  *********************************************/
  function getLanguageMenuStyle() {
    if (showLanguageMenu) {
      return style.flag_dropdown_show;
    } else {
      return style.flag_dropdown;
    }

  }

  /**********************************************
  * getElementStyle()
  *********************************************/
  function getElementStyle(index) {
    if (index === selectedElement) {
      return [style.menuTop_itemSelected, style.menuTop_itemMargin];
    } else {
      return [style.menuTop_itemNotSelected, style.menuTop_itemNSMargin];
    }
  }

  /**********************************************
  * getLanguage()
  *********************************************/
  function getLanguage() {
    return menuLanguages[selectedLanguage];
  }

  /**********************************************
  * getMenuTopGridStyle()
  *********************************************/
  function getMenuTopGridStyle() {
    if (menuUser != null) {
      return style.menuTop_grid_logged;
    } else {
      return style.menuTop_grid;
    }

  }

  /**********************************************
  * getRightMenuStyle()
  *********************************************/
  function getRightMenuStyle() {
    if (menuUser != null) {
      return style.menuTop_right_logged;
    } else {
      return style.menuTop_right;
    }

  }

  /**********************************************
  * getButtonStyle()
  *********************************************/
  function getButtonStyle(buttonDefinition) {
    var style1 = style.menuTop_button_light;
    var style2 = style.showAlways;

    if (buttonDefinition.style === 'dark') {
      style1 = style.menuTop_button_dark;
    }

    if (buttonDefinition.mobileImage) {
      style2 = style.showOnlyOnDesktop;
    }

    return [style1, style2];
  }

  /**********************************************
  * logoClicked()
  *********************************************/
  function logoClicked() {
    props.resetBorderMenu()
    if (props.isMobile) {
      props.toggleLeftMenu(false)
    }
    displayPage(constants.ROUTE_HOME, navigate, location.pathname);
  }


  /**********************************************
  * changeLanguage()
  *********************************************/
  function changeLanguage(index) {
    toggleLanguageMenu();
    setSelectedLanguage(index);
    const value = menuLanguages[index].code;
    props.setLanguage(index, value);

  }

  /**********************************************
  * buttonClicked()
  *********************************************/
  function buttonClicked(index) {
    if (!isMaintenance()) {
      if (menuButtons[index].action === 'EVENT_LOGIN') {
        props.displayModalLogin();
      }
      else if (menuButtons[index].action === 'EVENT_REGISTER') {
        props.displayModalRegister();
      }
      else if (menuButtons[index].action === 'EVENT_BALANCE') {
        updateMoneyBalance();
      }
      else if (menuButtons[index].action === 'MAXIMIZE') {
        fullscreen();
      }
      else {
        props.resetBorderMenu()
        displayPage(menuButtons[index].action, navigate, location.pathname)
        toggleUserMenu(false);
        if (props.isMobile) {
          props.toggleLeftMenu(false)
        }
      }
    }
  }

  /**********************************************
  * toggleLeftMenu()
  *********************************************/
  function toggleLeftMenu() {
    props.toggleLeftMenu();
  }

  /**********************************************
  * toggleWalletsMenu()
  *********************************************/
  function toggleWalletsMenu(open) {
    setShowLanguageMenu(false);
    if (props.toggleUserMenu) {
      props.toggleUserMenu(false);
    }
    if (props.toggleWalletsMenu) {
      props.toggleWalletsMenu(open);
    }
  }

  /**********************************************
  * toggleUserMenu()
  *********************************************/
  function toggleUserMenu(open) {
    setShowLanguageMenu(false);
    if (props.toggleWalletsMenu) {
      props.toggleWalletsMenu(false);
    }
    if (props.toggleUserMenu) {
      props.toggleUserMenu(open);
    }
  }

  /**********************************************
  * toggleLanguageMenu()
  *********************************************/
  function toggleLanguageMenu() {
    if (props.toggleWalletsMenu) {
      props.toggleWalletsMenu(false);
    }
    if (props.toggleUserMenu) {
      props.toggleUserMenu(false);
    }

    setShowLanguageMenu(!showLanguageMenu);
  }

  /**********************************************
  * changeTab()
  *********************************************/
  function changeTab(index) {
    setSelectedElement(index);
    props.resetBorderMenu()
    displayPage(menuElements[index].action, navigate, location.pathname)
  }

  setUserDetailInChat()
  try {
    if (props.forceHide === false) {
      return (
        <React.Fragment key={Math.random}>
          {(isLogged) &&
            <Websocket url={`wss://${process.env.REACT_APP_WEBSOCKET_URL}/ws/?token=${user.uniqueId}`} onMessage={receivedEmit} />
          }
          <div className={`${style.menuTop_sticky}`}>
            <div className={`${style.menuTop_sticky} ${style.menuTop_mainDiv} ${getMenuTopGridStyle()}`}>
              <div className={`${style.menuTop_left}`}>
                <DivClick className={`${style.menuTop_burgerMenu}`} onClick={toggleLeftMenu}><div className={`${style.menuTop_centerize_burger}`}><i className="fa fa-bars" aria-hidden="true"></i></div></DivClick>
                <div className={`${style.menuTop_grayVertLine}`}></div>
                <DivClick onClick={() => logoClicked()} className={`${style.menuTop_logo}`}
                  style={{
                    backgroundImage: logo.image
                  }}
                />
              </div>
              <div className={`${style.menuTop_center} ${style.menuTop_centerize_elements} ${style.maximize}`}>
                <div className={`${style.menuTop_centerize_elements}`}>
                  {(menuElements.map((item, index) => ((canAccess(user, item)) ? (
                    <DivClick key={'topMenu_' + index} className={getElementStyle(index)[0]} onClick={() => changeTab(index)}>
                      <div className={getElementStyle(index)[1]}>
                        <FormattedMessage
                          id={item.intl}
                          defaultMessage={item.default}
                        />
                      </div>
                    </DivClick>
                  ) : ''))
                  )}
                </div>
              </div>
              <div className={getRightMenuStyle()}>
                <div className={`${style.menuTop_buttonsDiv}`}>

                  {(gamePlaying) &&
                    <DivClick className={`${style.menuTop_centerize_elements_max}`} onClick={() => fullscreen()}>
                      <div className={`${style.menuTop_max}`}>
                        <i title={intl.formatMessage({
                          id: 'newcasino.menutop.maximize',
                          defaultMessage: 'Fullscreen'
                        })}
                          className={'fa fa-window-maximize fa-2x ' + style.maximize} aria-hidden="true"></i>
                      </div>
                    </DivClick>
                  }
                  {(isLogged && wallets && wallets.length > 1) &&
                    <DivClick className={`${style.menuTop_centerize_elements_max}`} onClick={() => toggleWalletsMenu()}>
                      <div className={`${style.menuTop_wallet}`}>
                        <i title={intl.formatMessage({
                          id: 'newcasino.menutop.wallet',
                          defaultMessage: 'Choose your wallet'
                        })}
                          className={'fa fa-money fa-2x ' + style.wallets} aria-hidden="true"></i>
                      </div>
                    </DivClick>
                  }
                  {(menuButtons.map((item, index) => ((canAccess(user, item)) ? (
                    <div key={'topMenu_button_' + index}>
                      <DivClick onClick={() => buttonClicked(index)} className={`${getButtonStyle(item)[0]} ${getButtonStyle(item)[1]}`}>
                        <div className={`${style.menuTop_centerize}`}>
                          {(item.action === 'EVENT_BALANCE') &&
                            <div className={`${style.balanceWidth}`}>
                              <i>
                                <CountUp
                                  delay={0}
                                  preserveValue={true}
                                  end={getCurrentBalance()}
                                  duration={1}
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  suffix={` ${getCoin('signo')}`}
                                >
                                  {({ countUpRef, start }) => (
                                    <span ref={countUpRef} />
                                  )}
                                </CountUp>
                              </i>
                            </div>
                          }
                          {(item.action !== 'EVENT_BALANCE') &&
                            <i>
                              <FormattedMessage id={item.intl} defaultMessage={item.default} />
                            </i>
                          }
                        </div>
                      </DivClick>
                      {item.mobileImage &&
                        <DivClick key={'topMenu_mobile_button_' + index} onClick={() => buttonClicked(index)} className={`${style.showOnlyOnMobile}`}>
                          <div >
                            <div className={`${style.mobile_trans}`} style={{
                              backgroundImage: item.mobileImage
                            }}>
                            </div>
                          </div>
                        </DivClick>
                      }
                    </div>
                  ) : ''))
                  )}
                </div>
                {menuUser &&
                  <div className={`${style.menuTop_userBox}`}>
                    <DivClick className={`${style.dot_trans}`} onClick={() => toggleUserMenu()} style={{
                      backgroundImage: menuUser.image
                    }}>
                    </DivClick>
                    <DivClick onClick={() => toggleUserMenu()} className={`${style.menuTop_chevron_user}`} />
                    <DivClick className={`${style.menuTop_arrowDown} ${style.menuTop_chevron_user}`} onClick={() => toggleUserMenu()}><i className="fa fa-chevron-down" aria-hidden="true"></i></DivClick>
                  </div>
                }
                <div className={`${style.menuTop_grayVertLine}`}></div>
                <div className={`${style.menuTop_langBox}`}>
                  <DivClick className={`${style.dot}`} onClick={() => toggleLanguageMenu()} style={{
                    backgroundImage: getLanguage().image
                  }}>
                  </DivClick>
                  <DivClick onClick={() => toggleLanguageMenu()} />
                  <DivClick className={`${style.menuTop_arrowDown}`} onClick={() => toggleLanguageMenu()}><i className="fa fa-chevron-down" aria-hidden="true"></i></DivClick>
                  <div className={getLanguageMenuStyle()} onMouseLeave={() => toggleLanguageMenu()}>
                    <LanguageMenu changeLanguage={changeLanguage} languages={menuLanguages} />
                  </div>
                </div>
              </div >
            </div >
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <></>
      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
  }
}

export default TopStickyMenu
