import { useEffect } from 'react';
import { useIntl } from 'react-intl'
import { displayErrorNotificationIntl } from '../../../Utils/uiUtil';
import { displayPage } from '../../../Utils/routerUtil';
import * as constants from '../../../constants.js'
import { useLocation, useNavigate } from 'react-router-dom';


function System404(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl()

    useEffect(() => {
        displayErrorNotificationIntl(intl, 'newcasino.error.404');
        displayPage(constants.ROUTE_HOME, navigate, location.pathname)
    });
}


export default System404;
