import React, { useState } from 'react';
import style from './style.module.scss';
import { useIntl } from 'react-intl';
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work

import { isNumeric } from '../../../../../../Utils/uiUtil';

import TextField from '../../../../../../Generic/Inputs/TextField';
import DatePicker from '../../../../../../Generic/Inputs/DatePicker';

function PromoWager(props) {

    const intl = useIntl();
    const [amount, setAmount] = useState(null);

    const [wagerX, setWagerX] = useState(null);
    const [wagerMaxWin, setWagerMaxWin] = useState(null);
    const [wagerExpirationDate, setWagerExpirationDate] = useState('');
    const [wagerExpirationTime, setWagerExpirationTime] = useState('');

    const [isAmountValidMaster, setIsAmountValidMaster] = useState(false);
    const [isWagerXTimesValidMaster, setIsWagerXTimesValidMaster] = useState(false);
    const [isWagerMaxWinValidMaster, setIsWagerMaxWinValidMaster] = useState(false);

    const [isWagerExpirationDateValidMaster, setIsWagerExpirationStartDateValidMaster] = useState(false);
    const [isWagerExpirationTimeValidMaster, setIsWagerExpirationStartTimeValidMaster] = useState(false);

    function isAmountValid(isValid, value) {
        setIsAmountValidMaster(isValid);

        if (isValid) {
            value = Number(value)
            setAmount(value);
        } else {
            setAmount(value);
        }

        if (isValid && isWagerXTimesValidMaster && isWagerMaxWinValidMaster && isWagerExpirationDateValidMaster && isWagerExpirationTimeValidMaster) {
            props.sendDetails({ amount: value, expirationDate: wagerExpirationDate, expirationTime: wagerExpirationTime, multiplicator: wagerX, maxwin: wagerMaxWin });
        } else {
            props.sendDetails(null);
        }
    }

    function isXTimesValid(isValid, value) {
        setIsWagerXTimesValidMaster(isValid);

        if (isValid) {
            value = Number(value)
            setWagerX(value);
        } else {
            setWagerX(value);
        }


        if (isValid && isAmountValidMaster && isWagerMaxWinValidMaster && isWagerExpirationDateValidMaster && isWagerExpirationTimeValidMaster) {
            props.sendDetails({ amount: amount, expirationDate: wagerExpirationDate, expirationTime: wagerExpirationTime, multiplicator: value, maxwin: wagerMaxWin });
        } else {
            props.sendDetails(null);
        }

    }

    function isMaxWinValid(isValid, value) {
        setIsWagerMaxWinValidMaster(isValid);

        if (isValid) {
            value = Number(value)
            setWagerMaxWin(value);
        } else {
            setWagerMaxWin(value);
        }

        if (isValid && isAmountValidMaster && isWagerXTimesValidMaster && isWagerExpirationDateValidMaster && isWagerExpirationTimeValidMaster) {
            props.sendDetails({ amount: amount, expirationDate: wagerExpirationDate, expirationTime: wagerExpirationTime, multiplicator: wagerX, maxwin: value });
        } else {
            props.sendDetails(null);
        }
    }

    function updateWagerExpirationDateTime(dateValue, timeValue) {
        setIsWagerExpirationStartDateValidMaster(true);
        setWagerExpirationDate(dateValue);
        setIsWagerExpirationStartTimeValidMaster(true);
        setWagerExpirationTime(timeValue);

        if (isAmountValidMaster && isWagerMaxWinValidMaster && isWagerXTimesValidMaster) {
            props.sendDetails({ amount: amount, expirationDate: dateValue, expirationTime: timeValue, multiplicator: wagerX, maxwin: wagerMaxWin });
        } else {
            props.sendDetails(null);
        }
    }

    try {

        return (
            <>
                <div className={style.threefields}>
                    <div className={style.bottom}><TextField maxLength='100' autofocus='true' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager.amount', defaultMessage: 'Amount In Euro' })} values={['']} validator={isNumeric} validatorInformer={isAmountValid} mandatory='true' showCheck='true' /></div>
                    <div className={style.bottom}><TextField maxLength='100' autofocus='false' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager.xtime', defaultMessage: 'X times' })} values={['']} validator={isNumeric} validatorInformer={isXTimesValid} mandatory='true' showCheck='true' /></div>
                    <div className={style.bottom}><TextField maxLength='100' autofocus='false' type='digit' hint='' label={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager.maxwin', defaultMessage: 'Max Win' })} values={['']} validator={isNumeric} validatorInformer={isMaxWinValid} mandatory='true' showCheck='true' /></div>
                </div>
                <div className={style.lineDates}>
                    <div className={style.bottom}><DatePicker minDate='TODAY' initDate='TODAY' startTimeLabel={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager.expiration.time', defaultMessage: 'Expiration Time' })} onChange={updateWagerExpirationDateTime} startDateLabel={intl.formatMessage({ id: 'newcasino.promotions.admin.prom.new.gift.wager.expiration.date', defaultMessage: 'Expiration Date' })} /></div>
                </div>
            </>

        )
    } catch (error) {
        console.log(error);
    }
}


export default PromoWager;
